import axios from './axios';
import { db } from '../firebase';
import { identity } from '../helpers';
import * as Consts from '../consts';

import * as Types from 'types';

export function getGlobalPermissions(): Promise<Types.Permission[]> {
  return db
    .collection('permissions')
    .where('type', 'in', [Consts.PermissionType.organization, Consts.PermissionType.both])
    .get()
    .then(reference => {
      return reference
        .docs.map(doc => doc.data())
    })
    .then(data => {
      return identity<Types.Permission[]>(data);
    })
}

export function getTeamPermissions(): Promise<Types.Permission[]> {
  return db
    .collection('permissions')
    .where('type', 'in', [Consts.PermissionType.team, Consts.PermissionType.both])
    .get()
    .then(reference => {
      return reference
        .docs.map(doc => doc.data())
    })
    .then(data => {
      return identity<Types.Permission[]>(data);
    })
}
