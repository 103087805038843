import React from 'react';


const PrivacyPolicy: React.FC = () => {

  return (
    <div className="privacy-policy">
      <h3>Privacy Policy</h3>
      <small>
        BrandSocial values your privacy. This Privacy Policy covers our commitment to protect your privacy and to disclose our information and privacy practices for https://brandsocial.com and its services. We reserve the right to change our Privacy Policy and will notify you of such changes via email or our website. If you have questions or concerns regarding this Privacy Policy, you may email us at <a href="mailto:support@brandsocial.com">support@brandsocial.com</a>.
        <br/>
        <br/>

        <h1>Information We Collect</h1>
        <br/>
        BrandSocial collects limited personal information from customers who register with our services. When customers register with our services, we require a username, password, and email address. We use the customer registration information to authenticate users and provide access to https://www.brandsocial.com. We also use the email addresses used in the registration information to communicate with our users. From time to time, BrandSocial may contact you via email to notify you of changes to its services, maintenance notifications, information about BrandSocial and promotional materials from BrandSocial. BrandSocial will be permitted to display an active customer's corporate logo on its website for the purpose of promoting both the customer and BrandSocial.
        <br/>
        <br/>

        BrandSocial collects and logs aggregate user statistics and website traffic within BrandSocial. Such information includes website traffic statistics, date and time of visits, browser type used to access the service, frequency of visits and to which pages, etc. We use this information to improve the services delivered to our customers, to track and diagnose performance problems and administer the website.
        <br/>
        <br/>

        BrandSocial may disclose aggregated user statistics (for example, 50% of BrandSocial customers use the service daily) in order to describe our services to prospective partners, advertisers, affiliates and other third parties for lawful purposes. BrandSocial will never disclose such data on an individual or identifiable basis to third parties except when we must comply with laws that require such disclosure to law enforcement authorities or other government third party officials such as subpoenas.
        <br/>
        <br/>

        You may deactivate your BrandSocial account and delete your personally identifiable information at any time by contacting us here, clearly indicating that you wish to deactivate and delete such information.
        <br/>
        <br/>

        In accordance with the Federal Children's Online Privacy Protection Act of 1998 (COPPA) BrandSocial does not intentionally collect personally identifiable information from nor solicit children under the age of thirteen (13) years of age.
        <br/>
        <br/>

        Other than as disclosed in this Privacy Policy, at no time will BrandSocial share, rent or sell your identifiable personal information to any third parties without your express consent.
        <br/>
        <br/>

        <h1>Anti-Tracking Practices</h1>
        <br/>

        <h1>Cookies</h1>
        <br/>
        BrandSocial uses cookies to store and track information about your usage of our services. Cookies are encrypted and unidentifiable bits of information sent to your browser from a web server that is stored on your computer's hard drive. Cookies are set each time you register, or log in or log out of BrandSocial. Cookies may be used to store your username and password so that you don't have to re-enter it each time you visit https://www.brandsocial.com. We may also use cookies to gather aggregated information about user habits on our website and services. For example, we may use cookies to estimate the number of concurrent users, estimate the total number of users, measure traffic statistics and to better understand how our users are using our services. We may share this data, in its aggregate form, with advertisers, affiliates and partners who may advertise on our website and services.
        <br/>
        <br/>

        <h1>Passwords</h1>
        <br/>
        Your BrandSocial account information, username, password and customer profile are password-protected so that you have secure access to entering and editing personal information. It is the user's responsibility to protect the security of their password. Access to BrandSocial services are protected by a unique username and password that is known by you only. BrandSocial has designed internal security processes that encrypt customer passwords to protect it from being divulged or accessed by anyone other than you. Neither BrandSocial employees nor any of its contractors can obtain or access your password. BrandSocial employees or nor any of its contractors will ask you for your password via mail, email or telephone nor any other unsolicited manner.
        <br/>
        <br/>

        <h1>Third Party Links</h1>
        <br/>
        When customers and users access and use BrandSocial, there may be links to websites and services operated by third parties. BrandSocial makes no representations or warranties about the policies of third party websites that are linked to BrandSocial or any of its services. BrandSocial recommends that you read the privacy policies posted by those third party websites.
        <br/>
        <br/>

        <h1>Security</h1>
        <br/>
        BrandSocial stores its servers in a highly secure server environment with 24 x 7 monitoring, surveillance and support to prevent unauthorized access and data security. Advanced security measures including firewalls, security guards and surveillance are taken to ensure the continued service and protection of your data from natural disaster, intruders and disruptive events.
        <br/>
        <br/>

        We cannot guarantee the security of your data while it is being transmitted over the Internet and through servers that are out of our control. We strive to protect your personal information but BrandSocial cannot ensure or warrant the security of any information you transmit to our website or services. Any data transmissions you make over the internet are done so at your own risk. Once we receive the data transmission, we make our best efforts to ensure its security and privacy on our systems.
        <br/>
        <br/>

        <h1>General Data Protection Regulation (GDPR)</h1>
        <br/>
        In accordance with the European Union General Data Protection Regulation (GDPR) BrandSocial does not intentionally collect personally identifiable information from nor solicit children under the age of sixteen (16) years of age.
        <br/>
        <br/>

        <h1>Further Questions</h1>
        <br/>
        If you have further questions about our Privacy Policy, email us at <a href="mailto:support@brandsocial.com">support@brandsocial.com</a>.
      </small>
  </div>
  );
}

export default PrivacyPolicy;
