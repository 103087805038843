import React, { useEffect, useState } from 'react';
import api from '../api';

import * as Types from 'types';
import DraftPost from './DraftPost';
import PostAssignmentScroller from './PostAssignmentScroller';
import TabBar from './TabBar';
import Button from './Button';
import NewPostButton from './NewPostButton';


const DraftsPage: React.FC = () => {
  const [posts, setPosts] = useState<Types.JoinedPostAssignment[]>([]);

  useEffect(() => {
    api
      .postAssignments
      .getDrafts()
      .then(posts => {
        setPosts(posts)
      })
      .catch(err => console.log(err))
  }, [])

  return (
    <div className="feed_page_section drafts_page">
      <TabBar
        title="Drafts"
        explainer="These are drafts of posts that you have previously saved."
        button={
          <NewPostButton />
        }
      > 
      </TabBar>
      <PostAssignmentScroller 
        load={api.postAssignments.getDrafts}
        posts={posts}
        setPosts={setPosts}
        render={
          (post: Types.JoinedPostAssignment, key: string) => (
          <DraftPost 
            post={post} 
            key={post.id}
            removePost={(post: Types.JoinedPostAssignment) => setPosts(posts.filter(x => x.id !== post.id))}
          />
          )
        }
      />
    </div>
  );
}

export default DraftsPage;
