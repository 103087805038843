import axios from './axios';
import { authorizedHeader } from '../helpers';
import { db } from '../firebase';
import { identity } from '../helpers';
import { getNetworkById } from './networks';
import { getUser } from './users';

import * as Types from 'types';

export function getProfiles(): Promise<Types.Profile[]> {
  return getUser()
    .then(user => {
    return db
      .collection('profiles')
      .where('userId', '==', user.id)
      .where('deleted', '==', false)
      .get()
      .then(reference => {
        return reference
          .docs.map(doc => doc.data());
      })
      .then(data => {
        return identity<Types.Profile[]>(data);
      })
  })
}

export function getProfilesForTeam(teamId: string): Promise<Types.Profile[]> {
  return db
    .collection('profiles')
    .where('teamId', '==', teamId)
    .where('deleted', '==', false)
    .get()
    .then(reference => {
      return reference
        .docs.map(doc => doc.data());
    })
    .then(data => {
      return identity<Types.Profile[]>(data);
  })
}

export function getProfileById (profileId: string): Promise<Types.JoinedProfile> {
  let profile: Partial<Types.JoinedProfile>;
  return db
    .collection('profiles')
    .where('id', '==', profileId)
    .where('deleted', '==', false)
    .get()
    .then(reference => {
      return reference
        .docs[0]
        .data()
    })
    .then(data => {
      profile = data;
      return getNetworkById(profile.networkId as string);
    })
    .then(network => {
      return identity<Types.JoinedProfile>({ ...profile, network: network });
    })
}

export function deleteProfile (profileId: string): Promise<Types.Profile> {
  return new Promise(async (resolve, reject) => {
    axios.delete(`/profiles/${profileId}`, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

export function authProfile(networkSlug: string): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.get(`/profiles/auth/${networkSlug}`, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function authTeamProfile(networkSlug: string, teamId: string): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.get(`/profiles/auth/${networkSlug}/team/${teamId}`, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function getMetadata(
  networkSlug: string, 
  oauthToken: string, 
  oauthVerifier: string
): Promise<{ profiles: Types.Profile[], team: Types.Team }> {
  return new Promise(async (resolve, reject) => {
    axios.post(
      `/profiles/auth/${networkSlug}?oauth_token=${oauthToken}&oauth_verifier=${oauthVerifier}`, 
      {}, 
      await authorizedHeader()
    )
      .then((res) => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function getMetadataOAuth2(networkSlug: string, code: string): Promise<{ profiles: Types.Profile[], team: Types.Team }> {
  return new Promise(async (resolve, reject) => {
    axios.post(
      `/profiles/auth/${networkSlug}?code=${code}`, 
      {}, 
      await authorizedHeader()
    )
      .then((res) => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function addProfile(profileNames: string[]): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.post('/profiles', profileNames, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function updateProfile(profileData: Types.Profile): Promise<Types.Profile> {
  return new Promise(async (resolve, reject) => {
    axios.put(`/profiles/${profileData.id}`, profileData, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}
