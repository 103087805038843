import axios from './axios';
import { db } from '../firebase';
import { getUser, getUserById } from './users';
import { getPostAssignmentsByPostId } from './postAssignments';
import { authorizedHeader, identity, getPage } from '../helpers';
import { 
  DocumentData,
  QuerySnapshot,
  WriteResult
} from '@google-cloud/firestore';

import * as Types from 'types';

async function joinPosts (posts: Types.Post[]) {
  const joinedPosts: Partial<Types.JoinedPost>[] = await Promise.all(posts
    .map(async (post: Types.Post) => {
      const joinedPost: Partial<Types.JoinedPost> = post;

      const author = await getUserById(post.authorId)

      if(author) {
        delete author.token;
        joinedPost.author = author;
      } else {
        joinedPost.author = {
          name: 'Deleted User',
          email: 'deleteduser@brandsocial.com',
          id: 'none',
          updatedAt: 0,
          createdAt: 0,
          lastLogin: 0,
          pause: false,
          organizationId: 'none',
          inviteStatus: 0,
          permissionIds: [],
          notificationSettingIds: [],
          deleted: false
        };
      }

      if(post.reposterIds) {
        joinedPost.reposters = await Promise.all(post
          .reposterIds
          .map(async reposterId => {
            const reposter = await getUserById(reposterId);

            delete reposter.token;

            return reposter;
          })
        )
      }

      const postAssignments = await getPostAssignmentsByPostId(post.id as string)
      function reducer (acc: string[], postAssignment: Types.JoinedPostAssignment) {
        if(postAssignment.teamId && !acc.includes(postAssignment.teamId)) {
          return [ ...acc, postAssignment.teamId];
        } else {
          return acc;
        }
      }
      const teamIds = postAssignments.reduce(reducer, []);
      joinedPost.teamIds = teamIds;

      return joinedPost;
    })
  )
  return joinedPosts;
}

export function getPosts(lastSnapshot: string = ''): Promise<Types.JoinedPost[]> {
  return getUser()
    .then(user => {
      return getPage('posts', [['organizationId', '==', user.organizationId]], 10, lastSnapshot)
    })
    .then((posts: Types.Post[]) => {
      return joinPosts(posts)
    })
    .then(joinedPosts => {
      return identity<Types.JoinedPost[]>(joinedPosts);
    })
}

export function getPostsForUser(userId: string): Promise<Types.JoinedPost[]> {
  return new Promise(async (resolve, reject) => {
    axios.get(`/posts/users/${userId}`, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function getPostsForTeam(teamId: string, lastSnapshot: string= ''): Promise<Types.JoinedPost[]> {
  return getPage('posts', [['teamId', '==', teamId]], 10, lastSnapshot)
    .then((posts: Types.Post[]) => {
      return joinPosts(posts)
    })
    .then(joinedPosts => {
      return identity<Types.JoinedPost[]>(joinedPosts);
    })
}

export function getPostById(postId: string): Promise<Types.JoinedPost> {
  return new Promise(async (resolve, reject) => {
    axios.get(`/posts/${postId}`, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function getTeamPostById(teamId: string, postId: string): Promise<Types.JoinedPost> {
  return new Promise(async (resolve, reject) => {
    axios.get(`/teams/${teamId}/posts/${postId}`, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function createPost(postData: Types.Post, shareToUsers: Types.User[], selectedProfiles: Types.Profile[]): Promise<string> {
  return new Promise(async (resolve, reject) => {
    axios.post('/posts', { postData: postData, shareToUsers: shareToUsers, selectedProfiles: selectedProfiles }, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function sharePost(data: { post: Types.Post, users: Types.User[] }): Promise<string> {
  return new Promise(async (resolve, reject) => {
    axios.post('/shares', data, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}
export function uploadImage (formData: FormData): Promise<string> {
  return new Promise(async (resolve, reject) => {
    axios.post('/upload-images', formData, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

export function getUrlMetadata (data: { urlString: string }): Promise<Types.urlMetadata> {
  return new Promise(async (resolve, reject) => {
    axios.post('/get-url-metadata', data, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

