import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../api';
import Profile from './Profile';
import Button from './Button';
import MultiButton from './MultiButton';
import ShareToModal from './ShareToModal';
import Modal from './Modal';
import DateTimePicker from './DateTimePicker';
import PostPreview from './PostPreview';
import ImageUpload from './ImageUpload';
import Spinner from './Spinner';
import TabBar from './TabBar';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import TextareaAutosize from 'react-autosize-textarea';

import UserContext from './UserContext';

import * as Types from 'types';
import * as Consts from '../consts';

import {ReactComponent as ImageDropBoxSVG} from '../svgs/image-drop-box_image.svg';
import {ReactComponent as ImageDropBoxSmallSVG} from '../svgs/image-drop-box-small.svg';
import {ReactComponent as CloseWhiteSVG} from '../svgs/close-white.svg';
import {ReactComponent as CalendarSVG} from '../svgs/calendar-black.svg';
import {ReactComponent as ChevronLeftGraySVG} from '../svgs/chevron-left-gray.svg';

import OrganizationContext from './OrganizationContext';

import { Row, Col } from 'react-bootstrap';

let charCountTimeout: NodeJS.Timeout;
TimeAgo.locale(en)
const timeAgo = new TimeAgo('en-US')

const NewPostPage: React.FC = () => {
  const history = useHistory();
  const [isApproval, setIsApproval] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [charCountVisible, setCharCountVisible] = useState(false);
  const [showShareToModal, setShowShareToModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [step, setStep] = useState(1);
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [networks, setNetworks] = useState<Types.Network[]>([]);
  const [profiles, setProfiles] = useState<Types.Profile[]>([]);
  const [teams, setTeams] = useState<Types.JoinedTeam[]>([]);
  const [urlMetadata, setUrlMetadata] = useState<Types.urlMetadata | {}>({});
  const [errors, setErrors] = useState({ description: '', server: '', url: '', images: '' });
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<Types.User[]>([]);
  const [selectedProfiles, setSelectedProfiles] = useState<Types.Profile[]>([]);
  const [organizationUsers, setOrganizationUsers] = useState<Types.User[]>([]);
  const [work, setWork] = useState<{
    facebook: Partial<Types.Post> | null;
    twitter: Partial<Types.Post> | null;
    pinterest: Partial<Types.Post> | null;
    linkedin: Partial<Types.Post> | null;
  } | null>(null);
  const [post, setPost] = useState<Types.Post>({
    id: '',
    description: '',
    deleted: false,
    url: '',
    images: [],
    createdAt: 0,
    updatedAt: 0,
    scheduled: 0,
    organizationId: '',
    authorId: '',
    reposterIds: [],
  });
  const [networksLoaded, setNetworksLoaded] = useState(false);
  const [profilesLoaded, setProfilesLoaded] = useState(false);
  const [teamsLoaded, setTeamsLoaded] = useState(false);
  const [organizationUsersLoaded, setOrganizationUsersLoaded] = useState(false);
  const [response, setResponse] = useState({ data: { success: false, message: '' } });
  const [responseOn, setResponseOn] = useState(false);

  const user = useContext(UserContext) as Types.User;
  const organization = useContext(OrganizationContext) as Types.Organization;

    useEffect(() => {
	if(organization)
	{
	    if(organization.status === Consts.OrgStatus.trial) {
		  if(getDaysLeftInTrial () <= 0)
		    window.location.href = '/app/feed/all';
	    } else if (organization.status === Consts.OrgStatus.invalidPayment) {
		   window.location.href = '/app/feed/all';
	    } 
	}
  }, [])


  function getDaysLeftInTrial () {
    return Math.round((organization.trialEnd * 1000 - new Date().getTime())/(1000*60*60*24));
  }


  useEffect(() => {
    api
      .networks
      .getNetworks()
      .then(networks => {
        setNetworks(networks)
        setNetworksLoaded(true);
      })
  }, [])

  useEffect(() => {
    api
      .profiles
      .getProfiles()
      .then(profiles => {
        setProfiles(profiles)
        setProfilesLoaded(true);
      })
  }, [])

  useEffect(() => {
    api
      .teams
      .getTeams()
      .then(teams => {
        setTeams(teams)
        setTeamsLoaded(true);
      })
  }, [])

  useEffect(() => {
    api
      .users
      .getUsersByOrganization()
      .then((users: Types.User[]) => {
        setOrganizationUsers(users)
        setOrganizationUsersLoaded(true);
      })
  }, [])

  const search = window.location.search;
  const qParams = new URLSearchParams(search);
  const repostFromPostId = qParams.get('repostFromPostId');
  const editDraftFromPostId = qParams.get('editDraftFromPostId');
  const editApprovalPostFromPostAssignmentId = qParams.get('editApprovalPostFromPostAssignmentId');
  useEffect(() => {
    if(repostFromPostId) {
      api
        .posts
        .getPostById(repostFromPostId)
        .then(repost => {
          if(repost) {
            const newPost: Types.Post = {
              ...repost,
              authorId: repost.authorId as string,
              scheduled: post.scheduled,
              reposterIds: [],
              id: repost.id,
            }

            setPost(newPost);

            setWork({
              facebook: newPost,
              twitter: newPost,
              pinterest: newPost,
              linkedin: newPost
            })

            if(repost.url) {
              urlBlur(repost.url);
	      post.url = repost.url;
	      post.description = repost.description;
            }
          }
        })
    } else if(editDraftFromPostId) {
      setIsDraft(true);
      api
        .postAssignments
        .getPostAssignmentById(editDraftFromPostId)
        .then(repost => {
          if(repost) {
            const newPost: Types.Post = {
              ...repost,
              reposterIds: [],
              scheduled: post.scheduled,
            }

            setPost(newPost);

            setWork({
              facebook: newPost,
              twitter: newPost,
              pinterest: newPost,
              linkedin: newPost
            })

            if(repost.url) {
              urlBlur(repost.url);
	      post.url = repost.url;
	      post.description = repost.description;
            }
          }
        })
    } else if(editApprovalPostFromPostAssignmentId) {
      setIsApproval(true);
      api
        .postAssignments
        .getPostAssignmentById(editApprovalPostFromPostAssignmentId)
        .then(repost => {
          if(repost) {
            const newPost: Types.Post = {
              ...post,
              id: repost.id,
              description: repost.description,
              url: repost.url,
              images: repost.images,
              organizationId: repost.organizationId,
              createdAt: repost.createdAt,
              updatedAt: repost.updatedAt,
              scheduled: repost.scheduled,
              authorId: repost.authorId
            }

            setPost(newPost);

            setWork({
              facebook: newPost,
              twitter: newPost,
              pinterest: newPost,
              linkedin: newPost
            })

            if(repost.url) {
              urlBlur(repost.url);
	      post.url = repost.url;
	      post.description = repost.description;

            }
          }
        })
    }
  }, [repostFromPostId]);


  function checkTime()
  {
  	const currentTime:number = new Date().getTime() / 1000;
  	console.log(post.scheduled + ' <=' + currentTime);
	
	if(post.scheduled <= currentTime){					
	  setResponse({ data: { success: false, message: 'Select the valid schedule date and time' } })
	  setResponseOn(true);
	  //setTimeout(() => setResponseOn(false), Consts.responseToastLifetime);	   
	}
	else{
	  setStep(3);	
	}
  }

  function submitPost () {   
   if(post.scheduled === 0){
   	post.scheduled = new Date().getTime() / 1000;
   }
    setSubmitting(true);
    if(isDraft) {
      return api
        .postAssignments
        .updateDraft({ postData: post, shareToUsers: selectedUsers, selectedProfiles: selectedProfiles })
          .then(() => {
            setSubmitting(false);
            setShowConfirm(false);
            history.goBack();
          })
          .catch((err: any) => {
            setSubmitting(false)
            setShowConfirm(false);
            console.log(err);
          })
    } else if(isApproval) {
      return api
        .postAssignments
        .updateApproval({ postData: post, shareToUsers: selectedUsers, selectedProfiles: selectedProfiles })
          .then((postData: Types.PostAssignment) => {
            setSubmitting(false);
            setShowConfirm(false);
            history.goBack();
          })
          .catch((err: any) => {
            setSubmitting(false)
            setShowConfirm(false);
            console.log(err);
          })
    } else {
      return api
        .posts
        .createPost(post, selectedUsers, selectedProfiles)
          .then((postId: string) => {
            setSubmitting(false);
            setShowConfirm(false);
            history.goBack();
          })
          .catch((err: any) => {
            setSubmitting(false)
            setShowConfirm(false);
            console.log(err);
          })
    }
  }

  function createDraft () {
    if(post.description === '' && post.url === '' && post.images.length === 0) {
      setResponse({ data: { success: false, message: 'The post has no content. Please write a description, specify a link, or upload an image.' } })
      setResponseOn(true);
      setTimeout(() => setResponseOn(false), Consts.responseToastLifetime);
    } else {
      post.scheduled = new Date().getTime() / 1000;
      setSubmitting(true);
      api
        .postAssignments
        .createDraft(post)
          .then((postId: string) => {
            setSubmitting(false);
            history.goBack();
          })
          .catch((err: any) => {
            setSubmitting(false)
            console.log(err);
          })
    }
  }

  function createSchedule (stepNum: number ) {
    post.scheduled = new Date().getTime() / 1000;
    if(post.description === '' && post.url === '' && post.images.length === 0) {
      setResponse({ data: { success: false, message: 'The post has no content. Please write a description, specify a link, or upload an image.' } })
      setResponseOn(true);
      setTimeout(() => setResponseOn(false), Consts.responseToastLifetime);
    } else {
      return setStep(2);
    }	
  }

  function onSelectAllTeamUsers(selected: number, team: Types.JoinedTeam) {
    if(selected === 0) {
      setSelectedUsers(selectedUsers.filter(user => {
        return user.teamId !== team.id
      })) 
    } else if (selected === 1) {
      let profList = selectedUsers.filter(user => {
        return user.teamId !== team.id
      }) 

      team.users.forEach(teamUser => {
        profList.push({ ...teamUser, teamId: team.id });
      })

      setSelectedUsers(profList);
    }
  }

  function onSelectAllOrgUsers(selected: boolean) {
    if(selected) {
      setSelectedUsers(organizationUsers.filter(orgUser => {
        return orgUser.id !== user.id;
      })) 
    } else {
      setSelectedUsers([]);
    }
  }

  function onSelectAllTeam(selected: number, team: Types.JoinedTeam) {
    if(selected === 0) {
      setSelectedProfiles(selectedProfiles.filter(profile => {
        return profile.teamId !== team.id
      })) 
    } else if (selected === 1) {
      let profList = selectedProfiles.filter(profile => {
        return profile.teamId !== team.id
      }) 

      setSelectedProfiles(profList.concat(team.profiles))
    }

    if(selected === 0) {
      setSelectedUsers(selectedUsers.filter(user => {
        return user.teamId !== team.id
      })) 
    } else if (selected === 1) {
      let profList = selectedUsers.filter(user => {
        return user.teamId !== team.id
      }) 

      team.users.forEach(teamUser => {
        profList.push({ ...teamUser, teamId: team.id });
      })

      setSelectedUsers(profList);
    }
  }

  function onNetworkSelection(selected: number, network: Types.Network) {
    if(selected === 0) {
      setSelectedProfiles(selectedProfiles.filter(profile => {
        return profile.networkId !== network.slug
      })) 
    } else if (selected === 1) {
      let profList = selectedProfiles.filter(profile => {
        return profile.networkId !== network.slug
      }) 

      setSelectedProfiles(profList.concat(profiles.filter(prof => {
        return prof.networkId === network.slug;
      })))
    }
  }

  function onProfileSelection (selected: boolean, profile: Types.Profile) {
    if(selected) {
      setSelectedProfiles([...selectedProfiles, profile]);
    } else {
      setSelectedProfiles([...selectedProfiles.filter(selectedProfile => selectedProfile.id !== profile.id)]);
    }
  }

  function onUserSelection (selected: boolean, user: Types.User) {
    if(selected) {
      setSelectedUsers([...selectedUsers, user]);
    } else {
      setSelectedUsers(selectedUsers.filter(selectedUser => {
        if(selectedUser.teamId) {
          return true;
        } else {
          return selectedUser.id !== user.id && !selectedUser.teamId;
        }
      }));
    }
  }

  function onTeamUserSelection (selected: boolean, user: Types.User, teamId: string) {
    if(selected) {
      setSelectedUsers([...selectedUsers, user]);
    } else {
      setSelectedUsers(selectedUsers.filter(selectedUser => {
        if(!selectedUser.teamId) {
          return true;
        } else {
          return selectedUser.id !== user.id || selectedUser.teamId !== teamId;
        }
      }));
    }
  }

  function handleImageUpload (imageUrl: string) {
    setPost({ ...post, images: [ ...post.images, imageUrl ] });
    
    const newWork = {
      user: {},
      createdAt: post.createdAt,
      updatedAt: post.updatedAt,
      description: post.description,
      url: post.url,
      images: [ ...post.images, imageUrl ],
      postId: '',
      scheduled: post.scheduled,
      status: Consts.WorkStatus.approvalRequested,
      organizationId: '',
      profileId: '' 
    }

    setWork({
      facebook: newWork,
      twitter: newWork,
      pinterest: newWork,
      linkedin: newWork
    })

    setLoading(false);
  }

  function deleteImage (imageUrl: string) {
    setPost({ ...post, images: [...post.images.filter(image => image !== imageUrl)] });

    const newWork = {
      user: {},
      createdAt: post.createdAt,
      updatedAt: post.updatedAt,
      description: post.description,
      url: post.url,
      images: [...post.images.filter(image => image !== imageUrl)],
      postId: '',
      scheduled: post.scheduled,
      status: Consts.WorkStatus.approvalRequested,
      organizationId: '',
      profileId: '' 
    }

    setWork({
      facebook: newWork,
      twitter: newWork,
      pinterest: newWork,
      linkedin: newWork
    })
  }

  function displayImages () {
    if(post.images) {
      return post.images.map((image: string) => {
        return <div style={{ backgroundImage: `url('${image}')` }} key={image} className="image flex-column">
          <div className="mask flex-column">
          </div>
          {!isApproval &&
            <CloseWhiteSVG onClick={() => deleteImage(image)}/>
          }
        </div>
      });
    };
  }

  async function urlBlur (urlString: string) {
    // eslint-disable-next-line
    const urlRe = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;/* eslint-disable-line */
    if(urlRe.test(urlString)) {
      setLoading(true);

      if(!urlString.includes('http')) {
        urlString = `http://${urlString}`;
      }
      api
        .posts
        .getUrlMetadata({ urlString: urlString })
          .then(metadata => {
            setUrlMetadata(metadata);
            if(metadata.image) {
	     if(!post.images.includes(metadata.image))
	     {
	      
	      /*
              setPost({
                ...post,
                images: [
                  metadata.image,
                  ...post.images
                ]
              })

              const newWork: Types.Post = {
                ...post,
                images: [
                  metadata.image,
                  ...post.images
                ]
              }
	      */

	      post.images[0] = metadata.image;
	      /*
              setPost({
                ...post,
              })
	      */

              const newWork: Types.Post = {
                ...post,
              }

              setWork({
                facebook: newWork,
                twitter: newWork,
                pinterest: newWork,
                linkedin: newWork
              })
	      }
            }
            setLoading(false);
          })
          .catch(err => {
            console.log(err);
            setLoading(false);
          });
    }
    else
    {
      setResponse({ data: { success: false, message: 'Enter valid link url' } })
      setResponseOn(true);
      setTimeout(() => setResponseOn(false), Consts.responseToastLifetime);	
      post.url = '';
    }
  }

  function handleDescriptionChange (e: React.FormEvent<HTMLTextAreaElement>) {
    setPost({ ...post, description: (e.target as HTMLTextAreaElement).value });
    const newWork = {
      user: {},
      createdAt: new Date().getTime() / 1000,
      updatedAt: new Date().getTime() / 1000,
      description: (e.target as HTMLTextAreaElement).value,
      url: post.url,
      images: post.images,
      postId: '',
      scheduled: post.scheduled,
      status: Consts.WorkStatus.approvalRequested,
      organizationId: '',
      profileId: '' 
    }

    clearTimeout(charCountTimeout);
    setCharCountVisible(true)
    charCountTimeout = setTimeout(() => {
      setCharCountVisible(false);
    }, 1000);

    setWork({
      facebook: newWork,
      twitter: newWork,
      pinterest: newWork,
      linkedin: newWork
    })
  }

  function handleLinkChange (e: React.ChangeEvent<HTMLInputElement>) {
    setPost({ ...post, url: e.target.value });

    const newWork = {
      user: {},
      createdAt: post.createdAt,
      updatedAt: post.updatedAt,
      description: post.description,
      url: e.target.value,
      images: post.images,
      postId: '',
      scheduled: post.scheduled,
      status: Consts.WorkStatus.approvalRequested,
      organizationId: '',
      profileId: '' 
    }

    setWork({
      facebook: newWork,
      twitter: newWork,
      pinterest: newWork,
      linkedin: newWork
    })
  }

  function handleContinue () {
     if(post.description === '' && post.url === '' && post.images.length === 0) {
      setResponse({ data: { success: false, message: 'The post has no content. Please write a description, specify a link, or upload an image.' } })
      setResponseOn(true);
      setTimeout(() => setResponseOn(false), Consts.responseToastLifetime);
    } else {
      setStep(3);
    }
  }

  function getPermittedTeamProfiles () {
    const teamProfiles = selectedProfiles.filter(selectedProfile => selectedProfile.teamId);
    return teamProfiles
      .filter(teamProfile => {
        const correctTeam = teams
          .find(team => {
            return team.id === teamProfile.teamId;
          })

        if(correctTeam) {
          const myRole = getMyTeamRole(correctTeam);
          if(myRole) {
            return myRole
              .permissionIds
              .includes('submitPosts');
          }
        }
      })
  }

  function getNonPermittedTeamProfiles () {
    const teamProfiles = selectedProfiles.filter(selectedProfile => selectedProfile.teamId);
    return teamProfiles
      .filter(teamProfile => {
        const correctTeam = teams
          .find(team => {
            return team.id === teamProfile.teamId;
          })

        if(correctTeam) {
          const myRole = getMyTeamRole(correctTeam);
          if(myRole) {
            return !myRole
              .permissionIds
              .includes('submitPosts');
          }
        }
      })
  }

  function getMyTeamRole (team: Types.JoinedTeam) {
    const me = team
      .users
      .find(teamUser => {
        return teamUser.id === user.id
      });

    if(me && me.teamRole) {
      return me.teamRole;
    }
  }

  function showResponse () {
    if(response) {
      return <div className={`response-box ${responseOn ? 'on' : '' } ${response.data.success ? 'green' : 'red'} flex-column`}>{response.data.message}</div>;
    }
  }

  function handleShareProceed () {
    if(selectedProfiles.length + selectedUsers.length === 0) {
      setResponse({ data: { success: false, message: 'Please select at least one destination.'} })
      setResponseOn(true);
      setTimeout(() => setResponseOn(false), Consts.responseToastLifetime);
    } else {
      setStep(4);
    }
  }

  if(networksLoaded && profilesLoaded && teamsLoaded && organizationUsersLoaded) {
    return (
      <div className="feed_page_section new_post_page">
        <Row className="new_post_wrapper">
          <Col lg={6} md={6} sm={12} className="drafting_posts">

            <div className='new_post_title'>
            <h1>New Post</h1>
            <p>These are the most recent posts across all of {organization.name}</p>
            </div>
            <div className="new_post_form_field">
              <TextareaAutosize 
                id="description" 
                name="description" 
                disabled={isApproval}
                placeholder="Start typing your text..." 
                value={post.description}
                onChange={handleDescriptionChange}
              />
              <div className={`char-count ${charCountVisible ? 'visible' : 'hidden'}`}>{post.description ? post.description.length : 0}</div>
              {errors.description}
            </div>
            <div className="new_post_form_field">
            <input 
              disabled={isApproval}
              type="url" 
              id="url" 
              name="url" 
              placeholder="Link URL" 
              onChange={handleLinkChange}
              value={post.url}
              onBlur={e => urlBlur(e.target.value)}
            />
            </div>
            <div className="new_post_form_field">
            {errors.url}
            <div className="images flex-row">
              {displayImages()}
            <ImageUpload onUploading={() => setLoading(true)} onUpload={handleImageUpload} disabled={isApproval}>
              {!isApproval &&
                ( post.images.length > 0 
                  ? <ImageDropBoxSmallSVG className="image-drop-box small"/>
                  : <ImageDropBoxSVG className="image-drop-box"/>
                )
              }
            </ImageUpload>
            </div>
            {errors.server}
            </div>
          </Col>
          <PostPreview 
            networks={networks}
            work={work}
            urlMetadata={urlMetadata}
          />
        </Row>
        <Row className="new_post_footer app_feed_row justify-content-md-end bottom_space">
            {step == 2 
              && (<DateTimePicker 
                closeModal={() => setStep(1)}
                proceed={() => checkTime()}
                selectedDate={post.scheduled}
                setDateTime={(dateTime: number) => setPost({ ...post, scheduled: dateTime })}
              />)
            }
          <MultiButton 
            enabled={true} 
            color={'orange'} 
            onConfirm={handleContinue}
            options={[
              {title: 'Save as Draft', action: createDraft },
              {title: 'Schedule', action: () => createSchedule(2) },
            ]}
          >Next</MultiButton>
        </Row>
        <ShareToModal 
          show={step === 3} 
          closeModal={() => setStep(1)} 
          proceed={handleShareProceed} 
          profiles={profiles}
          networks={networks}
          onProfileSelection={onProfileSelection}
          post={post}
          selectedUsers={selectedUsers}
          selectedProfiles={selectedProfiles}
          organizationUsers={organizationUsers}
          onUserSelection={onUserSelection}
          onTeamUserSelection={onTeamUserSelection}
          onNetworkSelection={onNetworkSelection}
          onSelectAllTeam={onSelectAllTeam}
          onSelectAllOrgUsers={onSelectAllOrgUsers}
          teams={teams}
          isApproval={isApproval}
        />

      <Modal 
        show={step === 4}
        closeModal={() => setStep(1)}
        onConfirm={submitPost}
        title="Confirm Post"
      >
        <div className="confirmation flex-column">
          By clicking Submit you’ll be sending this post to:

          <ul>
            {getPermittedTeamProfiles().length > 0 &&
              <li>
                {`${getPermittedTeamProfiles().length} team profile${getPermittedTeamProfiles().length > 1 ? 's' : ''}`}
              </li>
            }
            {getNonPermittedTeamProfiles().length > 0 &&
              <li>
                {`${getNonPermittedTeamProfiles().length} team profile${getNonPermittedTeamProfiles().length > 1 ? 's' : ''} pending approval`}
              </li>
            }
            {selectedProfiles.filter(selectedProfile => !selectedProfile.teamId).length > 0 &&
              <li>
                {`${selectedProfiles.filter(selectedProfile => !selectedProfile.teamId).length} personal profile${selectedProfiles.filter(selectedProfile => !selectedProfile.teamId).length > 1 ? 's' : ''}${!user.permissionIds.includes('submitPosts') && !isApproval ? ' pending approval' : ''}`}
              </li>
            }
            {selectedUsers.length > 0 &&
              <li>
                {`${selectedUsers.length} user${selectedUsers.length !== 1 ? 's' : ''} for approval`}
              </li>
            }
          </ul>
          {`It is scheduled to be posted ${new Date(post.scheduled * 1000) >= new Date() ? timeAgo.format(new Date(post.scheduled * 1000)) : 'now'}.`}
        </div>
      </Modal>
      {showResponse()}
    </div>
    );
  } else {
    return(
      <div className="page flex-column">
        <Spinner/>
      </div>
    );
  }
}

export default NewPostPage;
