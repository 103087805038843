import React, { useState, useEffect } from 'react';

import Button from './Button';
import Spinner from './Spinner';

import {ReactComponent as CloseSVG} from '../svgs/close.svg';

export interface Props {
  show: boolean;
  closeModal: () => void;
  onConfirm: any;
  children: React.ReactNode;
  title: string;
  btnText?:string;
}

const Modal = ({
  show,
  closeModal,
  onConfirm,
  children,
  title,
  btnText
}: Props)=> {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [show])

  if(!show) {
    return null;
  }

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if(e.key === 'Escape') {
      closeModal();
    }
  }

  function confirm () {
    setLoading(true);
    onConfirm()
  }

  return (
    <div className="new_post_modal confirm_post modal-container" onKeyUp={handleKeyUp}>
      <div onClick={closeModal} className="modal-background"></div>
      <div className="share-to modal">
        {loading
          ? <div className="modal-spinner">
              <Spinner/>
            </div>
          : <>
            <div className="modal_header">
              <h1>{title}</h1>
              <div onClick={closeModal} className="popup_close">
                <CloseSVG />
              </div>
            </div>
            <div className="post_popup_content">
                <div>{children}</div>
                <div className="share_footer flex-row">
                <Button 
                  color="orange"
                  onConfirm={confirm} 
                  style={{margin: '0 0 0 auto'}}
                >{btnText ? btnText : 'Confirm'}</Button>
               </div>
            </div>
          </>
        }
      </div>
    </div>
  );
}

export default Modal;
