import axios from './axios';
import { db } from '../firebase';
import { identity } from '../helpers';

import * as Types from 'types';

export function getNetworks(): Promise<Types.Network[]> {
  return db
    .collection('networks')
    .get()
    .then(reference => {
      return reference
        .docs
        .map(doc => doc.data())
    })
    .then(data => {
      return identity<Types.Network[]>(data);
    })
}

export function getNetworkById(networkId: string): Promise<Types.Network> {
  return db
    .collection('networks')
    .where('id', '==', networkId)
    .get()
    .then(reference => {
      return reference
        .docs[0]
        .data();
    })
    .then(data => {
      return identity<Types.Network>(data);
    })
}
