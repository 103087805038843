import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Link, NavLink, RouteComponentProps } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Container, Col, Row, Button, Navbar, Nav} from "react-bootstrap";
import logo from '../public/images/logo.png';

//import Button from './Button';
import ButtonRedirect from './ButtonRedirect';


import {ReactComponent as BSLogoSVG} from '../svgs/bslogo.svg';
import {ReactComponent as MenuSVG} from '../svgs/menu.svg';

const Topbar: React.FC<RouteComponentProps> = props => {
  const [open, setOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  
  const isMobile = useMediaQuery({
    query: '(max-device-width: 1000px)'
  })

  const excludedPaths = [
    '/login',
    '/register',
    '/signup/team',
    '/signup/pro',
    '/mobile',
    '/account-paused',
    '/account-deleted'
  ];


  const checkActive = (match: any, location: any) => {
    if(!location) return false;
    const {pathname} = location;
    return pathname === "/";
  }

  function handleMenuClick (e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    e.stopPropagation();
    //setOpen(!open);

    if(!isMobileView){
	setIsMobileView(true);
    }
    else
    	setIsMobileView(false);

  }

  {/*if(isMobile) {
    return (
      <div 
        className={`topbar mobile ${open && 'open'} flex-column ${excludedPaths.includes(props.history.location.pathname) ? 'off' : ''}`}
        onClick={() => setOpen(false)}
      >
        <div className="top flex-row">
          <Link className="left flex-row" to="/"><BSLogoSVG /></Link>
          <MenuSVG onClick={e => handleMenuClick(e)}/>
        </div>
        <div className="content flex-column">
          <Link to="/mobile">Login</Link>
          <Button 
            color={'orange'} 
            to="/mobile"
            style={{cursor: 'pointer', marginLeft: '59px', height: '48px', width: '240px'}}
          >Sign Up</Button>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`topbar flex-row ${excludedPaths.includes(props.history.location.pathname) ? 'off' : ''}`}>
        <Link className="left flex-row" to="/"><BSLogoSVG /></Link>
        <div className="right flex-row">
          <a href="/login">Login</a>
          <ButtonRedirect 
            color={'orange'} 
            to="/register"
            style={{cursor: 'pointer', marginLeft: '59px', height: '48px', width: '240px'}}
          >Sign Up</ButtonRedirect>
        </div>
      </div>
    );
  }*/}

  return(
  <div className={isMobileView ? 'topbar-mobile-view':''}>
    <div className={`topbar-section ${excludedPaths.includes(props.history.location.pathname) ? 'off' : ''}`}>
      <Navbar bg="white" expand="lg">
      <Container>
      <Row className='topbar_row'>
      <Col className="header-left" lg={8} md={11} sm={8}>
        <Navbar.Brand href="/" className="navbar-brand"><img src={logo} /></Navbar.Brand>
        </Col>
      <Col className='mobile_toggle d-sm-block d-lg-none' md={1}>
	<Navbar.Toggle aria-controls="basic-navbar-nav" onClick={e => handleMenuClick(e)}>
		<MenuSVG />
	</Navbar.Toggle>
     </Col>
  <Navbar.Collapse className="header-right col-lg-4 col-sm-12" id="basic-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="/login" className='login_btn topbar_btn'>Login</Nav.Link>
      <Nav.Link href="/register" className='signup_btn topbar_btn'>Start Free Trial</Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Row>
        </Container>
        </Navbar>
    </div>
</div>
  );

}

export default withRouter(Topbar);
