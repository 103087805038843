import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import api from '../api';
import { Col, Row } from "react-bootstrap";

import Back from './Back';
import TabBar from './TabBar';
import Button from './Button';
import UserList from './UserList';
import Network from './Network';
import FeedPostAssignment from './FeedPostAssignment';
import Spinner from './Spinner';
import LogList from './LogList';
import PostAssignmentScroller from './PostAssignmentScroller';

import TeamContext from './TeamContext';
import UserContext from './UserContext';

import * as Types from 'types';

const TeamPage: React.FC = () => {
  const [networks, setNetworks] = useState<Types.Network[]>([]);
  const [myTeamPermissions, setMyTeamPermissions] = useState<Types.PermissionId[]>([]);
  const [teamProfiles, setTeamProfiles] = useState<Types.Profile[]>([]);
  const [teamPosts, setTeamPosts] = useState<Types.JoinedPostAssignment[]>([]);
  const [logs, setLogs] = useState<Types.Log[]>([]);
  const [tab, setTab] = useState<'info' | 'posts' | 'activity' | 'profiles' | 'members'>('members');
  const [meTeamRole, setMeTeamRole] = useState<Types.Role | null>(null);
  const [team, setTeam] = useState<Types.JoinedTeam>({
    id: '',
    organizationId: '',
    name: '',
    createdAt: 0,
    updatedAt: 0,
    users: [],
    teamUsers: [],
    profiles: [],
    deleted: false,
    pause: false
  });
  const [networksLoaded, setNetworksLoaded] = useState(false);
  const [teamLoaded, setTeamLoaded] = useState(false);
  const [profilesLoaded, setProfilesLoaded] = useState(false);

  const uParams = useParams() as { teamId: string };

  const user = useContext(UserContext) as Types.User;

  useEffect(() => {
    api
      .networks
      .getNetworks()
      .then(networks => {
        setNetworks(networks)
        setNetworksLoaded(true);
      })

  }, [])

  useEffect (() => {
    const myTeamRole = getMyTeamRole();
    if(myTeamRole && myTeamRole.permissionIds) {
      setMyTeamPermissions(myTeamRole.permissionIds);
    }
  }, [team])

  function getMyTeamRole () {
    const me = team
      .users
      .find(teamUser => {
        return teamUser.id === user.id
      });

    if(me && me.teamRole) {
      return me.teamRole;
    }
  }

  useEffect(() => {
    if(uParams.teamId) {
      api
        .teams
        .getTeamById(uParams.teamId)
        .then((team: Types.JoinedTeam) => {
          setTeam(team);
          setTeamLoaded(true);
        })
        .catch((err: any) => {
          console.log(err);
        })

      api
        .profiles
        .getProfilesForTeam(uParams.teamId)
        .then((teamProfiles: Types.Profile[]) => {
          setTeamProfiles(teamProfiles);
          setProfilesLoaded(true);
        })
        .catch((err: any) => {
          console.log(err);
        })
    }

  }, [uParams.teamId])

  function networksList () {
    if(networks && team) {
      return networks.map((network: Types.Network) => {
        return <Network 
          network={network} 
          profiles={teamProfiles}  
          key={network.slug} 
          teamId={team.id as string}
          onLoading={() => setProfilesLoaded(false)}
          load={() => {
            api
              .profiles
              .getProfilesForTeam(uParams.teamId)
              .then((teamProfiles: Types.Profile[]) => {
                setTeamProfiles(teamProfiles);
                setProfilesLoaded(true);
              })
              .catch((err: any) => {
                console.log(err);
              })
          }}
        />;
      });
    } else {
      return <h2>Loading...</h2>
    }
  }

  function showTab () {
    if(team && tab === 'info') {
      return (
        <div className="info tab-content">
          Info
        </div>
      );
    } else if(team && tab === 'posts') {
      return (
        <div className="info tab-content">
          <PostAssignmentScroller 
            load={(lastSnapshot?: string) => api.postAssignments.getPostAssignmentsForTeam(team.id as string, lastSnapshot)}
            posts={teamPosts}
            setPosts={setTeamPosts}
            team={true}
            render={
              (post: Types.JoinedPostAssignment, key: string) => (
                <FeedPostAssignment post={post} key={post.id}/>
              )
            }
          />
        </div>
      );
    } else if(team && tab === 'members') { 
      return (
        <div className="activity tab-content">
          <UserList 
            title={<Row className="app_feed_row justify-content-md-center row" style={{width: '100%'}}><Col lg={10} md={9} xs={6} className='app_col app_feed_title'><h2 className="table-heading">Members</h2></Col>{ (myTeamPermissions.includes('manageTeamUsers') || user.permissionIds.includes('administrateOrganization') || user.permissionIds.includes('manageTeams')) &&  
            <Col lg={2} md={3} xs={6} className='app_col app_feed_btn'><Button to={`/app/team/${team.id}/edit`} color="light-orange">Edit Team</Button></Col>
            }</Row>}
            users={team.users} 
            team={team} 
            myTeamPermissions={myTeamPermissions} 
            getUsers={() =>
              api
                .teams
                .getTeamById(uParams.teamId)
                .then((team: Types.JoinedTeam) => {
                  setTeam(team);
                  setTeamLoaded(true);
                })
                .catch((err: any) => {
                  console.log(err);
                })
            }
          />
        </div>
      );
    } else if(team && tab === 'profiles') { 
      return (
        <div className="profiles tab-content">
          <div className="profile_desc">These are the profiles directly connected to the team.</div>
          <div className='network_list_sec'>
          {networksList()}
          </div>
        </div>
      );
    } else if(team && tab === 'activity') { 
      return (
        <div className="activity tab-content">
          <LogList 
            load={(lastSnapshot: any) => api.logs.getLogsForTeam(team.id as string, lastSnapshot)} 
          />
        </div>
      );
    }
  }

  if(networksLoaded && teamLoaded && profilesLoaded) {
    return (
      <TeamContext.Provider value={team}>
        <Row className='app_feed_row'>

          <Col className='app_col' lg={12} md={12} xs={12}>
         
        <div className="Sidebar_me team_details_page feed_page_section">
          <Back />
          <TabBar
            title={team.name}
            explainer={`These are the details and settings for the team ${team.name}.`}
          > 
          </TabBar>
          
            <div className="team_detail_section">
              <div className='team_nav_tabs'>
            <ul className="nav nav-tabs" role="tablist">
              <li  className={`tab first  ${tab === 'members' ? 'active' : ''}`}><a onClick={() => setTab('members')}>Team Members</a></li>

                  <li className={`tab first  ${tab === 'posts' ? 'active' : ''}`}><a onClick={() => setTab('posts')}>Team Posts</a></li>
                   {(myTeamPermissions.includes('manageTeamProfiles') || user.permissionIds.includes('administrateOrganization'))  &&

                  <li className={`tab first ${tab === 'profiles' ? 'active' : ''}`}><a onClick={() => setTab('profiles')}>Team Networks</a></li>}

                  {(myTeamPermissions.includes('viewTeamLogs') || user.permissionIds.includes('administrateOrganization'))  &&

                  <li className={`tab first ${tab === 'activity' ? 'active' : ''}`}><a onClick={() => setTab('activity')}>Team Activity</a></li>}

              </ul>
              </div>
                {/*
                <div 
                  className={`tab first flex-row ${tab === 'info' ? 'active' : ''}`}
                  onClick={() => setTab('info')}
                >General Info</div>*/}
              
            </div>
            {showTab()}
        </div>
        </Col>
        </Row>      
      </TeamContext.Provider>
    );
  } else {
    return (<div className="page">
      <Spinner/>
    </div>);
  }
}

export default TeamPage;