import React, { useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router';
import api from '../api';

import UserImage from './UserImage';

import UserContext from './UserContext';

import {ReactComponent as BlankUserSVG} from '../svgs/blank-user.svg';

import Types from 'types';

const UserCard: React.FC = () => {
  const [imageError, setImageError] = useState(false);

  const user = useContext(UserContext) as Types.User;

  function userCard () {
    if(user) {
      return (<>{<UserImage user={user} size="tiny" />}{user.name}</>);
    } else {
      return 'Loading...';
    }
  }

  function onboarding() {
    if(!!user && !user.organizationId) {
      return <Redirect to={{ pathname: '/app/organization' }} />
    }
  }

  return (
    <div className="me-card black-link flex-row">
      {userCard()}
      {onboarding()}
    </div>
  );
}

export default UserCard;
