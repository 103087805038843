import axios from './axios';
import { authorizedHeader } from '../helpers';
import { db } from '../firebase';
import { identity } from '../helpers';
import { getUser } from './users';

import * as Types from 'types';

export function getOrganization(): Promise<Types.Organization> {
  return getUser()
    .then(user => {
    return db
      .collection('organizations')
      .where('id', '==', user.organizationId)
      .where('deleted', '==', false)
      .get()
      .then(reference => {
        return reference
          .docs[0]
          .data()
      })
      .then(data => {
        return identity<Types.Organization>(data);
      })
    })
}

export function createOrganization(organization: Types.Organization): Promise<Types.Organization> {
  return new Promise(async (resolve, reject) => {
    axios.post('/organizations', organization, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function updateOrganization(organizationData: Types.Organization): Promise<Types.Organization> {
  return new Promise(async (resolve, reject) => {
    axios.put('/organizations', organizationData, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}
