import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/app';
import api from '../api';
import { FBSignOut, getFirebaseUser, firebaseAuth, fb, f} from '../firebase';
import { validateEmail } from '../helpers';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { Col, Row } from "react-bootstrap";

import UserCard from './UserCard';
import Button from './Button';
import FeedPostAssignment from './FeedPostAssignment';
import PostAssignmentScroller from './PostAssignmentScroller';
import TwoFactor from './TwoFactor';
import LogList from './LogList';
import Checkbox from './Checkbox';
import ImageUpload from './ImageUpload';

import {ReactComponent as HugeBlankUserSVG} from '../svgs/huge-blank-user.svg';
import {ReactComponent as BigOrangeDeleteSVG} from '../svgs/big-orange-delete.svg';

import UserContext from './UserContext';

import * as Types from 'types';
import * as Consts from '../consts';

function updateMe (userData: Types.User) {
  return api
    .users
    .updateMe(userData)
    .then(() => {
    })
    .catch((err: any) => {
      console.log(err);
    })
}

const MePage: React.FC = () => {
  const uParams = useParams() as { tab: string; };
  const [logs, setLogs] = useState<Types.Log[]>([]);
  const [loading, setLoading] = useState(false);
  const [nameLoading, setNameLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [posts, setPosts] = useState<Types.JoinedPostAssignment[]>([]);
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('us');
  const [verificationCode, setVerificationCode] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [authyId, setAuthyId] = useState('');
  const [tab, setTab] = useState<'info' | 'posts' | 'activity' | 'security' | 'notifications' | string>(uParams.tab || 'info');
  const [userLoaded, setUserLoaded] = useState(false);
  const [notificationSettings, setNotificationSettings] = useState<Types.NotificationSetting[]>([]);
  const [newPassword, setNewPassword] = useState('');
  const [sent, setSent] = useState(false);
  const [failed, setFailed] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [response, setResponse] = useState({ data: { success: false, message: '' } });
  const [responseOn, setResponseOn] = useState(false);
  const [user, setUser] = useState<Types.User>({
    id: '',
    name: '',
    email: '',
    createdAt: 0,
    lastLogin: 0,
    updatedAt: 0,
    pause: false,
    organizationId: '',
    permissionIds: [],
    notificationSettingIds: [],
    deleted: false,
    inviteStatus: 0 
  });

  const me = useContext(UserContext) as Types.User;

  useEffect(() => {
    setUser(me);
    setNewEmail(me.email);
    setNameLoading(true);
    setEmailLoading(true);
  }, [me])

  useEffect(() => {
    fetch('https://extreme-ip-lookup.com/json/')
      .then( res => res.json())
      .then(response => {
        setCountry(response.countryCode.toLowerCase());
      })
      .catch(() => {
      })
  }, [])

  useEffect(() => {
    api
      .notifications
      .getNotificationSettings()
      .then((notificationSettings: Types.NotificationSetting[]) => {
        setNotificationSettings(notificationSettings);
      })
      .catch((err: any) => {
        console.log(err);
      })
  }, [])

  function handleUpload (imageUrl: string) {
    if(user) {
      setUser({ ...user, image: imageUrl });
      api.users.updateMe({ ...user, image: imageUrl });
    }
  }

  function handleCheckVerification () {
    setLoading(true);
    api
      .authy
      .checkWithId(authyId, verificationCode)
      .then(passed => {
        if(passed.success) {
          api
            .authy
            .enroll(authyId)
            .then(() =>  {
              setSent(false);
              setAuthyId('');
              setLoading(false);
            })
            .catch(() => {
              setFailed(true);
              setLoading(false);
              setAuthyId('');
              setSent(false);
            })
        } else {
          setFailed(true);
          setAuthyId('');
          setLoading(false);
          setSent(false);
        }
      })
      .catch(() => {
        setFailed(true);
        setAuthyId('');
        setLoading(false);
        setSent(false);
      })
    /*
    const cred = f.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = f.auth.PhoneMultiFactorGenerator.assertion(cred);
    (getFirebaseUser() as firebase.User).multiFactor.enroll(multiFactorAssertion);
    // verificationId will be needed for enrollment completion.
    // */
  }

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if(e.key === 'Enter') {
      handleCheckVerification();
    }
  }

  function handleDeleteImage () {
    if(user) {
      setUser({ ...user, image: '' });
      api.users.updateUser({ ...user, image: '' });
    }
  }

  function signOut() {
    FBSignOut()
      .catch(err => {
        console.log(err);
      });
  }

  function checkPasswordStrength(password: string) {
    const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    return regex.test(password);
  }

  function handleChangePassword () {
    
    if(!checkPasswordStrength(newPassword)) {
      setResponse({ data: { success: false, message: 'Password is too weak. Use a more complex password.' } })
      setResponseOn(true);
      setTimeout(() => setResponseOn(false), Consts.responseToastLifetime);

    }
    else if(newPassword === confirmPassword) {
      setLoading(true);
      (getFirebaseUser() as firebase.User)
        .updatePassword(newPassword)
        .then((res: any) => {
          setResponse({ data: { success: true, message: 'Successfully updated password.' } })
          setResponseOn(true);
          setTimeout(() => setResponseOn(false), Consts.responseToastLifetime);
          setLoading(false);
        })
        .catch((err: any) => {
          setResponse({ data: { success: false, message: err.message } })
          setResponseOn(true);
          setTimeout(() => setResponseOn(false), Consts.responseToastLifetime);
          setLoading(false);
        })
    } else {
      setResponse({ data: { success: false, message: 'Passwords do not match.' } })
      setResponseOn(true);
      setTimeout(() => setResponseOn(false), Consts.responseToastLifetime);
    }
  }

  function handleChangeEmail () {
    setLoading(true);
    api
      .users
      .changeEmail(newEmail)
      .then(() => {
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setResponse({ data: { success: false, message: err.message } })
        setResponseOn(true);
        setTimeout(() => setResponseOn(false), Consts.responseToastLifetime);
        setLoading(false);
      })
  }

  function handleSubmit() {
    setLoading(true);
    updateMe(user)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
  }

  function handleEnable2fa () {
    setLoading(true);
    api
      .authy
      .create(phone)
      .then(res => {
        setAuthyId(res);
        setLoading(true);
        api
          .authy
          .sendWithId(res)
          .then(sent => {
            if(sent.success) {
              setSent(true);
              setLoading(false);
            } else {
              setFailed(true);
              setLoading(false);
            }
          })
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      })
    /*
    const recaptchaVerifier = new f.auth.RecaptchaVerifier(
      'recaptcha-container',
      // Optional reCAPTCHA parameters.
      {
        'size': 'normal',
        'callback': function(response: any) {
          // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
          // ...
        },
        'expired-callback': function() {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        }
      }
    );

    (getFirebaseUser() as firebase.User)
      .multiFactor
      .getSession()
        .then(function(multiFactorSession: any) {
          console.log(multiFactorSession);
          const phoneInfoOptions = {
            phoneNumber: phone,
            session: multiFactorSession
          };

          const phoneAuthProvider = new f.auth.PhoneAuthProvider();
          // Send SMS verification code.
          return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then(function(verificationId) {
              setVerificationId(verificationId);
            })
        })
  */
  }

  function handleDisable2fa () {
    if(me) {
      setLoading(true);
      api
        .users
        .updateMe({
          ...me,
          authyId: ''
        })
        .then(() => {
          setLoading(false);
        })
    }
  }

  function onSelection (selected: boolean, notificationSetting: Types.NotificationSetting) {
    if(selected) {
      setUser({ ...user, notificationSettingIds: [...user.notificationSettingIds, notificationSetting.id] });
    } else {
      setUser({ ...user, notificationSettingIds: user.notificationSettingIds.filter((existingNotificationSettingId: string) => existingNotificationSettingId !== notificationSetting.id) });
    }
  }

  function showResponse () {
    if(response) {
      return <div className={`response-box ${responseOn ? 'on' : '' } ${response.data.success ? 'green' : 'red'} flex-column`}>{response.data.message}</div>;
    }
  }

  function checkNewName (name: string) {
	if(name.trim() != '')
	{
	  setNameLoading(false);
        }
	else
	{
	  setNameLoading(true);
	}	  
	setUser({ ...user, name: name})
  }

  function checkNewEmail (name: string) {
	if(name.trim() != '' && validateEmail(name))
	{
	  setEmailLoading(false);
        }
	else
	{
	  setEmailLoading(true);
	}	  
	setNewEmail(name)
  }

  function showTab () {
    if(me && tab === 'info') {
      return (
        <div className="info tab-content feed_page_section">
          <div className="basics">
            <div className="basic">
              <div className="info_field_label">Name</div>
              <Row className="input-row">
              <Col className="info_col_l" lg={6} md={6} xs={8}><input 
                  placeholder={user.name}
                  onChange={e => checkNewName(e.target.value)}
                />
                </Col>
                <Col className="info_col_r" lg={6} md={6} xs={4}><Button
                  color="light-orange"
                  onConfirm={handleSubmit}
                  enabled={!nameLoading}
                >
                  Update Name
                </Button>
                </Col>
              </Row>
              <div className="info_field_label">Email</div>
              <div className='info_field_desc'>
              <p>You will be sent an email with a link to verify your new address.</p>
              </div>
              <Row className="input-row">
              <Col className="info_col_l" lg={6} md={6} xs={8}><input 
                  placeholder={newEmail}
                  onChange={e => checkNewEmail(e.target.value) }
                />
                </Col>
                <Col className="info_col_r" lg={6} md={6} xs={4}>
                <Button
                  color="light-orange"
                  onConfirm={handleChangeEmail}
                  enabled={!emailLoading && validateEmail(user.email)}
                >
                  Update Email
                </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        
      );

    } else if(me && tab === 'posts') {
      return (
        <div className="posts tab-content">
          {
            me && 
              <PostAssignmentScroller 
                load={(lastSnapshot?: string) => api.postAssignments.getPostAssignmentsForUser(me.id, lastSnapshot)}
                posts={posts}
                setPosts={setPosts}
                render={
                  (post: Types.JoinedPostAssignment, key: string) => (
                    <FeedPostAssignment post={post} key={post.id}/>
                  )
                }
              />
          }
        </div>
      );

    } else if(me && tab === 'notifications') { 
      return (
        <div className="notifications tab-content">
          <div className="edit-notification-settings mb-4">

            <div className='notification_title'>
          <Row className="app_feed_row justify-content-md-center">
<Col className='app_col app_feed_title' lg={10} md={9} xs={6}>
<h2 className="flex-row table-heading">Notification Settings</h2>
</Col>

<Col className='app_col app_feed_btn' lg={2} md={3} xs={6}><Button color="orange" onConfirm={handleSubmit}
enabled={!loading}>Update User</Button></Col>
</Row>
</div>

<div className='notifications_list'>
              {notificationSettings.map(notificationSetting => {
                return (
                  <Row 
                    className="notification-setting app_feed_row" 
                    key={notificationSetting.id}
                  >
                    <Col lg={10} md={10} xs={10} className="notification_list_l app_col">
                      <h5>{notificationSetting.name}</h5>
                      <div className="notification_list_desc">{notificationSetting.description}</div>
                    </Col>
                    
                    <Col lg={2} md={2} xs={2} className="notification_list_r app_col">
                      <div className='notification_checkbox' align="right">
                    <Checkbox 
                      idName={notificationSetting.id}
                      onChange={(selected) => onSelection(selected, notificationSetting)}
                      initialChecked={me.notificationSettingIds.includes(notificationSetting.id)}
                    />
                    </div>
                    </Col>
                  </Row>
                );
              })}
              </div>

          </div>
        </div>
      );

    } else if(me && tab === 'security') { 
      if(!authyId && !sent) {
        return (
          <div className="security tab-content">
            {me.authyId 
              ? <div className="basic two-factor-wrapper disable">
                <h1>Two Factor Authentication</h1>
                <div className="gray-text">Two-Factor Authentication is currently enabled.</div>
                <Button
                  onConfirm={handleDisable2fa}
                  enabled={!loading}
                  color="orange"
                >
                  Disable
                </Button>
              </div>
              : <div className="basic phone-wrapper enable hide_section">
                <h1>Two Factor Authentication</h1>
                <div className="gray-text">Enter your phone number.</div>
                <div className="input-wrapper">
                  <PhoneInput
                    country={country}
                    value={phone}
                    onChange={setPhone} 
                  />
                  <Button
                    onConfirm={handleEnable2fa}
                    enabled={!loading}
                    color="light-orange"
                  >
                    Enable
                  </Button>
                </div>
              </div>
            }
            {/*me.authyId 
              ? <div className="basic two-factor-wrapper disable flex-column">
                <h1>Two Factor Authentication</h1>
                <div className="gray-text">Two-Factor Authentication is currently enabled.</div>
                <Button
                  onConfirm={handleDisable2fa}
                  enabled={!loading}
                  color="orange"
                >
                  Disable
                </Button>
              </div>
              */}


            <div className="bottom">
				      {(getFirebaseUser() as firebase.User).providerData.find((provider: any) => provider.providerId === 'password') &&
                <div className="password-change">
                  <h1>Change Password</h1>

                  <div className="security_field">
                  <div className="info_field_label">Enter New Password</div>
                    <input
                      placeholder='Enter New Password'
                      type="password"
                      onChange={e => setNewPassword(e.target.value)}
                    />
                    </div>

                    <div className="security_field">
                    <div className="info_field_label">Repeat New Password</div>
                    <input
                      placeholder='Repeat New Password'
                      type="password"
                      onChange={e => setConfirmPassword(e.target.value)}
                    />
                    </div>

                    <div className="security_password">
		    <div className="passwordRules">
		    	<ul>
			   <li>At least 8 characters in length.</li>
			   <li>At least one digit, lowercase, uppercase & special character.</li>
			</ul>
		    </div>
           
          <div className='security_password_btn'><Button
                      color="light-orange"
                      enabled={!loading}
                      onConfirm={handleChangePassword}
                    >
                      Change Password
                    </Button>
                    </div>

                  </div>
                </div>
              }
            </div>
          </div>
        );

      } else {
        return (
          <div className="security two-factor tab-content">
            <div className="verification">
              <div className="two-factor-subheading">We have sent a Text Message with a verification code.  If your number is associated with an existing Authy account, please check your Authy mobile app.</div> 
              <input
                type="text"
                placeholder="Verification Code"
                onKeyUp={handleKeyUp}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVerificationCode(e.target.value)}
              />
              <Button
                color="orange"
                onConfirm={handleCheckVerification}
                enabled={!loading}
              >Verify</Button>
            </div>
          </div>
        );
      }

    } else if(me && tab === 'activity') { 
      return (
        <div className="activity tab-content">
          <LogList 
            load={(lastSnapshot: string) => api.logs.getLogsForMe(lastSnapshot)} 
          />
        </div>
      );
    }
  }

  return (
    <Row className="app_feed_row">
      <Col className="app_col" lg={12} md={12} sm={12}>
      
    
    <div className="Sidebar_me feed_page_section">
      
    <div className='feed_tabbar_title'>
        <Row className="app_feed_row">
        <Col className="app_col app_feed_title" lg={10} md={9} xs={7}>
        <h1>{user.name}</h1>
        </Col>

        <Col className="app_col app_feed_btn" lg={2} md={3} xs={5}>
        <Button onConfirm={signOut} color="light-orange">Sign Out</Button>
            </Col>
        </Row>
        
        <Row className="feed_tabbar_desc app_feed_row">
        <Col className="app_col app_feed_title_desc" lg={12} md={12} sm={12}>
        These are your personal settings.
        </Col>
        </Row>

        </div>

    <div className='profile_image_sec'>
        <Row className="app_feed_row">
        <Col className='app_col' lg={6} md={6} xs={8}>
          <div className="image-control">
            <ImageUpload onUpload={handleUpload}>
              {user.image
                ? <div className="huge-user-image upload" style={{ backgroundImage: `url('${user.image}')` }}/>
                : <HugeBlankUserSVG className="huge-user-image upload" />
              }
            </ImageUpload>
	    <div className="user_image_delete">
            {me.permissionIds.includes('manageUsers') && user.image &&
              <BigOrangeDeleteSVG onClick={handleDeleteImage} />
            }
	    </div>
          </div>
       </Col>
        </Row>
      </div>

      <Row>
      <Col lg={12} md={12} sm={12}>
     
        <div className="content team-details">
          <div className='profile_tab_sec'>
      <ul className="nav nav-tabs" role="tablist">

        <li 
        className={`tab first ${tab === 'info' ? 'active' : ''}`}>
          <a onClick={() => setTab('info')}>General Info</a></li>

        <li
        className={`tab middle ${tab === 'security' ? 'active' : ''}`}>
        <a onClick={() => setTab('security')}>Security</a></li>

        <li
        className={`tab middle ${tab === 'notifications' ? 'active' : ''}`}>
        <a onClick={() => setTab('notifications')}>Notifications</a></li>

        <li
         className={`tab middle ${tab === 'posts' ? 'active' : ''}`}>
           <a onClick={() => setTab('posts')}>Posts</a></li>

         <li
         className={`tab last ${tab === 'activity' ? 'active' : ''}`}>
           <a onClick={() => setTab('activity')}>Activity</a></li>

        </ul>
        </div>
        {showTab()}
        </div>
        {showResponse()}
          
      </Col>
    </Row>
    </div>
    
    </Col>
    </Row>
  );
}

export default MePage;
