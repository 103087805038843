import axios from './axios';
import { authorizedHeader } from '../helpers';
import { db, getGoogleId } from '../firebase';
import { identity } from '../helpers';

import * as Types from 'types';
import * as Consts from '../consts';

export async function getUser () {
  return db
    .collection('users')
    .where('googleId', '==', await getGoogleId())
    .where('deleted', '==', false)
    .get()
    .then(reference => {
      if(reference.docs.length > 0) {
        return reference
          .docs[0]
          .data()
      } else {
        return null;
      }
    })
    .then(data => {
      return identity<Types.User>(data);
    })
}

export function getUserById (userId: string): Promise<Types.User> {
  return db
    .collection('users')
    .where('id', '==', userId)
    .where('deleted', '==', false)
    .get()
    .then(reference => {
      if(reference.docs[0]) {
        return reference
          .docs[0]
          .data()
      } else {
        return {
          name: 'Deleted User',
          email: 'deleteduser@brandsocial.com',
          id: 'none',
          updatedAt: 0,
          createdAt: 0,
          lastLogin: 0,
          pause: false,
          organizationId: 'none',
          inviteStatus: 0,
          permissionIds: [],
          notificationSettingIds: [],
          deleted: false
        }
      }
    })
    .then(data => {
      return identity<Types.User>(data);
    })
}

export async function getUsersByOrganization (): Promise<Types.User[]> {
  return getUser()
    .then(user => {
      return db
        .collection('users')
        .where('organizationId', '==', user.organizationId)
        .where('deleted', '==', false)
        .get()
        .then(reference => {
          return reference
            .docs
            .map(doc => doc.data())
        })
    })
    .then(data => {
      return identity<Types.User[]>(data);
    })
}

export function loginEmail (userData: Types.User): Promise<string> {
  return new Promise(async (resolve, reject) => {
    axios.post('/login/email', userData)
      .then(res => {
        resolve(res.data.data.token)
      })
      .catch(err => reject(err))
  });
}

export function signupEmail (userData: Partial<Types.User>): Promise<Types.User> {
  return axios.post('/signup/email', userData)
    .then(res => {
      return res.data.data
    })
}

export function signupGoogle (userData: Partial<Types.User>): Promise<Types.User> {
  return new Promise(async (resolve, reject) => {
    axios.post('/signup/google', userData)
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

export function updateUser(userData: Types.User): Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    axios.put('/users', userData, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function updateMe(userData: Types.User): Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    axios.put('/me', userData, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function changeEmail(email: string): Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    axios.put('/me/email/change', { email: email }, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function verifyEmail(jwt: string): Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    axios.put('/me/email/verify', { jwt: jwt }, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function invite (invite: { email: string }[]): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.post('/users/invites', invite, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

export function resendInvite (user: Types.User): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.post('/users/resend-invite', user, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

export function resendInvites (users: Types.User[]): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.post('/users/resend-invites', users, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

export function getInvite (inviteId: string): Promise<Types.User> {
  return db
    .collection('users')
    .doc(inviteId)
    .get()
    .then(documentSnapshot => {
      return documentSnapshot.data();
    })
    .then(data => {
      return identity<Types.User>(data);
    })
}

export function getInvitesByOrganization (): Promise<Types.User[]> {
  return getUser()
    .then(user => {
      return db
        .collection('invites')
        .where('organizationId', '==', user.organizationId)
        .where('deleted', '==', false)
        .get()
        .then(reference => {
          return reference
            .docs
            .map(doc => doc.data())
        })
    })
    .then(data => {
      return identity<Types.User[]>(data);
    })
}

export function deleteUser (userId: string): Promise<Types.User> {
  return new Promise(async (resolve, reject) => {
    axios.delete(`/users/${userId}`, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

