import axios from './axios';
import { db } from '../firebase';
import { identity } from '../helpers';
import { authorizedHeader } from '../helpers';

import { 
  DocumentData,
  QuerySnapshot,
  WriteResult
} from '@google-cloud/firestore';

import * as Types from 'types';

export function getUsers(filters: any): Promise<Types.User[]> {
  let query = db.collection('users') as any;

  Object.keys(filters).forEach((filterKey: string) => query = query.where(filterKey, '==', filters[filterKey]));

  return query
    .get()
    .then((reference: any) => {
      return reference
        .docs
        .map((doc: any) => doc.data())
    })
    .then((data: any) => {
      return identity<Types.User[]>(data);
    })
}

export function getNetworkProfiles(filters: any): Promise<Types.Profile[]> {
  let query = db.collection('profiles') as any;

  Object.keys(filters).forEach((filterKey: string) => query = query.where(filterKey, '==', filters[filterKey]));

  return query
    .orderBy('createdAt', 'desc')
    .get()
    .then((reference: any) => {
      return reference
        .docs
        .map((doc: any) => doc.data())
    })
    .then((data: any) => {
      return identity<Types.Profile[]>(data);
    })
}

export function destroyWorkAndProfileRecord (
    organizationId: string | null, 
    userId: string,
    collection: string,
  ) {
  let query = db.collection(collection) as any;

  if(organizationId) {
    query = query.where('organizationId', '==', organizationId);
  }  

  query = query.where('userId', '==', userId);

  return query
    .get()
    .then((querySnapshot: QuerySnapshot) => {
      
      /*
      return querySnapshot.docs[0]
        .ref
        .delete()
        .then((writeResult: WriteResult) => {
          return true;
        })
      */

	  querySnapshot.forEach(function(doc) {
	    doc.ref.delete();
	  });

    })
}

export function destroySingleWorkAndProfileRecord(
	organizationId: string, 
	profileId: string, 
	collection: string, 
	fieldName: string
  ){

  let query = db.collection(collection) as any;

  if(organizationId) {
    query = query.where('organizationId', '==', organizationId);
  }  

  query = query.where(fieldName, '==', profileId);

  return query
    .get()
    .then((querySnapshot: QuerySnapshot) => {
      
      /*
      return querySnapshot.docs[0]
        .ref
        .delete()
        .then((writeResult: WriteResult) => {
          return true;
        })
      */

	  querySnapshot.forEach(function(doc) {
	    doc.ref.delete();
	  });

    })
}


export function getOrganizations(filters: any): Promise<Types.Organization[]> {
  let query = db.collection('organizations') as any;

  Object.keys(filters).forEach((filterKey: string) => query = query.where(filterKey, '==', filters[filterKey]));
  
  if(Object.keys(filters).length <= 0)
    query = query.where("lastPaymentId", "!=", "");

  return query
    .get()
    .then((reference: any) => {
      return reference
        .docs
        .map((doc: any) => doc.data())
    })
    .then((data: any) => {
      return identity<Types.Organization[]>(data);
    })
}

export function getUserById (userId: string): Promise<Types.User> {
  return db
    .collection('users')
    .where('id', '==', userId)
    .get()
    .then(reference => {
      
	if(reference.docs.length > 0) {
	  return reference
		.docs[0]
		.data()
	}

    })
    .then(data => {
      return identity<Types.User>(data);
    })
}

export function getUserExceptions (userId: string): Promise<Types.Exception[]> {
  return db
    .collection('exceptions')
    .where('user.id', '==', userId)
    .get()
    .then(reference => {
      return reference
        .docs
        .map(doc => doc.data())
    })
    .then(data => {
      return identity<Types.Exception[]>(data);
    })
}

export function updateUser(userData: Types.User): Promise<Types.User | void> {
  return db
    .collection('users')
    .doc(userData.id)
    .update(userData)
    .then(reference => {
      return reference;
    });
}

export function getOrganizationById(organizationId: string): Promise<Types.Organization> {
  return db
    .collection('organizations')
    .where('id', '==', organizationId)
    .get()
    .then(reference => {
      return reference
        .docs[0]
        .data()
    })
    .then(data => {
      return identity<Types.Organization>(data);
    })
}


export function updateOrganization(organization: Types.Organization): Promise<Types.Organization | void> {
  return db
    .collection('organizations')
    .doc(organization.id)
    .update(organization)
    .then(reference => {
      return reference;
    });
}

export function sendVerificationCode(email: string): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.post('/admin/send-verification-code', { email: email }, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function impersonate(googleId: string): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.post('/admin/impersonate', { googleId: googleId }, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

