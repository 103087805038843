import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view'

import api from '../api';

import * as Types from 'types';

interface Props {
}

const AdminExceptionsPage: React.FC<Props> = props => {
  const [exceptions, setExceptions] = useState<Types.Exception[]>([]);

  useEffect(() => {
    api
      .exceptions
      .getExceptions()
      .then(exceptions => {
        setExceptions(exceptions);
      })
  }, [])

  return (
    <div className="content flex-column">
      {exceptions.map(exception => {
        return (<div className="exception flex-column">
          <strong>{ exception.exception }</strong>
          <br/>
          Time: { new Date(exception.createdAt * 1000).toLocaleString() }
          <br/>
          Organization Id: { exception.organizationId }
          <br/>
          Endpoint: { exception.url }
          <br/>
          Method: { exception.reqMethod }
          <br/>
          Params: <ReactJson collapsed={true} collapseStringsAfterLength={50} theme={'solarized'} src={exception.reqParams} />
          <br/>
          Body: <ReactJson collapsed={true} collapseStringsAfterLength={50} theme={'solarized'} src={exception.reqBody} />
          <br/>
          Organization: { exception.organization ? <ReactJson collapsed={true} collapseStringsAfterLength={50} theme={'solarized'} src={exception.organization} /> : 'None' }
          <br/>
          User: { exception.user ? <ReactJson collapsed={true} collapseStringsAfterLength={50} theme={'solarized'} src={exception.user} /> : 'None' }
          <br/>
          { exception.stack && exception.stack.split(' at ').map((x, i) => <div>{ i !== 0 && '\u00A0\u00A0\u00A0\u00A0at' } { x }</div>) }
        </div>);
      })}
    </div>
  );
}

export default AdminExceptionsPage;
