import React, { useState } from 'react';

import {ReactComponent as MultiButtonChevronSVG} from '../svgs/multi-button-chevron.svg';

export interface Props {
  enabled?: boolean,
  to?: string,
  color?: string,
  style?: any,
  onConfirm?: any,
  children: React.ReactNode
  options: { title: string; action: () => void }[];
}


const MultiButton = ({
  enabled = true,
  to,
  color,
  style,
  onConfirm,
  children,
  options
}: Props) => {
  const [open, setOpen] = useState(false);

  function onClick(e: any) {
    if(enabled !== false && onConfirm) {
      onConfirm();
    }
    if(!!to) {
      window.location.href = to;
    }
    
    return false;
  }

  function optionsList () {
    return options.map(option => {
      return (<div className="option flex-row" onClick={option.action}> 
        {option.title}
      </div>)
    });
  }

  function handleOpen () {
    if(enabled) {
      if(!open) {
        document.addEventListener('click', () => setOpen(false), { once: true });
      }

      setOpen(!open);

    }

  }

  return (
    <div className={`multi_button_wrapper ${enabled ? '' : 'disabled'}`}>
      <button 
        className={`button multi_button ${color} ${enabled ? '' : 'disabled'}`}
        onClick={onClick}
        style={style}
        disabled={!enabled}
      >
        {children}
      </button>
      <MultiButtonChevronSVG onClick={handleOpen} />
      <div className={`options ${open ? 'open' : 'closed'} flex-column`}>
        {optionsList()}
      </div>
    </div>
  );
}

export default MultiButton;
