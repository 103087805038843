import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import api from '../api';

import * as Types from 'types';
import FeedPostAssignment from './FeedPostAssignment';
import PostAssignmentScroller from './PostAssignmentScroller';
import TabBar from './TabBar';
import Button from './Button';
import NewPostButton from './NewPostButton';


const OutboxPage: React.FC = () => {
  const [posts, setPosts] = useState<Types.JoinedPostAssignment[]>([]);

  return (
    <div className="feed_page_section outbox_page">
      <TabBar
        title="Outbox"
        explainer="These are the posts you've made. Click on a post to check it's status."
        button={
          <NewPostButton />
        }
      > 
        <NavLink className="black-link" to="/app/feed/all">All Posts</NavLink>
        <NavLink className="black-link" to="/app/feed/me">My Posts</NavLink>
        <NavLink className="black-link" to="/app/feed/team">Team Posts</NavLink>
        <NavLink className="black-link" to="/app/feed/by">Posted By</NavLink>
        <NavLink className="black-link" to="/app/feed/score">By Score</NavLink>
      </TabBar>
      <PostAssignmentScroller 
        load={api.postAssignments.getPostAssignmentsForMe}
        posts={posts}
        setPosts={setPosts}
        render={
          (post: Types.JoinedPostAssignment, key: string) => (
            <FeedPostAssignment post={post} key={post.id}/>
          )
        }
      />
    </div>
  );
}

export default OutboxPage;
