import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import api from '../api';
import DOMPurify from 'dompurify';

import FeedPostAssignment from './FeedPostAssignment';
import Back from './Back';

import * as Consts from '../consts';

import * as Types from 'types';

import { Table, Row, Col } from 'react-bootstrap';

import {ReactComponent as ConnectedSVG} from '../svgs/connected.svg';
import {ReactComponent as DisconnectedSVG} from '../svgs/disconnected.svg';

const TeamPostAssignmentPage: React.FC = () => {
  const [workForPost, setWorkForPost] = useState<Types.JoinedWork[]>([]);
  const [post, setPost] = useState<Types.JoinedPostAssignment>({
    id: '',
    description: '',
    url: '',
    images: [],
    createdAt: 0,
    deleted: false,
    updatedAt: 0,
    user: {
      id: '',
      name: '',
      email: '',
      deleted: false,
      notificationSettingIds: [],
      createdAt: 0,
      lastLogin: 0,
      updatedAt: 0,
      pause: false,
      organizationId: '',
      permissionIds: [],
      inviteStatus: 0,
    },
    profiles: [],
    scheduled: 0,
    organizationId: '',
    status: 0,
    authorId: '',
    approvedBy: '',
  });

  const uParams = useParams() as { teamId: string, postId: string; };

  useEffect(() => {
    if(uParams.postId) {
      api
        .postAssignments
        .getTeamPostAssignmentById(uParams.teamId, uParams.postId)
        .then((post: Types.JoinedPostAssignment) => {
          setPost(post);
        })
        .catch((err: any) => {
          console.log(err);
        })

      api
        .postAssignments
        .getWorkForTeamPostAssignment(uParams.teamId, uParams.postId)
        .then((work: Types.JoinedWork[]) => {
          setWorkForPost(work);
        })
        .catch((err: any) => {
          console.log(err);
        })
    }

  }, [uParams.postId])

  function createMarkup(networkIcon: string) {
    return { __html: DOMPurify.sanitize(networkIcon) };
  }

  function renderStatus (status: number) {
    switch (status) {
      case Consts.WorkStatus.scheduled :
        return (<div className="status scheduled flex-row">
          Scheduled
        </div>);

      case Consts.WorkStatus.success :
        return (<div className="status success flex-row">
          Success
        </div>);

      case Consts.WorkStatus.failed :
        return (<div className="status bounced flex-row">
          Bounced
        </div>);

      case Consts.WorkStatus.processing :
        return (<div className="status scheduled flex-row">
          Processing
        </div>);

      case Consts.WorkStatus.approvalRequested :
        return (<div className="status scheduled flex-row">
          Approval Requested
        </div>);
    }
  }

  function workList () {
    if(workForPost) {
      return (
        <div className='table_responsive'><Table className="profiles-list" responsive="lg">
          <thead>
          <tr>
            <th>
              Profile Name
            </th>
            <th>
              Result
            </th>
            <th>
              Status
            </th>
          </tr>
          </thead>
          <tbody>
          {workForPost &&
            workForPost.map(workItem => {
              return (
                <tr className="work-item" key={workItem.id}>
                  <td>
                    <div className="profile flex-row">
                      <div className="network-icon" dangerouslySetInnerHTML={createMarkup(workItem.profile.network.icon)} />
                      <div>{workItem.profile.name}</div>
                    </div>
                  </td>
                  <td>
                    {workItem.result && workItem.result.shortMessage}
                  </td>
                  <td>
                    <div className="status-row">
                      {renderStatus(workItem.status)}
              {workItem.profile.status === Consts.ProfileStatus.normal ? <ConnectedSVG /> : <DisconnectedSVG />}
                    </div>
                  </td>
                </tr>
              );
            })
          }
          </tbody>
        </Table>
        </div>
      )
    }
  }

  return (
    <div className="feed_page_section post_detail_page">
      <Back />
      
      <div className="feed_tabbar_title">
<Row className="app_feed_row">
<Col className="app_col app_feed_title" lg={12} md={12} sm={12}>
<h1>Post Details</h1>
</Col>
  </Row>
  </div>

      <FeedPostAssignment post={post} teamId={uParams.teamId}/>
      <div className="profiles_content_list">
        {workList()}
      </div>
    </div>
  );
}

export default TeamPostAssignmentPage;