import React, { useEffect, useState } from 'react';

import {ReactComponent as CheckBoxFalseSVG} from '../svgs/checkbox-false.svg';
import {ReactComponent as CheckBoxTrueSVG} from '../svgs/checkbox-true.svg';
import {ReactComponent as CheckBoxSomeSVG} from '../svgs/checkbox-some.svg';

/*
 0 = unchecked
 1 = checked
 2 = partially checked
 */

export interface Props {
  children: React.ReactNode;
  onChange: (selected: number) => void;
  initialChecked?: number;
  idName: string;
  disabled?: boolean;
}


const TripleCheckbox = ({
  children,
  onChange,
  initialChecked,
  idName,
  disabled
}: Props) => {
  const [checked, setChecked] = useState(0);

  useEffect(() => {
    if(initialChecked !== undefined) {
      setChecked(initialChecked);
    }
  }, [initialChecked])

  function handleClick () {
    if(checked === 1 || checked === 2) {
      setChecked(0);
      onChange(0);
    } else {
      setChecked(1);
      onChange(1);
    }
  }

  return (
    <div className="triple-checkbox flex-row" onClick={handleClick}>
      { 
        (checked === 1 && (<CheckBoxTrueSVG />))
        || (checked === 2 && (<CheckBoxSomeSVG />))
        || (<CheckBoxFalseSVG />)
      }
      {children}
    </div>
  );
}

export default TripleCheckbox;
