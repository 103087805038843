import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import UserContext from './UserContext';

import * as Types from 'types';

interface Props {
  log: Types.Log;
}

const Log: React.FC<Props> = props => {
  const history = useHistory();

  const user = useContext(UserContext) as Types.User;

  function handleClick () {
    const resourceType = Object.keys(props.log.resources)[0];
    const resourceId = Object.entries(props.log.resources)[0][1];

    if(resourceType === 'userId' && resourceId !== user.id) { 
      history.push(`/app/user/${resourceId}`);
    } else if(resourceType === 'userId' && resourceId === user.id) {
      history.push(`/app/me`)
    } else if(resourceType === 'postId') {
      history.push(`/app/post/${resourceId}`);
    } else if(resourceType === 'organizationId') {
      history.push(`/app/organization`);
    } else if(resourceType === 'organizationId') {
      history.push(`/app/organization`);
    } else if(resourceType === 'profileId') {
      history.push(`/app/networks`);
    }
  }

  return (
    <tr className="log" onClick={handleClick}>
      <td>{new Date(props.log.createdAt * 1000).toLocaleString().split(',')[0]}</td> 
      <td>{new Date(props.log.createdAt * 1000).toLocaleString().split(',')[1]}</td> 
      <td>{props.log.text}</td>
      <td>{props.log.location ? `${props.log.location.city}, ${props.log.location.region}, ${props.log.location.country}` : 'N/A'}</td>
      <td>{props.log.ip}</td>
    </tr>
  );
}

export default Log;
