import React, { useState } from 'react';
import {loadStripe, StripeCardElement } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import Spinner from './Spinner';

import api from '../api';

import { Col, Row } from 'react-bootstrap';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-205261524-1');

const CheckoutForm: React.FC<{ hasStripeId?: string, onComplete: () => void, planId: string, price: string, paymentAction: string }> = props => {
  const [response, setResponse] = useState<null | { data: { message: string, success: boolean } }>(null);
  const [responseOn, setResponseOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  function showResponse () {
    if(response) {
      return <div className={`response-box ${responseOn ? 'on' : '' } ${response.data.success ? 'green' : 'red'} flex-column`}>{response.data.message}</div>;
    }
  }

  function handleSubmit (event: React.FormEvent<HTMLFormElement>) {

    setLoading(true);
    event.preventDefault();
    let paymentMethodId:string = '';
    if(stripe && elements) {
      stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement) as StripeCardElement,
      })
      .then((response: any) => {
        if(!response.error) {
	  paymentMethodId = response.paymentMethod.id;
          if(!props.hasStripeId) {
            return api
              .payments
              .createCustomer(response.paymentMethod.id, props.planId);
          } else {
            if(response.paymentMethod) {
              return api
                .payments
                .attachMethod(response.paymentMethod.id)
                .then(() => {
                  api
                   .payments
                   .updateCustomer({
                     invoice_settings: {
                       default_payment_method: response.paymentMethod.id
                     }
                   });
                })
            }
          }
        } else {
          console.log(response);
          setResponse({ data: { success: false, message: response.error.message } });
          setResponseOn(true);
          setTimeout(() => setResponseOn(false), 4000);
          return null;
        }
      })
      .then((resp: any) => {
	      if(resp)
	      {
		let transId:string = resp.id + '_PID_' + paymentMethodId;
		let productName:string = 'BrandSocial ' + props.planId;
		let categoryName:string = 'BrandSocial Billing';
		if(props.paymentAction === 'Test')
		{
		   productName = 'Test BrandSocial ' + props.planId;
		   categoryName = 'Test BrandSocial Billing';
		}
		ReactGA.plugin.require('ecommerce');
		ReactGA.plugin.execute(
		      'ecommerce',
		      'addItem',
		      {
			id: transId,				// Transaction ID. Required.
			name: productName,			// Product name. Required.
			sku: props.planId,			// SKU/code. seems to be required
			price: props.price,			// Unit price.
			category: categoryName,			// Category or variation.
			quantity: '1',				// Quantity.
		      }
		);
		ReactGA.plugin.execute(
		      'ecommerce',
		      'addTransaction',
		      {
			id: transId,				// the same as for addItem to connect them
			affiliation: 'BrandSocial Store',	// Affiliation or store name.
			revenue: props.price,			// obviously it's price * quantity
		      }
		);
		ReactGA.plugin.execute('ecommerce', 'send', {});
		ReactGA.plugin.execute('ecommerce', 'clear', {});
		}

        if(elements.getElement(CardElement)) {
          (elements.getElement(CardElement) as StripeCardElement).clear();
        }
        setLoading(false);
        props.onComplete();
      })
      .catch(err => {
        console.log(err);
      })
    }
  };

    return (
      <form onSubmit={handleSubmit}>
        <CardElement />
        <Row className="billing_btn_row">
        <Col lg={12} md={12} sm={12} className="billing_btn_col">
          <div className='submit_btn' align="right">
        <button type="submit" color="orange" disabled={loading} className={`button ${loading && 'disabled'}`}>
          Submit
        </button>
        </div>
        </Col>  
        </Row>
        
        {showResponse()}
      </form>
    );
};


/*********************** Comment For Live Payments ***********
const stripePromise = loadStripe('pk_test_51HcJblCWMuDN0JnejUlFcQve6Yj3n45N39msK5F9IIPGKH3k1TpWVoGlmqNXNmlKwNeTCkLHmXFxeHLgwtV81a8d00psn2Voo5');
*/

/*********************** Uncomment For Live Payments */
const stripePromise = loadStripe('pk_live_51HcJblCWMuDN0Jneg9zcY606UszBberBN1ZfmgzXL2LH5gyLHa3xcgK3mkdJGMZKYiVF0cFZPC2g1LpVPtSRFqC000bC8NSNcR');


const Stripe: React.FC<{ hasStripeId?: string, onComplete: () => void, planId: string, price: string, paymentAction: string }> = props => (
  <Elements stripe={stripePromise}>
    <CheckoutForm hasStripeId={props.hasStripeId} onComplete={props.onComplete} planId={props.planId} price={props.price} paymentAction={props.paymentAction} />
  </Elements>
);

export default Stripe;
