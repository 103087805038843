export enum OrgStatus {
  trial,
  normal,
  invalidPayment,
  overLimit,
  canceled,
  invalidEmail,
  pendingApproval,
  dormant,
  expired
}

export const TrialDays = 90;

export enum UserPause {
  normal,
  system,
  user,
  dev
}

export enum ProfileStatus {
  normal,
  reauth,
  banned,
  invalid,
  deleted
}

export enum NetworkAuth {
  none,
  emailPassword,
  usernamePassword,
  usernameOrEmailPassword,
  oauth1,
  oauth2,
  withLink,
  brandSocial
}

export enum ResultType {
  submit,
  metaData
}

export enum WorkStatus {
  scheduled,
  processing,
  failed,
  success,
  approvalRequested
}

export enum PostAssignmentStatus {
  normal,
  draft,
  approvalRequested,
  shared
}

export enum PermissionType {
  organization,
  team,
  both
}

export enum InviteStatus {
  joined,
  invited
}

export const responseToastLifetime = 4000;
