import React from 'react';
import ReactTooltip from 'react-tooltip';


interface Props {
  children: React.ReactNode,
  element?: any;
}

const Tooltip: React.FC<Props> = props => {

  return (
    <div className="tooltip">
      <div data-tip data-for='global' className={`${!props.element && 'tooltip-badge'} flex-row`}>{props.element || ' ? '}</div>
      <ReactTooltip 
        className="tooltip-body" 
        id='global' 
        aria-haspopup='true' 
        effect='solid' 
        place='right'
      >
        {props.children}
      </ReactTooltip>
    </div>
  );
}

export default Tooltip;
