import React from 'react';
import { useHistory } from 'react-router-dom';

import * as Types from 'types';


import {ReactComponent as ConnectedSVG} from '../svgs/connected.svg';
import {ReactComponent as DisconnectedSVG} from '../svgs/disconnected.svg';

interface Props {
  profile: Types.Profile;
};

const Profile: React.FC<Props> = props => {
  const history = useHistory();

  function goToProfile () {
    history.push(`/app/profile/${props.profile.id}`);
  }

  return (
    <div className="profile network_profile_info" onClick={goToProfile}>
      
        <p className="cursor">{props.profile.username}
     
        
       { 
        props.profile.status === 0
        ?  <ConnectedSVG />
        :  <DisconnectedSVG />
      }
         </p>
    </div>
  );
}

export default Profile;
