import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

interface AdminRouteProps extends RouteProps {
  isAuthenticated: boolean;
  isOWAdmin: boolean;
  isAllowed: boolean;
  restrictedPath: string;
  path: string;
  authenticationPath: string;
}

export const AdminRoute: React.FC<AdminRouteProps> = props => {
  let redirectPath = '';
  if (!props.isAuthenticated || !props.isOWAdmin) {
    redirectPath = props.authenticationPath;
    localStorage.setItem('targetPath', window.location.pathname)
  }
  if (props.isAuthenticated && !props.isAllowed) {
    redirectPath = props.restrictedPath;
  }

  if (redirectPath) {
    const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
    return <Route {...props} component={renderComponent} render={undefined} />;
  } else {
    return <Route {...props} />;
  }
};

export default AdminRoute;
