import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Button} from "react-bootstrap";

// import HeroBanner from './HeroBanner';
// import Benefits from './Benefits';
// import SideBySide from './SideBySide';
import { useMediaQuery } from 'react-responsive';

import HeaderbannerImage from '../public/images/hero-right-people.png';

import Headingborder from '../public/images/heading-border-line.png';

import BenefitsIcon1 from '../public/images/benefits-icon1.png';
import BenefitsIcon2 from '../public/images/benefits-icon2.png';
import BenefitsIcon3 from '../public/images/benefits-icon3.png';
import BenefitsIcon4 from '../public/images/benefits-icon4.png';

import FeaturesImage from '../public/images/home_feature_image.png';
import FeatureIcon1 from '../public/images/fea_icon1.png';
import FeatureIcon2 from '../public/images/fea_icon2.png';
import FeatureIcon3 from '../public/images/fea_icon3.png';
import FeatureIcon4 from '../public/images/fea_icon4.png';
import FeatureIcon5 from '../public/images/fea_icon5.png';

import AllsocialImage from '../public/images/socials-left.png';

import EnsureImage from '../public/images/side-by-side-left.png';

import BrandvoiceImage from '../public/images/brand-voice.png';

import ProtectImage from '../public/images/side-by-side-right.png';

import PricedIcon1 from '../public/images/priced-icon1.png';
import PricedIcon2 from '../public/images/priced-icon2.png';
import PricedIcon3 from '../public/images/priced-icon3.png';
import Tick from '../public/images/tick.png';
import BrandvoiceMobile from '../public/images/brand-voice-mobile.png';

import { useHistory } from 'react-router-dom';

export interface HomeRouteProps {
  isAuthenticated: boolean;
}

const Home: React.FC<HomeRouteProps> = props => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 1000px)'
  })

  if(props.isAuthenticated || localStorage.getItem('isLoggedIn') == 'Yes') {
    window.location.href = '/app/feed/all';
  }

  function scheduleDemo(){
	window.open('https://calendly.com/ryanonlywire', '_blank');
  }

  const history = useHistory();

  function startFreeTrail() {

    //history.push('/register');  

    window.location.href = "/register";
    
    return false;
  }

  return (
    <div className="home-page-container">
    
      {/* <HeroBanner />

      <Benefits />

      <SideBySide />

  
      <div className="last-button flex-column ">
	<div className="flex-row flex-direction-column buttons-direction">
          <Button
            style={{height: '56px', width: '280px', fontSize: '16px', borderRadius: '6px', color: 'white', fontWeight: 'normal', margin: '15px'}}
            color="orange"
            to={isMobile ? "mobile" : "/register"}
          >
            Get Started for Free
          </Button>

	  <Button
            style={{height: '56px', width: '280px', fontSize: '16px', borderRadius: '6px', color: 'white', fontWeight: 'normal', margin: '15px'}}
            color="black"
	    onConfirm={scheduleDemo}            
          >
            Schedule a Demo
          </Button>
	</div>
      </div> */}



        <div className='hero-banner-section'>
          <Container>
            <Row>
            <Col lg={6} sm={12}>
            <div className='hero-banner-heading'>
            <h2><span className='hero-capitalltr'>E</span>mpower your team to promote brand-approved
             content on social media.</h2>
          </div>
          <div className='hero-banner-para'>
          <p>BrandSocial is a simple and cost-effective platform for growing brand awareness
             and managing your team's social content on LinkedIn, Facebook, Pinterest and Twitter.</p>
          </div>

              <div className='hero-banner-price'>
          Only<span className='hero-price'> $99/mo</span> 
          </div>
          
          <div className='banner_get_btn'>
            <Button variant="secondary" size="lg" onClick={startFreeTrail}>Start 90-day Free Trial</Button>
            </div>

              </Col>

              <Col lg={6} sm={12}>
              <div className='hero-banner-right-side'>
                  <img src={HeaderbannerImage}/>
              </div>

                </Col>

            </Row>
          </Container>
     
        </div>



        <div className='hero-benefits-section'>
          <Container>
            <Row>
                <Col lg={12} md={12} sm={12}>
                    <div className='hero-benefits-heading'>
                  <h2>Benefits</h2>
                  </div>
                  <div className='hero-heading-border'>
                  <img src={ Headingborder } />
                  </div>
              </Col>
            </Row>

            <div className='hero-benifits-icons'>
              <Row>
                <Col lg={3} sm={12}>
                <div className='benifits-col'>
                    <div className='benefits-image'>
                    <img src={ BenefitsIcon1 } className='benefits-img' />
                    </div>
                    <div className='benefits-para'>
                 <p> Ensure compliance with your social media policy</p>
                  </div>
                  </div>
                  </Col>

                  <Col lg={3} sm={12}>
                  <div className='benifits-col'>
                    <div className='benefits-image'>
                      <img src={ BenefitsIcon2 } className='benefits-img maintain_col' />
                      </div>
                      <div className='benefits-para'>
                    <p>Maintain a consistent brand voice on top social media sites</p>
                    </div>
                    </div>
                  </Col>

                  <Col lg={3} sm={12}>
                    <div className='benifits-col'>
                      <div className='benefits-image'>
                        <img src={ BenefitsIcon3 } className='benefits-img' />
                        </div>
                        <div className='benefits-para'>
                       <p>Strengthen your brand presence and brand reputation</p>
                      </div>
                      </div>
                  </Col>

                  <Col lg={3} sm={12}>
                    <div className='benifits-col'>
                    <div className='benefits-image'>
                      <img src={ BenefitsIcon4 } className='benefits-img empower_col' />
                      </div>
                      <div className='benefits-para'>
                      <p>Empower your team to generate and distribute quality content</p>
                    </div>
                    </div>
                  </Col>
            </Row>
            </div>
        </Container>
      </div>



      <div className="hero-fetures-section">
                <Container>
                <Row>
                  <Col lg={12} md={12} sm={12}>
                      <div className='hero-fetures-heading'>
                      <h2>Features</h2> 
                    </div>
                    <div className='hero-fetures-border'>
                      <img src={ Headingborder } />
                      </div>
                </Col>

                    
                </Row>

             
                    <Row className="feature-page-details">
                    <Col lg={6} md={6} sm={12} className="">
                    <Row>
                    <Col lg={6} md={6} sm={12} className="card-col assign_role">
                          <div className='features-col'>
                        <div className='hero-feature-icon'>
                          <img src={ FeatureIcon1 } />
                        </div>
                        <div className='feature-heading'>
                          ASSIGN ROLE
                        </div>
                        <div className='feature-para'>
                          Assign role and team based permissions
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} className="card-col socialize_reshare">
                    <div className='features-col'>
                        <div className='hero-feature-icon'>
                          <img src={ FeatureIcon2 } />
                        </div>
                        <div className='feature-heading'>
                        SOCIALIZE & RESHARE
                        </div>
                        <div className='feature-para'>
                        Socialize and re-share content through a single interface
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} className="card-col enforce_approvals">
                    <div className='features-col'>
                        <div className='hero-feature-icon'>
                          <img src={ FeatureIcon3 } />
                        </div>
                        <div className='feature-heading'>
                        ENFORCE APPROVALS
                        </div>
                        <div className='feature-para'>
                        Enforce approval on posts to ensure brand-approved content and compliance
                        </div>
                      </div>
                    </Col>

                    <Col lg={6} md={6} sm={12} className="card-col workflows">
                    <div className='features-col'>
                        <div className='hero-feature-icon'>
                          <img src={ FeatureIcon4 } />
                        </div>
                        <div className='feature-heading'>
                        WORKFLOWS
                        </div>
                        <div className='feature-para'>
                        Create workflows for drafting and approving posts
                        </div>
                      </div>
                        </Col>


                        <Col lg={6} md={6} sm={12} className="card-col">
                        {/* <div className="Card">
                        <img src="./assets/document 1.png" class="img-fluid"></img>
                        <div className="Title">WORKFLOWS</div>
                        <div className="card-text">Create workflows for drafting and approving posts</div>
                        </div> */}
                        </Col>

                        <Col lg={6} md={6} sm={12} className="card-col schedule">
                        <div className='features-col'>
                        <div className='hero-feature-icon'>
                          <img src={ FeatureIcon5 } />
                        </div>
                        <div className='feature-heading'>
                        SCHEDULE
                        </div>
                        <div className='feature-para'>
                        Schedule and preview posts before they are submitted
                        </div>
                      </div>
                        </Col>
                       </Row>
               
                        </Col>
                        <Col lg={6} md={6} sm={12} className="feature_right_col">
                    <div className='feature_img'><img src={ FeaturesImage } /></div>
                        </Col>
                   
                    </Row>
                 </Container>
            </div>



          <div className='hero-allsocials-section'>
            <Container>
              <Row>
              <Col lg={12} md={12} sm={12}>
                  <div className='hero-allsocials-heading'>
                   <h2>All Socials In One Place</h2> 
                </div>
                <div className='hero-allsocials-border'>
                  <img src={ Headingborder } />
                  </div>
                </Col>
                </Row>
              
                <Row className='social_manage_row'>
                <Col lg={6} sm={12} className="social_left_col">
                  <div className='allsocial-para'>
                    <p>Manage your brand's content on the world's <br/>best social networks. BrandSocial services <br/>the leading social media sites.</p>
                    <div className='d-none d-lg-block social_submit_btn'>
                    <Button size="lg" onClick={startFreeTrail}>Submit Now for Free</Button>
                    </div>
                  </div>
                </Col>

                <Col lg={6} sm={12} className="social_right_col">
                  <div className='hero-allsocils-left-side'>
                    <img src={ AllsocialImage } />
                 </div>
                </Col>
                 
                <Col lg={12} md={12} sm={12} className="d-sm-block d-lg-none social_mobile_btn">
                <div className='social_submit_btn'>
                    <Button size="lg" onClick={startFreeTrail}>Submit Now for Free</Button>
                    </div>
                    </Col>

              </Row>
            </Container>
            </div>



            <div className='hero-ensure-section'>
              <Container>
                <Row className='ensure_row'>
                <Col lg={6} sm={12}>
                    <div className='hero-ensure-left-side'>
                  <h2 className='ensure-heading'>Ensure Social Compliance</h2>
                  <div className='d-sm-block d-lg-none hero-ensure-border'>
                  <img src={ Headingborder } />
                  </div>
                  <p>Enforce Your Social Media Compliance Policy With A Simple Tool That Your Team Will Appreciate And Enjoy Using.</p>
                </div>   
                </Col>

                <Col lg={6} sm={12}>
                  <div className='ensure-image'><img src={ EnsureImage } /></div>
                  </Col>
                </Row>
              </Container>
            </div>


            <div className='hero-brandvoice-section'>
              <Container>
                <Row>
                <Col lg={12} md={12} sm={12}>
                <div className='hero-brandvoice-heading'>
                 <h2>Maintain A Consistent Brand Voice</h2>
                </div>
                <div className='hero-brandvoice-border'>
                  <img src={ Headingborder } />
                  </div>
                  <div className='hero-brandvoice-para'>
                  Ensure That Your Team Is Using Your Approved Content,
                  Language And Tone. Prevent Typos And Embarrassing Posts From Your Team Members.
              </div>
                </Col>
               </Row>
               </Container>
              
              <div className='brand_img_section'>
               <Container fluid>
               <Row>
                <Col lg={12} md={12} sm={12}>
                  <div className='brandvoice-image'>
                  <img src={ BrandvoiceImage } className='d-none d-lg-block'/>
                  <img src={ BrandvoiceMobile } className='d-sm-block d-lg-none'/>
                    </div>
                </Col>
                </Row>
              </Container>
            </div>
          
            </div>



            <div className='hero-protect-section'>
              <Container>
                <Row className='protect_row'>
                <Col lg={6} sm={12} className="protect_left">
                <div className='protect_strength_info'>
                <h2 className='protect-heading'>Protect And Strengthen Your Brand</h2>
                <div className='d-sm-block d-lg-none hero-protect-border'>
                  <img src={ Headingborder } />
                  </div>
                <p>The BrandSocial Platform Feed Makes It Easy For Team Members To Share And Distribute Content, Encouraging Collaboration.</p>
                </div>
                </Col>

                <Col lg={6} sm={12} className="protect_right">
                <div className='protect-image'>
                <img src={ ProtectImage } />
                </div>
                </Col>

                </Row>
              </Container>
            </div>


            <div className='hero-priced-section'>
              <Container>

                <Row>
                <Col lg={12} md={12} sm={12}>
                  <div className='price_scale_heading'>
                <h2>Priced To Scale</h2>
                <div className='hero-priced-border'>
                <img src={ Headingborder } />
                </div>
                <h6>Only $99 Per Month Which Includes:</h6>
              </div>
                </Col>
               </Row>
              
              <div className='price_col_list'>
                <Row>
                    <Col lg={4} sm={12} className="price_col">
                        <div className="price_info_box">
                        <div className="tickmark"><img src={ Tick } /></div>
                        <div className='priced-box'><img src={ PricedIcon1 } />
                        <h4>Unlimited Users</h4>
                        </div>
                        </div>
                    </Col>
                    <Col lg={4} sm={12} className="price_col">
                    <div className="price_info_box">
                    <div className="tickmark"><img src={ Tick } /></div>
                     <div className='priced-box'><img src={ PricedIcon2 } />
                    <h4>Unlimited Social Profiles</h4>
                    </div>
                    </div>
                    </Col>
                    <Col lg={4} sm={12} className="price_col">
                    <div className="price_info_box">
                    <div className="tickmark"><img src={ Tick } /></div>
                    <div className='priced-box'><img src={ PricedIcon3 } />
                    <h4>Unlimited Team Profiles</h4>
                    </div>
                    </div>
                    </Col>
                     </Row>
                     </div>
                     
                     <div className='price_list_btns'>
                        <Row>
                        <Col lg={3} md={1} sm={12} className="empty_col">
                        </Col>

                        <Col lg={3} md={5} sm={12} className="get_started_btn">
                        <Button size="lg" onClick={startFreeTrail}>Get Started for Free</Button>
                        </Col>

                        <Col lg={3} md={5} sm={12} className="schedule_demo">
                        <Button size="lg" onClick={scheduleDemo}>Schedule a Demo</Button>
                        </Col>

                    <Col lg={3} md={1} sm={12} className="empty_col">
                        </Col>
                        </Row>
                        </div>

              </Container>
            </div>







    </div>
  );
}

export default Home;
