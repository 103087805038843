import React from 'react';
import { Link } from 'react-router-dom';

import {ReactComponent as BSLogoSVG} from '../svgs/bslogo-white.svg';
import {ReactComponent as CouchSVG} from '../svgs/couch.svg';

interface Props {
}

const MobilePage: React.FC<Props> = props => {

  return (
    <div className="mobile page flex-column">
      <Link className="left flex-row" to="/"><BSLogoSVG /></Link>
      <CouchSVG className="couch"/>
      BrandSocial is available on your desktop or laptop computer
    </div>
  );
}

export default MobilePage;
