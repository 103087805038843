import React from 'react';

import Calendar from 'react-calendar';
import Button from './Button';
import Select from './Select';

import {ReactComponent as CloseSVG} from '../svgs/close.svg';

export interface Props {
  closeModal: () => void;
  proceed: () => void;
  selectedDate: number;
  setDateTime: (dateTime: number) => void;
}
const DateTimePicker: React.FC<Props> = props => {

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if(e.key === 'Escape') {
      props.closeModal();
    }
  }

  function setHour (e: any) {
    props.setDateTime(new Date(props.selectedDate * 1000).setHours(parseInt(e)) / 1000);
  }

  function setMinute (e: any) {
    props.setDateTime(new Date(props.selectedDate * 1000).setMinutes(parseInt(e)) / 1000);
  }

  function setMeridiem (e: any) {
    const hours = new Date(props.selectedDate * 1000).getHours();
    const newMeridiem = e;

    if(newMeridiem === 'AM') {
      props.setDateTime(new Date(props.selectedDate * 1000).setHours(hours - 12) / 1000);
    } else if (newMeridiem === 'PM') {
      props.setDateTime(new Date(props.selectedDate * 1000).setHours(hours + 12) / 1000);
    }
  }

  function dateFromUTC (utc: number) {
    return new Date(utc * 1000);
  }

  function get12Hour () {
    let hour = dateFromUTC(props.selectedDate).getHours();

    if (hour > 12) {
      hour -= 12;
    }

    return +hour;
  }

  function getMinutes () {
    const minutes = dateFromUTC(props.selectedDate).getMinutes();

    if (minutes < 15) {
      return '00';
    } else if (minutes < 30) {
      return '15';
    } else if (minutes < 45) {
      return '30';
    } else if (minutes < 60) {
      return '45';
    }
  }

  function getMeridiem () {
    return dateFromUTC(props.selectedDate).getHours() >= 12 ? 'PM' : 'AM';
  }

  function onClickDay(day: Date) {
    const newDay = day.getDate();
    const newMonth = day.getMonth();
    const newYear = day.getFullYear();
    const oldTime = dateFromUTC(props.selectedDate);
    oldTime.setDate(newDay);
    oldTime.setMonth(newMonth);
    oldTime.setFullYear(newYear);
    props.setDateTime(oldTime.getTime() / 1000);
  }

  return (
      <div className="modal-container flex-column" onKeyUp={handleKeyUp}>
        <div onClick={props.closeModal} className="modal-background"></div>
        <div className="date-time-picker modal flex-column">
          <div className="modal-header flex-row">
            <h1>Schedule Post</h1>
            <div onClick={props.closeModal} className="close">
              <CloseSVG />
            </div>
          </div>
          <div className="content flex-column">
            <Calendar
              className="calendar"
              calendarType="US"
              minDate={new Date()}
              value={new Date(props.selectedDate * 1000)}
              minDetail={'month'}
              onClickDay={onClickDay}
            />
            <div className="time-picker flex-row">
              <Select 
                onChange={setHour} 
                value={get12Hour()} 
                placeholder={get12Hour().toString()}
                options={[
                  {value:"1", labelHeading:"1"},
                  {value:"2", labelHeading:"2"},
                  {value:"3", labelHeading:"3"},
                  {value:"4", labelHeading:"4"},
                  {value:"5", labelHeading:"5"},
                  {value:"6", labelHeading:"6"},
                  {value:"7", labelHeading:"7"},
                  {value:"8", labelHeading:"8"},
                  {value:"9", labelHeading:"9"},
                  {value:"10", labelHeading:"10"},
                  {value:"11", labelHeading:"11"},
                  {value:"12", labelHeading:"12"},
                ]}
              />
              <div className="colon flex-row">:</div>
              <Select 
                onChange={setMinute} 
                value={getMinutes()} 
                placeholder={getMinutes() as string}
                options={[
                  {value:"00", labelHeading:"00"},
                  {value:"15", labelHeading:"15"},
                  {value:"30", labelHeading:"30"},
                  {value:"45", labelHeading:"45"},
                ]}
              />
              <Select 
                onChange={setMeridiem} 
                placeholder={getMeridiem()}
		value={getMeridiem()}
                options={[
                  {value:"AM", labelHeading:"AM"},
                  {value:"PM", labelHeading:"PM"},
                ]}
              />
            </div>
            <div className="date_picker_buttons schedule_buttons flex-row">
              <Button color={'light-orange'} onConfirm={() => props.setDateTime(new Date().getTime() / 1000)}>Clear</Button>
              <Button color={'orange'} onConfirm={props.proceed}>Next</Button>
            </div>
          </div>
        </div>
      </div>
  );
}

export default DateTimePicker;
