import React from 'react';

import {ReactComponent as AccordianEndSVG} from '../svgs/accordian-end.svg';
import {ReactComponent as ChevronUpSVG} from '../svgs/chevron-up-orange.svg';
import {ReactComponent as ChevronDownSVG} from '../svgs/chevron-down.svg';

interface Props {
  text: string;
  defaultLink: string;
  icon: { on: React.ReactNode,  off: React.ReactNode };
  children: React.ReactNode;
  active: boolean;
  handleClick: (name: string, defaultLink: string) => void;
}

const Accordian: React.FC<Props> = props => {
  return (
    <div className={`accordian flex-column ${props.active ? 'active' : ''}`}>
      <div 
        className={`accordian-button flex-row ${props.active ? 'active' : ''}`}
        onClick={() => props.handleClick(props.text, props.defaultLink)}
      >
        <div className={`end ${props.active ? '' : 'off'}`}>
          <AccordianEndSVG />
        </div>
        <div className="left flex-row">
          {props.active ? props.icon.on : props.icon.off}
          {props.text}
        </div>
        {props.active ? <ChevronUpSVG /> : <ChevronDownSVG />}
      </div>
      <div className="accordian-links flex-column">
        {props.children}
      </div>
    </div>
  );
}

export default Accordian;
