import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import api from '../api';
import DOMPurify from 'dompurify';

import FeedPostAssignment from './FeedPostAssignment';
import Back from './Back';
import Spinner from './Spinner';

import * as Consts from '../consts';

import * as Types from 'types';

import { Table, Col, Row } from 'react-bootstrap';

import {ReactComponent as ConnectedSVG} from '../svgs/connected.svg';
import {ReactComponent as DisconnectedSVG} from '../svgs/disconnected.svg';

const PostAssignmentPage: React.FC = () => {
  const history = useHistory();
  const [workForPost, setWorkForPost] = useState<Types.JoinedWork[]>([]);
  const [shares, setShares] = useState<Types.JoinedPostAssignment[]>([])
  const [post, setPost] = useState<Types.JoinedPostAssignment>({
    id: '',
    description: '',
    url: '',
    images: [],
    createdAt: 0,
    updatedAt: 0,
    deleted: false,
    user: {
      id: '',
      name: '',
      email: '',
      createdAt: 0,
      lastLogin: 0,
      deleted: false,
      notificationSettingIds: [],
      updatedAt: 0,
      pause: false,
      organizationId: '',
      permissionIds: [],
      inviteStatus: 0,
    },
    profiles: [],
    scheduled: 0,
    organizationId: '',
    status: 0,
    authorId: '',
    approvedBy: '',
  });
  const [postLoaded, setPostLoaded] = useState(false);
  const [workLoaded, setWorkLoaded] = useState(false);

  const uParams = useParams() as { postId: string; };

  useEffect(() => {
    if(uParams.postId) {
      api
        .postAssignments
        .getPostAssignmentById(uParams.postId)
        .then((post: Types.JoinedPostAssignment) => {
          setPost(post);
          setPostLoaded(true);
          return api
            .postAssignments
            .getPostAssignmentsByPostId(post.postId as string)
        })
        .then(postAssignments => {
          setShares(postAssignments.filter(postAssignment => postAssignment.id !== uParams.postId));
        })
        .catch((err: any) => {
          console.log(err);
        })

      api
        .postAssignments
        .getWorkForPostAssignment(uParams.postId)
        .then((work: Types.JoinedWork[]) => {
          setWorkForPost(work);
          setWorkLoaded(true);
        })
        .catch((err: any) => {
          console.log(err);
        })
    }

  }, [uParams.postId])

  function createMarkup(networkIcon: string) {
    return { __html: DOMPurify.sanitize(networkIcon) };
  }

  function renderPostStatus (status: number) {
    switch (status) {
      case Consts.PostAssignmentStatus.normal :
        return (<div className="status success">
          Accepted
        </div>);

      case Consts.PostAssignmentStatus.shared :
        return (<div className="status scheduled">
          Shared
        </div>);
    }
  }

  function renderStatus (status: number) {
    switch (status) {
      case Consts.WorkStatus.scheduled :
        return (<div className="status scheduled">
          Scheduled
        </div>);

      case Consts.WorkStatus.success :
        return (<div className="status success">
          Success
        </div>);

      case Consts.WorkStatus.failed :
        return (<div className="status bounced">
          Bounced
        </div>);

      case Consts.WorkStatus.processing :
        return (<div className="status scheduled">
          Processing
        </div>);

      case Consts.WorkStatus.approvalRequested :
        return (<div className="status scheduled">
          Approval Requested
        </div>);
    }
  }

  function shareList () {
    if(shares) {
      return (
        <div className='table_responsive'><Table className="profiles-list" responsive="lg">
          <thead>
          <tr>
            <th>
              User Name
            </th>
            <th>
            </th>
            <th>
              Status
            </th>
          </tr>
          </thead>
          <tbody>
          {shares &&
            shares.map(share => {
              return (
                <tr className="work-item" key={share.id}>
                  <td>
                      <div className="profile" onClick={() => history.push(`/app/profile/${share.userId}`)} >
                      {(share.user as Types.User).name}
                    </div>
                  </td>
                  <td>
                  </td>
                  <td>
                    <div className="share_status">
                      {renderPostStatus(share.status)}
                    </div>
                  </td>
                </tr>
              );
            })
          }
          </tbody>
        </Table>
        </div>
      )
    }
  }

  function workList () {
    if(workForPost) {
      return (
        <div className='table_responsive'><Table className="profiles-list" responsive="lg">
          <thead>
          <tr>
            <th>
              Profile Name
            </th>
            <th>
              Result
            </th>
            <th>
              Status
            </th>
          </tr>
          </thead>
          <tbody>
          {workForPost &&
            workForPost.map(workItem => {
              return (
                <tr className="work-item" key={workItem.id}>
                  <td>
                      <div className="profile flex-row" onClick={() => history.push(`/app/profile/${workItem.profileId}`)} >
			      <div className="network-icon" dangerouslySetInnerHTML={createMarkup(workItem.profile.network.icon)} />
			      <div className="cursor">{workItem.profile.name}</div>
                    </div>
                  </td>
                  <td>
                    {workItem.result && workItem.result.shortMessage}
                  </td>
                  <td>
                    <div className="status-row">
                      {renderStatus(workItem.status)}
              {/*workItem.profile.status === Consts.ProfileStatus.normal ? <ConnectedSVG /> : <DisconnectedSVG />*/}
                    </div>
                  </td>
                </tr>
              );
            })
          }
          </tbody>
        </Table>
        </div>
      )
    }
  }

  if(postLoaded && workLoaded) {
    return (
      <div className="feed_page_section post_detail_page">
        <Back />

        <div className="feed_tabbar_title">

          <Row className="app_feed_row">
          <Col className="app_col app_feed_title" lg={12} md={12} sm={12}>
          <h1>Post Details</h1>
          </Col>
            </Row>
            
            <Row className="feed_tabbar_desc app_feed_row">
            <Col className="app_col app_feed_title_desc" lg={12} md={12} sm={12}>
            Here you can see the status of a post.
              </Col>
            </Row>
            
            </div>
        
        <FeedPostAssignment post={post} />
        {workForPost.length > 0 && 
          <div className="profiles_content_list mb-5">
            {workList()}
          </div>
        }
        {shares.length > 0 && 
          <div className="profiles_content_list mb-5">
            {shareList()}
          </div>
        }
      </div>
    );
  } else {
    return(<div className="page flex-column">
      <Spinner/>
    </div>);
  }
}

export default PostAssignmentPage;
