import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import api from '../api';

import * as Types from 'types';

import Back from './Back';
import TabBar from './TabBar';
import Button from './Button';
import Checkbox from './Checkbox';
import Spinner from './Spinner';

import { Col, Row, Table } from "react-bootstrap";

const EditRolePage: React.FC = () => {
  const history = useHistory();
  const [permissions, setPermissions] = useState<Types.Permission[]>([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState<Types.Role>({
    name: '',
    deleted: false,
    organizationId: '',
    permissionIds: [],
    id: '',
    createdAt: 0,
    updatedAt: 0
  });
  const [roleLoaded, setRoleLoaded] = useState(false);
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);

  const uParams = useParams() as { roleId: string; };

  useEffect(() => {
    if(uParams.roleId) {
      api
        .roles
        .getRoleById(uParams.roleId)
        .then((role: Types.Role) => {
          setRole(role);
          setRoleLoaded(true);
        })
        .catch((err: any) => {
          console.log(err);
        })
    }
  }, [])

  useEffect(() => {
    api
      .permissions
      .getTeamPermissions()
      .then((permissions: Types.Permission[]) => {
        setPermissions(permissions)
        setPermissionsLoaded(true);
      })
      .catch(err => console.log(err))
  }, [])

  function onSelection (selected: boolean, permission: Types.Permission) {
    if(selected) {
      setRole({ ...role, permissionIds: [...role.permissionIds, permission.id] });
    } else {
      setRole({ ...role, permissionIds: role.permissionIds.filter((existingPermissionId: string) => existingPermissionId !== permission.id) });
    }
  }

  function updateRole () {
    setLoading(true);
    api
      .roles
      .updateRole(role)
      .then((roleData: Types.Role) => {
        setRole(roleData);
        setLoading(false);
        history.goBack();
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      })
  }

  if(roleLoaded && permissionsLoaded) {
    return (
      <div className="Sidebar_new-team Sidebar_me team_details_page feed_page_section edit-role page">
        <Back />
        <TabBar
          title={role.name}
          explainer={`Here you can change the name and team permissions for the role ${role.name}.`}
        > 
        </TabBar>
        <div className="info tab-content feed_page_section">
		<div className="info_field_label">
			Name of Role
		</div>
		<Row className="input-row name">
				<Col className='info_col_l' lg={6} md={6} xs={8}>
				      <input 
					type="text"
					placeholder="Role Name"
					onChange={e => setRole({ ...role, name: e.target.value })}
					value={role.name}
				      /> 
				</Col>

				<Col className='info_col_r' lg={6} md={6} xs={4}>
				      <Button
					color="orange"
					onConfirm={updateRole}
					enabled={!loading}
				      >Save</Button>
				</Col>
		</Row>

          <div className="edit-permissions flex-column">
            <h2>Team Permissions</h2>
            {permissions.map(permission => {
              return (
                <div 
                  className="permission flex-row" 
                  key={permission.id}
                >
                  <div className="left flex-column">
                    {permission.name}
                    <div className="new_role_desc">{permission.description}</div>
                  </div>
                  <Checkbox 
                    idName={permission.id}
                    onChange={(selected) => onSelection(selected, permission)}
                    initialChecked={role.permissionIds.includes(permission.id)}
                  />
                </div>
              );
            })}
          </div>

        </div>
      </div>
    );
  } else {
    return (<div className="page flex-column">
        <Spinner/>
      </div>
    );
  }
}

export default EditRolePage;
