import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view'
import { Switch, Route, withRouter, NavLink } from 'react-router-dom';

import api from '../api';

import * as Types from 'types';

import TabBar from './TabBar';
import Button from './Button';



const AdminSupportPage: React.FC = () => {
  const [exceptions, setExceptions] = useState<Types.Exception[]>([]);
  const [filters, setFilters] = useState({});
  const [users, setUsers] = useState<Types.User[]>([]);
  const [loading, setLoading] = useState(false);

  function getUsers() {
    setLoading(false);
    api
      .admin
      .getUsers(filters)
      .then(users => {       
	 setUsers(users);
	 setLoading(true);

      })
  }

function checkEmail(searchEmail:any)
{
	  const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	  if(searchEmail.match(regEx)) {
	    setFilters({ ...filters, email: searchEmail });
	    setLoading(true);
	  } else {
	    setLoading(false);
	  }
	
}



  return (
    <div className="users page admin-page flex-column">
      <TabBar
        title="Users"
        explainer="Search Users"
      >
      </TabBar>

      <div className="input-row flex-row">
        {/* <div className="cellContainer pr-10"><input onChange={e => setFilters({ ...filters, name: e.target.value })} placeholder="Name" /></div> */}
        <div className="cellContainer pr-10" ><input onChange={e => checkEmail(e.target.value)} placeholder="Email" style={{width: '300px'}} /></div>

	{/*
	<div className="cellContainer pr-10" ><input onChange={e => setFilters({ ...filters, id: e.target.value })} placeholder="ID"/></div>

        <div className="cellContainer pr-10" ><input onChange={e => setFilters({ ...filters, organizationId: e.target.value })} placeholder="Organization ID" />      </div>
	*/}

        <div className="cellContainer ">   <Button color="light-orange" onConfirm={getUsers} enabled={loading}>Search</Button></div>
      </div>

      <div className="user-list">
        <div className="title flex-row">Results: {users.length}</div>
        <table>
          <thead>
            <tr>
              <th >Name</th>
              <th >Email</th>
              {/*<th className="medium">Created At</th>*/}
              <th >Last Login</th>
              {/*<th className="tbl_width200">Organization ID</th>*/}
              {/*<th className="tiny">Authy ID</th>*/}
              {/*<th className="tbl_width75">Invite Status</th>*/}
              <th className="tbl_width75">Deleted</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => {
              return <tr key={user.id}>
                <td ><div className="user-name text-wrap pr-10"><NavLink to={`/admin/user/${user.id}`}>{user.name}</NavLink></div></td>
                <td ><div className="user-name text-wrap pr-10">{user.email}</div></td>
                {/*<td className="medium">{new Date(user.createdAt * 1000).toLocaleString()}</td>*/}
                <td >{new Date(user.lastLogin * 1000).toLocaleString()}</td>
                {/*<td >{user.organizationId}</td>*/}
                {/*<td className="medium">{user.authyId}</td>*/}
                {/*<td >{user.inviteStatus}</td>*/}
                <td>{user.deleted ? 'True' : 'False'}</td>
              </tr>
            })
            }
	    {users.length <= 0 && 
		<tr><td colSpan={5} className="text-align-center pt30">No Records Found</td></tr>}	    
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminSupportPage;
