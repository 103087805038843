import React from 'react';

import {ReactComponent as BigStarSVG} from '../svgs/big-star.svg';
import {ReactComponent as BSLogoWhiteSVG} from '../svgs/bslogo-white.svg';

interface Props {
  children?: React.ReactElement;
}

const BigStar: React.FC<Props> = props => {

  function returnHome () {    
    window.location.href = '/';
  }

  return (
    <div className="reg_brand_col">
      <div className="reg_brand_logo" onClick={returnHome} >
       <BSLogoWhiteSVG />
      </div>
      <div className="reg_text_white">{props.children}</div>
      <BigStarSVG className="brand_star"/>

    </div>
  );
}

export default BigStar;
