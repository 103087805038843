import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { addResponseHandler } from '../api/axios';
import api from '../api';
import { useMediaQuery } from 'react-responsive';

import { db, getGoogleId, firebaseAuth } from '../firebase';
import { getUser, getUserById } from '../api/users';
import { getRoleById } from '../api/roles';
import { identity } from '../helpers';

import Sidebar from './Sidebar';
import Spinner from './Spinner';

import FeedPage from './FeedPage';
import QueuePage from './QueuePage';
import DraftsPage from './DraftsPage';
import NetworksPage from './NetworksPage';
import NewPostPage from './NewPostPage';
import OrganizationPage from './OrganizationPage';
import TeamsPage from './TeamsPage';
import UsersPage from './UsersPage';
import NewUserPage from './NewUserPage';
import PostAssignmentPage from './PostAssignmentPage';
import PostPage from './PostPage';
import TeamPostAssignmentPage from './TeamPostAssignmentPage';
import UserPage from './UserPage';
import TeamPage from './TeamPage';
import NewTeamPage from './NewTeamPage';
import TeamEditPage from './TeamEditPage';
import ProfilePage from './ProfilePage';
import MePage from './MePage';
import MeEditPage from './MeEditPage';
import NewRolePage from './NewRolePage';
import EditRolePage from './EditRolePage';
import EditUserPage from './EditUserPage';
import OutboxPage from './OutboxPage';
import Onboarding from './Onboarding';
import PostAuthPage from './PostAuthPage';
import NotificationsPage from './NotificationsPage';
import OnboardingNetworks from './OnboardingNetworks';
import VerifyEmailPage from './VerifyEmailPage';
import PlansPage from './PlansPage';

import NotificationBanner from './NotificationBanner';

import UserContext from './UserContext';
import OrganizationContext from './OrganizationContext';
import TeamsContext from './TeamsContext';

import * as Consts from '../consts';
import * as Types from 'types';
import { Container, Row, Col, Navbar } from 'react-bootstrap';

const AppInterior: React.FC = () => {
  const history = useHistory();
  const [response, setResponse] = useState<null | { data: { message: string, success: boolean } }>(null);
  const [responseOn, setResponseOn] = useState(false);
  const [pathname, setPathname] = useState(window.location.pathname);
  const [organization, setOrganization] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [queueShares, setQueueShares] = useState<any[]>([]);
  const [queueApprovals, setQueueApprovals] = useState<any[]>([]);
  const [teams, setTeams] = useState<any[]>([]);

  const isMobile = useMediaQuery({
    query: '(max-device-width: 1000px)'
  })

  useEffect(() => {
    function refreshToken() {
      const currentUser = firebaseAuth.currentUser;
      if(currentUser) {
        currentUser.getIdToken(true)
          .then(newIdToken => {
          })
      }
    }

    refreshToken();
    setInterval(refreshToken, 1000 * 60 * 10);
  }, [])

  useEffect(() => {
    addResponseHandler(response => {
      if(response.data && response.data.message) {
        setResponse(response);
        setResponseOn(true);
        setTimeout(() => setResponseOn(false), 4000);
      }
    });
  }, [])

  function showResponse () {
    if(response) {
      return <div className={`response-box ${responseOn ? 'on' : '' } ${response.data.success ? 'green' : 'red'} flex-column`}>{response.data.message}</div>;
    }
  }

  useEffect(() => {
    getGoogleId()
      .then(googleId => {
        const doc = db.collection('users').where('googleId', '==', googleId);

        const observer = doc.onSnapshot(docSnapshot => {
          setUser(docSnapshot.docs[0].data());
        }, err => {
          console.log(err);
        });
      })
  }, [])

  useEffect(() => {
    if(user && user.organizationId) {
      const doc = db.collection('organizations').doc(user.organizationId);

      const observer = doc.onSnapshot(docSnapshot => {
        setOrganization(docSnapshot.data());
      }, err => {
        console.log(err);
      });
    }
  }, [user])
  
  useEffect(() => {
    if(user && user.organizationId) {
      api
        .notifications
        .getNotifications()
        .then((notifications: any) => {
          setNotifications(notifications)
        })
    }
  }, [user])
  
  useEffect(() => {
    if(user && user.organizationId) {
      const query = db
        .collection('teams')
        .where('organizationId', '==', user.organizationId)
        .where('deleted', '==', false)

      const observer = query.onSnapshot(async querySnapshot => {
        let teams: Partial<Types.JoinedTeam[]>;
        let teamUsers: Types.TeamUser[];
        const teamData = identity<Types.Team[]>(querySnapshot.docs.map(doc => doc.data()));
        return Promise.all(teamData.map((team: Types.Team) => {
          return db
            .collection('teamUsers')
            .where('teamId', '==', team.id)
            .where('deleted', '==', false)
            .get()
            .then(usersReference => {
              return usersReference
                .docs
                .map(doc => doc.data());
            })
            .then(teamUsersData => {
              teamUsers = identity<Types.TeamUser[]>(teamUsersData);
              return Promise.all(teamUsers
                .map(teamUser => {
                  return getUserById(teamUser.userId);
                })
              )
            })
            .then(usersData => {
              return Promise.all(teamUsers
                .map((teamUser, i) => {
                  return getRoleById(teamUser.roleId)
                    .then(role => {
                      if(usersData.find(userData => userData.id === teamUser.userId)) {
                        return { ...usersData.find(userData => userData.id === teamUser.userId), teamRole: role };
                      } else {
                        delete teamUsers[i];
                      }
                    })
                })
              )
            })
            .then(usersWithRoles => {
              return { ...team, users: usersWithRoles, teamUsers: teamUsers };
            })
        }))
          .then(joinedTeams => {
            setTeams([ ...joinedTeams.filter(joinedTeam => !!joinedTeam)]);
          })
      });
    }
  }, [user])

  useEffect(() => {
    if(user && user.organizationId) {
      const query = db
        .collection('postAssignments')
        .where('organizationId', '==', user.organizationId)
        .where('userId', '==', user.id)
        .where('deleted', '==', false)
        .where('status', '==', Consts.PostAssignmentStatus.shared)

      const observer = query.onSnapshot({ includeMetadataChanges: false }, async querySnapshot => {
        const postAssignments = querySnapshot.docs.map(doc => doc.data());
        const promises = postAssignments.map(postAssignment => {
          if(postAssignment.status === Consts.PostAssignmentStatus.shared || user.permissionIds.includes('approvePosts')) { 
          return api.users
            .getUserById(postAssignment.authorId)
            .then(author => {
              delete author.token;
              return {
                ...postAssignment,
                author: author,
                user: user,
              }
            })
          }
        });

        Promise.all(promises)
          .then(joinedPostAssignments => {
            setQueueShares(joinedPostAssignments.filter(jpa => !!jpa));
          })
      });
    }
  }, [user, teams])

  useEffect(() => {
    if(user && user.organizationId) {
      const query = db
        .collection('postAssignments')
        .where('organizationId', '==', user.organizationId)
        .where('authorId', '!=', user.id)
        .where('deleted', '==', false)
        .where('status', '==', Consts.PostAssignmentStatus.approvalRequested)

      const observer = query.onSnapshot({ includeMetadataChanges: false }, async querySnapshot => {
        const postAssignments = querySnapshot.docs.map(doc => doc.data());
        const promises = postAssignments.map(postAssignment => {
          if(postAssignment.status === Consts.PostAssignmentStatus.shared || user.permissionIds.includes('approvePosts')) { 
          return api.users
            .getUserById(postAssignment.authorId)
            .then(author => {
              delete author.token;
              return {
                ...postAssignment,
                author: author,
                user: user,
              }
            })
          }
        });

        Promise.all(promises)
          .then(joinedPostAssignments => {
            const validatedPostAssignments = identity<Types.JoinedPostAssignment[]>(joinedPostAssignments.filter(jpa => !!jpa));
            const globalApprovals = validatedPostAssignments.filter(jpa => !jpa.teamId);
            let teamApprovals = validatedPostAssignments.filter(jpa => jpa.teamId);

            let permittedApprovals: Types.JoinedPostAssignment[] = [];
            if(user.permissionIds.includes('approvePosts')) {
              permittedApprovals = [...permittedApprovals, ...globalApprovals];
            }

            teamApprovals = teamApprovals.filter(teamPost => {
              const team = teams.find(team => teamPost.teamId === team.id);  
              if(team) {
                const teamUser = team.users.find((teamUser: Types.User) => teamUser && teamUser.id === user.id);
                if(teamUser) {
                  return teamUser.teamRole.permissionIds.includes('approvePosts');
                }
              }

            })

            const allApprovals = [...permittedApprovals, ...teamApprovals];
            const joinApprovals = allApprovals
              .map(approval => {
                return api
                  .postAssignments
                  .getWorkForPostAssignment(approval.id as string)
                  .then(work => {
                    return { ...approval, work: work };
                  });
              });
            return Promise.all(joinApprovals);
          }).then(joinedApprovals => {
            setQueueApprovals(joinedApprovals);
          })
      });
    }
  }, [user, teams])

  useEffect(() => {
    if(isMobile) {
      {/* history.push('/mobile');*/}
    }
  }, [isMobile])

  useEffect(() => {
    if(user 
      && organization 
      && user.permissionIds.includes('managePayments') 
      && organization.status === Consts.OrgStatus.invalidPayment
    ) {
      history.push('/app/organization/billing');
    }
  }, [user, organization])

  function getDaysLeftInTrial () {
    return Math.round((organization.trialEnd * 1000 - new Date().getTime())/(1000*60*60*24));
  }

  function handleClick () {
    if(pathname !== window.location.pathname) {
      window.scrollTo(0, 0);
    }
    setPathname(window.location.pathname);
  }

  if(user && organization) {
    return (
      <UserContext.Provider value={user}>
        <OrganizationContext.Provider value={organization}>
          <TeamsContext.Provider value={teams}>
            <div className={`app_feed_section ${window.location.pathname.includes('onboarding') && 'onboarding'}`} onClick={handleClick}>
              <Container fluid className='app_feed_container'>
              <Navbar className='app_feed_nav' expand="lg">
                <Row className='app_feed_row'>
              {!window.location.pathname.includes('onboarding') &&
               <Sidebar notifications={notifications} queueItems={[...queueShares, ...queueApprovals]} />
              }
              {showResponse()}
              <Col lg={9} md={12} sm={12} className="feed_main">
              <div className={`app_int_main ${window.location.pathname.includes('onboarding') && 'onboarding'}`}>
                {user
                  && organization
                  && user.permissionIds.includes('managePayments') 
                  && (organization.status === Consts.OrgStatus.trial
                  || organization.status ===Consts.OrgStatus.invalidPayment)
                  && !organization.stripeId 
                  && !window.location.pathname.includes('onboarding')
                  && <NotificationBanner updateBill={true}>
                    {getDaysLeftInTrial() >= 0
                      ? <span><span className="number">{getDaysLeftInTrial()}</span> {`day${getDaysLeftInTrial() !== 1 ? 's' : ''} left in trial`}</span>
                      : <span>Your BrandSocial license has expired.</span>
                    }
                  </NotificationBanner>
                }

		{user
                  && organization
                  && !user.permissionIds.includes('managePayments') 
                  && (organization.status === Consts.OrgStatus.trial
                  || organization.status ===Consts.OrgStatus.invalidPayment)
                  && !organization.stripeId 
                  && !window.location.pathname.includes('onboarding')
                  && getDaysLeftInTrial() <= 0 && <NotificationBanner >                    
                      <div className="user-trail-status">Your BrandSocial license has expired. Please contact your BrandSocial administrator to purchase a subscription.</div>
                  </NotificationBanner>
                }

                <Switch>
                  <Route exact path="/app/feed/:filter" component={FeedPage}/>
                  <Route exact path="/app/outbox/:filter" component={OutboxPage}/>
                  <Route exact path="/app/queue/:filter/:tab?" render={() => <QueuePage queueItems={[...queueShares, ...queueApprovals]} />}/>
                  <Route exact path="/app/drafts" component={DraftsPage}/>
                  <Route exact path="/app/posts/new" component={NewPostPage}/>
                  <Route exact path="/app/networks/:authSlug?" component={NetworksPage}/>
                  <Route exact path="/app/organization/:tab?" component={OrganizationPage}/>
                  <Route exact path="/app/teams" component={TeamsPage}/>
                  <Route exact path="/app/teams/new" component={NewTeamPage}/>
                  <Route exact path="/app/users" component={UsersPage}/>
                  <Route exact path="/app/plans" component={PlansPage}/>
                  <Route exact path="/app/users/new" component={NewUserPage}/>
                  <Route exact path="/app/user/:userId" component={UserPage}/>
                  <Route exact path="/app/user/:userId/edit" component={EditUserPage}/>
                  <Route exact path="/app/team/:teamId" component={TeamPage}/>
                  <Route exact path="/app/team/:teamId/edit" component={TeamEditPage}/>
                  <Route exact path="/app/profile/:profileId" component={ProfilePage}/>
                  <Route exact path="/app/post/:postId" component={PostPage}/>
                  <Route exact path="/app/post-assignment/:postId" component={PostAssignmentPage}/>
                  <Route exact path="/app/teams/:teamId/post-assignment/:postId" component={TeamPostAssignmentPage}/>
                  <Route exact path="/app/me/:tab?" component={MePage}/>
                  <Route exact path="/app/roles/edit/:roleId" component={EditRolePage}/>
                  <Route exact path="/app/verify-email/:jwt" component={VerifyEmailPage}/>
                  <Route exact path="/app/roles/new" component={NewRolePage}/>
                  <Route exact path="/app/onboarding" component={Onboarding}/>
                  <Route exact path="/app/onboarding/networks" component={OnboardingNetworks}/>
                  <Route exact path="/app/notifications" component={NotificationsPage}/>
                  <Route exact path="/app/post-auth/:authSlug?" component={PostAuthPage}/>
                </Switch>
              </div>
              </Col>
              </Row>
              </Navbar>
              </Container>    
            </div>
          </TeamsContext.Provider>
        </OrganizationContext.Provider>
      </UserContext.Provider>
    );
  } else {
      return (<div className="flex-column">
        <Spinner fullscreen={true} />
      </div>);
  }
}

export default AppInterior;
