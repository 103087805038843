import React, { useEffect, useState } from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { FBSignOut, getFBIdToken, f } from './firebase'; 

import * as Types from 'types';

import ProtectedRoute from './components/ProtectedRoute';
import AppInterior from './components/AppInterior';
import AppExterior from './components/AppExterior';
import AdminInterior from './components/AdminInterior';
import AdminRoute from './components/AdminRoute';
import Spinner from './components/Spinner';

import { createBrowserHistory } from 'history';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-205261524-1');
ReactGA.pageview(window.location.pathname + window.location.search);

/*
const browserHistory = createBrowserHistory();
browserHistory.listen((location, action) => {
  ReactGA.pageview(window.location.pathname + window.location.search);
})
*/

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isOWAdmin, setIsOWAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    f.auth().onAuthStateChanged((user) => {
      if(user) {
        getFBIdToken()
          .then((token: string | unknown) => {
            if(token && token !== '' && !['/login', '/', '/register'].includes(window.location.pathname)) {
              const decodedToken: Types.JWT = jwtDecode(token as string);

              user
                .getIdTokenResult()
                .then(tokenResult => {
                  const claims = tokenResult.claims;
                  if(decodedToken.exp * 1000 < Date.now() || (claims.authy && !claims.authyAuthed)) {
                    FBSignOut()
                      .then(() => {
                        window.location.href = './login'
                        setIsAuthenticated(false);
                        setLoading(false);
                      })
                      .catch((err) => {
                        console.log(err);
                        setIsAuthenticated(false);
                        setLoading(false);
                      })
                  } else {
                    if(claims.OWAdmin) {
                      setIsOWAdmin(true);
                    }
                    setIsAuthenticated(true);
                    setLoading(false);
                    if(window.location.pathname === '/') {
                      window.location.href = './app/feed/all'
                      setIsAuthenticated(true);
                      setLoading(false);
                    }
                  }
                })
            } else {
              setIsAuthenticated(true);
              setLoading(false);
            }
        })
          .catch(err => {
            console.log(err);
            setIsAuthenticated(false);
            setLoading(false);
          })
      } else {
        setIsAuthenticated(false);
        setLoading(false);
      }
    })
  })

  if(!loading) {
    return (
      <div className="App">
        <Router>
          <Switch>
            <AdminRoute
              isAuthenticated={isAuthenticated}
              isOWAdmin={isOWAdmin}
              isAllowed={true}
              authenticationPath='/login'
              exact={false}
              path='/admin'
              restrictedPath='/admin/:section'
              component={AdminInterior}
            />
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              isAllowed={true}
              authenticationPath='/login'
              exact={false}
              path='/app/:section'
              restrictedPath='/app/:section'
              component={AppInterior}
            />
            <Route path="/" component={AppExterior}/>
          </Switch>
        </Router>
      </div>
    );
  } else {
    return (<div className="App">
      <Spinner fullscreen={true} />
    </div>);
  }
}

export default App;
