import axios from './axios';
import { authorizedHeader } from '../helpers';
import { getUser, getUserById } from './users';
import { getRoleById } from './roles';
import { db } from '../firebase';
import { identity } from '../helpers';

import * as Types from 'types';

export function getTeamUser(userId: string, teamId: string): Promise<Types.TeamUser> {
  let teamUser: Types.TeamUser;
  return db
    .collection('teamUsers')
    .where('teamId', '==', teamId)
    .where('userId', '==', userId)
    .where('deleted', '==', false)
    .get()
    .then(teamUsersReference => {
      return teamUsersReference
        .docs
        .map(doc => doc.data());
    })
    .then(teamUsers => {
      teamUser =  teamUsers[0] as Types.TeamUser;
      return getRoleById(teamUser.roleId)
    })
    .then(role => {
      return { ...teamUser, role: role };
    })
}

export function getTeams(): Promise<Types.JoinedTeam[]> {
  let teams: Partial<Types.JoinedTeam[]>;
  return getUser()
    .then(user => {
      return db
        .collection('teams')
        .where('organizationId', '==', user.organizationId)
        .where('deleted', '==', false)
        .get()
        .then(teamsReference => {
          return teamsReference
            .docs
            .map(doc => doc.data());
        })
        .then((teamData: any) => {
          teams = teamData;
          return Promise.all(teamData.map((team: Types.Team) => {
            let teamUsers: Types.TeamUser[];
            return db
              .collection('teamUsers')
              .where('teamId', '==', team.id)
              .where('deleted', '==', false)
              .get()
              .then(usersReference => {
                return usersReference
                  .docs
                  .map(doc => doc.data());
              })
              .then(teamUsersData => {
                teamUsers = identity<Types.TeamUser[]>(teamUsersData);
                return Promise.all(teamUsers
                  .map(teamUser => {
                    return getUserById(teamUser.userId);
                  })
                )
              })
              .then(usersData => {
                return Promise.all(teamUsers
                  .map(teamUser => {
                    return getRoleById(teamUser.roleId)
                      .then(role => {
                        return { ...usersData.find(userData => userData.id === teamUser.userId), teamRole: role };
                      })
                  })
                )
              })
              .then(usersWithRoles => {
                return { ...team, users: usersWithRoles, teamUsers: teamUsers };
              })
          }))
        })
        .then((teamData2: any) => {
          teams = teamData2;
          return Promise.all(teamData2.map((team: Types.Team) => {
            return db
              .collection('profiles')
              .where('teamId', '==', team.id)
              .where('deleted', '==', false)
              .get()
              .then(profilesReference => {
                return profilesReference
                  .docs
                  .map(doc => doc.data());
              })
              .then(profiles => {
                return { ...team, profiles: profiles };
              })
          }))
        })
    })
    .then(data => {
      return identity<Types.JoinedTeam[]>(data);
    })
}

export function getTeamById (teamId: string): Promise<Types.JoinedTeam> {
  return db
    .collection('teams')
    .where('id', '==', teamId)
    .where('deleted', '==', false)
    .get()
    .then(teamsReference => {
      return teamsReference
        .docs[0]
        .data();
    })
    .then((team: any) => {
      return db
        .collection('teamUsers')
        .where('teamId', '==', team.id)
        .where('deleted', '==', false)
        .get()
        .then(usersReference => {
          return usersReference
            .docs
            .map(doc => doc.data());
        })
        .then(teamUsers => {
          return Promise.all(teamUsers
            .map(teamUser => {
              return getUserById(teamUser.userId)
                .then(user => {
                  return getRoleById(teamUser.roleId)
                    .then(role => {
                      return { ...user, teamRole: role };
                    })
                })
            })
          )
        })
        .then(users => {
          return { ...team, users: users };
        })
    })
}

export function createTeam (data: {}): Promise<Types.Team> {
  return new Promise(async (resolve, reject) => {
    axios.post('/teams', data, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function createTeamUsers (newTeamUsers: Types.TeamUser[]): Promise<Types.Team> {
  return new Promise(async (resolve, reject) => {
    axios.post(`/teams/${newTeamUsers[0].teamId}/users`, newTeamUsers, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function updateTeam (team: Types.Team): Promise<Types.Team> {
  return new Promise(async (resolve, reject) => {
    axios.put(`/teams/${team.id}`, team, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function updateTeamUserRole (teamId: string, userId: string, roleId: string): Promise<Types.Team> {
  return new Promise(async (resolve, reject) => {
    axios.put(`/teams/${teamId}/users/${userId}/roles/${roleId}`, {}, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function deleteTeam (team: Types.Team): Promise<string> {
  return new Promise(async (resolve, reject) => {
    axios.delete(`/teams/${team.id}`, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function deleteTeamUser (teamId: string, userId: string): Promise<string> {
  return new Promise(async (resolve, reject) => {
    axios.delete(`/teams/${teamId}/users/${userId}`, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}
