import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../api';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import UserContext from './UserContext';

import TabBar from './TabBar';
import Spinner from './Spinner';

import * as Types from 'types';

interface Props {
}

TimeAgo.locale(en)
const timeAgo = new TimeAgo('en-US')

const NotificationsPage: React.FC<Props> = props => {
  const history = useHistory();
  const [notifications, setNotifications] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState<'new' | 'history'>('new');

  const me = useContext(UserContext) as Types.User;

  useEffect(() => {
    setLoading(true);
    api
      .notifications
      .getNotifications()
      .then((notifications: any) => {
        setNotifications(notifications)
        setLoading(false);
        api
          .notifications
          .viewed(notifications.filter((notification: any) => !notification.viewed))
      })
  }, [])

  function handleClick (notification: any) {
    const resourceType = Object.keys(notification.resources)[0];
    const resource: any = Object.entries(notification.resources)[0][1];

    if(!resource.deleted && !notification.text.includes('deleted')) {
      if(resourceType === 'user' && resource !== me.id) { 
        history.push(`/app/user/${resource.id}`);
      } else if(resourceType === 'user' && resource === me.id) {
        history.push(`/app/me`)
      } else if(resourceType === 'postAssignment') {
        if(notification.text.includes('shared')) {
          history.push(`/app/queue/all`);
        } else if(notification.text.includes('denied')) {
        } else {
          history.push(`/app/post-assignment/${resource.id}`);
        }
      } else if(resourceType === 'team') {
        history.push(`/app/team/${resource.id}`);
      } else if(resourceType === 'post') {
        history.push(`/app/post/${resource.id}`);
      } else if(resourceType === 'organization') {
        history.push(`/app/organization`);
      } else if(resourceType === 'organization') {
        history.push(`/app/organization`);
      } else if(resourceType === 'work') {
        history.push(`/app/post-assignment/${resource.postAssignmentId}`);
      } else if(resourceType === 'profile') {
        history.push(`/app/networks`);
      }
    }
  }

  function showTab () {
    if(tab === 'new') {
      return (
        <div className="info tab-content notification_history_list">
          {notifications.map(notification => {
            if(!notification.viewed) {
              return <div key={notification.id} className={`notification_list_item  p-3`} onClick={() => handleClick(notification)}>
                <div className="notification_item_details">
                <div className='notification_item_title fs-16'>{notification.text}</div>
                <div className="notification_item_desc">{timeAgo.format(notification.createdAt * 1000)}</div>
                </div>
              </div>
            }
          })}
        </div>
      );
    } else if(tab === 'history') { 
      return (
        <div className="info tab-content notification_history_list">
          {notifications.map(notification => {
            if(notification.viewed) {
              return <div key={notification.id} className={`notification_list_item p-3`} onClick={() => handleClick(notification)}>
                <div className="notification_item_details">
                  <div className='notification_item_title fs-16'>{notification.text}</div>
                  <div className="notification_item_desc">{timeAgo.format(notification.createdAt * 1000)}</div>
                </div>
              </div>
            }
          })}
        </div>
      );
    }
  }

  if(!loading) {
    return (
      <div className="notifications_page feed_page_section">
        <TabBar
          title="Notifications"
          explainer="Here are the latest events that need your attention"
        > 
        </TabBar>
        <div className="notification_tabs_sec">
        
        <div className='notification_tabs_list'>
          <ul className="nav nav-tabs" role="tablist">
            <li 
              className={`tab first ${tab === 'new' ? 'active' : ''}`}
            ><a onClick={() => setTab('new')}>New <span className="tab-number">{notifications.filter(notification => !notification.viewed).length}</span></a></li>
            <li 
              className={`tab first ${tab === 'history' ? 'active' : ''}`}
            ><a onClick={() => setTab('history')}>History</a></li>
          </ul>
          </div>

          {showTab()}
        </div>
      </div>
    );
  } else {
    return(
      <div className="page flex-column">
        <Spinner/>
      </div>
    );
  }
}

export default NotificationsPage;