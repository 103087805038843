import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import UserImage from './UserImage';

import TeamsContext from './TeamsContext';

import * as Types from 'types';

import {ReactComponent as ShareOrangeSVG} from '../svgs/share-orange.svg';
import { Row, Col } from 'react-bootstrap';

interface PostProps {
  post: Types.JoinedPostAssignment;
  teamId?: string;
}

TimeAgo.locale(en)
const timeAgo = new TimeAgo('en-US')

const FeedPostAssignment: React.FC<PostProps> = props => {
  const history = useHistory();

  const teams = useContext(TeamsContext);

  function shareHandler (e: any) {
    e.stopPropagation();
  }

  function getTeamName () {
    if(teams) {
      const team = teams.find(team => team.id === props.post.teamId)
      if(team) {
        return '  •  '+team.name;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  function postCard () {
    if(props.post.author) {
    const post_images =  props.post.images.map(imageUrl => { return <Col className='feed_image_col'><div 
                  className="feed_up_images feed_up_images_width"><img src={imageUrl} /></div></Col>;});
      return (
        <>
          <div className="post_user_header">
            <UserImage user={props.post.author} size="large" />
            <div className="post_user_title">
              <h3>{props.post.author.name} <span className="gray-text">{getTeamName()}</span></h3>
              <p className="gray-text">{timeAgo.format(props.post.scheduled * 1000)}</p>
            </div>
            {/*
              <div className="score gray-text flex-row">
                1,234 <GrayStarSVG />
              </div>
            */}
          </div>
          <div className="feed_post_content">
            <p>{props.post.description}</p>
            <a className="blue-link" target="_blank" href={props.post.url}>{props.post.url}</a>
          </div>
	  {props.post.images.length > 0 ?
	  
		  <div className="feed_post_images">
		    <Row className="feed_up_images">
		    {
		      props.post.images.length > 0 ? post_images : null
		    }
		    </Row>
		  </div>
	   : null}
          <div className="feed_post_footer">
            <div className="kissing-images small flex-row">
            </div>
            <div className="feed_post_share">
              <Link className="post_share_link" to={`/app/posts/new?repostFromPostId=${props.post.postId}`} onClick={shareHandler}>Share<ShareOrangeSVG /></Link>
            </div>
          </div>
        </>
      );
    }
  }

  function goToPostAssignment () {
    if(props.teamId) {
      history.push(`/app/teams/${props.teamId}/post-assignment/${props.post.id}`);
    } else {
      history.push(`/app/post-assignment/${props.post.id}`);
    }
  }

  return (
    <Col lg={9} md={12} sm={12}><div className="feed_post_list" onClick={goToPostAssignment}>
      {postCard()}
    </div></Col>
  );
}

export default FeedPostAssignment;
