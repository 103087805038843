import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../api';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import Button from './Button';
import DeleteButton from './DeleteButton';
import UserImage from './UserImage';
import Modal from './Modal';

import * as Types from 'types';

import { Row, Col } from 'react-bootstrap';

interface PostProps {
  post: Types.JoinedPostAssignment;
  removePost: (post: Types.JoinedPostAssignment) => void;
}

TimeAgo.locale(en)
const timeAgo = new TimeAgo('en-US')

const DraftPost: React.FC<PostProps> = props => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [profiles, setProfiles] = useState<Types.Profile[]>([]);
  const [profilesLoaded, setProfilesLoaded] = useState(false);

  useEffect(() => {
    api
      .profiles
      .getProfiles()
      .then(profiles => {
        setProfiles(profiles)
        setProfilesLoaded(true);
      })
  }, [])

  function onApprove() {
    api.postAssignments.approveDraftForAll({
      postData: props.post,
      shareToUsers: [],
    })
    props.removePost(props.post);
  }

  function onDelete() {
    props.removePost(props.post)
    return api.postAssignments.denyApproval(props.post.id as string)
      .then(() => {
        return true;
      })
  }
  function postCard () {
    if(props.post.user) {
      return (
        <>
          <div className="post_user_header">
            <UserImage user={props.post.user} size="large" />
            <div className="post_user_title">
              <h3>{props.post.user.name}</h3>
              <p className="gray-text">{timeAgo.format(props.post.scheduled * 1000)}</p>
            </div>
          </div>
          <div className="feed_post_content">
            <p>{props.post.description}</p>
            <a className="blue-link" target="_blank" href={props.post.url}>{props.post.url}</a>
          </div>
          <div className="feed_post_images">
		    <Row className="feed_up_images">
          {props.post.images.length > 0 ? <Col className='feed_image_col'><div className="feed_up_images"><img src={props.post.images[0]} /></div></Col> : null}
          </Row>
          </div>
          <div className="feed_post_footer draft_post_footer">
            <Row className='app_feed_row justify-content-md-end'>
            <DeleteButton
              onConfirm={onDelete}
              message="Are you sure you want to delete this post draft?"
              buttonText="Delete"
            />
	    {/*
            <Button
              color="green"
              onConfirm={() => setShowModal(true)}
            >Post to All</Button>
	    */}

<div className="edit_button col_wh draft_post_edit"><Button
              color="gray"
              to={`/app/posts/new?editDraftFromPostId=${props.post.id}`}
            >Edit</Button></div>
</Row>
          </div>
          <Modal 
            show={showModal} 
            closeModal={() => setShowModal(false)}
            title="Confirm Post"
            onConfirm={profiles.length > 0 ? onApprove : () => history.push('/app/networks')}
          >
            {profiles.length > 0 
                ? `By clicking confirm, you will be posting this to all ${profiles.length} of your connected personal profiles.`
                : 'You have no personal Social Profiles connected. Please proceed to the My Networks page by clicking Confirm, then connect a social profile.'
            }
          </Modal>
        </>
      );
    }
  }

  return (
    <Col lg={9} md={12} sm={12}><div className="feed_post_list">
      {postCard()}
    </div></Col>
  );
}

export default DraftPost;
