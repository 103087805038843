import React, { useState, useEffect }  from 'react';

import * as Types from 'types';

interface Props {
  network: Types.Network;
  work: Partial<Types.Work> | null;
  urlMetadata: Types.urlMetadata | {};
  previewIsLarge: boolean;
}
const PinterestPreview: React.FC<Props> = props => {
  const [largeText, setLargeText] = useState(false);

  useEffect(() => {
    if(props.work && props.work.description && props.work.description.length < 85 && !props.work.url) {
      setLargeText(true);
    } else {
      setLargeText(false);
    }
  }, [props.work, props.urlMetadata])

  function buildText(work: Types.Work) {
    let description = '';
    if(work.description) {
      description = work.description;
    }

    function getContent () {
      const content = [];
      if(description) {
        content.push(description);
      }
      if(work.url) {
        content.push(' - ');
        content.push(work.url);
      }
      return content;
    }

    let joined = getContent().join('');
    let length = joined.length;
    let trimmed = '';
    let difference = length - 280;

    if(length > 280) {
      description = description.substring(0, description.length - (difference + 3));
      description = [description, '...'].join('');
      trimmed = getContent().join('');
    } else {
      trimmed = joined;
    }

    return trimmed;
  }

  function getDescription () {
    let text = '';
    if(props.work && props.work.description) {
      return text = props.work.description;;
    } else if((props.urlMetadata as Types.urlMetadata).description) {
      return text = (props.urlMetadata as Types.urlMetadata).description;
    } else {
      return text.substring(0, 500);
    }
  }

  if(props.work && props.work.images && props.work.images.length > 0) {
    return (
      <div className="pinterest preview flex-column">
        <img className="uploaded-image" src={props.work.images[0]} />
        <div className="bottom flex-column">
          <div className="url flex-row">
            {(props.urlMetadata as Types.urlMetadata).url && <span>Saved from <span className="bold">{(props.urlMetadata as Types.urlMetadata).url.split('//')[1]}</span></span>}
          </div>
          <div className="title flex-column">
            {(props.urlMetadata as Types.urlMetadata).title && ((props.urlMetadata as Types.urlMetadata).title).substring(0, 100)}
          </div>
          <div className="description">
            {getDescription()}
          </div>
          <div className="pinterest-profile flex-row">
            Saved by <span className="bold">Your Profile</span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>An image is required to post to Pinterest.</div>
    );
  }
}

export default PinterestPreview;
