import React, { useRef, useEffect, useState, useContext } from 'react';

import api from '../api';

import * as Types from 'types';

import Back from './Back';
import TabBar from './TabBar';
import Button from './Button';
import Checkbox from './Checkbox';
import Spinner from './Spinner';
import Select from './Select';

import UserContext from './UserContext';
import { Col, Row } from "react-bootstrap";


import {ReactComponent as AddSVG} from '../svgs/add.svg';
import {ReactComponent as CloseSVG} from '../svgs/close.svg';

interface Props {
  onCreate: () => void;
}

const NewTeamPage: React.FC<Props> = props => {
  const refContainer = useRef(null);
  const me = useContext(UserContext) as Types.User;
  const [roles, setRoles] = useState<Types.Role[]>([]);
  const [loading, setLoading] = useState(false);
  const [newTeamUsers, setNewTeamUsers] = useState<Types.TeamUser[]>([{
    teamId: '',
    deleted: false,
    userId: me.id,
    roleId: 'teamManager',
    organizationId: me.organizationId,
    createdAt: 0,
    updatedAt: 0
  }]);
  const [addUsers, setAddUsers] = useState(false);
  const [organizationUsers, setOrganizationUsers] = useState<Types.User[]>([]);
  const [team, setTeam] = useState<Types.Team>({
    id: '',
    organizationId: '',
    name: '',
    deleted: false,
    createdAt: 0,
    updatedAt: 0,
    profiles: [],
    pause: false
  });
  const [rolesLoaded, setRolesLoaded] = useState(false);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [permissions, setPermissions] = useState<Types.Permission[]>([]);
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);

  useEffect(() => {
    api
      .users
      .getUsersByOrganization()
      .then((users: Types.User[]) => {
        setOrganizationUsers(users);
        setUsersLoaded(true);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      })
  }, [])

  useEffect(() => {
    api
      .permissions
      .getTeamPermissions()
      .then((permissions: Types.Permission[]) => {
        setPermissions(permissions)
        setPermissionsLoaded(true);
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    api
      .roles
      .getRoles()
      .then((roles: Types.Role[]) => {
        setRoles(roles)
        setRolesLoaded(true);
      })
      .catch(err => console.log(err))
  }, [])

  function createTeam () {
    if(newTeamUsers.length > 0 && newTeamUsers.every(newTeamUser =>newTeamUser.roleId)) {
      setLoading(true);
      api
        .teams
        .createTeam({ team: team, teamUsers: newTeamUsers })
        .then(() => {
          setLoading(false);
          props.onCreate();
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
        })
    }
  }

  useEffect(() => {
    const current: any = refContainer.current;
      console.log(refContainer);
    if(current) {
      current.firstChild.firstChild.firstChild.click()
      console.log(current.firstChild.firstChild.firstChild);
    }
    
  }, [newTeamUsers])

  function addNewTeamUser () {
    setNewTeamUsers([...newTeamUsers, {
      id: '',
      deleted: false,
      teamId: '',
      organizationId: '',
      userId: '',
      roleId: '',
      createdAt: 0,
      updatedAt: 0
    }]);
  }

  function updateNewTeamUser (newTeamUser: Types.TeamUser, change: any) {
    setNewTeamUsers(newTeamUsers.map((i, x) => {
      if(newTeamUser === i) {
        return { ...newTeamUser, ...change };
      }
      return i;
    }));
  }

  function removeNewTeamUser (i: number) {
    setNewTeamUsers(newTeamUsers.filter((teamUser, x) => x !== i));
  }


 function checkNewTeam (name: string) {	
	setLoading(true);
	if(name.trim() != '' && name.trim().length > 0)
	{
	  setLoading(false);
        }  
	setTeam({ ...team, name:  name})
  }

  if(rolesLoaded && permissions && permissions.length > 0) {
    return (
    

      <Row className='app_feed_row'>
        <Col className='app_col' lg={12} md={12} xs={12}>
         <div className="Sidebar_new-team-content new_teams_page">
           <div className='new_team_field'>
          <div className="new_team_field_label">
            Name of Team
          </div>
          <div className="new_team_field_input">
            <input 
              type="text"
              placeholder="Team Name"
              onChange={(e) => checkNewTeam(e.target.value)}
              value={team.name}
	      maxLength={30}
            />
          </div>
          </div>

          <div className='new_team_field'>
          <div className="new_team_field_label">
          Add Users To Team
          </div>
          <div className="new_team_field_input">
            <Row className="user admin">
            <Col lg={5} md={6} xs={6}> 
                <Select 
                  onChange={() => {}}
                  placeholder={me.name}
                  disabled={true}
                  options={organizationUsers.filter(user => !newTeamUsers.find(newTeamUser => newTeamUser.userId === user.id)).map(user => {
                    return { 
                      labelHeading: user.name || user.email, 
                      value: user.id, 
                    };
                  })}
                />
                </Col>

                <Col lg={5} md={4} xs={5}>
                <Select 
                  onChange={() => {}} 
                  placeholder="Team Manager"
                  width=''
                  disabled={true}
                  options={roles.map(role => {
                    return { 
                      labelHeading: role.name, 
                      value: role.id, 
                      labelSubheading: role.permissionIds.reduce((acc, permissionId) => {
                        const perm = permissions.find(permission => permission.id === permissionId);
                        if(perm) {
                          return [...acc, perm.name];
                        } else {
                          return acc;
                        }
                      }, [] as string[]).join(', ')
                    };
                  })} />

                  </Col>

            </Row>

              {newTeamUsers.map((newTeamUser, i) => {
                if(i !== 0) {
                  return ( <div className="user" ref={refContainer} key={`new-user-${i}`}>
                    <Row>
                   <Col lg={5} md={6} xs={6}> 
                   <Select 
                      onChange={userId => updateNewTeamUser(newTeamUser, {userId: userId})}
                      placeholder='Select User'
                      options={organizationUsers.filter(user => !newTeamUsers.find(newTeamUser => newTeamUser.userId === user.id)).map(user => {
                        return { 
                          labelHeading: user.name || user.email, 
                          value: user.id, 
                        };
                      })}
                    /></Col>
                     <Col lg={5} md={4} xs={5}>
                       <Select 
                      onChange={(roleId: any) => updateNewTeamUser(newTeamUser, {roleId: roleId})} 
                      placeholder='Select Role'
                      width='px'
                      options={roles.map(role => {
                        return { 
                          labelHeading: role.name, 
                          value: role.id, 
                          labelSubheading: role.description || role.permissionIds.reduce((acc, permissionId) => {
                            const perm = permissions.find(permission => permission.id === permissionId);
                            if(perm) {
                              return [...acc, perm.name];
                            } else {
                              return acc;
                            }
                          }, [] as string[]).join(', ')
                        };
                    })} />
                    </Col>

                    <Col className='close' lg={2} md={2} xs={1}>
			<span className="cursor"><CloseSVG onClick={() => removeNewTeamUser(i)}/></span>
                    </Col>
                    </Row>
                  </div>)
                }
              })}
              </div>

              <Row className='invite_footer justify-content-md-center bottom_space'>
              <Col className='invite_footer_l' lg={6} md={6} xs={6}>
              <span className="add_another cursor" onClick={addNewTeamUser}> Add User<AddSVG /></span>
              </Col>
              <Col className='invite_footer_r' lg={6} md={6} xs={6}>
              <Button
              color="orange"
              onConfirm={createTeam}
              enabled={
                !loading &&
                newTeamUsers.length > 0 && 
                newTeamUsers.every(newTeamUser =>newTeamUser.roleId) &&
                newTeamUsers.some(newTeamUser => newTeamUser.roleId === "teamManager") &&
                newTeamUsers.every(newTeamUser => newTeamUser.userId) &&
                team.name.length > 0
              }
            >Create</Button>
              </Col>
              </Row>
        </div>
    
      </div>
      </Col>
      </Row>
     
    );
  } else {
    return (<div className="page">
      <Spinner />
    </div>
    );
  }
}

export default NewTeamPage;