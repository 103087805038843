import React from 'react';
import { withRouter } from 'react-router-dom';
import { NavLink, RouteComponentProps } from 'react-router-dom';


import FooterSVG from '../public/images/footer.png';


declare global {
    interface Window {
        showLiveChatWidget(): void;
    }
}

const Bottombar: React.FC<RouteComponentProps> = props => {

  const excludedPaths = [
    '/login',
    '/register',
    '/signup/team',
    '/signup/pro',
    '/mobile',
    '/account-paused',
    '/account-deleted'
  ];


function contactUs()
{
   window.showLiveChatWidget();
}

  return (
   <div className="flex-column footer-sec mt30">
    <div className="flex-row footer-column-position flex-direction-column">
	    {/*
	    <div className={`bottombar flex-row ${excludedPaths.includes(props.history.location.pathname) ? 'off' : ''}`}>
	      <div className="left flex-column">
		<NavLink className="left flex-row" to="/"><BSLogoSVG /></NavLink>
		® 2021 BrandSocial, an OnlyWire, LLC service
	      </div>
	      <div className="legal-links flex-row">
		<NavLink className="legal-link" to="/terms-of-service">Terms of Use</NavLink>
		<NavLink className="legal-link" to="/privacy-policy">Privacy Policy</NavLink>
	      </div>
	    </div>
	    */}

	<div className="flex-column footer-column1">
	  <div className="footer-heading">About Us</div>
	  <div className="footer-heading-border"></div>
	  <div className="footer-text">
		BrandSocial is a simple and cost-effective
		platform for growing brand awareness and managing your team's social content on
		LinkedIn, Facebook, Pinterest and Twitter.	    
	  </div>
	</div>

	<div className="flex-column footer-column2">
	  <div className="footer-heading">Legal</div>
	  <div className="footer-heading-border"></div>
	  <div className="footer-menu">
		<NavLink className="legal-link" to="/terms-of-service">Terms of Use</NavLink>
		<NavLink className="legal-link" to="/privacy-policy">Privacy Policy</NavLink>			    
	  </div>
	</div>

	<div className="flex-column footer-column3">
	  <div className="footer-heading">Quick Link</div>
	  <div className="footer-heading-border"></div>
	  <div className="footer-menu">
		<NavLink className="legal-link" to="/" onClick={() => contactUs()}>Contact Us</NavLink>
		<NavLink className="legal-link" to={{pathname:"https://calendly.com/ryanonlywire"}} target="_blank">Schedule a Demo</NavLink>	    
	  </div>
	</div>
    </div>
    
    <div className="flex-column footer-copyright">
	® 2021 BrandSocial, an OnlyWire, LLC service
    </div>

    <div className="flex-column mobile-view">
     &nbsp;
    </div>

  </div>
  );
}

export default withRouter(Bottombar);
