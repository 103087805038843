import React, { useEffect, useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import api from '../api';

import * as Types from 'types';
import FeedPost from './FeedPost';
import PostScroller from './PostScroller';
import TabBar from './TabBar';
import Button from './Button';

import OrganizationContext from './OrganizationContext';
import NewPostButton from './NewPostButton';

import { Row, Col } from 'react-bootstrap';

const Feed: React.FC = () => {
  const organization = useContext(OrganizationContext) as Types.Organization;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
	if(organization)
	{
	  if(getDaysLeftInTrial () <= 0)
	    setLoading(true);
	}
  }, [])

  function getDaysLeftInTrial () {
    return Math.round((organization.trialEnd * 1000 - new Date().getTime())/(1000*60*60*24));
  }

  return (
    <div className="feed_page_section">

      <TabBar
        title="Feed"
        explainer={`These are the most recent posts across all of ${organization.name}.`}
        button={
          <NewPostButton />
        }
        tooltip={
        <div>From here you can see all of the most recent posts from your <span className="orange-text">Organization</span> as well as who has re-posted them. If you see a post by someone else that you want to re-post to your own <span className="orange-text">Social Profiles</span>, just click its <span className="orange-text">Share Button</span>.</div>
        }
      > 
        <NavLink className="black-link" to="/app/feed/all">All Posts</NavLink>
        <NavLink className="black-link" to="/app/feed/me">My Posts</NavLink>
        <NavLink className="black-link" to="/app/feed/team">Team Posts</NavLink>
        <NavLink className="black-link" to="/app/feed/by">Posted By</NavLink>
        <NavLink className="black-link" to="/app/feed/score">By Score</NavLink>
      </TabBar>
      
      <Col lg={9} sm={12} md={12}>
      <div className='feed_posts_row'>
      <PostScroller 
        load={api.posts.getPosts}
        render={
          (post: Types.JoinedPost, key: string) => (
           <FeedPost post={post} key={post.id}/>
          )
        }
      />
      </div>
      </Col>
    </div>
  );
}

export default Feed;
