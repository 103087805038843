import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import api from '../api';
import DOMPurify from 'dompurify';

import Spinner from './Spinner';
import Button from './Button';

import {ReactComponent as ConnectedSVG} from '../svgs/connected.svg';
import {ReactComponent as DisconnectedSVG} from '../svgs/disconnected.svg';

import * as Types from 'types';
import * as Consts from '../consts';

import { Col, Row, Table } from "react-bootstrap";

const ProfilePage: React.FC = () => {
  const history = useHistory();
  const [network, setNetwork] = useState<Types.Network | null>(null);
  const [loading, setLoading] = useState(false);
  const [work, setWork] = useState<Types.JoinedWork[]>([]);
  const [profile, setProfile] = useState<Types.Profile>({
    id: '',
    accessToken: '',
    accessSecret: '',
    createdAt: 0,
    deleted: false,
    updatedAt: 0,
    networkId: '',
    siteId: '',
    status: Consts.ProfileStatus.normal,
    name: '',
    userId: '',
    username: '',
    organizationId: ''
  });
  const [profileLoaded, setProfileLoaded] = useState(false);

  const uParams = useParams() as { profileId: string; };

  useEffect(() => {
    let profileId: string;
    if(uParams.profileId) {
      api
        .profiles
        .getProfileById(uParams.profileId)
        .then((profileData: Types.Profile) => {
          profileId = profileData.id as string;
          setProfile(profileData);
					return api
							.networks
							.getNetworkById(profileData.networkId);
				})
				.then(network => {
					setNetwork(network);
          setProfileLoaded(true);
          return api
            .work
            .getWorkForProfile(profileId as string);
        })
				.then(work => {
          setWork(work);
        })
        .catch((err: any) => {
          console.log(err);
        })
    }
  }, [uParams.profileId])

  function submit () {
    setLoading(true)
    api
      .profiles
      .updateProfile(profile)
        .then(() => {
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false)
          console.log(err);
        })
  }

  function createMarkup() {
		if(network) {
      return { __html: DOMPurify.sanitize(network.icon) };
		}
  }

	function getProfileStatusString () {
    switch (profile.status) {
        case Consts.ProfileStatus.normal: 
          return 'This profile is in a normal working status.';
          break;
        case Consts.ProfileStatus.reauth: 
          return 'This profile needs to be re-authenticated to continue posting.';
          break;
        case Consts.ProfileStatus.banned: 
          return 'This profile has been banned from its social network.';
          break;
        case Consts.ProfileStatus.invalid: 
          return 'The profile information is invalid.';
          break;
        case Consts.ProfileStatus.deleted: 
          return 'This profile has been deleted from its social network.';
          break;
    }
	}

  function renderStatus (status: number) {
    switch (status) {
      case Consts.WorkStatus.scheduled :
        return (<div className="status scheduled flex-row">
          Scheduled
        </div>);

      case Consts.WorkStatus.success :
        return (<div className="status success flex-row">
          Success
        </div>);

      case Consts.WorkStatus.failed :
        return (<div className="status bounced flex-row">
          Bounced
        </div>);

      case Consts.WorkStatus.processing :
        return (<div className="status scheduled flex-row">
          Processing
        </div>);

      case Consts.WorkStatus.approvalRequested :
        return (<div className="status scheduled flex-row">
          Approval Requested
        </div>);
    }
  }

  function workList () {
    if(work) {
      return (
        <div className='table_responsive'><Table responsive="lg profiles-list">
          <thead>
            <tr>
              <th>
                Description
              </th>
              <th>
                Scheduled
              </th>
              <th>
                Result
              </th>
              <th>
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {work &&
              work.map(workItem => {
                return (
                  <tr className="work-item cursor" key={workItem.id} onClick={() => history.push(`/app/post-assignment/${workItem.postAssignmentId}`)}>
                    <td>
                      <div className="flex-row">
                        {workItem.description}
                      </div>
                    </td>
                    <td>
                      <div className="flex-row">
                        {new Date(workItem.scheduled * 1000).toLocaleString()}
                      </div>
                    </td>
                    <td>
                      {workItem.result && workItem.result.shortMessage}
                    </td>
                    <td>
                      <div className="flex-row">
                        {renderStatus(workItem.status)}
                {workItem.profile.status === Consts.ProfileStatus.normal ? <ConnectedSVG /> : <DisconnectedSVG />}
                      </div>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
        </div>
      )
    }
  }

  if(profileLoaded) {
    return (
      <div className="feed_page_section profile_page">
        <Row className='justify-content-md-center profile_card'>
         
            <Col lg={12} md={12} xs={12} className="network_title_col flex-row">
		    <div className="network-icon ms-3" dangerouslySetInnerHTML={createMarkup()} />
		    <div className="ms-2 text-wrap"><h1>{profile.name}</h1></div>
              </Col>
            </Row>

            <Row className='profile_status'>
          <Col lg={12} md={12} xs={12}>
          <div className={`profile-status ${profile.status === 0 ? 'normal' : 'broken'}`}>{getProfileStatusString()}</div>
          </Col>
          </Row>

          <Row className='justify-content-md-center profile_rename'>
          <Col lg={6} md={6} xs={8} className="profile_rename_col_l">
          <div className="rename_profile">
              Rename Profile
            </div>
            <input 
              type="text" 
              value={profile.name}
              placeholder={profile.name}
              onChange={(e) => setProfile({ ...profile, name: e.target.value })} 
            />
            </Col>
            <Col lg={6} md={6} xs={4} className="profile_rename_col_r">
            <Button
            color="light-orange"
            enabled={!loading}
            onConfirm={submit}
          >Rename
          </Button>
              </Col>
            </Row>

        {work.length > 0 && 
          <div className="profile_content_list mb-5">
            {workList()}
          </div>
        }
      </div>
    );
  } else {
    return(<div className="page flex-column">
      <Spinner/>
    </div>);
  }
}

export default ProfilePage;
