import React, { useState } from 'react';
import Button from './Button';

import {ReactComponent as PricingDotsSVG} from '../svgs/pricing-dots.svg';
import {ReactComponent as ToggleOnSVG} from '../svgs/toggle-on.svg';
import {ReactComponent as ToggleOffSVG} from '../svgs/toggle-off.svg';
import {ReactComponent as IndividualSVG} from '../svgs/individual.svg';
import {ReactComponent as AgencySVG} from '../svgs/agency.svg';

const PricingPage: React.FC = () => {
  return (
    <div className="pricing-page flex-column">
      <h1 className="heading">
        <span className="orange-text">BrandSocial</span> Pricing
      </h1>
      <div className="dots mobile-hidden">
        <PricingDotsSVG />
      </div>

      <div className="tables flex-row">
        <div className="table flex-column">
          <div className="square">
            <AgencySVG />
          </div>
          <h3>Team Plan</h3>
          <h5
            className="orange-text"
          >
            $100 per month (billed annually)
          </h5>
          <span className="feature flex-row">
            <h4>Users</h4>
            <div>Unlimited</div>
          </span>
          <span className="feature flex-row">
            <h4>Social Profiles</h4>
            <div>Unlimited</div>
          </span>
          <span className="feature flex-row">
            <h4>Automation Sources</h4>
            <div>Unlimited</div>
          </span>
          <span className="feature flex-row">
            <h4>Posts</h4>
            <div>5,000/mo</div>
          </span>
          <span className="feature flex-row">
            <h4>Scheduled Posts</h4>
            <div>Unlimited</div>
          </span>
          <span className="feature flex-row">
            <h4>Analytics</h4>
            <div>Yes</div>
          </span>
          <span className="feature last flex-row">
            <h4>Support</h4>
            <div>Live Support</div>
          </span>
          <div className="table-footer flex-column">
            <Button
              to="/login"
              color="orange"
              style={{width: '100%', margin: 0, cursor: 'pointer'}}
            >
              Start Free Trial
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricingPage;
