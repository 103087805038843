import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import api from '../api';
import { useHistory } from 'react-router-dom';

import Back from './Back';
import Button from './Button';
import Checkbox from './Checkbox';
import ImageUpload from './ImageUpload';
import Spinner from './Spinner';
import DeleteButton from './DeleteButton';

import {ReactComponent as BlankUserSVG} from '../svgs/blank-user.svg';
import {ReactComponent as HugeBlankUserSVG} from '../svgs/huge-blank-user.svg';
import {ReactComponent as BigOrangeDeleteSVG} from '../svgs/big-orange-delete.svg';

import * as Types from 'types';

const EditUserPage: React.FC = () => {
  const history = useHistory();
  const [user, setUser] = useState<Types.User | null>(null);
  const [permissions, setPermissions] = useState<Types.Permission[]>([]);
  const [imageError, setImageError] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);

  const uParams = useParams() as { userId: string; };

  useEffect(() => {
    if(uParams.userId) {
      api
        .users
        .getUserById(uParams.userId)
        .then((user: Types.User) => {
          setUser(user);
        })
        .catch((err: any) => {
          console.log(err);
        })
    }
  }, [uParams.userId])

  useEffect(() => {
    if(uParams.userId) {
      api
        .permissions
        .getGlobalPermissions()
        .then((permissions: Types.Permission[]) => {
          setPermissions(permissions);
        })
        .catch((err: any) => {
          console.log(err);
        })
    }
  }, [uParams.userId])

  function onPermissionSelection (selected: boolean, permission: Types.Permission) {
    if(user) {
      if(selected) {
        setUser({ ...user, permissionIds: [ ...user.permissionIds, permission.id ] });
      } else {
        setUser({ ...user, permissionIds: user.permissionIds.filter(permissionId => permission.id !== permissionId) });
      }
    }
  }

  function handleUpload (imageUrl: string) {
    if(user) {
      setUser({ ...user, image: imageUrl });
    }
  }

  function handleDeleteImage () {
    if(user) {
      setUser({ ...user, image: '' });
    }
  }

  function onDeleteUser () {
    if(user) {
      return api.users.deleteUser(user.id)
      history.push('/app/users');
    } else {
      return new Promise((resolve, reject) => resolve(true));
    }
  }

  function onUpdateUser () {
    if(user) {
      api.users.updateUser(user);
      history.goBack();
    }
  }

  if(user && permissions) {
    return (
      <div className="edit-user page flex-column">
        <div className="top flex-column">
          <Back />
          <h1>Edit {user.name}</h1>
          <div className="gray-text">{`From here you can control ${user.name}'s picture and organization level permissions, or delete them.`}</div>
          <div className="image-control flex-row">
            <ImageUpload onUpload={handleUpload}>
              {user.image
                ? <div className="huge-user-image flex-row" style={{ backgroundImage: `url('${user.image}')` }}/>
                : <HugeBlankUserSVG className="huge-user-image" />
              }
            </ImageUpload>
            <BigOrangeDeleteSVG onClick={handleDeleteImage} />
          </div>
        </div>
        <div className="edit-permissions flex-column">
          <h2>Global Permissions</h2>
          {permissions.map(permission => {
            return (
              <div 
                className="permission flex-row" 
                key={permission.id}
              >
                <div className="left flex-column">
                  {permission.name}
                  <div className="gray-text">{permission.description}</div>
                </div>
                <Checkbox 
                  idName={permission.id}
                  onChange={(e) => onPermissionSelection(e, permission)}
                  initialChecked={user && user.permissionIds.includes(permission.id)}
                />
              </div>
            )

          })}
        </div>
        <div className="buttons flex-row">
          <DeleteButton 
            onConfirm={onDeleteUser} 
            message={`Are you sure you want to delete ${user.name}?`}
            buttonText="Delete User"
          />
          <Button color="orange" onConfirm={onUpdateUser}>Update</Button>
        </div>
      </div>
    )
  } else {
    return (
      <div className="page flex-column">
        <Spinner/>
      </div>
    )
  }
}

export default EditUserPage;
