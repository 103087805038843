import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../api';

import * as Types from 'types';

import Back from './Back';
import TabBar from './TabBar';
import Button from './Button';
import Checkbox from './Checkbox';
import Spinner from './Spinner';

import { Row, Col } from 'react-bootstrap';

const NewRolePage: React.FC = () => {
  const history = useHistory();
  const [permissions, setPermissions] = useState<Types.Permission[]>([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState<Types.Role>({
    name: '',
    organizationId: '',
    permissionIds: [],
    id: '',
    deleted: false,
    createdAt: 0,
    updatedAt: 0
  });
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .permissions
      .getTeamPermissions()
      .then((permissions: Types.Permission[]) => {
        setPermissions(permissions)
        setPermissionsLoaded(true);
      })
      .catch(err => console.log(err))
  }, [])

  function onSelection (selected: boolean, permission: Types.Permission) {
    if(selected) {
      setRole({ ...role, permissionIds: [...role.permissionIds, permission.id] });
    } else {
      setRole({ ...role, permissionIds: role.permissionIds.filter((existingPermissionId: string) => existingPermissionId !== permission.id) });
    }
  }

  function createRole () {
    setLoading(true);
    api
      .roles
      .createRole(role)
      .then(() => {
        setLoading(false);
        history.goBack();
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      })
  }

  function checkNewRole (name: string) {

	if(name.trim() != '')
	{
	  setLoading(false);
        }
	else
	{
	  setLoading(true);
	}	  
	setRole({ ...role, name: name})

  }


  if(permissionsLoaded) {
    return (
      <div className="feed_page_section new_role_page">
        <Back />
        <TabBar
          title="New Role"
          explainer={`Create a new team role. Users who are assigned this role on a team will have these permissions for that team.`}
        > 
        </TabBar>
        <div className="new_role_content">
          <Row className="app_feed_row role_search">
          <Col lg={12} md={12} sm={12} className="app_col">
            <h6>Name of Role</h6>
            <div className="role_search_form">
              <input 
                type="text"
                placeholder="Role Name"
                onChange={e => checkNewRole(e.target.value)}
                value={role.name}
              />
            </div>
            </Col>
          </Row>

          <div className="edit_permissions mb-4">
            <h3>Team Permissions</h3>
            <div className='role_permissions_list'>
            {permissions.map(permission => {
              return (
                <Row 
                  className="app_feed_row permission" 
                  key={permission.id}
                >
                  <Col lg={10} md={10} xs={9} className="app_col new_role_title">
                    <h5>{permission.name}</h5>
                    <div className="new_role_desc">{permission.description}</div>
                  </Col>
                  <Col lg={2} md={2} xs={3} className="app_col role_check"><div align="right"><Checkbox 
                    idName={permission.id}
                    onChange={(selected) => onSelection(selected, permission)}
                  /></div></Col>
                </Row>
              );
            })}
            </div>

          <Row className='role_create'>
        <Col lg={12} md={12} sm={12}>
        <div className="create_button">
          <Button
            color="orange"
            onConfirm={createRole}
            enabled={!loading}
          >Create</Button>
        </div>
        </Col>
        </Row>
        
        </div>
        </div>

      </div>
    );
  } else {
    return (<div className="new_role_page">
      <Spinner/>
    </div>
    );
  }
}

export default NewRolePage;
