import React, { useEffect, useState } from 'react';
import api from '../api';

import BigStar from './BigStar';
import Button from './Button';
import Network from './Network';
import Spinner from './Spinner';

import * as Types from 'types';

const Onboarding: React.FC = () => {
  const [networks, setNetworks] = useState<Types.Network[]>([]);
  const [profiles, setProfiles] = useState<Types.Profile[]>([]);
  const [profilesLoaded, setProfilesLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api
      .networks
      .getNetworks()
      .then(networks => {
        setNetworks(networks)
      })

    api
      .profiles
      .getProfiles()
      .then(profiles => {
        setProfiles(profiles)
        setProfilesLoaded(true);
      })
  }, [])

  function networksList () {
    if(networks && profiles) {
      return networks.map((network: Types.Network) => {
        return <Network 
          network={network} 
          profiles={profiles}  
          key={network.slug} 
          onLoading={() => setLoading(true)}
          load={() => {
            api
              .profiles
              .getProfiles()
              .then(profiles => {
                setProfiles(profiles)
                setProfilesLoaded(true);
              })
          }}
        />;
      });
    } else {
      return <h2>Loading...</h2>
    }
  }

  return (
    <div className="onboarding-page flex-row">
      <div className="wrapper flex-column">
        <div className="content flex-column">
          <div className="step four flex-column">
            <h1>Connect Networks</h1>
            <div className="subheading">Connect your first social profile. You, or your team members, can draft, schedule, and send posts from BrandSocial to many different social profiles.</div>
            {networksList()}
            <Button color="light-orange" to="/app/feed/all" enabled={!loading}>Skip</Button>
          </div>
        </div>
      </div>
      <BigStar />
    </div>
  );
}

export default Onboarding;
