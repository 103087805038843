import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view'

import api from '../api';

import * as Types from 'types';

interface Props {
}

const AdminResultsPage: React.FC<Props> = props => {
  const [exceptions, setExceptions] = useState<Types.Exception[]>([]);

  return (
    <div className="content flex-column">
      Results
    </div>
  );
}

export default AdminResultsPage;
