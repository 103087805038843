import React from 'react';

import Tooltip from './Tooltip';

import { Row, Col } from 'react-bootstrap';

interface Props {
  title: string;
  explainer?: string;
  button?: React.ReactNode;
  children: React.ReactNode;
  tooltip?: React.ReactNode;
}

const TabBar: React.FC<Props> = props => {

  return (

    <div className='feed_tabbar_title'>
    <Row className="app_feed_row justify-content-md-center">
      <Col lg={10} md={9} sm={7} className="app_col app_feed_title">
        <h1>{props.title}</h1> 
      </Col>

      <Col lg={2} md={3} sm={5} className='app_col app_feed_btn'>
      {props.button}
      </Col>
     </Row>

     <Row className='feed_tabbar_desc app_feed_row'>
      <Col lg={12} md={12} sm={12} className="app_col app_feed_title_desc">
          {props.explainer}
          {props.tooltip &&
            <Tooltip>
              {props.tooltip}
            </Tooltip>
          }
      </Col>   
    </Row>
    </div>
  );
}

export default TabBar;
