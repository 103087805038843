import axios from './axios';
import { authorizedHeader } from '../helpers';
import { db } from '../firebase';
import { identity } from '../helpers';
import { getUser } from './users';

import * as Types from 'types';

export function getLogsForOrganization(lastSnapshot: string = ''): Promise<Types.Log[]> {
  const url = lastSnapshot ? `/logs/after/${lastSnapshot}` : `/logs`;
  return new Promise(async (resolve, reject) => {
    axios.get(url, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}


export function getLogsForUser(userId: string, lastSnapshot: string = ''): Promise<Types.Log[]> {
  const url = lastSnapshot ? `/logs/user/${userId}/after/${lastSnapshot}` : `/logs/user/${userId}`;
  return new Promise(async (resolve, reject) => {
    axios.get(url, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function getLogsForMe(lastSnapshot: string = ''): Promise<Types.Log[]> {
  const url = lastSnapshot ? `/logs/me/after/${lastSnapshot}` : `/logs/me`;
  return new Promise(async (resolve, reject) => {
    axios.get(url, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function getLogsForTeam(teamId: string, lastSnapshot: string = ''): Promise<Types.Log[]> {
  const url = lastSnapshot ? `/teams/${teamId}/logs/after/${lastSnapshot}` : `/teams/${teamId}/logs`;
  return new Promise(async (resolve, reject) => {
    axios.get(url, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function createLog(logData: any): Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    axios.post('/logs', logData, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}
