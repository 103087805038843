import React, { useEffect, useState, useRef } from 'react';
import FeedPost from './FeedPost';

import * as Types from 'types';

interface Props {
  load: (lastSnapshot?: string) => Promise<Types.JoinedPostAssignment[]>;
  render:  (post: Types.JoinedPostAssignment, key: string) => React.ReactNode;
  posts: Types.JoinedPostAssignment[];
  setPosts: (posts: Types.JoinedPostAssignment[]) => void;
  team?: boolean;
}

const PostScroller: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [mounted, setMounted] = useState(true);
  const [lastSnapshot, setLastSnapshot] = useState<any>('');
  const firstPromiseRef = useRef<Promise<any> | null>(null);

  useEffect(() => {
    setMounted(true);
    setHasMore(true);
    setLoading(false);
    if(props.posts.length === 0 && hasMore) {
      setLoading(true);
      firstPromiseRef.current = props
        .load()
        .then(newPosts => {
          props.setPosts(props.posts.concat(newPosts));
          if(newPosts.length !== 0) {
            setLastSnapshot(newPosts[newPosts.length - 1].id);
          }
          if(newPosts.length !== 10) {
            setHasMore(false);
          }
          setLoading(false);
        })
    }
    return function cleanup() {
      props.setPosts([]);
      if(firstPromiseRef.current) {
      //@ts-ignore
        firstPromiseRef.current.cancel();
      }
    }
  }, [])

  window.onscroll = () => {
    if (loading || !hasMore) return;

    if (
      window.innerHeight + document.documentElement.scrollTop
      === document.documentElement.offsetHeight
    ) {
      setLoading(true);
      props.load(lastSnapshot)
        .then(newPosts => {
          if(mounted) {
            props.setPosts([...props.posts, ...newPosts]);
            if(newPosts.length !== 0) {
              setLastSnapshot(newPosts[newPosts.length - 1].id);
            }
            if(newPosts.length !== 10) {
              setHasMore(false);
            }
            setLoading(false);
          }
        })
    }
  }

  if(props.posts.length > 0) {
    return (
      <>{
          props.posts
          .map((post: Types.JoinedPostAssignment) => props.render(post, post.id as string))
        }
        { loading && <div>Getting More Posts...</div> }
      </>
    )
  } else if(props.posts.length === 0 && !loading && props.team) {
    return (<div>There are no posts yet. To create Team Posts, you need to set up Social Network Profiles for the Team, and then post to the Team Profile.</div>);
  } else if(props.posts.length === 0 && !loading) {
    return (<div>There are no posts yet.</div>);
  } else {
    return (<div>Getting More Posts...</div>);
  }
}

interface PostListProps {
  render:  (post: Types.JoinedPostAssignment, key: string) => React.ReactNode;
  posts: Types.JoinedPostAssignment[];
}
  
const PostList: React.FC<PostListProps> = props => {
  return (
    <>
      {props.posts.map((post: Types.JoinedPostAssignment) => props.render(post, post.id as string))}
    </>
  );
}

export default PostScroller;
