import React from 'react';
import ReactSelect from 'react-select';

interface Props {
  options: { labelHeading: string, labelSubheading?: string, value: string | number}[];
  onChange: (e: any) => void;
  placeholder: string;
  width?: string;
  value?: any;
  defaultInputValue?: string;
  disabled?: boolean;
}

const Select: React.FC<Props> = props => {

  function backgroundColor (isSelected: boolean, isFocused: boolean) {
    if(isSelected) {
      return '#fef6f2';
    } else if(isFocused) {
      return '#fef6f2';
    } else {
      return 'white';
    }
  }

  function customFilter(option: any, searchText: string) {
    if (
      option.value.toLowerCase().includes(searchText.toLowerCase()) ||
      option.label.props.children[0].toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <ReactSelect 
      value={props.value}
      defaultInputValue={props.defaultInputValue}
      filterOption={customFilter}
      isDisabled={props.disabled || false}
      styles={{ 
        control: styles => ({
          ...styles, 
          color: '#333', 
          height: '48px', 
          alignItems: 'center'
        }), 
        container: styles => ({
          ...styles,
          minWidth: '88px', 
          width: props.width || '-webkit-fill-available'
        }),
        singleValue: styles => ({
          ...styles,
          maxHeight: '23px',
          overflow: 'hidden'
        }),
        valueContainer: (styles, state) => ({
          ...styles,
          maxHeight: '48px'
        }),
        indicatorsContainer: (styles, state) => ({
          height: '100%',
        }),
        indicatorSeparator: (styles, state) => ({
          ...styles,
          display: 'none'
        }),
        menu: (styles, state) => ({
          ...styles,
          minWidth: '150px'
        }),
        dropdownIndicator: (styles, state) => ({
          ...styles,
          width: '30px',
          height: '47px',
          display: 'flex',
          alignItems: 'center',
          padding: '0 8px 0 0'
        }),
        option: (styles, state) => ({
          ...styles,
          background: backgroundColor(state.isSelected, state.isFocused),
          color: state.isSelected ? '#F45F0C' : '#333',
        })
      }} 
      className="select" 
      placeholder={props.placeholder}
      onChange={(e: any) => props.onChange(e.value)}
      options={props.options.map(option => {
        return {
          value: option.value,
          label: <div className="flex-column" style={{alignItems: 'flex-start'}}>
            {option.labelHeading}
            {option.labelSubheading && <small className="gray-text small">{option.labelSubheading}</small>}
          </div>
        };
      })} 
    />
  );
}

export default Select;
