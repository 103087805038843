import * as React from 'react';
import { useHistory } from 'react-router-dom';

export interface Props {
  enabled?: boolean,
  to?: string,
  color?: string,
  style?: any,
  onConfirm?: any,
  children: React.ReactNode
}


const Button = ({
  enabled = true,
  to,
  color,
  style,
  onConfirm,
  children
}: Props) => {
  const history = useHistory();

  function onClick(e: any) {
    if(enabled !== false && onConfirm) {
      onConfirm();
    }
    if(!!to) {
      history.push(to);
    }
    
    return false;
  }

  return (
    <div className='newpost-button' align="right"><button 
      className={`button ${enabled ? color : 'disabled'}`}
      onClick={onClick}
      style={style}
      disabled={!enabled}
    ><span className='post_icon'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" class="bi bi-plus-lg" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
  </svg></span>
      {children}
    </button></div>
  );
}

export default Button;
