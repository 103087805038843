import React, { useEffect, useState } from 'react';

import {ReactComponent as CheckBoxFalseSVG} from '../svgs/checkbox-false.svg';
import {ReactComponent as CheckBoxTrueSVG} from '../svgs/checkbox-true.svg';
import {ReactComponent as CheckBoxDisabledSVG} from '../svgs/checkbox-disabled.svg';

export interface Props {
  children?: React.ReactNode;
  onChange: (selected: boolean) => void;
  initialChecked?: boolean;
  idName: string;
  disabled?: boolean;
}


const Checkbox = ({
  children,
  onChange,
  initialChecked,
  idName,
  disabled
}: Props) => {
  const [checked, setChecked] = useState(initialChecked);

  useEffect(() => {
    if(initialChecked !== undefined) {
      setChecked(initialChecked);
    }
  }, [initialChecked])

  function display () {
    if(disabled) {
      return <CheckBoxDisabledSVG />;
    }

    if(checked) {
      return <CheckBoxTrueSVG />;
    } else {
      return <CheckBoxFalseSVG />;
    }
  }

  return (
    <div className={`checkbox ${disabled && 'disabled'}`} onClick={() => {
      if(!disabled) {
        onChange(!checked)
        setChecked(!checked)
      }
    }}>
      {display()}
      {children}
    </div>
  );
}

export default Checkbox;
