import axios from './axios';
import { authorizedHeader } from '../helpers';
import { db } from '../firebase';
import { identity } from '../helpers';
import { getUser } from './users';

import * as Types from 'types';

export function getNotifications(): Promise<any> {
  return getUser()
    .then(user => {
      return db
        .collection('notifications')
        .where('userId', '==', user.id)
        .orderBy('createdAt', 'desc')
        .get()
        .then(reference => {
          return reference
            .docs.map(doc => doc.data())
        })
        .then(data => {
          return identity<any[]>(data);
        })
    })
}

export function viewed (notifications: any[]): Promise<any[]> {
  return new Promise(async (resolve, reject) => {
    axios.put(`/notifications/viewed`, notifications, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function getNotificationSettings(): Promise<Types.NotificationSetting[]> {
  return db
    .collection('notificationSettings')
    .get()
    .then(reference => {
      return reference
        .docs.map(doc => doc.data())
    })
    .then(data => {
      return identity<Types.NotificationSetting[]>(data);
    })
}
