import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import api from '../api';

import Back from './Back';
import TabBar from './TabBar';
import Button from './Button';
import UserCard from './UserCard';
import Spinner from './Spinner';
import Select from './Select';
import DeleteButton from './DeleteButton';
import DeleteIcon from './DeleteIcon';

import {ReactComponent as AddSVG} from '../svgs/add.svg';
import {ReactComponent as CloseSVG} from '../svgs/close.svg';

import UserContext from './UserContext';

import { Col, Row, Table } from "react-bootstrap";

import * as Types from 'types';

const TeamPage: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [addUsers, setAddUsers] = useState(false);
  const [organizationUsers, setOrganizationUsers] = useState<Types.User[]>([]);
  const [roles, setRoles] = useState<Types.Role[]>([]);
  const [permissions, setPermissions] = useState<Types.Permission[]>([]);
  const [newTeamUsers, setNewTeamUsers] = useState<Types.TeamUser[]>([]);
  const [team, setTeam] = useState<Types.JoinedTeam>({
    id: '',
    organizationId: '',
    name: '',
    createdAt: 0,
    updatedAt: 0,
    deleted: false,
    users: [],
    teamUsers: [],
    profiles: [],
    pause: false
  });
  const [teamLoaded, setTeamLoaded] = useState(false);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [rolesLoaded, setRolesLoaded] = useState(false);
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);

  const uParams = useParams() as { teamId: string; };

  const user = useContext(UserContext) as Types.User;

  useEffect(() => {
    if(uParams.teamId) {
      api
        .teams
        .getTeamById(uParams.teamId)
        .then((team: Types.JoinedTeam) => {
          setTeam(team);
          setNewTeamUsers([{
            id: '',
            deleted: false,
            teamId: team.id as string,
            organizationId: '',
            userId: '',
            roleId: '',
            createdAt: 0,
            updatedAt: 0
          }]);
          setLoading(false);
          setTeamLoaded(true);
        })
        .catch((err: any) => {
          console.log(err);
          setLoading(false);
        })
    }
  }, [uParams.teamId])

  useEffect(() => {
    if(uParams.teamId) {
      api
        .users
        .getUsersByOrganization()
        .then((users: Types.User[]) => {
          setOrganizationUsers(users);
          setUsersLoaded(true);
        })
        .catch((err: any) => {
          console.log(err);
          setLoading(false);
        })
    }
  }, [uParams.teamId])

  useEffect(() => {
    api
      .permissions
      .getTeamPermissions()
      .then((permissions: Types.Permission[]) => {
        setPermissions(permissions)
        setPermissionsLoaded(true);
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    if(uParams.teamId) {
      setLoading(true);
      api
        .roles
        .getRoles()
        .then((roles: Types.Role[]) => {
          setRoles(roles);
          setRolesLoaded(true);
        })
        .catch((err: any) => {
          console.log(err);
        })
    }
  }, [uParams.teamId])

  function deleteTeamUser (teamId: string, userId: string) {
    api
      .teams
      .deleteTeamUser(teamId, userId)
      .then(() => {
        api
          .teams
          .getTeamById(uParams.teamId)
          .then((team: Types.JoinedTeam) => {
            setTeam(team);
            setNewTeamUsers([{
              id: '',
              deleted: false,
              teamId: team.id as string,
              organizationId: '',
              userId: '',
              roleId: '',
              createdAt: 0,
              updatedAt: 0
            }]);
            setLoading(false);
            setTeamLoaded(true);
          })
          .catch((err: any) => {
            console.log(err);
            setLoading(false);
          })
      })
  }

  function submit() {
    setLoading(true);
    api
      .teams
      .updateTeam(team)
        .then(() => {
          return api
            .teams
            .getTeamById(uParams.teamId)
        })
        .then(teams => {
          setTeam(teams)
          setLoading(false);
        })
        .catch((err: any) => {
          console.log(err);
          setLoading(false);
        })
  }

  function updateNewTeamUser (newTeamUser: Types.TeamUser, change: any) {
    setNewTeamUsers(newTeamUsers.map((i, x) => {
      if(newTeamUser === i) {
        return { ...newTeamUser, ...change };
      }
      return i;
    }));
  }

  function addNewTeamUser () {
    setNewTeamUsers([...newTeamUsers, {
      id: '',
      teamId: team.id as string,
      deleted: false,
      organizationId: '',
      userId: '',
      roleId: '',
      createdAt: 0,
      updatedAt: 0
    }]);
  }

  function removeNewTeamUser (i: number) {
    setNewTeamUsers(newTeamUsers.filter((teamUser, x) => x !== i));
  }

  function updateTeamUserRole (userId: string, roleId: string) {
    const updatedTeamUsers = team.users.map(teamUser => {
      if(teamUser.id === userId) {
        return { ...teamUser, teamRole: roleId};
      } else {
        return teamUser;
      }
    })
    setTeam({ ...team, users: updatedTeamUsers as Types.User[]});
    api
      .teams
      .updateTeamUserRole(team.id as string, userId, roleId)
  }

  function handleCreateUsers () {
    setLoading(true);
    api.teams.createTeamUsers(newTeamUsers)
      .then(() => {
        setLoading(false);
        history.goBack();
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
        return false;
      })
  }

  function handleDeleteTeam () {
    setLoading(true);
    return api.teams.deleteTeam(team)
      .then(() => {
        return history.push('/app/teams');
      });
  }

  function handleDeleteTeamUser (user: Types.User) {
    setLoading(true);
    return api
      .teams
      .deleteTeamUser(team.id as string, user.id)
      .then(() => {
        return api
          .teams
          .getTeamById(uParams.teamId)
          .then((team: Types.JoinedTeam) => {
            setTeam(team);
            setNewTeamUsers([{
              id: '',
              deleted: false,
              teamId: team.id as string,
              organizationId: '',
              userId: '',
              roleId: '',
              createdAt: 0,
              updatedAt: 0
            }]);
            setLoading(false);
            return true;
          })
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
        return false;
      })
  }

  if(teamLoaded && usersLoaded && rolesLoaded && permissionsLoaded) {
    return (
      <Row className='app_feed_row'>
    <Col className='app_col' lg={12} md={12} sm={12}>
      <div className="Sidebar_new-team Sidebar_me team_details_page feed_page_section team_edit_page">
        <Back />
        <TabBar
          title={`Edit ${team.name}`}
          explainer={`Change the name or edit members for the team ${team.name}.`}
          button={ (user.permissionIds.includes('administrateOrganization') || user.permissionIds.includes('manageTeams')) && 
          <DeleteButton 
            enabled={!loading} 
            onConfirm={handleDeleteTeam} 
            buttonText="Delete Team" 
            message={`Are you sure you want to delete the team ${team.name}?`}
          />
          }
        > 
        </TabBar>
        {addUsers
          ? <div className='new_teams_page'><div className='new_team_field'><div className="new_team_field_input">
              {newTeamUsers.map((newTeamUser, i) => {
                return (<div className="user">
                  <Row className="user_row">
                    <Col lg={5} md={6} xs={6}>
                    <Select
                      onChange={userId => updateNewTeamUser(newTeamUser, {userId: userId})}
                      placeholder='Select a Team Member'
                      options={organizationUsers.map(user => {
                        return { 
                          labelHeading: user.name || user.email, 
                          value: user.id, 
                        };
                      })}
                    />
                    </Col>

                    <Col lg={5} md={4} xs={5}>
                    <Select 
                      onChange={(roleId: any) => updateNewTeamUser(newTeamUser, {roleId: roleId})} 
                      placeholder='Select a Role'
                      width='300px'
                      options={roles.map(role => {
                        return { 
                          labelHeading: role.name, 
                          value: role.id, 
                          labelSubheading: role.description || role.permissionIds.reduce((acc, permissionId) => {
                            const perm = permissions.find(permission => permission.id === permissionId);
                            if(perm) {
                              return [...acc, perm.name];
                            } else {
                              return acc;
                            }
                          }, [] as string[]).join(', ')
                        };
                    })} />
                    </Col>

                    <Col className='close' lg={2} md={2} xs={1}>
                    <CloseSVG onClick={() => removeNewTeamUser(i)} />
                    </Col>
                  </Row>
                </div>)
              })}
              <Row className="invite_footer justify-content-md-center">
                <Col className='invite_footer_l' lg={6} md={6} xs={6}>
                <span className="add_another" onClick={addNewTeamUser}>Add Another <AddSVG /></span>
                </Col>

                <Col className='invite_footer_r' lg={6} md={6} xs={6}>
                <Button
                  color="orange"
                  onConfirm={handleCreateUsers}
                  enabled={
                    !loading && 
                    newTeamUsers.length > 0 &&
                    newTeamUsers.every(newTeamUser => newTeamUser.userId.length > 0) &&
                    newTeamUsers.every(newTeamUser => newTeamUser.roleId.length > 0)
                  }
                >
                  Create
                </Button>
                </Col>
              </Row>
            </div>
            </div>
            </div>
          : <>
	   {(user.permissionIds.includes('administrateOrganization') || user.permissionIds.includes('manageTeams')) &&   
	      <div className="info tab-content feed_page_section">
		      <div className="info_field_label">Team Name</div>
		      <Row className="input-row name">
            <Col className='info_col_l' lg={6} md={6} xs={8}>
            <input 
			  className="change-team-name"
			  type="text"
			  placeholder={team.name} 
			  onChange={e => setTeam({ ...team, name: e.target.value })}
			/> 
              </Col>

              <Col className='info_col_r' lg={6} md={6} xs={4}>
              <Button
			  color="orange"
			  onConfirm={submit}
			  enabled={!loading}
			>
			  Update Team Name
			</Button>
              </Col>
		      </Row>
	       </div>	      
	      }

              <div className="edit-notification-settings team_edit_user">       

              <Row className="app_feed_row justify-content-md-center row">
              <Col lg={12} md={12} xs={12} className='app_col app_feed_title'>
              <h2 className="table-heading">Team Members</h2></Col>
              </Row>
              
                <div className='table_responsive'>
                <Table responsive="lg">
                 
                 <thead>
                  <tr>
                    <th>
                      Name
                    </th>
                    <th>
                      Team Role
                    </th>
		    { /*
                    <th>
                      2FA
                    </th>
		    */ }
                    <th>
                      Status
                    </th>
                  </tr>
                  </thead>

                  <tbody>
                  { team.users.length > 0
                    ? team.users.map((user: Types.User) => {
                      return <tr>
                        <td><UserCard key={user.id} user={user}/></td>
                        <td>
                          <Select 
                            value={user.teamRole && user.teamRole.id} 
                            placeholder={user && user.teamRole && user.teamRole.name || 'Select a Role'} 
                            onChange={e => updateTeamUserRole(user.id, e)}
                            options={roles.map(role => {
                              return { 
                                labelHeading: role.name, 
                                value: role.id, 
                                labelSubheading: role.description || role.permissionIds.reduce((acc, permissionId) => {
                                  const perm = permissions.find(permission => permission.id === permissionId);
                                  if(perm) {
                                    return [...acc, perm.name];
                                  } else {
                                    return acc;
                                  }
                                }, [] as string[]).join(', ')
                              };
                            })}
                          />
                        </td>
                        { /*<td>{user.authyId ? <div className="orange-text">On</div> : 'Off'}</td> */}
                        <td>
                          <div className="flex-row">
                            <div className="active flex-row">Active</div>
                            <DeleteIcon 
                              onConfirm={() => handleDeleteTeamUser(user)} 
                              message={`Are you sure you want to remove ${user.name} from the team?`}
                            />
                          </div>
                        </td>
                      </tr>
                    })
                    : <div className="gray-text flex-row">There are no users</div>
                  }
                  </tbody>

                </Table>
                </div>

              </div>

              <Row className='invite_footer team_edit_footer justify-content-md-center'>
              <Col className='invite_footer_l' lg={6} md={6} xs={6}>
              <span className="add_another" onClick={() => setAddUsers(true)}>Add User <AddSVG /></span>
              </Col>
              <Col className='invite_footer_r' lg={6} md={6} xs={6}>
              </Col>
              </Row>

            </>
        }

      </div>
      </Col>
      </Row>
    );
  } else {
    return (<div className="page flex-column">
      <Spinner/>
    </div>);
  }
}

export default TeamPage;
