import React, { useEffect, useState, useContext } from 'react';
import api from '../api';

import * as Types from 'types';

import UserCard from './UserCard';
import TabBar from './TabBar';
import Button from './Button';
import UserList from './UserList';
import Spinner from './Spinner';

import UserContext from './UserContext';

const Users: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [organizationUsers, setOrganizationUsers] = useState<Types.User[]>([]);
  const [userLoaded, setUserLoaded] = useState(false);

  const me = useContext(UserContext) as Types.User;

  useEffect(() => {
    setLoading(true);
    api
      .users
      .getUsersByOrganization()
      .then((users: Types.User[]) => {
        setOrganizationUsers(users)
        setUserLoaded(true);
      })
      .catch(err => console.log(err))
  }, [])

  if(userLoaded) {
    return (
      <div className="feed_page_section users_page_section">
        {me.permissionIds.includes('manageUsers')
          ? <TabBar
            title="Users"
            explainer="These are all the people in your organization."
            button={
              <Button to="/app/users/new" color="orange">New User</Button>
            }
          > 
          </TabBar>
          : <TabBar
            title="Users"
            explainer="These are all the people in your organization."
          > 
          </TabBar>
        }
        <UserList title="Users" users={organizationUsers} />
      </div>
    );
  } else {
    return (<div className="page flex-column">
        <Spinner/>
      </div>
    );
  }
}

export default Users;
