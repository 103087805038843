import React, { useEffect, useState, useContext } from 'react';
import api from '../api';

import * as Types from 'types';
import { Col, Row } from "react-bootstrap";

import Team from './Team';
import TabBar from './TabBar';
import Button from './Button';
import Spinner from './Spinner';

import UserContext from './UserContext';

const Teams: React.FC = () => {
  const [teams, setTeams] = useState<Types.JoinedTeam[]>([]);
  const [loading, setLoading] = useState(false);
  const [teamEnabled, setTeamEnabled] = useState(true);
  const [newTeam, setNewTeam] = useState<any>({
    name: '',
    users: [],
    profiles: []
  });
  const [teamsLoaded, setTeamsLoaded] = useState(false);

  const me = useContext(UserContext) as Types.User;

  useEffect(() => {
    setLoading(true);

    api
      .teams
      .getTeams()
      .then(teams => {
        setLoading(false);
        setTeams(teams)
        setTeamsLoaded(true);

	if(teams.length === 100)
	  setTeamEnabled(false);
      })
      .catch((err: any) => {
        console.log(err);
      })
  }, [])

  function teamList() {
    if(teams) {
      return teams.map((team: Types.JoinedTeam) => {
        if(team) {
          return <Team key={team.id} team={team}/>
        }
      })
    } else {
      return <h2>Loading...</h2>
    }
  }


  function submit() {
    setLoading(true)
    api
      .teams
      .createTeam(newTeam)
        .then(() => {
          return api
            .teams
            .getTeams()
        })
        .then(teams => {
          setTeams(teams)
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false)
          console.log(err);
        })
  }

  if(teamsLoaded) {
    return (

      <Row className='app_feed_row'>
        <Col className='app_col' lg={12} md={12} sm={12}>
         <div className="Sidebar_teams feed_page_section">
        {me.permissionIds.includes('manageTeams')
        
          ? <TabBar
            title="Teams"
            explainer={"These are all the teams(" + teams.length.toString() + "/100) in your organization."}
            button={
              <Button to="/app/teams/new" color="orange" enabled={teamEnabled}>New Team</Button>
            }
          > 
          </TabBar>
          : <TabBar
            title="Teams"
            explainer="These are all the teams in your organization."
          > 
          </TabBar>
        }
        <div className="teams-container">
          <Row className='teams_details'>
          {teamList()}
          </Row>
        </div>
      </div>
      </Col>
      </Row>
      
    );
  } else {
    return(
      <Row>
	<Col lg={12} md={12} sm={12}>

	    <div className="page">
	      <Spinner/>
	    </div>
	</Col>
      </Row>

    );
  }

}

export default Teams;
/*
      <h1>Teams</h1>
      <input
        id="new-team" 
        name="new-team" 
        type="new-team" 
        placeholder="Name of new team" 
        value={newTeam.name}
        onChange={e => {
          setNewTeam({ ...newTeam, name: e.target.value });
        }}
      />
      <button disabled={loading} onClick={submit}>Create New Team</button>
 */