import axiosBase, { AxiosInstance } from 'axios';
import firebaseAuth, { FBSignOut} from '../firebase';
import { isLive } from '../helpers';

let axios: AxiosInstance;

if(isLive()) {
  axios = axiosBase.create({
    baseURL: 'https://brandsocial.com/api',
  });
} else {
  axios = axiosBase.create();
}

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if(error.response && error.response.status === 403 && window.location.href !== '/') {
    FBSignOut()
      .then(() => {
        window.location.href = '/login';
      })
      .catch(err => {
        console.log(err);
      });
  } 
  return Promise.reject(error.response);
});

export function addResponseHandler (handler: (error: any) => void) {
  axios
    .interceptors
    .response
    .use((response) => {
      if(response.status === 201 || response.status === 204) {
        handler(response);
        const currentUser = firebaseAuth.auth().currentUser;
        if(currentUser) {
          currentUser.getIdToken(true)
            .then(newIdToken => {
            })
        }
      }
      return response;
    }, (error) => {
      if(error && error.status >= 500 || error && error.status === 401) {
        handler(error);
      }
      if(error && error.status === 201) {
        handler(error);
      }
      return Promise.reject(error);
    });
}

export default axios;
