import React, { useState, useContext } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { FBSignOut } from '../firebase';
import {ReactComponent as BSLogoSVG} from '../svgs/bslogo.svg';

import MeCard from './MeCard';

import UserContext from './UserContext';

import * as Types from 'types';

import Button from './Button';

const AdminSidebar: React.FC = () => {
    const history = useHistory();
    const [active, setActive] = useState('Home');
    const [viewed, setViewed] = useState(false);
    const me = useContext(UserContext) as Types.User;
/* Commented by vivek as we will not have any accordian at least for now.
  function handleAccordianClick (name: string, defaultLink: string) {
    setActive(name);
    history.push(defaultLink);
  }
*/

  function signOut() {
    FBSignOut()
      .catch(err => {
        console.log(err);
      });
  }

    return (
        <div className="sidebar flex-column">
            <div className="top flex-column">
                <NavLink className="flex-row" to="/app/admin/support">
                    <div className="logo">
                        <BSLogoSVG />
                    </div>
                </NavLink>
            </div>
            <div className="scroll_view">
                <div className="bottom flex-column">  		  
                    <NavLink activeClassName="active-link" className="black-link" to="/admin/support">Users</NavLink>
		    <NavLink activeClassName="active-link" className="black-link" to="/admin/payments">Payments</NavLink>
		    <NavLink activeClassName="active-link" className="black-link" to="/admin/networkProfile">Network Profiles</NavLink>

                    {/*
		    <NavLink activeClassName="active-link" className="black-link" to="/admin/exceptions">Exceptions</NavLink>
                    <NavLink activeClassName="active-link" className="black-link" to="/admin/results">Results</NavLink>
		    */}
                </div>  
		
		<div className="pl30"><Button onConfirm={signOut} color="light-orange">Sign Out</Button></div>

            </div>
        </div>
    );
}

export default AdminSidebar;
