import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view'
import { FBSignOut, getFirebaseUser, firebaseAuth, fb, f} from '../firebase';
import { useParams } from 'react-router';
import { Switch, Route, withRouter, NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import api from '../api';
import * as Consts from '../consts';

import Checkbox from './Checkbox';
import Select from './Select';
import Button from './Button';
import TabBar from './TabBar';

import * as Types from 'types';

const AdminUserPage: React.FC = () => {
  const history = useHistory();
  const [user, setUser] = useState<Types.User | null>(null);
  const [organization, setOrganization] = useState<Types.Organization | null>(null);
  const [verificationCode, setVerificationCode] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orgLoading, setOrgLoading] = useState(false);
  const [response, setResponse] = useState({ data: { success: false, message: '' } });
  const [responseOn, setResponseOn] = useState(false);

  const uParams = useParams() as { userId: string; };
  const [exceptions, setExceptions] = useState<Types.Exception[]>([]);

  const [tab, setTab] = useState<'userdetails' | 'organization' | 'payments' | 'exceptions' | string>('userdetails');

  useEffect(() => {
    if(uParams.userId) {
      api
        .admin
        .getUserById(uParams.userId)
        .then(user => {
          setUser(user);
          return api
            .admin
            .getOrganizationById(user.organizationId)
        })
        .then(organization => {
          setOrganization(organization);
        })
    }
  }, [uParams.userId])

  useEffect(() => {
      api
        .admin
        .getUserExceptions(uParams.userId)
        .then(exceptions => {
        setExceptions(exceptions);
      })
  }, [])

  function sendVerificationCode () {
    if(user) {
      api
        .admin
        .sendVerificationCode(user.email)
        .then(code => {
          setVerificationCode(code);
        })
    }
  }

  function loginAsUser () {
    if(user && user.googleId) {
      api
        .admin
        .impersonate(user.googleId)
        .then((newToken: string) => {
          return f
            .auth()
            .signInWithCustomToken(newToken)
        })
        .then(() => {
          history.push(`/app/feed/all`);
        })
    }
  }

  function showOrgStatus () {

    if(organization)
    {
	    if(organization.status === Consts.OrgStatus.trial) {
	      return <div className="org-status trial">Trial</div>;
	    } else if (organization.status === Consts.OrgStatus.normal) {
	      return <div className="org-status normal">Normal</div>;
	    } else if (organization.status === Consts.OrgStatus.invalidPayment) {
	      return <div className="org-status invalid-payment">Invalid Payment</div>;
	    } else {
	      return 'Error';
	    }
    }

  }

  function updateUser()
  {

    if(user)
    {
	setLoading(true);
	api
	   .admin
	   .updateUser(user)
	   .then(() => {
		setLoading(false);
		setResponse({ data: { success: true, message: 'User Updated Successfully' } })
		setResponseOn(true);
		setTimeout(() => setResponseOn(false), Consts.responseToastLifetime);
            })
     }
  }

  function updateOrganization()
  {

    if(organization)
    {
	setOrgLoading(false);
	api
	   .admin
	   .updateOrganization(organization)
	   .then(() => {
		setOrgLoading(true);
		setResponse({ data: { success: true, message: 'Organization Updated Successfully' } })
		setResponseOn(true);
		setTimeout(() => setResponseOn(false), Consts.responseToastLifetime);
            })
     }
  }

  function checkNewOrganization (name: any) {
    if(organization)
    {

	if(name.trim() != '')
	{
	  setOrgLoading(true);
        }
	else
	{
	  setOrgLoading(false);
	}	  
	setOrganization({ ...organization, name: name })
   }

  }

  function showResponse () {
    if(response) {
      return <div className={`response-box ${responseOn ? 'on' : '' } ${response.data.success ? 'green' : 'red'} flex-column`}>{response.data.message}</div>;
    }
  }

   function showTab () {
    if(tab === 'userdetails') {
      return (
        <div className="tab-content org flex-column">
	  {user && <div>
          <div className="detail-row flex-row  mb30">
            <div className="detail-column flex-column">
              <div className="gray-text">
                User Name
              </div>
              {user.name}
            </div>
	    <div className="detail-column flex-column text-wrap">
              <div className="gray-text">
                Email
              </div>
              {user.email}
            </div>
            <div className="detail-column flex-column">
              <div className="gray-text">
                Invite Status
              </div>
              {user.inviteStatus === 0 ? 'Joined' : 'Invited'}
            </div>
	  </div>
          <div className="detail-row flex-row  mb30">
            <div className="detail-column flex-column">
              <div className="gray-text">
                User Id
              </div>
              {user.id}
            </div>
            <div className="detail-column flex-column">
              <div className="gray-text">
                Organization Id
              </div>
              {user.organizationId}
            </div>
	    <div className="detail-column flex-column">
              <div className="gray-text">
                Google Id
              </div>
              {user.googleId}
            </div>
          </div>

          <div className="detail-row flex-row  mb30">
            <div className="detail-column flex-column">
              <div className="gray-text">
                Created At
              </div>
              {new Date(user.createdAt * 1000).toLocaleString()}
            </div>
            <div className="detail-column flex-column">
              <div className="gray-text">
                Updated At
              </div>
              {new Date(user.updatedAt * 1000).toLocaleString()}
            </div>
	    <div className="detail-column flex-column">
              <div className="gray-text">
                Last Login
              </div>
              {new Date(user.lastLogin * 1000).toLocaleString()}
            </div>
          </div>

          <div className="detail-row flex-row  mb30">
            <div className="min-detail-column flex-column">
              <div className="gray-text">
                Global Permissions
              </div>
              {user.permissionIds.join(', ')}
            </div>
          </div>

          <div className="detail-row flex-row  mb30">
            <div className="min-detail-column flex-column">
              <div className="gray-text">
                Notification Settings
              </div>
              {user.notificationSettingIds.join(', ')}
            </div>
          </div>
	  
          <div className="rename-row flex-row">
            <div className="rename-column flex-column pr-10">
              <div className="gray-text">
                Rename User
              </div>
              <input placeholder={user.name} onChange={e => setUser({ ...user, name: e.target.value })}/>
            </div>
	    {/*
	    <div className="rename-column flex-column pr-10">
              <div className="gray-text">
                Rename Email
              </div>
              <input placeholder={user.email} onChange={e => setUser({ ...user, email: e.target.value })} />
            </div>
	    */}

	    <div className="rename-column flex-column pr-10">
              <div className="gray-text">
                Delete User
              </div>
		<Checkbox
		  idName={'deleted'}
		  onChange={x => setUser({ ...user, deleted: x })}
		  initialChecked={user.deleted}
		>
		</Checkbox>
            </div>
	    {/*
	     <div className="rename-column flex-column pr-10">
              <div className="gray-text">
                Pause User
              </div>
		<Checkbox
		  idName={'pause'}
		  onChange={x => setUser({ ...user, pause: x })}
		  initialChecked={user.pause}
		>
		</Checkbox>
            </div>
	    */}
	    <Button onConfirm={() => updateUser()} color="light-orange" enabled={!loading}>Update User</Button>	
          </div>

          <div className="detail-row flex-row  mb30">
            <div className="detail-column flex-column">
              <div className="gray-text">
                
              </div>
            </div>
          </div>

	  </div>}
        </div>

      );
    } else if(tab ==='organization') {
      return (
        <div className="tab-content org flex-column">
	  {organization && <div>

          <div className="detail-row flex-row">
            <div className="detail-column flex-column">
              <div className="gray-text">
                Organization Id
              </div>
              {organization.id}
            </div>

            <div className="detail-column flex-column">
              <div className="gray-text">
                Organization Name
              </div>
              {organization.name}
            </div>
            <div className="detail-column flex-column">
              <div className="gray-text">
                Organization Status
              </div>
              {showOrgStatus()}
            </div>
            {organization.status === Consts.OrgStatus.trial &&
              <div className="flex-column detail-column">
                <div className="gray-text">
                  Trial End
                </div>
                {new Date((organization.trialEnd * 1000)).toLocaleString()}
              </div>
            }
	 </div>


          <div className="detail-row flex-row">
            <div className="detail-column flex-column">
              <div className="gray-text">
                Created At
              </div>
              {new Date(organization.createdAt * 1000).toLocaleString()}
            </div>
            <div className="detail-column flex-column">
              <div className="gray-text">
                Updated At
              </div>
              {new Date(organization.updatedAt * 1000).toLocaleString()}
            </div>
          </div>

	  {/*
          <div className="detail-row flex-row">
            <div className="detail-column flex-column">
              <div className="gray-text">
               Total Post Count
              </div>
	      {organization.postCount}
            </div>
	  </div>
	  */}

          <div className="rename-row flex-row">
            <div className="rename-column flex-column">
              <div className="gray-text">
                Rename Organization
              </div>
              <input placeholder={organization.name} onChange={(e) => checkNewOrganization(e.target.value)} maxLength={50}/>
            </div>
	    <Button onConfirm={() => updateOrganization()} color="light-orange" enabled={orgLoading}>Update Organization</Button>
          </div>

	  </div>}
        </div>
      );

    } else if(tab === 'payments') {
      return (
        <div className="tab-content org flex-column">
	  {organization && <div>

          <div className="detail-row flex-row">
            <div className="detail-column flex-column">
              <div className="gray-text">
                Plan
              </div>
              {organization.planId}
            </div>

	    <div className="detail-column flex-column">
              <div className="gray-text">
                Stripe ID
              </div>
	      <NavLink to={{pathname: `https://dashboard.stripe.com/customers/${organization.stripeId}`}} target="_blank">{organization.stripeId}</NavLink>
            </div>
	  </div>

          <div className="detail-row flex-row">
            <div className="detail-column flex-column">
              <div className="gray-text">
                Last Payment ID
              </div>
              {organization.lastPaymentId || 'N/A'}
            </div>
            <div className="detail-column flex-column">
              <div className="gray-text">
               Last Payment Date
              </div>
              {organization.lastPaymentDate ? new Date(organization.lastPaymentDate * 1000).toLocaleString() : 'N/A'}
            </div>
	 </div>

          <div className="detail-row flex-row">
            <div className="detail-column flex-column">
              <div className="gray-text">
               {organization.stripeId && <Button color="light-orange" enabled={false}>Cancel Subscription</Button>}
              </div>
            </div>
	 </div>

	  </div>}
        </div>
        );
    } else if(tab === 'exceptions') {
      return (
        <div className="tab-content org flex-column">
	  {exceptions && <div>

      {exceptions.map(exception => {
        return (<div className="m10 exceptions-row text-wrap">
          <strong>{ exception.exception }</strong>
          <br/>
          Date : { new Date(exception.createdAt * 1000).toLocaleString() }
          <br/>
          Organization Id: { exception.organizationId }
          <br/>
          Endpoint: { exception.url }
          <br/>
          Method: { exception.reqMethod }
          <br/>
          Params: <ReactJson collapsed={true} collapseStringsAfterLength={50} theme={'solarized'} src={exception.reqParams} />
          <br/>
          Body: <ReactJson collapsed={true} collapseStringsAfterLength={50} theme={'solarized'} src={exception.reqBody} />
          <br/>
          Organization: { exception.organization ? <ReactJson collapsed={true} collapseStringsAfterLength={50} theme={'solarized'} src={exception.organization} /> : 'None' }
          <br/>
          User: { exception.user ? <ReactJson collapsed={true} collapseStringsAfterLength={50} theme={'solarized'} src={exception.user} /> : 'None' }
          <br/>
          { exception.stack && exception.stack.split(' at ').map((x, i) => <div>{ i !== 0 && '\u00A0\u00A0\u00A0\u00A0at' } { x }</div>) }
        </div>);
      })}

	  </div>}

	{exceptions.length <= 0 && 
		<div className="mt10 text-align-center">No Exceptions Found</div>}
        </div>
        );
    }
  }

  return (
    <div className="settings page admin-page flex-column">
	<div className="header flex-row">
          <h1>User Details</h1>
        </div>
        <div className="gray-text">User Detailed Information.</div>

      <div className="btn-pullright">
	<Button color="orange" onConfirm={loginAsUser}>Login as User</Button>
      </div>
	<div className="content flex-column mt20">
          <div className="tabs flex-row">
              <div 
                className={`tab first flex-row ${tab === 'userdetails' ? 'active' : ''}`}
                onClick={() => setTab('userdetails')}
              >User Info</div>
              <div 
                className={`tab middle flex-row ${tab === 'organization' ? 'active' : ''}`}
                onClick={() => setTab('organization')}
              >Organization</div>
              <div 
                className={`tab last flex-row ${tab === 'payments' ? 'active' : ''}`}
                onClick={() => setTab('payments')}
              >Payments</div>
              <div 
                className={`tab first flex-row ${tab === 'exceptions' ? 'active' : ''}`}
                onClick={() => setTab('exceptions')}
              >Exceptions</div>
          </div>
          {showTab()}
	</div>


      {/* user && <div className="user">
        
        <Button onConfirm={sendVerificationCode}>Send Verification Code</Button>
        Verification Code: {verificationCode}
        <h1>User: {user.name}</h1>
        <div>Id: {user.id}</div>
        <div>Google Id: {user.googleId}</div>
        <div>Organization Id: {user.organizationId}</div>
        <div>Created At: {new Date(user.createdAt * 1000).toLocaleString()}</div>
        <div>Updated At: {new Date(user.updatedAt * 1000).toLocaleString()}</div>
        <div>Last Login: {new Date(user.lastLogin * 1000).toLocaleString()}</div>
        <div>Global Permissions: {user.permissionIds.join(', ')}</div>
        <div>Notification Settings: {user.notificationSettingIds.join(', ')}</div>
        <div>Invite Status: {user.inviteStatus === 0 ? 'Joined' : 'Invited'}</div>
        <input placeholder={user.name} onChange={e => setUser({ ...user, name: e.target.value })}/>
        <input placeholder={user.authyId || 'Authy ID'} onChange={e => setUser({ ...user, authyId: e.target.value })} />
        <div className="flex-row">
          <div>Authy ID: {user.authyId}</div>
          <Button onConfirm={() => setUser({ ...user, authyId: '' })} >Remove</Button>
        </div>
        <input placeholder={user.email} onChange={e => setUser({ ...user, email: e.target.value })} />
        <Checkbox
          idName={'deleted'}
          onChange={x => setUser({ ...user, deleted: x })}
          initialChecked={user.deleted}
        >
          Deleted
        </Checkbox>
        <Checkbox
          idName={'pause'}
          onChange={x => setUser({ ...user, pause: x })}
          initialChecked={user.pause}
        >
          Paused
        </Checkbox>
        <Button onConfirm={() => api.admin.updateUser(user)}>Update User</Button>
      </div> */}
      {
      /*
      organization && <div className="organization flex-column">
        <h1>Organization: {organization.name}</h1>
        <div>Id: {organization.id}</div>
        <div>Created At: {new Date(organization.createdAt * 1000).toLocaleString()}</div>
        <div>Updated At: {new Date(organization.updatedAt * 1000).toLocaleString()}</div>
        <div>Admin Id: <NavLink to={`/admin/user/${organization.adminId}`}>{organization.adminId}</NavLink></div>
        <div>Status: {organization.status}</div>
        <Select 
          placeholder={Consts.OrgStatus[organization.status]}
          onChange={x => setOrganization({ ...organization, status: x })}
          options={[
            {
              labelHeading: 'Trial',
              value: 0
            },
            {
              labelHeading: 'Normal',
              value: 1
            },
            {
              labelHeading: 'Invalid Payment',
              value: 2
            },
            {
              labelHeading: 'Over Limit',
              value: 3
            },
            {
              labelHeading: 'Canceled',
              value: 4
            },
            {
              labelHeading: 'Invalid Email',
              value: 5
            },
            {
              labelHeading: 'Pending Approval',
              value: 6
            },
            {
              labelHeading: 'Dormant',
              value: 7
            },
            {
              labelHeading: 'Expired',
              value: 8
            },
          ]}
        />

        <div>Post Count: {organization.postCount}</div>
        <div>Plan: {organization.planId}</div>
        <div>Stripe ID: {organization.stripeId}</div>
        <div>Last Payment Date: {organization.lastPaymentDate ? new Date(organization.lastPaymentDate * 1000).toLocaleString() : 'N/A'}</div>
        <div>Last Payment ID: {organization.lastPaymentId || 'N/A'}</div>
        <div className="flex-row">
          <div>Stripe ID: {organization.stripeId}</div>
          <Button onConfirm={() => setOrganization({ ...organization, stripeId: '' })} >Remove</Button>
        </div>
        <input placeholder={organization.name} onChange={e => setOrganization({ ...organization, name: e.target.value })}/>
        <Checkbox
          idName={'orgdeleted'}
          onChange={x => setOrganization({ ...organization, deleted: x })}
          initialChecked={organization.deleted}
        >
          Deleted
        </Checkbox>
        <Button onConfirm={() => api.admin.updateOrganization(organization)}>Update Organization</Button>
      </div>
      */
      }

      {/* exceptions.map(exception => {
        return (<div className="exception flex-column">
          <strong>{ exception.exception }</strong>
          <br/>
          Time: { new Date(exception.createdAt * 1000).toLocaleString() }
          <br/>
          Organization Id: { exception.organizationId }
          <br/>
          Endpoint: { exception.url }
          <br/>
          Method: { exception.reqMethod }
          <br/>
          Params: <ReactJson collapsed={true} collapseStringsAfterLength={50} theme={'solarized'} src={exception.reqParams} />
          <br/>
          Body: <ReactJson collapsed={true} collapseStringsAfterLength={50} theme={'solarized'} src={exception.reqBody} />
          <br/>
          Organization: { exception.organization ? <ReactJson collapsed={true} collapseStringsAfterLength={50} theme={'solarized'} src={exception.organization} /> : 'None' }
          <br/>
          User: { exception.user ? <ReactJson collapsed={true} collapseStringsAfterLength={50} theme={'solarized'} src={exception.user} /> : 'None' }
          <br/>
          { exception.stack && exception.stack.split(' at ').map((x, i) => <div>{ i !== 0 && '\u00A0\u00A0\u00A0\u00A0at' } { x }</div>) }
        </div>);
      }) */}

      {showResponse()}

    </div>

  );
}

export default AdminUserPage;
