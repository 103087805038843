import * as authy from './authy';
import * as networks from './networks';
import * as users from './users';
import * as posts from './posts';
import * as profiles from './profiles';
import * as work from './work';
import * as organizations from './organizations';
import * as teams from './teams';
import * as roles from './roles';
import * as permissions from './permissions';
import * as logs from './logs';
import * as payments from './payments';
import * as postAssignments from './postAssignments';
import * as notifications from './notifications';
import * as exceptions from './exceptions';
import * as admin from './admin';

export default {
  authy: authy,
  networks: networks,
  users: users,
  posts: posts,
  profiles: profiles,
  work: work,
  organizations: organizations,
  teams: teams,
  roles: roles,
  permissions: permissions,
  logs: logs,
  payments: payments,
  postAssignments: postAssignments,
  exceptions: exceptions,
  notifications: notifications,
  admin: admin
}
