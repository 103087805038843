import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import api from '../api';

import FeedPost from './FeedPost';
import Back from './Back';
import Spinner from './Spinner';

import * as Consts from '../consts';

import * as Types from 'types';

import { Col, Row } from 'react-bootstrap';

import {ReactComponent as ConnectedSVG} from '../svgs/connected.svg';
import {ReactComponent as DisconnectedSVG} from '../svgs/disconnected.svg';

const PostPage: React.FC = () => {
  const [post, setPost] = useState<Types.JoinedPost>({
    id: '',
    description: '',
    url: '',
    images: [],
    createdAt: 0,
    deleted: false,
    updatedAt: 0,
    organizationId: '',
    authorId: '',
    reposterIds: [],
    reposters: [],
    scheduled: 0,
    author: {
      id: '',
      name: '',
      email: '',
      deleted: false,
      notificationSettingIds: [],
      createdAt: 0,
      lastLogin: 0,
      updatedAt: 0,
      pause: false,
      organizationId: '',
      permissionIds: [],
      inviteStatus: 0,
    },
  });
  const [postLoaded, setPostLoaded] = useState(false);

  const uParams = useParams() as { postId: string; };

  useEffect(() => {
    if(uParams.postId) {
      api
        .posts
        .getPostById(uParams.postId)
        .then((post: Types.JoinedPost) => {
          setPost(post);
          setPostLoaded(true);
        })
        .catch((err: any) => {
          console.log(err);
        })
    }
  }, [uParams.postId])

  if(postLoaded) {
    return (
      <div className="feed_page_section post_detail_page">
        <Back />
        
        <div className="feed_tabbar_title">

          <Row className="app_feed_row">
          <Col className="app_col app_feed_title" lg={12} md={12} sm={12}>
          <h1>Post Details</h1>
          </Col>
            </Row>
            
            <Row className="feed_tabbar_desc app_feed_row">
            <Col className="app_col app_feed_title_desc" lg={12} md={12} sm={12}>
            Here you can see the status of a post.
              </Col>
            </Row>
            
            </div>
        <FeedPost post={post} />
      </div>
    );
  } else {
    return(<div className="page flex-column">
      <Spinner/>
    </div>);
  }
}

export default PostPage;
