import React, { useEffect, useState, useContext } from 'react';

import Button from './Button';

import * as Consts from '../consts';
import OrganizationContext from './OrganizationContext';

import * as Types from 'types';

interface Props {

}

const NewPostButton: React.FC<Props> = props => {
  const organization = useContext(OrganizationContext) as Types.Organization;
  const [loading, setLoading] = useState(false);


  useEffect(() => {
	if(organization)
	{
	    if(organization.status === Consts.OrgStatus.trial) {
		  if(getDaysLeftInTrial () <= 0)
		    setLoading(true);
	    } else if (organization.status === Consts.OrgStatus.invalidPayment) {
	      setLoading(true);
	    } 
	}
  }, [])

  function getDaysLeftInTrial () {
    return Math.round((organization.trialEnd * 1000 - new Date().getTime())/(1000*60*60*24));
  }

  return (
    <div className="newpost_button">
      {<Button enabled={!loading} to="/app/posts/new" color="orange">New Post</Button>}
    </div>
  );
}

export default NewPostButton;
