import React, { useEffect, useState, useContext } from 'react';
import api from '../api';
import { useHistory } from 'react-router-dom';

import Back from './Back';
import Button from './Button';
import Toggle from './Toggle';

import UserContext from './UserContext';
import OrganizationContext from './OrganizationContext';

import * as Types from 'types';

interface Props {
}

const PlansPage: React.FC<Props> = props => {
  const history = useHistory();
  const [plans, setPlans] = useState<Types.Plan[]>([]);
  const [newOrganization, setNewOrganization] = useState<Types.Organization | null>(null);
  const [loading, setLoading] = useState(false);

  const user = useContext(UserContext) as Types.User;
  const organization = useContext(OrganizationContext) as Types.Organization;

  useEffect(() => {
    api
      .payments
      .getPlans()
      .then((plans: Types.Plan[]) => {
        setPlans(plans)
      })
      .catch(err => console.log(err))
  }, [])

  function update () {
    if(newOrganization && newOrganization.planId !== organization.planId) {
      setLoading(true);
      api
        .payments
        .updateCustomer({
        })
        .then(x => {
          console.log(x);
          return api
            .organizations
            .updateOrganization(newOrganization)
        })
        .then(() => {
          return history.goBack();
        })
      
    } else {
      history.goBack();
    }
  }

  function renderPrice () {
    if(newOrganization && newOrganization.planId === 'teamMonthly') {
      return '$99 / mo.';
    } else if(!newOrganization && organization.planId === 'teamMonthly') {
      return '$99 / mo.';
    } else {
      return '$891 / yr.';
    }
  }
  
  return (
    <div className="plans page flex-column">
      <Back />
      <h1>Change Plan</h1>
      <h3>Payment Frequency</h3>
      <Toggle 
        idName="planFrequency" 
        initialOn={organization.planId === 'teamYearly'}
        onChange={x => setNewOrganization({ ...organization, planId: (x ? 'teamYearly' : 'teamMonthly')})}
      />
      <div className="plan flex-column">
        <div className="top flex-row">
          <div className="left flex-column">
            Team
            <div className="gray-text">
              Best for Teams
            </div>
          </div>
        </div>
        <div className="item flex-row">
          <div className="gray-text flex-row">
            Social Profiles
          </div>
          Unlimited
        </div>
        <div className="item flex-row">
          <div className="gray-text flex-row">
            Users
          </div>
          Unlimited
        </div>
        <div className="item flex-row">
          <div className="gray-text flex-row">
            Posts
          </div>
          Unlimited
        </div>
        <div className="price orange-text flex-row">
          {renderPrice()}
        </div>
        <Button color="orange" onConfirm={update} enabled={!loading} >Change Plan</Button>
      </div>
    </div>
  );
}

export default PlansPage;
