import React, { useState, useEffect }  from 'react';

import * as Types from 'types';

import {ReactComponent as TwitterDotsSVG} from '../svgs/twitter-dots.svg';
import {ReactComponent as TwitterLikeSVG} from '../svgs/twitter-like.svg';
import {ReactComponent as TwitterCommentSVG} from '../svgs/twitter-comment.svg';
import {ReactComponent as TwitterShareSVG} from '../svgs/twitter-share.svg';
import {ReactComponent as TwitterRetweetSVG} from '../svgs/twitter-retweet.svg';
import {ReactComponent as TwitterLinkSVG} from '../svgs/twitter-link.svg';

interface Props {
  network: Types.Network;
  work: Partial<Types.Work> | null;
  urlMetadata: Types.urlMetadata | {};
  previewIsLarge: boolean;
}
const TwitterPreview: React.FC<Props> = props => {
  const [largeText, setLargeText] = useState(false);

  useEffect(() => {
    if(props.work && props.work.description && props.work.description.length < 85 && !props.work.url) {
      setLargeText(true);
    } else {
      setLargeText(false);
    }
  }, [props.work, props.urlMetadata])

  function buildText(work: Types.Work) {
    let description = '';
    if(work.description) {
      description = work.description;
    }

    function getContent () {
      const content = [];
      if(description) {
        content.push(description);
      }
      if(work.url) {
        content.push(' - ');
        content.push(work.url);
      }
      return content;
    }

    let joined = getContent().join('');
    let length = joined.length;
    let trimmed = '';
    let difference = length - 280;

    if(length > 280) {
      description = description.substring(0, description.length - (difference + 3));
      description = [description, '...'].join('');
      trimmed = getContent().join('');
    } else {
      trimmed = joined;
    }

    return trimmed;
  }

  return (
    <div className="twitter preview app_feed_row">
      <img className="avatar" src="/twu.png" />
      <div className="twitter-content flex-column">
        <TwitterDotsSVG className="twitter-dots" />
        <div className="twitter-top flex-row">
          <div className="twitter-header flex-row">
            <div className="twitter-heading flex-row">
              Your Profile
            </div>
            <div className="twitter-subtext flex-row">
              @YourProfile
               <div className="twitter-dot">·</div> 5s
            </div>
          </div>
        </div>
        <div className={`twitter-text ${largeText ? 'large' : ''}`}>
          {props.work && buildText(props.work as Types.Work)}
        </div>
          {Object.keys(props.urlMetadata).length > 0 && props.work && props.work.images && props.work.images.length === 0 &&
            <div className={`link-preview ${props.previewIsLarge ? 'large' : 'small'} flex-column mt20`}>
              <div className="image-wrapper flex-column">
                {((props.urlMetadata as Types.urlMetadata).image || (props.urlMetadata as Types.urlMetadata).icon) &&
                  <img 
                    className="link-preview-image flex-row" 
                    alt={ `${(props.urlMetadata as Types.urlMetadata).image || (props.urlMetadata as Types.urlMetadata).icon}` }
                    src={ `${(props.urlMetadata as Types.urlMetadata).image || (props.urlMetadata as Types.urlMetadata).icon}` }
                  />
                }
              </div>
              <div className="link-info flex-column">
                <div className="link-title flex-column">
                  {(props.urlMetadata as Types.urlMetadata).title}
                </div>
                <div className="link-description flex-column">
                  {(props.urlMetadata as Types.urlMetadata).description}
                </div>
                <div className="link-url flex-row">
                  <TwitterLinkSVG />{(props.urlMetadata as Types.urlMetadata).url.split('//')[1]}
                </div>
              </div>
            </div>
          }
          {props.work && props.work.images && props.work.images.length > 0 &&
            <div className="uploaded-images flex-row">
              {props.work.images.map(imageUrl => { 
                return <div 
                  className="uploaded-image flex-row" 
                  style={{'backgroundImage': `url('${imageUrl}')`}}
                />;
              })}
            </div>
          }
        <div className="twitter-controls flex-row">
          <div className="twitter-control flex-row">
            <TwitterCommentSVG />
          </div>
          <div className="twitter-control flex-row">
            <TwitterRetweetSVG />
          </div>
          <div className="twitter-control flex-row">
            <TwitterLikeSVG />
          </div>
          <div className="twitter-control flex-row">
            <TwitterShareSVG />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TwitterPreview;
