import React, { useEffect, useState, useContext } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import api from '../api';

import * as Types from 'types';
import QueuePost from './QueuePost';
import PostAssignmentScroller from './PostAssignmentScroller';
import TabBar from './TabBar';
import Button from './Button';

import * as Consts from '../consts';

import UserContext from './UserContext';
import TeamsContext from './TeamsContext';
import NewPostButton from './NewPostButton';


interface props {
  queueItems: Types.PostAssignment[];
}

const Queue: React.FC<props> = props => {
  const uParams = useParams() as { tab: string; };
  const [tab, setTab] = useState<'shared' | 'approval-requested' | string>(uParams.tab || 'shared');

  const me = useContext(UserContext) as Types.User;
  const teams = useContext(TeamsContext) as Types.JoinedTeam[];

  function showTab () {
    if(tab === 'approval-requested') {
      if(props.queueItems.length > 0)
      {
	return (props.queueItems.map(queueItem => {
				if(queueItem.status === Consts.PostAssignmentStatus.approvalRequested) {
					return <QueuePost 
						post={queueItem} 
						key={queueItem.id} 
					/>
				}
	}));
      }
      else
      {
	return <div className='no_posts_desc'>There are no approval posts yet.</div>;
      }



    } else if(tab === 'shared') {

      if(props.queueItems.length > 0)
      {
	return (props.queueItems.map(queueItem => {
				if(queueItem.status === Consts.PostAssignmentStatus.shared) {
					return <QueuePost 
						post={queueItem} 
						key={queueItem.id} 
					/>
				}
      }));
      }
      else
      {
	return <div className='no_posts_desc'>There are no shared posts yet.</div>;
      }


    }
  }

function checkTeam(){
 if(teams.length > 0)
 {
 	if(teams[0].users[0])
	{
		console.log(teams[0]);
		if(teams.find(team => team.users.find(teamUser => teamUser.id === me.id && (teamUser.teamRole as Types.Role).permissionIds.includes('approvePosts'))))
		return true;
	}
	else
		return false;
 }
 else
   return false;
}


  return (
    <div className="feed_page_section queue_page drafts_page">
      <TabBar
        title="Queue"
        explainer="These are posts that require your approval."
        button={
          <NewPostButton />
        }
        tooltip={
          <div>
            The <span className="orange-text">For Approval</span> tab contains posts that have been submitted by users that do not posess the <span className="orange-text">Submit Posts</span> permission, either at the <span className="orange-text">Organization</span> level or for a specific <span className="orange-text">Team</span>. Those posts must be reviewed by someone with the <span className="orange-text">Approve Posts</span> permission before being posted.
            <br/>
            <br/>
            The <span className="orange-text">Shared</span> tab contains posts that have been shared to you by another user. That user wants you to post the item to your personal <span className="orange-text">Social Profiles</span>. It must be reviewed by you first.
          </div>
        }
      > 
      </TabBar>
      <div className="quepage_content">
      <ul class="nav nav-tabs" role="tablist">
          <li className={`tab first ${tab === 'shared' ? 'active' : ''}`}><a onClick={() => setTab('shared')}>Shared{props.queueItems.filter(queueItem => queueItem.status === Consts.PostAssignmentStatus.shared).length > 0 && <span className="tab-number">{props.queueItems.filter(queueItem => queueItem.status === Consts.PostAssignmentStatus.shared).length}</span>}
          </a></li>
          {(me.permissionIds.includes('approvePosts') || checkTeam()) &&
            <li className={`tab first ${tab === 'approval-requested' ? 'active' : ''}`}><a onClick={() => setTab('approval-requested')}>For Approval{props.queueItems.filter(queueItem => queueItem.status === Consts.PostAssignmentStatus.approvalRequested).length > 0 && <span className="tab-number">{props.queueItems.filter(queueItem => queueItem.status === Consts.PostAssignmentStatus.approvalRequested).length}</span>}
            </a></li>
          }
        </ul>
      </div>
      {showTab()}
    </div>
  );
}

export default Queue;
