import React, { useState, useEffect, useContext } from 'react';
import DOMPurify from 'dompurify';
import { useHistory } from 'react-router-dom';
import api from '../api';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import Button from './Button';
import UserImage from './UserImage';
import Modal from './Modal';
import DeleteButton from './DeleteButton';

import TeamsContext from './TeamsContext';

import * as Types from 'types';
import * as Consts from '../consts';

import { Row, Col } from 'react-bootstrap';

interface PostProps {
  post: Types.JoinedPostAssignment;
}

TimeAgo.locale(en)
const timeAgo = new TimeAgo('en-US')

const QueuePost: React.FC<PostProps> = props => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [profiles, setProfiles] = useState<Types.Profile[]>([]);
  const [networks, setNetworks] = useState<Types.Network[]>([]);
  const [profilesLoaded, setProfilesLoaded] = useState(false);

  const teams = useContext(TeamsContext);

  useEffect(() => {
    api
      .networks
      .getNetworks()
      .then(networks => {
        setNetworks(networks)
      })
  }, []);

  useEffect(() => {
    api
      .profiles
      .getProfiles()
      .then(profiles => {
        setProfiles(profiles)
        setProfilesLoaded(true);
      })
  }, [])

  function createMarkup(icon: string) {
    return { __html: DOMPurify.sanitize(icon) };
  }

  function onApprove() {
    api.postAssignments.approveForAll({
      postData: props.post,
      shareToUsers: [],
    })
  }

  function onDelete() {
    return api
      .postAssignments
      .denyApproval(props.post.id as string)
  }

  function getTeamName () {
    if(teams) {
      const team = teams.find(team => team.id === props.post.teamId)
      if(team) {
        return '  •  '+team.name;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  function postCard () {
    if(props.post.author) {
      return (
        <>
          <div className="post_user_header">
            <UserImage user={props.post.author} size="large" />
            <div className="post_user_title">
              <h3>{props.post.author.name} <span>{getTeamName()}</span></h3>
              <p className="gray-text">{timeAgo.format(props.post.scheduled * 1000)}</p>
            </div>
            {props.post.status === Consts.PostAssignmentStatus.approvalRequested &&
              <div className="networks_icons">
                {networks
                  .filter(network => {
                    if(props.post.work) {
                      return (props
                        .post
                        .work as Types.JoinedWork[])
                        .find(workItem => {
                          return workItem.profile.networkId === network.slug
                        })
                    }
                  })
                  .map(ne => {
                    return <div className="network-icon" dangerouslySetInnerHTML={createMarkup(ne.icon)}/>
                  })
                }
              </div>
            }
          </div>
          <div className="feed_post_content">
            <p>{props.post.description}</p>
            <a className="blue-link" target="_blank" href={props.post.url}>{props.post.url}</a>
          </div>
          
          <div className="feed_post_images">
		    <Row className="feed_up_images">
        {props.post.images.length > 0 ? <Col className='feed_image_col'><div className="feed_up_images"><img src={props.post.images[0]} /></div></Col> : null}
          </Row>
          </div>

          <div className="feed_post_footer draft_post_footer">
            <Row className='app_feed_row justify-content-md-end'>
            <DeleteButton
              onConfirm={onDelete}
              buttonText = { props.post.status === Consts.PostAssignmentStatus.approvalRequested ? 'Deny' : 'Decline' }
              message={`Are you sure that you want to ${props.post.status === Consts.PostAssignmentStatus.approvalRequested ? 'deny' : 'decline'} this post?`}
            />
            {/* props.post.status === Consts.PostAssignmentStatus.shared &&
              <Button
                color="gray"
                to={`/app/posts/new?editApprovalPostFromPostAssignmentId=${props.post.id}`}
              >Edit</Button>
            */}
            <div className="edit_button col_wh draft_post_edit"><Button
              color="green"
              onConfirm={() => setShowModal(true)}
            >{ props.post.status === Consts.PostAssignmentStatus.approvalRequested ? 'Approve' : 'Post' }</Button></div>
          </Row>
          </div>

          {profilesLoaded &&
            <Modal 
              show={showModal} 
              closeModal={() => setShowModal(false)}
              title="Confirm Post"
              onConfirm={(profiles.length > 0 || props.post.status === Consts.PostAssignmentStatus.approvalRequested) ? onApprove : () => history.push('/app/networks')}
            >
              {props.post.status === Consts.PostAssignmentStatus.approvalRequested 
                ? `By clicking confirm, you will be approving this post to be posted to the author's specified profiles.`
                : (profiles.length > 0 
                  ? `By clicking confirm, you will be posting this to all ${profiles.length} of your connected personal profiles.`
                  : 'You have no Social Profiles connected. Please proceed to the My Networks page by clicking Confirm, then connect a social profile.'
                )
              }
            </Modal>
          }
        </>
      );
    }
  }

  function goToPost () {
    history.push(`post/${props.post.id}`);
  }

  return (
    <Col lg={9} md={12} sm={12}><div className="feed_post_list">
    {postCard()}
  </div></Col>
  );
}

export default QueuePost;
