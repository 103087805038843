import React, { useState } from 'react';

import Modal from './Modal';
import Button from './Button';

interface Props {
  message: string;
  onConfirm: () => Promise<any>;
  buttonText: string;
  enabled?: boolean;
}

const DeleteButton: React.FC<Props> = props => {
  const [showModal, setShowModal] = useState(false);

  function handleConfirm () {
    props.onConfirm()
      .then(() => {
        setShowModal(false);
      })
      .catch(() => {
        setShowModal(false);
      })
  }

  return (
    <div className="delete-button col_wh draft_post_delete">
      <Button onConfirm={() => setShowModal(true)} color="red" enabled={props.enabled} >
        {props.buttonText}
      </Button>
      <Modal 
        show={showModal} 
        closeModal={() => setShowModal(false)}
        title="Confirm Delete"
        onConfirm={props.onConfirm}
      >
        {props.message}
      </Modal>
    </div>
  );
}

export default DeleteButton;
