import React, {useState} from 'react';
import api from '../api';
import Modal from './Modal';



function uploadImage (formData: FormData) {
  return api
    .posts
    .uploadImage(formData)
    .then(imageResponse => {
      return imageResponse;
    })
    .catch(err => {
      console.log(err);
    })
}

interface Props {
  children: React.ReactNode;
  onUpload: (imageUrl: string) => void;
  onUploading?: () => void;
  disabled?: boolean;
}

const ImageUpload: React.FC<Props> = props => {

const [showModal, setShowModal] = useState(false);


  function handleImageForm (e: React.ChangeEvent<HTMLInputElement>) {
    if(props.onUploading) {
      props.onUploading();
    }
    //@ts-ignore
    const image = e.target.files[0] as File;

    if (!image.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setShowModal(true)
    }
    else
    {     
      const formData = new FormData();
      formData.append('image', image, image.name)

      uploadImage(formData)
        .then(imageUrl => {
          if(imageUrl) {
            props.onUpload(imageUrl);
          }
        })
        .catch(err => {
          console.log(err);
        })         
    }
  }

  async function handleDrop(e: any) {
    if(!props.disabled) {
      e.stopPropagation();
      e.preventDefault();

      await [...e.dataTransfer.files].forEach(async image => {
        const formData = new FormData();
        formData.append('image', image, image.name)

        await uploadImage(formData)
          .then(imageUrl => {
            if(imageUrl) {
              props.onUpload(imageUrl);
            }
          })
          .catch(err => {
            console.log(err);
          })
        })
    }
  }

  function handleDragOver(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <div className="image_control">
      <label htmlFor="single" onDrop={handleDrop} onDragOver={handleDragOver}>
        {props.children}
      </label>
      <input 
        disabled={props.disabled}
        type='file' 
        id='single' 
        name="file" 
        className="invisible" 
        onChange={handleImageForm} 
	accept="image/png, image/jpeg, image/gif"
      />

	<Modal 
            show={showModal} 
            closeModal={() => setShowModal(false)}
            title="Profile Image Upload" 
	    btnText="Ok" 
            onConfirm={() => setShowModal(false)}
          >
            Please upload valid image.
          </Modal>

    </div>




  );
}

export default ImageUpload;
