import { 
  DocumentData,
  QuerySnapshot,
  WriteResult
} from '@google-cloud/firestore';
import { db, getFBIdToken } from './firebase';

export function identity<T>(arg: any): T {
    return arg;
}

export function authorizedHeader () {
  return getFBIdToken()
    .then(token => {
      return { 
        headers: { 
          'Authorization': `Bearer ${token}`
        }, 
        withCredentials: true 
      };
    })
}

export function isLive() {
  return window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1";
}

export function validateEmail (email: string) {
  //const regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/igm

  const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if(email.match(regEx)) {
    return true;
  } else {
    return false;
  }
}

export async function getPage (
    collection: string,
    wheres: [ string, string, any][],
    amount: number,
    startAfter: string,
  ) {

  let lastSnap: any;
  if(startAfter) {
    lastSnap = await db
      .doc(`/${collection}/${startAfter}`)
      .get()
  }

  let query = db.collection(collection) as any;

  if(!['logs', 'appKeys', 'networks', 'permissions', 'plans', 'Session', 'results'].includes(collection)) {
    query = query.where('deleted', '==', false);
  }

  wheres.forEach(where => query = query.where(where[0], where[1], where[2]));

  query = query.orderBy('createdAt', 'desc');
  query = query.limit(amount);
  if(startAfter) {
    query = query.startAfter(lastSnap);
  }

  return query
    .get()
    .then((querySnapshot: QuerySnapshot) => {
      const posts = querySnapshot
        .docs
        .map((doc: DocumentData) => doc.data());

      return posts;
    })
}

