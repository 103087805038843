import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import * as Types from 'types';

import Network from './Network';
import Profile from './Profile';
import Modal from './Modal';
import api from '../api';
import Checkbox from './Checkbox';
import TabBar from './TabBar';
import Spinner from './Spinner';
import { Col, Row } from "react-bootstrap";

const NetworksPage: React.FC = match => {
  const [networks, setNetworks] = useState<Types.Network[]>([]);
  const [profiles, setProfiles] = useState<Types.Profile[]>([]);
  const [profilesLoaded, setProfilesLoaded] = useState(false);

  useEffect(() => {
    api
      .networks
      .getNetworks()
      .then(networks => {
        setNetworks(networks)
      })

    api
      .profiles
      .getProfiles()
      .then(profiles => {
        setProfiles(profiles)
        setProfilesLoaded(true);
      })
  }, [])

  function networksList () {
    if(networks && profiles) {
      return networks.map((network: Types.Network) => {
        return <Network 
          network={network} 
          profiles={profiles}  
          key={network.slug} 
          onLoading={() => setProfilesLoaded(false)}
          load={() => {
            api
              .profiles
              .getProfiles()
              .then(profiles => {
                setProfiles(profiles)
                setProfilesLoaded(true);
              })
          }}
        />;
      });
    } else {
      return <h2>Loading...</h2>
    }
  }

  if(networks.length > 0 && profilesLoaded) {
    return (
        <Row className='app_feed_row'>
          <Col className='app_col' lg={12} md={12} sm={12}>

         
      <div className="Sidebar_networks feed_page_section">
        <TabBar
          title="Networks"
          explainer="These are the social profiles connected to your individual account."
        > 
        </TabBar>

        <div className='network_list_sec'>
        {networksList()}
        </div>
        
      </div>
      </Col>
        </Row>
    );
  } else {
    return (<div className="network_page page">
      <Spinner/>
    </div>);
  }
}

export default NetworksPage;