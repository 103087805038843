import React, { useEffect, useState } from 'react';

import * as Types from 'types';

import {ReactComponent as BlankUserSVG} from '../svgs/blank-user.svg';

interface Props {
  user: Types.User;
  size: 'huge' | 'tiny' | 'large' | 'normal';
}

const UserImage: React.FC<Props> = props => {
  const [imageError, setImageError] = useState(false);

  function showUserImage (user: Types.User) {
    if(props.user.image && !imageError) {
      return <img 
        className={`user-image ${props.size}`}
        alt="user" 
        src={props.user.image}  
        onError={() => setImageError(true)}
      /> 
    } else {
      return <BlankUserSVG 
        className={`user-image ${props.size}`}
      /> 
    }
  }

  return (
    <>
      {showUserImage(props.user)}
    </>
  );
}

export default UserImage;
