import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import api from '../api';
import Stripe from './Stripe';

import * as Types from 'types';
import * as Consts from '../consts';

import CreateRole from './CreateRole';
import Button from './Button';
import Toggle from './Toggle';
import Radio from './Radio';
import Spinner from './Spinner';
import LogList from './LogList';
import DeleteIcon from './DeleteIcon';

import UserContext from './UserContext';
import OrganizationContext from './OrganizationContext';

import {ReactComponent as EditSVG} from '../svgs/edit.svg';
import {ReactComponent as AddSVG} from '../svgs/add.svg';
import {ReactComponent as VisaSVG} from '../svgs/visa.svg';
import {ReactComponent as MastercardSVG} from '../svgs/mastercard.svg';
import {ReactComponent as BlankCardSVG} from '../svgs/blank-card.svg';
import {ReactComponent as PlanProfilesSVG} from '../svgs/plan-profiles.svg';
import {ReactComponent as PlanUsersSVG} from '../svgs/plan-users.svg';
import {ReactComponent as PlanPostsSVG} from '../svgs/plan-posts.svg';
import {ReactComponent as PlanPriceSVG} from '../svgs/plan-price.svg';

import { Col, Row } from 'react-bootstrap';

const Organization: React.FC = () => {
  const uParams = useParams() as { tab: string; };
  const [loading, setLoading] = useState(false);
  const [loadingCards, setLoadingCards] = useState(false);
  const [name, setName] = useState('');
  const [planId, setPlanId] = useState('teamYearly');
  const [newOrganization, setNewOrganization] = useState<Types.Organization | null>(null);
  const [organizationUsers, setOrganizationUsers] = useState<Types.User[]>([]);
  const [plans, setPlans] = useState<Types.Plan[]>([]);
  const [orgAdmin, setOrgAdmin] = useState<Types.User | null>(null);
  const [roles, setRoles] = useState<Types.Role[]>([]);
  const [permissions, setPermissions] = useState<Types.Permission[]>([]);
  const [logs, setLogs] = useState<Types.Log[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<any>([]);
  const [customer, setCustomer] = useState<any>({});
  const [editOrg, setEditOrg] = useState(false);

  /*const [tab, setTab] = useState<'billing' | 'roles' | 'activity' | 'org' | string>(uParams.tab || 'org');*/

  const user = useContext(UserContext) as Types.User;
  const [tab, setTab] = useState<'billing' | 'roles' | 'activity' | 'org' | string>(uParams.tab || (user.permissionIds.includes('administrateOrganization') ? 'org' : user.permissionIds.includes('manageRoles') ? 'roles' : user.permissionIds.includes('viewLogs') ? 'activity' : user.permissionIds.includes('managePayments') ? 'billing' : ''));

  const [organizationUsersLoaded, setOrganizationUsersLoaded] = useState(false);
  const [logsLoaded, setLogsLoaded] = useState(false);
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);
  const [rolesLoaded, setRolesLoaded] = useState(false);
  const [payments, setPayments] = useState<any[]>([]);

  const organization = useContext(OrganizationContext) as Types.Organization;

  useEffect(() => {
    setPlanId(organization.planId);
  }, [organization])

  useEffect(() => {
    api
      .users
      .getUsersByOrganization()
      .then((users: Types.User[]) => {
        setOrganizationUsers(users)
        setOrganizationUsersLoaded(true);
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    if(user.permissionIds.includes('managePayments'))
    {
	    api
	      .payments
	      .getPlans()
	      .then((plans: Types.Plan[]) => {
		setPlans(plans)
	      })
	      .catch(err => console.log(err))
    }

  }, [])

  useEffect(() => {
    api
      .users
      .getUserById(organization.adminId)
      .then((admin: Types.User) => {
        setOrgAdmin(admin)
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    api
      .roles
      .getRoles()
      .then((roles: Types.Role[]) => {
        setRoles(roles)
        setRolesLoaded(true);
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    api
      .permissions
      .getTeamPermissions()
      .then((permissions: Types.Permission[]) => {
        setPermissions(permissions)
        setPermissionsLoaded(true);
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    if(organization && organization.stripeId && user.permissionIds.includes('managePayments')) {
      setLoadingCards(true);
      api
        .payments
        .getCustomer()
        .then((customer: any) => {
          setCustomer(customer);
          api
            .payments
            .getPaymentMethodsForCustomer()
            .then((methodsResponse: any) => {
              setLoadingCards(false);
              setPaymentMethods(methodsResponse.data);
            })

          api
            .payments
            .getPayments()
            .then((payments: any) => {
              setPayments(payments.data);
            })
        })
    }

    if(organization.name.trim() == '')
      	setLoading(true)

  }, [organization])


  function checkNewOrganization (name: string) {
	if(name.trim() != '')
	{
	  setLoading(false);
        }
	else
	{
	  setLoading(true);
	}	  
	setNewOrganization({ ...organization, name:  name})

  }

  function submit () {
    if(newOrganization) {
      setLoading(true)
      delete organization.stripeId;
      api
        .organizations
        .updateOrganization(newOrganization)
          .then((retrievedOrganization: Types.Organization) => {
            setNewOrganization(null);
            setLoading(false);
            setEditOrg(false);
          })
          .catch((err: any) => {
            setLoading(false)
            console.log(err);
          })
    }
  }

  function deletePaymentMethod(paymentMethodId: string) {
    setLoadingCards(true);
    return api
      .payments
      .deletePaymentMethod(paymentMethodId)
      .then(() => {
        api
          .payments
          .getCustomer()
          .then((customer: any) => {
            setCustomer(customer);
              api
                .payments
                .getPaymentMethodsForCustomer()
                .then((methodsResponse: any) => {
                  setPaymentMethods(methodsResponse.data);
                  setLoadingCards(false);
                })
            })
          })

  }

  function getStripeInfo () {
    if(organization.stripeId) {
      setLoadingCards(true);
      api
        .payments
        .getCustomer()
        .then((customer: any) => {
          setCustomer(customer);
          api
            .payments
            .getPaymentMethodsForCustomer()
            .then((methodsResponse: any) => {
              setPaymentMethods(methodsResponse.data);
              setLoadingCards(false);
            })
        })
    }
  }

  function cardImage (brand: string) {
    if(brand === 'visa') {
      return (<VisaSVG />);
    } else if(brand === 'mastercard') {
      return (<MastercardSVG />);
    } else {
      return (<BlankCardSVG />);
    }
  };

  function paymentMethodList () {
    if(loadingCards) {
      return (<div className="flex-row">Getting Payment Information...</div>);
    } else if (paymentMethods) {
      return paymentMethods.map((paymentMethod: any )=> {
        return (
          <div className="payment-method flex-row" key={paymentMethod.id}>
            <div className="flex-row left">
              <Radio
                name="card"
                value={paymentMethod.id}
                checked={customer.invoice_settings && (customer.invoice_settings.default_payment_method === paymentMethod.id)}
                onChange={handleSetDefaultCard}
              />
              <div className="brand flex-row">{cardImage(paymentMethod.card.brand)}</div>
              •••• •••• •••• {paymentMethod.card.last4} 
              {customer.invoice_settings && (customer.invoice_settings.default_payment_method === paymentMethod.id)
                && <div className="gray-text">Default</div>
              }
            </div>
            <div className="flex-row middle">
              <div>Expires {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}</div>
            </div>
            <DeleteIcon 
              onConfirm={() => deletePaymentMethod(paymentMethod.id)} 
              message={`Are you sure you want to delete the ${paymentMethod.card.brand} card ending in ${paymentMethod.card.last4}?`}
            />
          </div>
        );
      })
    }
  }

  function handleSetDefaultCard (e: React.ChangeEvent<HTMLInputElement>) {
    setLoading(true);
    setCustomer({...customer, invoice_settings: {
      default_payment_method: e.target.value
    }})
    api.payments.updateCustomer({
      invoice_settings: { 
        default_payment_method: e.target.value 
      } 
    })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
  }

  function handleDeleteRole (role: Types.Role) {
    return api.
      roles.
      deleteRole(role)
        .then(() => {
          return api
            .roles
            .getRoles()
            .then((roles: Types.Role[]) => {
              setRoles(roles)
            })
        })
  }

  function manageStripe () {
    setLoading(true);
    api
      .payments
      .manageStripe()
      .then(forwardUrl => {
        window.location.href = forwardUrl;
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      })
  }

  function showTab () {
    if(tab === 'billing') {
      return (
        <div className="tab-content billing">
          <div className="tab_billing plan">
            <Row className="billing_top">
            <Col lg={8} md={6} xs={4}>
            <h2>Team Plan</h2>
            </Col>
              {!organization.stripeId &&
                <Col lg={4} md={6} xs={8} className='team_plan_disc'>
                  <div className="frequency" align="right">
                  <h5>Monthly
                  <Toggle 
                    idName="planFrequency" 
                    initialOn={organization.planId === 'teamYearly' || organization.planId === 'testTeamYearly'}
                    onChange={x => setPlanId((x ? 'teamYearly' : 'teamMonthly'))}
                  />
                  Yearly <small className='discount'>25% discount!</small>
                  </h5>
                </div></Col>
              }
            </Row>
            <div className="billing_bottom">

            <Row className="billing_detail">

            <Col lg={3} md={6} xs={6} className="billing_item_l">
            <div className='billing_detail_info'>
            <PlanProfilesSVG />
            <h5><span>Social Profiles</span>Unlimited</h5>
            </div>
            </Col>
               
            <Col lg={3} md={6} xs={6} className="billing_item_r">
            <div className='billing_detail_info'>
            <PlanUsersSVG />
             <h5><span>Users</span>Unlimited</h5>
             </div>
            </Col>

            <Col lg={3} md={6} xs={6} className="billing_item_l">
            <div className='billing_detail_info'>
            <PlanPostsSVG />
            <h5><span>Posts</span>Unlimited</h5>
            </div>
            </Col>

            <Col lg={3} md={6} xs={6} className="billing_item_r">
            <div className='billing_detail_info'>
            <PlanPriceSVG />
             <h5><span>Price</span>{planId === "teamMonthly" || planId === "testTeamMonthly" ? '$99 / mo.' : '$891 / yr.'}</h5>
             </div>
            </Col>

              </Row>

            </div>
          </div>

          <div className='subscription_sec'>
          {organization.stripeId 
            ? <div className="manage-billing"><Button onConfirm={manageStripe} color="orange" enabled={!loading}>Manage Billing</Button></div>
            : <><h1>Start Subscription</h1>
              {user && user.permissionIds.includes('managePayments') && !organization.stripeId &&
                <div className="stripe-row">
                  {!organization.stripeId && <div className='subscription_desc'>You will be charged {planId === "teamMonthly" || planId === "testTeamMonthly" ? '$99' : '$891'} and your subscription will be started when you enter your payment information.</div>}
                  { organization === null 
                    || <Stripe hasStripeId={organization.stripeId} onComplete={getStripeInfo} planId={planId} price={planId === "teamMonthly" ? '99' : '891'} paymentAction={'Live'}/> 
                  }
                </div>}
              </>
          }
          </div>

      {/*user && user.permissionIds.includes('managePayments') && payments.length > 0 &&
            <div className="payments flex-column">
              <h1>Payment History</h1>
              {payments.map(payment => {
                return (
                  <div className="payment flex-row" key={payment.id}>
                    <div className="left flex-column">
                      {new Date(payment.created).toLocaleString()}
                      <div className="gray-text">{payment.description}</div>
                    </div>
                    <div className="right flex-row">
                      <div className="brand flex-row">{cardImage(payment.payment_method_details.card.brand)}</div>
                      •••• •••• •••• {payment.payment_method_details.card.last4} 
                      <div className="amounts flex-column">
                        <div className="captured"> 
                          {payment.amount_captured > 0 && `-${payment.amount_captured / 100}`}
                        </div>
                        <div className="refunded"> 
                          {payment.amount_refunded > 0 && `+${payment.amount_refunded / 100}`}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
        */}
        </div>
      );
    } else if(tab ==='roles') {
      return (
        <div className="tab-content roles">
          <div className='team_roles_content mb-5'>
          <Row className="app_feed_row team_role_header justify-content-md-center">
          <Col lg={8} md={8} xs={8} className="app_col team_role_title">
          <h4>Team Roles</h4>
          </Col>
          {user && user.permissionIds.includes('manageRoles') &&
          <Col lg={4} md={4} xs={4} className="app_col team_role_add">
            <div align="right"><NavLink className="add_role add" to="/app/roles/new">
              Add Role <AddSVG />
              </NavLink></div></Col>
            }
          </Row>
          <div className="team_roles_list">
            {roles &&
              roles.map((role: Types.Role) => (
                  <div className="team_role_list_item" key={role.id}>
                    <div className="list_item_details">
                     <div className='list_item_name'>
                       <h5>{role.name}</h5>
                       </div>
                      <div className="list_item_desc">
                        {role.permissionIds.length > 0 
                          ? role.description || role.permissionIds.map(permissionId => {
                            const permission = permissions.find(perm => {
                              return perm.id === permissionId
                            })

                            if(permission) {
                              return permission.name;
                            }
                          }).join(', ')
                          : 'No permissions'
                        }
                      </div>
                    </div>
                    {role.organizationId !== '' && user && user.permissionIds.includes('manageRoles') &&
                      <div className="tools team_tools_icons">
                        <NavLink className="tool_edit_link" to={`/app/roles/edit/${role.id}`}>
                          <EditSVG />
                        </NavLink>
                        <DeleteIcon 
                          onConfirm={() => handleDeleteRole(role)} 
                          message={`Are you sure you want to delete the role ${role.name}?`}
                        />
                      </div>
                    }
                  </div>
                )
              )
            }
          </div>
          </div>
        </div>
      );

    } else if(tab === 'activity') {
      return (
        <div className="tab-content activity">
          <LogList 
            load={(lastSnapshot: any) => api.logs.getLogsForOrganization(lastSnapshot)} 
          />
        </div>
        );
    } else if(tab === 'org') {
      return (
        <div className="setting_tab_list tab-content org">
          <Row className="app_feed_row setting_tab_details detail_row">
            <Col lg={4} md={6} xs={6} className="app_col detail_column">
              <div className="detail_title">
                Organization Status
              </div>
              {showOrgStatus()}
            </Col>
            {organization.status === Consts.OrgStatus.trial &&
              <Col lg={8} md={6} xs={6} className="app_col detail_column">
                <div className="detail_title">
                  Trial End
                </div>
                {new Date((organization.trialEnd * 1000)).toLocaleString() == 'Invalid Date' ? 'Expired' : new Date((organization.trialEnd * 1000)).toLocaleString()}
              </Col>
            }
          </Row>

          <Row className="app_feed_row setting_tab_details detail_row">
          <Col lg={4} md={6} xs={6} className="app_col detail_column">
              <div className="detail_title">
                Created By
              </div>
              {orgAdmin ? orgAdmin.name || orgAdmin.email : 'Loading...'}
            </Col>
            <Col lg={8} md={6} xs={6} className="app_col detail_column">
              <div className="detail_title">
                Created At
              </div>
              {new Date(organization.createdAt * 1000).toLocaleString()}
            </Col>
          </Row>

          <Row className="app_feed_row detail_row rename_row">
          <Col lg={4} md={6} xs={6} className="app_col detail_column rename_column">
              <div className="detail_title">
                Rename Organization
              </div>
              <input 
                type="text" 
                value={showOrgName()}
                placeholder={showOrgName()}
                onChange={(e) => checkNewOrganization(e.target.value)} 
		maxLength={50}
              />
            </Col>
            <Col lg={8} md={6} xs={6} className="app_col detail_column">
            <div className="detail_title empty"></div>
            <Button
              color="light-orange"
              enabled={!loading}
              onConfirm={submit}
            >Rename
            </Button>
            </Col>
          </Row>
        </div>
        );
    }
  }

  function showOrgStatus () {
    if(organization.status === Consts.OrgStatus.trial) {
      return <div className="org-status trial">Trial</div>;
    } else if (organization.status === Consts.OrgStatus.normal) {
      return <div className="org-status normal">Normal</div>;
    } else if (organization.status === Consts.OrgStatus.invalidPayment) {
      return <div className="org-status invalid-payment">Invalid Payment</div>;
    } else {
      return 'Error';
    }
  }

  function showOrgName () {
    if(newOrganization) {
      return newOrganization.name;
    }
    return organization.name;
  }

  if(organizationUsersLoaded && rolesLoaded && permissionsLoaded) {
    return (
      <div className="feed_page_section setting_page settings page">
        <div className='feed_tabbar_title'>
        <Row className="app_feed_row">
        <Col className="app_col app_feed_title" lg={10} md={9} sm={7}>
        <h1>{showOrgName()}</h1>
        </Col>
        </Row>
        
        <Row className="feed_tabbar_desc app_feed_row">
        <Col className="app_col app_feed_title_desc" lg={12} md={12} sm={12}>
        These are settings for the entire organization.
        </Col>
        </Row>

        </div>

        <div className="seetings_page_content">
          
          <div className='setting_tabs'>
          <ul class="nav nav-tabs" role="tablist">
            {user.permissionIds.includes('administrateOrganization') &&
              <li 
                className={`tab first ${tab === 'org' ? 'active' : ''}`}
              ><a onClick={() => setTab('org')}>Organization</a></li>
            }
            {user.permissionIds.includes('manageRoles') &&
              <li 
                className={`tab middle ${tab === 'roles' ? 'active' : ''}`}
              ><a onClick={() => setTab('roles')}>Team Roles</a></li>
            }
            {user.permissionIds.includes('viewLogs') &&
              <li 
                className={`tab last ${tab === 'activity' ? 'active' : ''}`}
              ><a onClick={() => setTab('activity')}>Activity</a></li>
            }
            {user.permissionIds.includes('managePayments') &&
              <li 
                className={`tab first ${tab === 'billing' ? 'active' : ''}`}
              ><a onClick={() => setTab('billing')}>Billing</a></li>
            }     
          </ul>
          </div>
          {showTab()}
        </div>
      </div>
    );
  } else {
      return (<div className="setting_page settings page">
        <Spinner/>
      </div>);
  }
}

export default Organization;
