import React, { useState, useEffect }  from 'react';

import * as Types from 'types';

import FacebookPreview from './FacebookPreview';
import LinkedInPreview from './LinkedInPreview';
import TwitterPreview from './TwitterPreview';
import PinterestPreview from './PinterestPreview';

import {ReactComponent as DesktopSVG} from '../svgs/desktop.svg';
import {ReactComponent as MobileBlackSVG} from '../svgs/mobile-black.svg';
import {ReactComponent as ChevronLeftSVG} from '../svgs/chevron-left.svg';
import {ReactComponent as ChevronRightSVG} from '../svgs/chevron-right.svg';
import {ReactComponent as FacebookSmallSVG} from '../svgs/facebook-small.svg';
import {ReactComponent as TwitterSmallSVG} from '../svgs/twitter-small.svg';
import {ReactComponent as LinkedinSmallSVG} from '../svgs/linkedin-small.svg';
import {ReactComponent as PinterestSmallSVG} from '../svgs/pinterest-small.svg';
import {ReactComponent as FacebookWorldSVG} from '../svgs/facebook-world.svg';
import {ReactComponent as FacebookLikeSVG} from '../svgs/facebook-like.svg';
import {ReactComponent as FacebookCommentSVG} from '../svgs/facebook-comment.svg';
import {ReactComponent as FacebookShareSVG} from '../svgs/facebook-share.svg';

import { Col } from 'react-bootstrap';

interface Props {
  networks: Types.Network[];
  work: {
    facebook: Partial<Types.Work> | null;
    twitter: Partial<Types.Work> | null;
    pinterest: Partial<Types.Work> | null;
    linkedin: Partial<Types.Work> | null;
  } | null;
  urlMetadata: Types.urlMetadata | {};
}
const PostPreview: React.FC<Props> = props => {
  const [selectedNetwork, setSelectedNetwork] = useState<Partial<Types.Network>>({});
  const [previewIsLarge, setPreviewIsLarge] = useState(false);

  useEffect(() => {
    if(props.networks) {
      setSelectedNetwork(props.networks[0]);
    }
  }, [props.networks])

  useEffect(() => {
    if(props.urlMetadata && (props.urlMetadata as Types.urlMetadata).image) {
      getImageDimensions((props.urlMetadata as Types.urlMetadata).image)
        .then((imageDimensions: any) => {
          setPreviewIsLarge(imageIsLarge(imageDimensions));
        })
    }
  }, [props.urlMetadata])

  function nextNetwork () {
    const oldIndex = props
      .networks
      .findIndex(network => network === selectedNetwork);

    if(oldIndex + 1 === props.networks.length) {
      setSelectedNetwork(props.networks[0]);
    } else {
      setSelectedNetwork(props.networks[oldIndex + 1]);
    }
  }

  function previousNetwork () {
    const oldIndex = props
      .networks
      .findIndex(network => network === selectedNetwork);

    if((oldIndex - 1) < 0) {
      setSelectedNetwork(props.networks[props.networks.length - 1]);
    } else {
      setSelectedNetwork(props.networks[oldIndex - 1]);
    }
  }

  function smallNetworkIcon(networkSlug: string) {
    if(networkSlug === 'facebook') {
      return <FacebookSmallSVG />;
    } else if (networkSlug === 'pinterest') {
      return <PinterestSmallSVG />;
    } else if (networkSlug === 'twitter') {
      return <TwitterSmallSVG />;
    } else if (networkSlug === 'linkedin') {
      return <LinkedinSmallSVG />;
    }
  }

  function imageIsLarge (imageDimensions: { width: number; height: number; }) {
    return imageDimensions.width > 400 && imageDimensions.height > 209;
  }

  function getImageDimensions (url: string) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.addEventListener("load", function (){
        resolve({width: this.naturalWidth, height: this.naturalHeight});
        reject();
      });
      img.src = url;
    })
  }

  return (
    <Col lg={6} md={6} sm={12} className="post_preview">
      {props.work &&
        <div className="preview_wrapper">
          <div className="preview_title">
            <h2>Post Preview</h2>
        {/*
            <div className="desktop-mobile flex-row">
              <DesktopSVG />
              <MobileBlackSVG />
            </div>
        */}
          </div>
          <div className="preview_navigation">
            <ChevronLeftSVG className="nav_button prev" onClick={previousNetwork}/>
            <div className="network_name">
              {selectedNetwork && smallNetworkIcon(selectedNetwork.slug as string)}
              {selectedNetwork && selectedNetwork.name}
            </div>
            <ChevronRightSVG className="nav_button next" onClick={nextNetwork}/>
          </div>
          <div className="preview_container">
            { selectedNetwork && selectedNetwork.slug === 'facebook' &&
              <FacebookPreview 
                network={selectedNetwork as Types.Network} 
                previewIsLarge={previewIsLarge}
                urlMetadata={props.urlMetadata}
                work={props.work.facebook || {}}
              />
            }
            { selectedNetwork && selectedNetwork.slug === 'linkedin' &&
              <LinkedInPreview 
                network={selectedNetwork as Types.Network} 
                previewIsLarge={previewIsLarge}
                urlMetadata={props.urlMetadata}
                work={props.work.facebook || {}}
              />
            }
            { selectedNetwork && selectedNetwork.slug === 'twitter' &&
              <TwitterPreview 
                network={selectedNetwork as Types.Network} 
                previewIsLarge={previewIsLarge}
                urlMetadata={props.urlMetadata}
                work={props.work.facebook || {}}
              />
            }
            { selectedNetwork && selectedNetwork.slug === 'pinterest' &&
              <PinterestPreview 
                network={selectedNetwork as Types.Network} 
                previewIsLarge={previewIsLarge}
                urlMetadata={props.urlMetadata}
                work={props.work.facebook || {}}
              />
            }
          </div>
        </div>
      }
    </Col>
  );
}

export default PostPreview;
