import React, { useEffect, useState, useContext } from 'react';
import api from '../api';

import BigStar from './BigStar';
import Button from './Button';
import Network from './Network';
import Spinner from './Spinner';
import Select from './Select';
import NewTeam from './NewTeam';

import UserContext from './UserContext';
import OrganizationContext from './OrganizationContext';

import {ReactComponent as AddSVG} from '../svgs/add.svg';
import {ReactComponent as CloseSVG} from '../svgs/close.svg';

import * as Types from 'types';

import { Container, Col, Row} from "react-bootstrap";

interface InviteStub {
  email: string;
}

export function validateEmail (email: string) {
  const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if(email.match(regEx)) {
    return true;
  } else {
    return false;
  }
}

const Onboarding: React.FC = () => {
  const user = useContext(UserContext) as Types.User;
  const organization = useContext(OrganizationContext) as Types.Organization;
  const [newOrganization, setNewOrganization] = useState<Types.Organization | null>(null);
  const [networks, setNetworks] = useState<Types.Network[]>([]);
  const [profiles, setProfiles] = useState<Types.Profile[]>([]);
  const [profilesLoaded, setProfilesLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orgLoading, setOrgLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [invites, setInvites] = useState<{
    email: string;
  }[]>([{
    email: '',
  }]);
  const [roles, setRoles] = useState<Types.Role[]>([]);
  const [newTeamUsers, setNewTeamUsers] = useState<Types.TeamUser[]>([{
    teamId: '',
    userId: '',
    roleId: '',
    organizationId: '',
    createdAt: 0,
    deleted: false,
    updatedAt: 0
  }]);
  const [addUsers, setAddUsers] = useState(false);

  useEffect(() => {
    api
      .networks
      .getNetworks()
      .then(networks => {
        setNetworks(networks)
      })

    api
      .profiles
      .getProfiles()
      .then(profiles => {
        setProfiles(profiles)
        setProfilesLoaded(true);
      })
  }, [])

  function updateOrganization () {
    if(newOrganization) {
      setLoading(true)
      delete organization.stripeId;
      api
        .organizations
        .updateOrganization(newOrganization)
          .then((retrievedOrganization: Types.Organization) => {
            setNewOrganization(null);
	    localStorage.setItem('isLoggedIn', 'Yes');
            setStep(2);
            setLoading(false);
          })
          .catch((err: any) => {
            setLoading(false)
            console.log(err);
          })
    }
  }

  function networksList () {
    if(networks && profiles) {
      return networks.map((network: Types.Network) => {
        return <Network 
          network={network} 
          profiles={profiles}  
          key={network.slug} 
          onLoading={() => setLoading(true)}
          load={() => {
            api
              .profiles
              .getProfiles()
              .then(profiles => {
                setProfiles(profiles)
                setProfilesLoaded(true);
              })
          }}
        />;
      });
    } else {
      return <h2>Loading...</h2>
    }
  }

  function inviteUsers () {
    setLoading(true);
    api
      .users
      .invite(invites)
      .then(() => {
        return api
          .users
          .getUsersByOrganization();
      })
      .then((users: Types.User[]) => {
        setLoading(false);
        setStep(3);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      })
  }

  function updateInvite (invite: InviteStub, change: any) {
    setInvites(invites.map((i, x) => {
      if(invite === i) {
        return { ...invite, ...change };
      }
      return i;
    }));
  }

  function changeEmail (email: string, invite: InviteStub) {
    updateInvite(invite, { email: email });
  }

  function addInviter () {
    setInvites([...invites, { email: '' }]);
  }

  function deleteInvite(invite: InviteStub) {
    setInvites(invites.filter(oldInvite => oldInvite !== invite));
  }

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if(e.key === 'Enter') {
      updateOrganization();
    }
  }

    function checkNewOrganization (name: string) {
	
	setOrgLoading(true);

	if(name.trim() != '' && name.trim().length > 1)
	{
	  setOrgLoading(false);
        }  
	setNewOrganization({ ...organization, name:  name})
  }

  function showStep () {
    switch (step) {

      case 1: 
        return (<div className="step one onboarding_step">

          <div className='onboarding_sec_title'>
          <h1>Hello, {user.name}!</h1>
          <div className="subheading">Let's start your introduction to BrandSocial.</div>
          </div>

          <div className='onboarding_sec_form'>
          
          <div className='onboarding_sec_field'>
          <input 
            type="text" 
            placeholder="Enter Company Name"             
	    onChange={(e) => checkNewOrganization(e.target.value)}
            disabled={loading}
            onKeyUp={handleKeyUp}
	    maxLength={50}
          />
          </div>
          <div className='onabord_form_btn' align="right"><Button color="orange" onConfirm={updateOrganization} enabled={!orgLoading}>Next</Button>
          </div>

          </div>

        </div>);

      case 2:
        return (<div className="step two onboarding_step">
          
          <div className='onboarding_sec_title'>
          <h1>Invite Team Members</h1>
          <div className="subheading">We'll email other members of your organization to help them sign up easily.</div>
          </div>
          
          <div className='invites onboarding_sec_form'>
            {invites.map((invite: { email: string }, inviteIndex: number) => {
              return (
                <div className='onboarding_sec_field invite'>
                  <Row>
                  <Col lg={11} md={11} xs={10} className="onboarding_field_l">
                  <input
                          id="inviteEmail" 
                          name="inviteEmail" 
                          type="email" 
                          placeholder="Email" 
                          value={invite.email}
                          onChange={e => changeEmail(e.target.value, invite)}
              maxLength={50}
                        />
                  </Col>
                  <Col lg={1} md={1} xs={2} className="onboarding_field_r">
                  <CloseSVG onClick={() => deleteInvite(invite)} />  
                  </Col>
                </Row>
                </div>
              )
            })}
            <div className="add_user" onClick={addInviter}><span className="cursor">Add User <AddSVG /></span></div>
          
            <div className='onabord_form_btn' align="right">
            <Button color="light-orange" onConfirm={() => setStep(3)} enabled={!loading}>Skip</Button>
            <Button 
              color="orange" 
              enabled={
                !loading &&
                invites.every(invite => validateEmail(invite.email))              
              } 
              onConfirm={inviteUsers}
            >Send Invites</Button>
            </div>
          
          </div>
        </div>);

      case 3:
          return (<div className="step three onboarding_step">

          <div className='onboarding_sec_title'>
          <h1>Create your first team</h1>
          <div className="subheading">Organization users should be organized into teams, especially when in large numbers. Every team must have a Team Manager.</div>
          </div>
            
            <NewTeam onCreate={() => setStep(4)} />
          </div>);
      case 4:
        return (<div className="step four onboarding_step onboarding_networks">
        
        <div className='onboarding_sec_title'>
          <h1>Connect Networks</h1>
          <div className="subheading">Connect your first social profile. You, or your team members, can draft, schedule, and send posts from BrandSocial to many different social profiles.</div>
          </div>
          
          {networksList()}

          <div className='onboarding_sec_form'>
          <div className='onabord_form_btn' align="right">
          <Button color="light-orange" to="/app/feed/all" enabled={!loading}>Skip</Button>
            </div>
          </div>
          
        </div>);
    }
  }

  function showExplainer () {
    if(step === 3) {
      return (
        <div className="explainer mt20 flex-column">
	  <div className="mt5">
		  <h3 className="white-text">Team Roles</h3>
		  BrandSocial provides role-based permissions that can be customized across your company, teams and users.
		  <br/>
		  Social Profiles can be created at the team level and individual user level.  When submitting posts, they can be targeted to team profiles or to individual user profiles.
	  </div>
          <div className="mt15">
		  Team members are assigned Team Roles. A user can have a different Team Role in different teams.  For example, Jane can be the Content Manager on the Marketing team, but Drafter on the Engineering team.
		  <br/>
		  <br/>
		  The default Team Roles are listed below:
	  </div>
	  <div className="mt15">
          <h3 className="white-text">Team Manager</h3>
          The Team Manager has the ability to add and remove team members, change the roles of the team members, create posts for the team members, submit posts to team profiles and approve posts drafted by team members who lack posting permission.
          </div>
	  <div className="mt15">
          <h3 className="white-text">Content Manager</h3>
          The Content Manager has the ability to create posts for the team members, submit posts to team profiles and approve posts drafted by team members who lack posting permission.
          </div>
	  <div className="mt15">
	  <h3 className="white-text">Submitter</h3>
          The Submitter can create posts to team profiles.
          </div>
	  <div className="mt15">
          <h3 className="white-text">Drafter</h3>
          The Trainee can write posts for Team Profiles, but the posts must be approved by the Content Manager or Team Manager before they go live. 
	  </div>
        </div>
      );
    }
  }

  return (
    <div className="onboarding-page">

      <div className="login-page_section">
     <Container fluid>
     <Row className="reg_row">
     
     <Col lg={6} sm={12} className="login_reg_right">
     <BigStar> 
             {showExplainer()}
           </BigStar>
     </Col>
     
     <Col lg={6} sm={12} className="login_reg_left">
     <div className="reg_wrapper reg_flex_column">
     {showStep()}
     </div>
     </Col>
     
     </Row>
     </Container>
     </div>

    </div>
     

  );
}

export default Onboarding;
