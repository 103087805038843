import React, { useState } from 'react';
import DOMPurify from 'dompurify';

import Checkbox from './Checkbox';
import Profile from './Profile';
import TripleCheckbox from './TripleCheckbox';

import * as Types from 'types';

import {ReactComponent as ChevronUpSVG} from '../svgs/chevron-up.svg';
import {ReactComponent as ChevronDownSVG} from '../svgs/chevron-down.svg';

interface Props {
  network: Types.Network;
  profiles: Types.Profile[];
  selectedProfiles: Types.Profile[];
  onProfileSelection: (selected: boolean, profile: Types.Profile) => void;
  onNetworkSelection: (selected: number, network: Types.Network) => void;
}
const ExpandingProfileList: React.FC<Props> = props => {
  const [open, setOpen] = useState(false);


  function createMarkup() {
    return { __html: DOMPurify.sanitize(props.network.icon) };
  }

  function profilesList () {
    if(props.profiles.length > 0 && open) {
      return (<div className="profiles">
        {
          props.profiles.filter(profile => props.network.slug === profile.networkId).map((profile: Types.Profile) => {
            return (
              <div className="profile_wrapper"> 
                <Checkbox 
                  key={profile.username} 
                  onChange={(selected) => props.onProfileSelection(selected, profile)} 
                  initialChecked={profileIsSelected(profile)}
                  idName={profile.createdAt + ''}
                >
                    <Profile profile={profile} />
                </Checkbox>
              </div>
            )
          })
        }
      </div>
      )
    }
  }

  function profileIsSelected(profile: Types.Profile) {
    return !!props.selectedProfiles.find(selectedProfile => {
      return selectedProfile.id === profile.id as string
    })
  }
  function networkCheckState () {
    const networkProfiles = props.profiles.filter(prof => {
        return prof.networkId === props.network.slug;
      })

    if(
      networkProfiles.every(np => profileIsSelected(np))
      && networkProfiles.length > 0
    ) {
      return 1;
    } else if (networkProfiles.find(np => profileIsSelected(np))) {
      return 2;
    } else {
      return 0;
    }
  }

  return (
    <div className={`expanding_profile_list ${open ? 'open' : 'closed'}`}>
      <div className="header flex-row">
        <TripleCheckbox 
          onChange={(selected: number) => props.onNetworkSelection(selected, props.network)} 
          idName={props.network.slug}
          initialChecked={networkCheckState()}
        >
          <div className="network-icon flex-row" dangerouslySetInnerHTML={createMarkup()} />
          <div className="name-column flex-column">
            <h3>{props.network.name}</h3>
            <p className="gray-text">{`${props.profiles.filter(profile => props.network.slug === profile.networkId).length} destinations`}</p>
          </div>
        </TripleCheckbox>
        <div className="chevron flex-row" onClick={() => setOpen(!open)}>
          { open
            ? <ChevronUpSVG />
            : <ChevronDownSVG />
          }
        </div>
      </div>
      {profilesList()}
    </div>
  );
}

export default ExpandingProfileList;
