import React, { useState, useEffect }  from 'react';

import * as Types from 'types';

import {ReactComponent as LinkedInUserSVG} from '../svgs/linkedin-user.svg';
import {ReactComponent as LinkedInWorldSVG} from '../svgs/linkedin-world.svg';
import {ReactComponent as LinkedInDotsSVG} from '../svgs/linkedin-dots.svg';
import {ReactComponent as LinkedInLikeSVG} from '../svgs/linkedin-like.svg';
import {ReactComponent as LinkedInCommentSVG} from '../svgs/linkedin-comment.svg';
import {ReactComponent as LinkedInShareSVG} from '../svgs/linkedin-share.svg';
import {ReactComponent as LinkedInSendSVG} from '../svgs/linkedin-send.svg';

interface Props {
  network: Types.Network;
  work: Partial<Types.Work> | null;
  urlMetadata: Types.urlMetadata | {};
  previewIsLarge: boolean;
}
const LinkedInPreview: React.FC<Props> = props => {
  const [largeText, setLargeText] = useState(false);

  useEffect(() => {
    if(props.work && props.work.description && props.work.description.length < 85 && !props.work.url) {
      setLargeText(true);
    } else {
      setLargeText(false);
    }
  }, [props.work, props.urlMetadata])

  return (
    <div className="linkedin preview app_feed_row">
      <div className="linkedin-top flex-row">
        <LinkedInUserSVG className="avatar" />
        <div className="linkedin-header flex-column">
          <div className="linkedin-heading flex-row">
            Your Profile
          </div>
          <div className="linkedin-subtext flex-column">
            <div>Position at Your Company </div>
            <div className="flex-row">now <div className="linkedin-dot">·</div> <LinkedInWorldSVG /></div>
          </div>
        </div>
        <LinkedInDotsSVG className="linkedin-dots" />
      </div>
      <div className={`linkedin-text ${largeText ? 'large' : ''}`}>
        {props.work && props.work.description}
      </div>
        {Object.keys(props.urlMetadata).length > 0 &&
          <div className={`link-preview ${props.previewIsLarge ? 'large' : 'small'} flex-column`}>
            <div className="image-wrapper flex-column">
              {((props.urlMetadata as Types.urlMetadata).image || (props.urlMetadata as Types.urlMetadata).icon) &&
                <img 
                  className="link-preview-image flex-row" 
                  alt={ `${(props.urlMetadata as Types.urlMetadata).image || (props.urlMetadata as Types.urlMetadata).icon}` }
                  src={ `${(props.urlMetadata as Types.urlMetadata).image || (props.urlMetadata as Types.urlMetadata).icon}` }
                />
              }
            </div>
            <div className="link-info flex-column">
              <div className="link-title flex-column">
                {(props.urlMetadata as Types.urlMetadata).title}
              </div>
              <div className="link-url flex-row">
                {(props.urlMetadata as Types.urlMetadata).url.split('//')[1]}<div className="linkedin-dot">·</div> 1 min read
              </div>
            </div>
          </div>
        }
        {Object.keys(props.urlMetadata).length === 0 && props.work && props.work.images && props.work.images.length > 0 &&
          <div className="uploaded-images flex-row">
            {props.work.images.map(imageUrl => { 
              return <div 
                className="uploaded-image flex-row" 
                style={{'backgroundImage': `url('${imageUrl}')`}}
              />;
            })}
          </div>
        }
      <div className="linkedin-controls flex-row">
        <div className="linkedin-control flex-row">
          <LinkedInLikeSVG />
          Like
        </div>
        <div className="linkedin-control flex-row">
          <LinkedInCommentSVG />
          Comment
        </div>
        <div className="linkedin-control flex-row">
          <LinkedInShareSVG />
          Share
        </div>
        <div className="linkedin-control flex-row">
          <LinkedInSendSVG />
          Send
        </div>
      </div>
    </div>
  );
}

export default LinkedInPreview;
