import React, { useContext } from 'react';
import api from '../api';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import UserCard from './UserCard';
import DeleteIcon from './DeleteIcon';
import Tooltip from './Tooltip';

import * as Types from 'types';
import * as Consts from '../consts';

import {ReactComponent as DeleteSVG} from '../svgs/delete.svg';
import {ReactComponent as SendSVG} from '../svgs/send.svg';

import UserContext from './UserContext';

import { Table } from 'react-bootstrap';

interface Props {
  title: string | React.ReactNode;
  users: Types.User[];
  team?: Types.JoinedTeam;
  myTeamPermissions?: Types.PermissionId[];
  getUsers?: () => void;
}

TimeAgo.locale(en)
const timeAgo = new TimeAgo('en-US')

const UserList: React.FC<Props> = props => {

  const me = useContext(UserContext) as Types.User;

  function deleteTeamUser (teamId: string, userId: string) {
    return api
      .teams
      .deleteTeamUser(teamId, userId)
      .then(() => {
        if(props.getUsers) {
          props.getUsers();
        }
      })
  }

  function showUsers () {
    return (<div className='table_responsive'><Table responsive="lg">
      <thead>
      <tr>
        <th>
          Name
        </th>
        <th>
          Email Address
        </th>
	{/*
        <th>
          2FA
        </th>
	 */}
        <th>
          Last Login
        </th>
        <th>
          Status
        </th>
	<th>
	{me.permissionIds.includes('manageUsers') && 
              <Tooltip
                element={
                  <SendSVG 
                    onClick={() => api.users.resendInvites(props.users.filter(user => user.inviteStatus !== Consts.InviteStatus.joined))} 
                    style={{cursor: 'pointer'}} 
                  />
                }
              >
                Resend all invites
              </Tooltip>
          }
        </th>
      </tr>
      </thead>
      <tbody>
      {props.users.length > 0
        ? props.users.map((user: Types.User) => {
          return <tr>
            <td><UserCard key={user.id} user={user}/></td>
            <td className="">{user.email} </td>
            {/* <td>{ user.authyId ? <div className="orange-text">On</div> : 'Off' }</td> */}
            <td className="">{user.lastLogin ? timeAgo.format(new Date(user.lastLogin * 1000)) : 'None'}</td>
            <td>
              {user.inviteStatus === Consts.InviteStatus.joined
                ? <div className="active flex-row">Active</div>
                : <div className="invited flex-row">Invited</div>
              }
            </td>
	    <td>
              {user.inviteStatus === Consts.InviteStatus.joined
                ? <div > </div>
                : <span>
                  {me.permissionIds.includes('manageUsers') && <SendSVG onClick={() => api.users.resendInvites([user])} style={{cursor: 'pointer'}} />}
                </span>
              }	    
	    </td>
          </tr>
        })
        : <div className="gray-text none flex-row">There are no users yet</div>
      }
      </tbody>
    </Table></div>
    );
  }


  function showTeamUsers () {
    if(props.team) {
      const teamId = props.team.id as string;
      return (<div className='table_responsive'><Table responsive="lg">
        <thead>
        <tr>
          <th>
            Name
          </th>
          <th>
            Email
          </th>
          <th>
            {/* 2FA */}
          </th>
          <th className='team_role'>
            Team Role
          </th>
          <th>
            Status
          </th>
        </tr>
        </thead>
        <tbody>
        { props.team.users.length > 0
          ? props.team.users.map((user: Types.User) => {
            let teamRoleName = '';
            if(user && user.teamRole) {
              teamRoleName = user.teamRole.name as string;
            }
            return <tr>
              <td><UserCard key={user.id} user={user}/></td>
              <td>{user.email}</td>
              <td>{/* user.authyId ? <div className="orange-text">On</div> : 'Off' */}</td>
              <td>{teamRoleName}</td>
              <td>
                <div className="flex-row">
                  {user.inviteStatus === Consts.InviteStatus.joined
                    ? <div className="active flex-row">Active</div>
                    : <div className="invited flex-row">Invited</div>
                  }
                  {((props.myTeamPermissions && props.myTeamPermissions.includes('manageTeamUsers')) || me.permissionIds.includes('administrateOrganization')) &&
                      <DeleteIcon 
                        message={`Are you sure you want to remove ${user.name} from team?`} 
                        onConfirm={() => deleteTeamUser(teamId, user.id)}
                      />
                  }
                </div>
              </td>
            </tr>
            })
          : <div className="gray-text none flex-row">There are no users yet</div>
        }
        </tbody>
        </Table></div>
      );
    }
  }

  return (
    <div className="edit-notification-settings team_edit_user">
      {<div className="notification_title">{props.title}</div>}
        {props.team ? showTeamUsers() : showUsers()}
    </div>
  );
}

export default UserList;
