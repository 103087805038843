import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import UserImage from './UserImage';

import { Col } from 'react-bootstrap';

import * as Types from 'types';
import {ReactComponent as BlankUserSVG} from '../svgs/blank-user.svg';

interface TeamProps {
  team: Types.JoinedTeam;
}

const Team: React.FC<TeamProps> = props => {
  const history = useHistory();

  function goToTeam () {
    history.push(`team/${props.team.id}`);
  }

  function displayUsers () {
    return props.team.users.map(user => {
      return (
        <div className="kissing_image" key={user.id}>
          <UserImage user={user} size="large" />
        </div>
      )
    })
  }
  return (
    <Col lg={6} md={6} sm={12}>
    <div className="team" onClick={goToTeam}>
      <div className="team_list_title">
        <h1>{props.team.name}</h1>
      </div>
      <div className="team_list_info">
        <div className="kissing_images_list">
          {displayUsers()}
        </div>
      </div>
    </div>
    </Col>
  );
}

export default Team;
