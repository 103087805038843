import React, { useState, useContext, useRef } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { FBSignOut } from '../firebase';
import { Col } from "react-bootstrap";

import MeCard from './MeCard';
import Accordian from './Accordian';

import {ReactComponent as BSLogoSVG} from '../svgs/bslogo.svg';
import {ReactComponent as SettingsBlackSVG} from '../svgs/settings-black.svg';
import {ReactComponent as NotificationsActiveSVG} from '../svgs/notifications-active.svg';
import {ReactComponent as NotificationsInactiveSVG} from '../svgs/notifications-inactive.svg';
import {ReactComponent as HomeOrangeSVG} from '../svgs/home-orange.svg';
import {ReactComponent as HomeBlackSVG} from '../svgs/home-black.svg';
import {ReactComponent as TeamOrangeSVG} from '../svgs/team-orange.svg';
import {ReactComponent as TeamBlackSVG} from '../svgs/team-black.svg';
import {ReactComponent as NetworksOrangeSVG} from '../svgs/networks-orange.svg';
import {ReactComponent as NetworksBlackSVG} from '../svgs/networks-black.svg';

import UserContext from './UserContext';

import * as Types from 'types';

import {Navbar} from "react-bootstrap";

import OpenToggle from '../public/images/mobile_menu-white_toggle.png';
import closeToggle from '../public/images/mobile_menu_close.png';

interface Props {
  notifications: any[];
  queueItems: any[];
}

const Sidebar: React.FC<Props> = props => {
  const history = useHistory();
  const [active, setActive] = useState('Home');
  const [viewed, setViewed] = useState(false);

  const me = useContext(UserContext) as Types.User;

  function handleAccordianClick (name: string, defaultLink: string) {
    setActive(name);
    history.push(defaultLink);
  }

  const closeMenu = useRef(null)
  function hideMenu()
  {
	closeMenu.current.click();
  }

  return (
    // <div className="sidebar flex-column__">

    <Col className='app_col sidebar-section' lg={3} md={12} sm={12} >

    <div className="toggle-menu before_sidebar">
	<Navbar.Toggle aria-controls="main_sidebar_area">
	<img className='open' src={OpenToggle} />
	{/* <img className='close' src={closeToggle} ref={closeMenu}/> */}
	</Navbar.Toggle>
    </div>

    <Navbar.Collapse id="main_sidebar_area">
      <div className="background-overlay"></div>

          <div className="sidebar_menu">
            <div className="top-logo">
              <NavLink className="flex-row" to="/app/feed/all">
                <div className="logo-image logo-section">
                  <BSLogoSVG />
                </div>
              </NavLink>
            </div>


    <div className="toggle-menu">
	<Navbar.Toggle aria-controls="main_sidebar_area">
	{/* <img className='open' src={OpenToggle} /> */}
	 <img className='close' src={closeToggle} ref={closeMenu}/>
	</Navbar.Toggle>
    </div>

            <div className="menu_scroll_view">

            <div className="menu_top">
              <Accordian
                text="Home"
                defaultLink={'/app/feed/all'}
                icon={{ on: <HomeOrangeSVG />, off: <HomeBlackSVG />}}
                handleClick={handleAccordianClick}
                active={active === 'Home'}
              >
              <NavLink activeClassName="active-link" className="black-link" to="/app/feed/all" onClick={()=> hideMenu()}>Feed</NavLink>
              <NavLink activeClassName="active-link" className="black-link" to="/app/queue/all"><span className="flex-row queue-link" onClick={()=> hideMenu()}>Queue { props.queueItems.length > 0 && <span className="flex-row queue-number">{ props.queueItems.length }</span> }</span></NavLink>
              <NavLink activeClassName="active-link" className="black-link" to="/app/drafts" onClick={()=> hideMenu()}>Drafts</NavLink>
              <NavLink activeClassName="active-link" className="black-link" to="/app/outbox/all" onClick={()=> hideMenu()}>Outbox</NavLink>
                </Accordian>
                <Accordian
                  text="Organization"
                  defaultLink={'/app/users'}
                  icon={{ on: <TeamOrangeSVG />, off: <TeamBlackSVG />}}
                  handleClick={handleAccordianClick}
                  active={active === 'Organization'}
                >
                  <NavLink activeClassName="active-link" className="black-link" to="/app/users" onClick={()=> hideMenu()}>Users</NavLink>
                  <NavLink activeClassName="active-link" className="black-link" to="/app/teams" onClick={()=> hideMenu()}>Teams</NavLink>
                </Accordian>
                <Accordian
                  text="My Networks"
                  defaultLink={'/app/networks'}
                  icon={{ on: <NetworksOrangeSVG />, off: <NetworksBlackSVG />}}
                  handleClick={handleAccordianClick}
                  active={active === 'My Networks'}
                >
                <NavLink activeClassName="active-link" className="black-link" to="/app/networks" onClick={()=> hideMenu()}>My Networks</NavLink>
              </Accordian>
              {/*
              <Accordian
                text="Automation"
                icon={{ on: <AutomationOrangeSVG />, off: <AutomationBlackSVG />}}
                handleClick={handleAccordianClick}
                active={active === 'Automation'}
              >
              </Accordian>
                */}
            </div>
      
              <div className="menu_bottom">
                {me.permissionIds.some(permissionId => ['viewLogs', 'administrateOrganization', 'manageUsers', 'manageRoles', 'managePayments'].includes(permissionId)) &&
                  <NavLink activeClassName="active-link" className="black-link flex-row" to="/app/organization" onClick={()=> hideMenu()}>
                    <SettingsBlackSVG />
                    Settings
                  </NavLink>
                }
                <NavLink activeClassName="active-link" className="black-link flex-row" to="/app/notifications" onClick={() => setViewed(true)}  onClick={()=> hideMenu()}>
                  {props.notifications.filter((notification: any) => !notification.viewed).length > 0 && !viewed ? <NotificationsActiveSVG /> : <NotificationsInactiveSVG />}
                  Notifications
                </NavLink>
                <NavLink activeClassName="active-link" className="black-link" to="/app/me" onClick={()=> hideMenu()}><MeCard /></NavLink>
              </div>
        </div>

      </div>
      </Navbar.Collapse>
  </Col>

  
  );
}

export default Sidebar;