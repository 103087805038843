import React, { useState, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { googleAuthRedirect, handleGoogleRedirect, loginGoogle, loginEmailFB, getUiConfig, firebaseui, firebaseAuth, getFBcurrentUser } from '../firebase';
import { Link } from 'react-router-dom';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { isLive } from '../helpers';

import api from '../api';

import Button from './Button';
import BigStar from './BigStar';
import TwoFactor from './TwoFactor';
import Spinner from './Spinner';

import { Container, Col, Row} from "react-bootstrap";

import {ReactComponent as GoogleLogoSVG} from '../svgs/google-logo.svg';

import * as Types from 'types';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [captcha, setCaptcha] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [invite, setInvite] = useState<Types.User | null>(null);
  const [captchaError, setCaptchaError] = useState(false);
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ email: '', password: '', server: '', name: '' });
  const [loading, setLoading] = useState(true);
  const [is2fa, setIs2fa] = useState(false);
  const [isVerifyEmail, setIsVerifyEmail] = useState(false);
  const [isGoogle, setIsGoogle] = useState(false);
  const [firebaseUserCredential, setFirebaseUserCredential] = useState<any>(null);
  const [passwordInput, setPasswordInput] = useState<any | null>(null);

  const search = window.location.search;
  const qParams = new URLSearchParams(search);
  const inviteCode = qParams.get('inviteCode');

  useEffect(() => {

  if(localStorage.getItem('isLoggedIn') == 'Yes') {
    //window.location.href = '/app/feed/all';

    const currentUser = getFBcurrentUser();
    console.log('Test');    
    
    if(currentUser && currentUser.email)
    {
	window.location.href = '/app/feed/all';
    }
  }

    if(inviteCode) {
      api
        .users
        .getInvite(inviteCode)
        .then((inviteData: Types.User) => {
          setInvite(inviteData);
          if(inviteData === undefined) {
            setErrorMessage('Could not find invite.');
          }
        })
    }
  }, [])

  useEffect(() => {
    const i = setInterval(() => {
      if(passwordInput) {
        clearInterval(i);
      } else {
        const titles = Array.from(document.getElementsByClassName('firebaseui-title') as HTMLCollectionOf<HTMLElement>);
        if(titles.length > 0) {
          setPasswordInput(document.getElementById('ui-sign-in-new-password-input'))
        }
          
      }
    }, 1000)

    return () => {
      clearInterval(i)
    }
  }, [])

  function checkPasswordStrength(password: string) {
    const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    return regex.test(password);
  }

  useEffect(() => {
    if(passwordInput) {
     const submitButton = Array.from(document.getElementsByClassName('firebaseui-id-submit firebaseui-button mdl-button mdl-js-button mdl-button--raised mdl-button--colored') as HTMLCollectionOf<HTMLElement>)[0];
     submitButton.setAttribute('disabled', 'true');
     submitButton.style.setProperty('background-color', 'gray');
     submitButton.style.setProperty('cursor', 'not-allowed');

      passwordInput.addEventListener("keyup", (e: any) => { 
	const passwordValid =Array.from(document.getElementsByClassName('firebaseui-id-new-password-error') as HTMLCollectionOf<HTMLElement>)[0];
        if(!checkPasswordStrength(e.target.value)) {
          passwordInput.style.removeProperty('border')
          passwordInput.style.setProperty('border', '1px solid red')
          submitButton.setAttribute('disabled', 'true');
          submitButton.style.setProperty('background-color', 'gray');
          submitButton.style.setProperty('cursor', 'not-allowed');
	  if(e.target.value.length > 2)
	  {	    	
	    passwordValid.innerHTML = 'Password is too weak. Use a more complex password.';
	  }

        } else {
          passwordInput.style.removeProperty('border')
          passwordInput.style.setProperty('border', '1px solid #C6C6C6 !important;')
          submitButton.removeAttribute('disabled');
          submitButton.style.setProperty('background-color', '#F45F0C');
          submitButton.style.setProperty('cursor', 'pointer');
	  passwordValid.innerHTML = '';
        }
      });
    }
  }, [passwordInput])

  const idpButtons = document.getElementsByClassName('firebaseui-idp-text firebaseui-idp-text-long');
  useEffect(() => {
    for (let i = 0; i < idpButtons.length; i++) {
      if(idpButtons[i].textContent === 'Sign in with Google') {
        idpButtons[i].textContent = 'Sign In with Google'
      }

      if(idpButtons[i].textContent === 'Sign in with email') {
        idpButtons[i].textContent = 'Sign In with Email';

      	document.getElementsByClassName('firebaseui-idp-button')[1].addEventListener("click", function() {
	  document.getElementsByClassName('error')[0].innerHTML = '';
		 setTimeout(
		    function() {
		    	document.getElementsByClassName('firebaseui-title')[0].innerHTML = 'Sign In with Email';
		    },
		    200
		);

		setTimeout(
		    function() {
		    	document.getElementsByClassName('firebaseui-id-submit')[0].addEventListener("click", function() {
				setTimeout(
				    function() {

					const uilabel = document.getElementsByClassName('firebaseui-label');
					for (let i = 0; i < uilabel.length; i++) { 
					      if(uilabel[i].textContent === 'First & last name') {
						uilabel[i].textContent = 'First & Last name'
					      }	
					}

					document.getElementsByClassName('firebaseui-id-submit')[0].addEventListener("click", function() {
						 setTimeout(
						    function() {
							const uierros = document.getElementsByClassName('firebaseui-error');
							for (let i = 0; i < uierros.length; i++) { 
							      if(uierros[i].textContent === 'Enter your account name') {
								uierros[i].textContent = 'Enter your First & Last name'
							      }	
							}
						    },
						    100
						);

					});
  
					var ul = document.createElement("ul");
					var li = document.createElement("li");
					li.appendChild(document.createTextNode("At least 8 characters in length."));
					ul.appendChild(li);

					var li = document.createElement("li");
					li.appendChild(document.createTextNode("At least one digit, lowercase, uppercase & special character."));
					ul.appendChild(li);
					
					if(document.getElementsByClassName('firebaseui-new-password-component')[0])
						document.getElementsByClassName('firebaseui-new-password-component')[0].appendChild(ul);

				    },
				    1000
				);
			});
		    },
		    1000
		);
	}); 



      }

      	idpButtons[i].addEventListener("click", function() {
	  document.getElementsByClassName('error')[0].innerHTML = '';
	}); 

    }


  }, [invite, idpButtons.length])

  const fstart = () => firebaseui.start(
      '#firebaseui-auth-container', 
      getUiConfig(
        fstart,
        setLoading, 
        setErrorMessage, 
        setIs2fa, 
        setIsVerifyEmail,
        setEmail, 
        inviteCode
      ))

  useEffect(() => {
    fstart()
  }, []);

  function render () {
    if(is2fa) {
      return <TwoFactor 
        email={email}
      />
    } else if(isVerifyEmail) {
      return <div className="reg_wrapper reg_flex_column mr_20">
        {errorMessage && <h3 className="error">{errorMessage}</h3>}
        <div className="reg_flex_column" />
        {loading
          ? <Spinner/>
          : <div className="reg_wrapper reg_flex_column p-3 mr_20">
            We've sent you an email to verify your email address.  Please click the link to continue. <br/>If you do not receive the email within a few seconds, please check your spam folder.
          </div>
        }
      </div>
    } else {
      return <div className="reg_wrapper reg_flex_column mr_20">
        <h3 className="error text-align-center">{errorMessage}</h3>
        <div className="reg_flex_column mr_20" id="firebaseui-auth-container" />
        {loading
          ? <Spinner/>
          : <div className="reg_flex_column">
          </div>
        }
      </div>
    }
  }

  return (

    <div className="login-page_section">
      <Container fluid>
      <Row className="reg_row">

      <Col lg={6} sm={12} className="login_reg_right">
      <BigStar />
      </Col>

      <Col lg={6} sm={12} className="login_reg_left">
      <div className="reg_wrapper reg_flex_column">
        { render() }
      </div>
      </Col>
      
      </Row>
      </Container>
    </div>

  );
}

export default Login;
