import React, { useState } from 'react';
import api from '../api';

import Button from './Button';
import Spinner from './Spinner';

interface Props {
  email: string;
}

const TwoFactor: React.FC<Props> = props => {
  const [sent, setSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [passed, setPassed] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleSendSMS () {
    setLoading(true);
    api
      .authy
      .send(props.email)
      .then(sent => {
        if(sent.success) {
          setSent(true);
          setLoading(false);
        } else {
          setFailed(true);
          setLoading(false);
        }
      })
  }

  function handleCheckVerification () {
    setLoading(true);
    api
      .authy
      .check(props.email, verificationCode)
      .then(passed => {
        if(passed.success) {
          setPassed(true);
          window.location.href = '/app/feed/all';
        } else {
          setFailed(true);
          setLoading(false);
          setSent(false);
        }
      })
      .catch(() => {
        setFailed(true);
        setLoading(false);
        setSent(false);
      })
  }

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if(e.key === 'Enter') {
      handleCheckVerification();
    }
  }

  function display () {
    if(!sent) {
      return (
        <div className="request flex-column">
          <div className="two-factor-subheading">Press Send and you will receive a text message at the phone number associated with your account shortly. If your number is associated with an existing Authy account, please check your Authy mobile app.</div> 
          {failed && (<div className="error-message">Failed!</div>)}
          <Button
            color="orange"
            onConfirm={handleSendSMS}
          >Send SMS</Button>
        </div>
      )
    } else if(passed) {
      return (
        <h1>Success!</h1>
      );
    } else {
      return (
        <div className="verification flex-column">
          <div className="two-factor-subheading">Enter the verification code you received in a text message.  If you’re using Authy, check your mobile app for a verification code.</div> 
          <input
            type="text"
            placeholder="Verification Code"
            onKeyUp={handleKeyUp}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVerificationCode(e.target.value)}
          />
          <Button
            color="orange"
            onConfirm={handleCheckVerification}
          >Verify</Button>
        </div>
      );
    }
  }
  if(loading) {
    return (<Spinner />);
  } else {
    return (
      <div className="two-factor flex-column">
        <h1 className="two-factor-heading">Two Factor Verification</h1>
        {display()}
      </div>
    );
  }
}

export default TwoFactor;
