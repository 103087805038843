import axios from './axios';
import { authorizedHeader } from '../helpers';
import { db } from '../firebase';
import { identity } from '../helpers';
import { getResultById } from './results';
import { getProfileById } from './profiles';
import { getUserById } from './users';

import * as Types from 'types';

export function getPostAssignments(lastSnapshot: string = ''): Promise<Types.JoinedPostAssignment[]> {
  const url = lastSnapshot ? `/post-assignments/after/${lastSnapshot}` : '/post-assignments';
  return new Promise(async (resolve, reject) => {
    axios.get(url, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function getPostAssignmentsForUser(userId: string, lastSnapshot: string = ''): Promise<Types.JoinedPostAssignment[]> {
  const url = lastSnapshot ? `/post-assignments/users/${userId}/after/${lastSnapshot}` : `/post-assignments/users/${userId}`;
  return new Promise(async (resolve, reject) => {
    axios.get(url, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function getPostAssignmentsByPostId(postId: string): Promise<Types.JoinedPostAssignment[]> {
  let postAssignments: Partial<Types.JoinedPostAssignment[]>;
  return db
    .collection('postAssignments')
    .where('postId', '==', postId)
    .where('deleted', '==', false)
    .get()
    .then(reference => {
      return reference
        .docs.map(doc => doc.data());
    })
    .then(data => {
      postAssignments = identity<Types.JoinedPostAssignment[]>(data);
      const promises = postAssignments.map(postAssignment => {
        if(postAssignment) {
          return db
            .collection('users')
            .where('id', '==', postAssignment.userId)
            .where('deleted', '==', false)
            .get()
            .then(reference => {
              if(reference.docs.length > 0) {
                return reference
                  .docs[0]
                  .data()
              }
            })
            .then(user => {
              return { ...postAssignment, user: user };
            })
        }
      });

      return Promise.all(promises);
    })
    .then(data => {
      return (data.filter(joinedPostAssignment => joinedPostAssignment !== undefined) as Types.JoinedPostAssignment[]);
    })
}

export function getPostAssignmentsForMe(lastSnapshot: string = ''): Promise<Types.JoinedPostAssignment[]> {
  const url = lastSnapshot ? `/post-assignments/me/after/${lastSnapshot}` : '/post-assignments/me';
  return new Promise(async (resolve, reject) => {
    axios.get(url, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function getPostAssignmentsForTeam(teamId: string, lastSnapshot: string= ''): Promise<Types.JoinedPostAssignment[]> {
  const url = lastSnapshot ? `teams/${teamId}/postAssignments/after/${lastSnapshot}` : `/teams/${teamId}/postAssignments`;
  return new Promise(async (resolve, reject) => {
    axios.get(url, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function getApprovals(lastSnapshot: string = ''): Promise<Types.JoinedPostAssignment[]> {
  const url = lastSnapshot ? `/approvals/after/${lastSnapshot}` : '/approvals';
  return new Promise(async (resolve, reject) => {
    axios.get(url, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function getDrafts(lastSnapshot: string = ''): Promise<Types.JoinedPostAssignment[]> {
  const url = lastSnapshot ? `/drafts/after/${lastSnapshot}` : '/drafts';
  return new Promise(async (resolve, reject) => {
    axios.get(url, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function updateApproval(data: {}): Promise<Types.PostAssignment> {
  return new Promise(async (resolve, reject) => {
    axios.put('/approvals', data, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function approveForAll (data: {}): Promise<Types.PostAssignment[]> {
  return new Promise(async (resolve, reject) => {
    axios.put('/approvals/all', data, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function updateDraft(data: {}): Promise<Types.Post> {
  return new Promise(async (resolve, reject) => {
    axios.put('/approvals/drafts', data, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function approveDraftForAll (data: {}): Promise<Types.PostAssignment[]> {
  return new Promise(async (resolve, reject) => {
    axios.put('/approvals/drafts/all', data, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function denyApproval(postAssignmentId: string): Promise<void> {
  return new Promise(async (resolve, reject) => {
    axios.delete(`/approvals/${postAssignmentId}`, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function getWorkForPostAssignment(postAssignmentId: string): Promise<Types.JoinedWork[]> {
  return db
    .collection('work')
    .where('postAssignmentId', '==', postAssignmentId)
    .where('deleted', '==', false)
    .get()
    .then(reference => {
      return reference
        .docs
        .map(doc => doc.data());
    })
    .then(data => {
      return Promise.all(data.map(workItem => {
        if(workItem.resultId) {
          return getResultById(workItem.resultId)
            .then(result => {
              return { ...workItem, result: result };
            })
        } else {
          return new Promise((resolve, reject) => {
            resolve(workItem);
          });
        }
      }))
    .then((workWithResult: any) => {
      return Promise.all(workWithResult.map((workItem: Partial<Types.JoinedWork>) => {
        return getProfileById(workItem.profileId as string)
          .then(profile => {
            return { ...workItem, profile: profile };
          })
      }))
    })
    .then(joinedWork => {
      return identity<Types.JoinedWork[]>(joinedWork);
    })
  })
}

export function getWorkForTeamPostAssignment(teamId: string, postAssignmentId: string): Promise<Types.JoinedWork[]> {
  return db
    .collection('work')
    .where('postAssignmentId', '==', postAssignmentId)
    .where('teamId', '==', teamId)
    .where('deleted', '==', false)
    .get()
    .then(reference => {
      return reference
        .docs.map(doc => doc.data());
    })
    .then(data => {
      return Promise.all(data.map(workItem => {
        if(workItem.resultId) {
          return getResultById(workItem.resultId)
            .then(result => {
              return { ...workItem, result: result };
            })
        } else {
          return new Promise((resolve, reject) => {
            resolve(workItem);
          });
        }
      }))
    .then((workWithResult: any) => {
      return Promise.all(workWithResult.map((workItem: Partial<Types.JoinedWork>) => {
        return getProfileById(workItem.profileId as string)
          .then(profile => {
            return { ...workItem, profile: profile };
          })
      }))
    })
    .then(joinedWork => {
      return identity<Types.JoinedWork[]>(joinedWork);
    })
  })
}

export function getPostAssignmentById(postAssignmentId: string): Promise<Types.JoinedPostAssignment> {
  let post: Partial<Types.JoinedPostAssignment>;
  return db
    .collection('postAssignments')
    .where('id', '==', postAssignmentId)
    .where('deleted', '==', false)
    .get()
    .then(reference => {
      return reference
        .docs[0]
        .data();
    })
    .then(data => {
      post = data;
      return getUserById(post.authorId as string);
    })
    .then(author => {
      return identity<Types.JoinedPostAssignment>({ ...post, author: author });
    })
}

export function getTeamPostAssignmentById(teamId: string, postAssignmentId: string): Promise<Types.JoinedPostAssignment> {
  let post: Partial<Types.JoinedPostAssignment>;
  return db
    .collection('postAssignments')
    .where('id', '==', postAssignmentId)
    .where('teamId', '==', teamId)
    .where('deleted', '==', false)
    .get()
    .then(reference => {
      return reference
        .docs[0]
        .data();
    })
    .then(data => {
      post = data;
      return getUserById(post.authorId as string);
    })
    .then(author => {
      return identity<Types.JoinedPostAssignment>({ ...post, author: author });
    })
}

export function createDraft(draftData: Types.Post): Promise<string> {
  return new Promise(async (resolve, reject) => {
    axios.post('/drafts', draftData, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function createShare(shareData: { postAssignment: Types.PostAssignment, userIds: string[] }): Promise<string> {
  return new Promise(async (resolve, reject) => {
    axios.post('/post-assignments/shares', shareData, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function uploadImage (formData: FormData): Promise<string> {
  return new Promise(async (resolve, reject) => {
    axios.post('/upload-images', formData, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

export function getUrlMetadata (data: { urlString: string }): Promise<Types.urlMetadata> {
  return new Promise(async (resolve, reject) => {
    axios.post('/get-url-metadata', data, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

