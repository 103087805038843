import React from 'react';
import BigStar from './BigStar';

const AccountDeleted: React.FC = () => {

  return (
    <div className="login-page flex-row">
      <div className="wrapper flex-column">
          <div className="wrapper flex-column">
            Your account is Deleted. 
          </div>
      </div>
      <BigStar />
    </div>
  );
}

export default AccountDeleted;
