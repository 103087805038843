import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from "react-bootstrap";

import api from '../api';

import * as Types from 'types';

import {ReactComponent as AddSVG} from '../svgs/add.svg';
import {ReactComponent as CloseSVG} from '../svgs/close.svg';

import Checkbox from './Checkbox';
import Back from './Back';
import TabBar from './TabBar';
import Button from './Button';

interface InviteStub {
  email: string;
}

export function validateEmail (email: string) {
  const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if(email.match(regEx)) {
    return true;
  } else {
    return false;
  }
}

const NewUserPage: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState<Types.Team[]>([]);
  const [invites, setInvites] = useState<{
    email: string;
    deleted: boolean;
  }[]>([{
    email: '',
    deleted: false
  }]);

  useEffect(() => {
    api
      .teams
      .getTeams()
      .then(teams => {
        setTeams(teams)
      })
  }, [])

  function submit () {
    setLoading(true);
    api
      .users
      .invite(invites)
      .then(() => {
        setLoading(false);
        history.goBack();
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      })
  }

  function updateInvite (invite: InviteStub, change: any) {
    setInvites(invites.map((i, x) => {
      if(invite === i) {
        return { ...invite, ...change };
      }
      return i;
    }));
  }

  function changeEmail (email: string, invite: InviteStub) {
    updateInvite(invite, { email: email });
  }

  function addInviter () {
    setInvites([...invites, { email: '', deleted: false }]);
  }

  function deleteInvite(invite: InviteStub) {
    setInvites(invites.filter(oldInvite => oldInvite !== invite));
  }

  return (
    <Row className='app_feed_row'>
      <Col className='app_col' lg={12} md={12} sm={12}>

      <div className="Sidebar_new-user feed_page_section">
      <Back />
      <TabBar
        title="New Users"
        explainer="Invite new people to your organization. They will receive an email with a registration link."
      > 
      </TabBar>
      <div className="content">
        <div className="invites">
          {invites.map((invite: { email: string }, inviteIndex: number) => {
            return (
              <Row className="invite invite_field_list justify-content-md-center">
               <Col className='invite_field_l' lg={11} md={11} xs={10}><input
                  id="inviteEmail" 
                  name="inviteEmail" 
                  type="email" 
                  placeholder="Email" 
                  value={invite.email}
                  onChange={e => changeEmail(e.target.value, invite)}
		  maxLength={50}
                />
                </Col>
                <Col className='invite_field_r' lg={1} md={1} xs={2}><CloseSVG onClick={() => deleteInvite(invite)} />
                </Col>
              </Row>
            )
          })}

<Row className="invite_footer justify-content-md-center">
<Col className='invite_footer_l' lg={6} md={6} xs={6}>
<div className="add_another" onClick={addInviter}>Add Another <AddSVG /></div>
  </Col>

  <Col className='invite_footer_r' lg={6} md={6} xs={6}>
  <Button 
            color="orange" 
            enabled={
              !loading &&
              invites.every(invite => validateEmail(invite.email))              
            } 
            onConfirm={submit}
          >Send Invites</Button>
  </Col>
  </Row>
          
        </div>
      </div>
    </div>
    </Col>
    </Row>
  );
}

export default NewUserPage;