import React, { useState, useEffect }  from 'react';

import * as Types from 'types';

import {ReactComponent as FacebookSmallSVG} from '../svgs/facebook-small.svg';
import {ReactComponent as FacebookWorldSVG} from '../svgs/facebook-world.svg';
import {ReactComponent as FacebookLikeSVG} from '../svgs/facebook-like.svg';
import {ReactComponent as FacebookCommentSVG} from '../svgs/facebook-comment.svg';
import {ReactComponent as FacebookShareSVG} from '../svgs/facebook-share.svg';

import { Row } from 'react-bootstrap';

interface Props {
  network: Types.Network;
  work: Partial<Types.Work> | null;
  urlMetadata: Types.urlMetadata | {};
  previewIsLarge: boolean;
}
const FacebookPreview: React.FC<Props> = props => {
  const [largeText, setLargeText] = useState(false);

  useEffect(() => {
    if(props.work && props.work.description && props.work.description.length < 85 && !props.work.url) {
      setLargeText(true);
    } else {
      setLargeText(false);
    }
  }, [props.work, props.urlMetadata])

  return (
    <Row className="facebook preview app_feed_row">
      <div className="facebook-top flex-row">
        <div className="facebook-avatar flex-row">YP</div>
        <div className="facebook-header flex-column">
          <div className="facebook-heading flex-row">
            Your Profile
          </div>
          <div className="facebook-subtext flex-row">
            <span className="facebook-subprofile">Published By BrandSocial <img className="fbqm" src="/fbqm.png" /> </span>
            · Just Now · <FacebookWorldSVG />
          </div>
        </div>
        <div className="facebook-dots flex-row">
          <div className="facebook-dot flex-row"></div>
          <div className="facebook-dot flex-row"></div>
          <div className="facebook-dot flex-row"></div>
        </div>
      </div>
      <div className={`facebook-text ${largeText ? 'large' : ''}`}>
        {props.work && props.work.description}
      </div>
        {Object.keys(props.urlMetadata).length > 0 &&
          <div className={`link-preview ${props.previewIsLarge ? 'large' : 'small'} flex-column`}>
            <div className="image-wrapper flex-column">
              {((props.urlMetadata as Types.urlMetadata).image || (props.urlMetadata as Types.urlMetadata).icon) &&
                <img 
                  className="link-preview-image flex-row" 
                  alt={ `${(props.urlMetadata as Types.urlMetadata).image || (props.urlMetadata as Types.urlMetadata).icon}` }
                  src={ `${(props.urlMetadata as Types.urlMetadata).image || (props.urlMetadata as Types.urlMetadata).icon}` }
                />
              }
            </div>
            <div className="link-info flex-column">
              <div className="link-url flex-row">
                {(props.urlMetadata as Types.urlMetadata).url.split('//')[1].toUpperCase()}
              </div>
              <div className="link-title flex-column">
                {(props.urlMetadata as Types.urlMetadata).title}
              </div>
              <div className="link-description flex-column">
                {(props.urlMetadata as Types.urlMetadata).description}
              </div>
            </div>
          </div>
        }
      <div className="facebook-controls flex-row">
        <div className="facebook-control flex-row">
          <FacebookLikeSVG />
          Like
        </div>
        <div className="facebook-control flex-row">
          <FacebookCommentSVG />
          Comment
        </div>
        <div className="facebook-control flex-row">
          <FacebookShareSVG />
          Share
        </div>
      </div>
    </Row>
  );
}

export default FacebookPreview;
