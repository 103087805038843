import React, { useContext, useEffect } from 'react';

import Button from './Button';

import {ReactComponent as NotificationDots} from '../svgs/notification-dots.svg';

import UserContext from './UserContext';
import OrganizationContext from './OrganizationContext';

import * as Types from 'types';

import {Navbar, Row, Col} from "react-bootstrap";

{/*
import OpenToggle from '../public/images/mobile_menu-white_toggle.png';
import closeToggle from '../public/images/mobile_menu_close.png';
*/}
interface Props {
  children: React.ReactNode,
  updateBill?:boolean;
}

const NotificationBanner: React.FC<Props> = props => {
  const me = useContext(UserContext) as Types.User;
  const organization = useContext(OrganizationContext) as Types.Organization;

  return (
    <div className="notification-banner">
      {/* <Navbar.Toggle aria-controls="main_sidebar_area"><img className='open' src={OpenToggle} /><img className='close' src={closeToggle} /></Navbar.Toggle> */}
    <Row>
      <Col lg={9} md={8} xs={7} className="notification_banner_l">
      {props.children}
      {/* {props.updateBill && <NotificationDots className="dots" />} */}
      </Col>
      <Col lg={3} md={4} xs={5} className="notification_banner_r">
      {props.updateBill && <Button color="white" to="/app/organization/billing">Update Billing</Button>}
      </Col>
    </Row>
      
      
    </div>
  );
}

export default NotificationBanner;
