import React, { useState } from 'react';
import { BrowserRouter as Router, Route, withRouter, useLocation } from 'react-router-dom';

import Topbar from './Topbar';
import Bottombar from './Bottombar';

import HomePage from './HomePage';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import AboutPage from './AboutPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import ContactPage from './ContactPage';
import TermsOfServicePage from './TermsOfServicePage';
import PricingPage from './PricingPage';
import MobilePage from './MobilePage';
import AccountPausedPage from './AccountPausedPage';
import AccountDeletedPage from './AccountDeletedPage';

const AppInterior: React.FC = () => {
  const [pathname, setPathname] = useState(window.location.pathname);


  function handleClick () {
    if(pathname !== window.location.pathname) {
      window.scrollTo(0, 0);
    }
    setPathname(window.location.pathname);
  }

  return (
    <div className="AppInterior" onClick={handleClick}>
      <Router>
        <Topbar/>
        <Route exact path="/" component={HomePage}/>
        <Route exact path="/login" component={LoginPage}/>
        <Route exact path="/register" component={RegisterPage}/>
        <Route exact path="/about" component={AboutPage}/>
        <Route exact path="/privacy-policy" component={PrivacyPolicyPage}/>
        <Route exact path="/terms-of-service" component={TermsOfServicePage}/>
        <Route exact path="/contact" component={ContactPage}/>
        <Route exact path="/pricing" component={PricingPage}/>
        <Route exact path="/mobile" component={MobilePage}/>
	<Route exact path="/account-paused" component={AccountPausedPage}/>
	<Route exact path="/account-deleted" component={AccountDeletedPage}/>
        <Bottombar/>
      </Router>
    </div>
  );
}

export default withRouter(AppInterior);
