import React, { useState, useContext } from 'react';
import DOMPurify from 'dompurify';

import Checkbox from './Checkbox';
import Profile from './Profile';
import TripleCheckbox from './TripleCheckbox';
import UserCard from './UserCard';

import * as Types from 'types';

import { Col, Row } from 'react-bootstrap';

import {ReactComponent as ChevronUpSVG} from '../svgs/chevron-up.svg';
import {ReactComponent as ChevronDownSVG} from '../svgs/chevron-down.svg';

import UserContext from './UserContext';

interface Props {
  team: Types.JoinedTeam;
  networks: Types.Network[];
  selectedProfiles: Types.Profile[];
  onProfileSelection: (selected: boolean, profile: Types.Profile) => void;
  onSelectAllTeam: (selected: number, team: Types.JoinedTeam) => void;
  selectedUsers: Types.User[];
  onTeamUserSelection: (selected: boolean, user: Types.User, teamId: string) => void;
}
const ExpandingTeamProfileList: React.FC<Props> = props => {
  const [open, setOpen] = useState(false);

  const me = useContext(UserContext) as Types.User;
  
  function usersList () {
    if(props.team.users.length > 0 && open) {
      return (<div className="profiles">
        {
          props.team.users.map((teamUser: Types.User) => {
            if(me.id !== teamUser.id) {
              return (
                <div className="user-wrapper"> 
                  <Checkbox 
                    key={teamUser.id} 
                    onChange={(selected) => props.onTeamUserSelection(selected, { ...teamUser, teamId: props.team.id }, props.team.id as string)} 
                    initialChecked={teamUserIsSelected(teamUser)}
                    idName={teamUser.id + props.team.id}
                  >
                      <UserCard user={teamUser} />
                  </Checkbox>
                </div>
              )
            }
          })
        }
      </div>
      )
    }
  }

  function teamUserIsSelected(teamUser: Types.User) {
    return !!props.selectedUsers.find(selectedTeamUser => {
      return selectedTeamUser.id === teamUser.id as string && selectedTeamUser.teamId === props.team.id as string
    })
  }

  function createMarkup(networkIcon: string) {
    return { __html: DOMPurify.sanitize(networkIcon) };
  }

  function showNetworkIcon(profile: Types.Profile) {
    const network = props.networks.find(network => network.slug === profile.networkId);
    if(network) {
      return(<div className="network-icon flex-row" dangerouslySetInnerHTML={createMarkup(network.icon)} />);
    }
  }

  function profilesList () {
    if(props.team.profiles.length > 0 && open) {
      return (<div className="profiles flex-column">
        {
          props.team.profiles.map((profile: Types.Profile) => {
            return (
              <div className="profile-wrapper flex-row"> 
                <Checkbox 
                  key={profile.username} 
                  onChange={(selected) => props.onProfileSelection(selected, profile)} 
                  initialChecked={teamProfileIsSelected(profile)}
                  idName={profile.createdAt + ''}
                >
                  {showNetworkIcon(profile)}
                  <Profile profile={profile} />
                </Checkbox>
              </div>
            )
          })
        }
      </div>
      )
    }
  }

  function teamProfileIsSelected(teamUser: Types.Profile) {
    return !!props.selectedProfiles.find(selectedProfile => {
      return selectedProfile.id === teamUser.id as string && selectedProfile.teamId === props.team.id as string
    })
  }

  function teamCheckState () {
    if(
      (
        props.team.profiles.every(teamProfile => teamProfileIsSelected(teamProfile))
        && props.team.profiles.length > 0
      ) && (
        props.team.users.every(teamUser => {
          return teamUserIsSelected(teamUser);
        })
        && props.team.users.length > 0
      )
    ) {
      return 1;
    } else if (
      props.team.profiles.find(teamProfile => teamProfileIsSelected(teamProfile))
      || (props.team.users.find(teamUser => {
        return teamUserIsSelected(teamUser);
      }))
    ) {
      return 2;
    } else {
      return 0;
    }
  }

  return (
    <div className={`expanding-profile-list team ${open ? 'open' : 'closed'}`}>
      <div className="profile_header">
        <Row className="team_profile_header">

       <Col lg={11} md={11} xs={10} className='team_header_l'>
       <TripleCheckbox 
          onChange={(selected: number) => props.onSelectAllTeam(selected, props.team)} 
          idName={props.team.id as string}
          initialChecked={teamCheckState()}
        >
          <div className="name-column">
            <h3>{props.team.name}</h3>
            <p className="gray-text">{`${props.team.profiles.length + props.team.users.length} destination${props.team.profiles.length + props.team.users.length === 1 ? '' : 's'}`}</p>
          </div>
        </TripleCheckbox>
        </Col>

        <Col lg={1} md={1} xs={2} className='team_header_r'>
        <div align="right" className="chevron" onClick={() => setOpen(!open)}>
          { open
            ? <ChevronUpSVG />
            : <ChevronDownSVG />
          }
        </div>
        </Col>
        </Row>
      </div>
      {profilesList()}
      {usersList()}
    </div>
  );
}

export default ExpandingTeamProfileList;
