import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import { Col, Row } from "react-bootstrap";

import Button from './Button';
import api from '../api';
import Profile from './Profile';
import DeleteIcon from './DeleteIcon';

import * as Types from 'types';
import * as Consts from '../consts';

import {ReactComponent as ChevronUpSVG} from '../svgs/chevron-up.svg';
import {ReactComponent as ChevronDownSVG} from '../svgs/chevron-down.svg';

interface Props {
  network: Types.Network;
  profiles: Types.Profile[];
  teamId?: string;
  load: () => void;
  onLoading: () => void;
}

const Network: React.FC<Props> = props => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);


  function createMarkup() {
    return { __html: DOMPurify.sanitize(props.network.icon) };
  }

  function deleteProfile (profileId: string) {
    return api.profiles.deleteProfile(profileId)
      .then(() => {
        props.load();
      })
  }
  function profilesList () {
    if(props.profiles.length > 0 && open) {
      return (<div className="Sidebar_profiles">
        {
          props.profiles.filter(profile => props.network.slug === profile.networkId).map((profile: Types.Profile) => {
            return (
              <div className="profile_wrapper"> 
               <Row className="app_feed_row">
               <Col className="app_col" lg={10} md={10} xs={9}><Profile profile={profile} />
                {profile.status !== Consts.ProfileStatus.normal && getProfileStatusString(profile)}</Col>

                <Col className="app_col" lg={2} md={2} xs={3}><DeleteIcon 
                  onConfirm={() => deleteProfile(profile.id as string)}
                  message={`Are you sure you want to delete the ${props.network.name} profile ${profile.name}?`}
                /></Col>
                </Row>
              </div>
            )
          })
        }
      </div>
      )
    }
  }

	function getProfileStatusString (profile: Types.Profile) {
    switch (profile.status) {
        case Consts.ProfileStatus.normal: 
          return 'This profile is in a normal working status.';
          break;
        case Consts.ProfileStatus.reauth: 
          return 'This profile needs to be re-authenticated to continue posting.';
          break;
        case Consts.ProfileStatus.banned: 
          return 'This profile has been banned from its social network.';
          break;
        case Consts.ProfileStatus.invalid: 
          return 'The profile information is invalid.';
          break;
        case Consts.ProfileStatus.deleted: 
          return 'This profile has been deleted from its social network.';
          break;
    }
	}

  function authProfile() {
    props.onLoading();
    setLoading(true);
    if(props.teamId) {
      api
        .profiles
        .authTeamProfile(props.network.slug, props.teamId) 
        .then(forwardUrl => {
          window.location.href = forwardUrl;
        })
        .catch(err => {
          console.log(err);
        })
    } else {
      api
        .profiles
        .authProfile(props.network.slug) 
        .then(forwardUrl => {
          window.location.href = forwardUrl;
        })
        .catch(err => {
          console.log(err);
        })
    }
  }

  return (
      <div className={`network_list ${open ? 'open' : 'closed'}`}>
      <div className="network_list_item">
        <Row className='justify-content-md-center'>
          <Col lg={1} md={1} xs={2} className="network_icon_col">
          <div className="network-icon" dangerouslySetInnerHTML={createMarkup()}  onClick={() => setOpen(!open)}/>
          </Col>

          <Col lg={8} md={7} xs={4} className="network_title_col">
          <div className="title_col" onClick={() => setOpen(!open)}>
            <h3>{props.network.name}</h3>
            <p onClick={() => setOpen(!open)}>{`${props.profiles.filter(profile => props.network.slug === profile.networkId).length} connected`}</p>
          </div>
          </Col>

          <Col lg={3} md={4} xs={6} className="network_btn_col">
        <div className="chevron" align="right">
          <Button color="light-orange" enabled={!loading} onConfirm={() => authProfile()}>Connect</Button>
          <div className='network_toggle_icon'>{ open
            ? <ChevronUpSVG  onClick={() => setOpen(!open)}/>
            : <ChevronDownSVG  onClick={() => setOpen(!open)}/>
          }
          </div>
        </div>
        </Col>
        </Row>
      </div>
      {profilesList()}
    </div>
  );
}

export default Network;