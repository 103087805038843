import React from 'react';


const AboutPage: React.FC = () => {

  return (
  <div className="about-page">
    <div className="wrapper flex-column">
    <div className="heading-container flex-column">
      <img
        className="dots mobile-hidden"
        src="about-dots.svg"
        alt = "about-dots"
      />
      <h1>About <span className="orange">Brand Social</span></h1>
      <div>
        We create software that allows you to automatically post online content to 20+ leading Social Media Networks at the same time. <span className="orange">Brand Social</span>'s patented software provides a unified platform for bloggers and marketers to easily post content to top social networks including Facebook, Twitter, and LinkedIn.
        <br/>
        <br/>
        Whether you’re an individual blogger or a corporate marketing department, we offer an array of packages to fit your needs.
      </div>
    </div>

    <div className="bottom flex-column">
      <div className="cards flex-row"> 
        <div className="left card-col flex-column"> 
          <div className="card flex-row"> 
            <span className="card-copy">Ability to <span className="orange">Scale</span> to Any Sized <span className="orange">Organization</span> and <span className="orange">Team Structure</span></span>
            <img src="scale2.svg" alt="scale2"/>
          </div>
          <div className="card flex-row"> 
            <span className="card-copy"><span className="orange">Simple</span> and <span className="orange">Easy</span> to Use</span>
            <img src="unlimited.svg" alt="unlimited"/>
          </div>
        </div>
        <div className="right card-col flex-column"> 
          <div className="card flex-row"> 
            <span className="card-copy">Flat Monthly Fee for <span className="orange">Unlimited Posts</span> ($99/mo)</span>
            <img src="baby.svg" alt="baby"/>
          </div>
          <div className="card flex-row"> 
            <span className="card-copy">Enterprise-grade <span className="orange">Security</span> & <span className="orange">Reporting</span></span>
            <img src="shield.svg" alt="shield"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

  );
}

export default AboutPage;
