import React from 'react';
import { useHistory } from 'react-router-dom';

import {ReactComponent as ChevronLeftGraySVG} from '../svgs/chevron-left-gray.svg';

import { Row, Col } from 'react-bootstrap';

const Back: React.FC = () => {
  const history = useHistory();

  return (
    <Row className="app_feed_row">
    <Col lg={12} md={12} sm={12} className="app_col new_role_back">
      <div 
      className="role_back_link" 
      onClick={history.goBack}
    >
      <ChevronLeftGraySVG />
      Back
    </div>
    </Col>
    </Row>
  );
}

export default Back;
