import React from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from "react-bootstrap";

import Back from './Back';
import TabBar from './TabBar';
import NewTeam from './NewTeam';

const NewTeamPage: React.FC = () => {
  const history = useHistory();

  return (
    <Row className='app_feed_row'>
    <Col className='app_col' lg={12} md={12} sm={12}>
    <div className="Sidebar_new-team feed_page_section">
      <Back />
      <TabBar
        title="New Team"
        explainer="Name your new team and assign users to it. Each user must have a role. Each team must have a Team Manager."
      > 
      </TabBar>
    
      <div className="team-content">
        <NewTeam onCreate={history.goBack} />
      </div>
     
    </div>
    </Col>
    </Row>
  );
}

export default NewTeamPage;