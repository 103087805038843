import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import api from '../api';
import { useHistory } from 'react-router-dom';

import UserCard from './UserCard';
import FeedPostAssignment from './FeedPostAssignment';
import Checkbox from './Checkbox';
import PostAssignmentScroller from './PostAssignmentScroller';
import ImageUpload from './ImageUpload';
import Back from './Back';
import LogList from './LogList';
import Button from './Button';
import DeleteButton from './DeleteButton';
import Spinner from './Spinner';

import {ReactComponent as HugeBlankUserSVG} from '../svgs/huge-blank-user.svg';
import {ReactComponent as BigOrangeDeleteSVG} from '../svgs/big-orange-delete.svg';

import UserContext from './UserContext';

import * as Types from 'types';
import * as Consts from '../consts';

import { Col, Row } from "react-bootstrap";

const UserPage: React.FC = () => {
  const history = useHistory();
  const [posts, setPosts] = useState<Types.JoinedPostAssignment[]>([]);
  const [user, setUser] = useState<Types.User | null>(null);
  const [tab, setTab] = useState<'info' | 'posts' | 'activity' | 'permissions' | 'notifications'>('info');
  const [userLoaded, setUserLoaded] = useState(false);
  const [permissions, setPermissions] = useState<Types.Permission[]>([]);
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);
  const [notificationSettings, setNotificationSettings] = useState<Types.NotificationSetting[]>([]);
  const [adminSelected, setAdminSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({ data: { success: false, message: '' } });
  const [responseOn, setResponseOn] = useState(false);

  const me = useContext(UserContext) as Types.User;

  const uParams = useParams() as { userId: string; };

  useEffect(() => {
    api
      .notifications
      .getNotificationSettings()
      .then((notificationSettings: Types.NotificationSetting[]) => {
        setNotificationSettings(notificationSettings);
      })
      .catch((err: any) => {
        console.log(err);
      })
  }, [])

  useEffect(() => {
    if(uParams.userId) {
      api
        .permissions
        .getGlobalPermissions()
        .then((permissions: Types.Permission[]) => {
          setPermissions(permissions);
        })
        .catch((err: any) => {
          console.log(err);
        })
    }
  }, [uParams.userId])

  useEffect(() => {
    if(uParams.userId) {
      api
        .users
        .getUserById(uParams.userId)
        .then((user: Types.User) => {
          setUser(user);
          setUserLoaded(true);
        })
        .catch((err: any) => {
          console.log(err);
        })
    }
  }, [uParams.userId])

  function onNotificationSelection (selected: boolean, notificationSetting: Types.NotificationSetting) {
    if(user) {
      if(selected) {
        setUser({ ...user, notificationSettingIds: [...user.notificationSettingIds, notificationSetting.id] });
      } else {
        setUser({ ...user, notificationSettingIds: user.notificationSettingIds.filter((existingNotificationSettingId: string) => existingNotificationSettingId !== notificationSetting.id) });
      }
    }
  }

  function onPermissionSelection (selected: boolean, permission: Types.Permission) {
    if(user) {
      if(permission.id === 'administrateOrganization') {
        if(selected) {
          setAdminSelected(true);
          setUser({ ...user, permissionIds:  permissions.map(perm => perm.id)});
        } else {
          setAdminSelected(false);
          setUser({ ...user, permissionIds: [] });
        }
      } else if(!adminSelected) {
        if(selected) {
          setUser({ ...user, permissionIds: [ ...user.permissionIds, permission.id ] });
        } else {
          setUser({ ...user, permissionIds: user.permissionIds.filter(permissionId => permission.id !== permissionId) });
        }
      }
    }
  }

  function handleUpload (imageUrl: string) {
    if(user) {
      setUser({ ...user, image: imageUrl });
      api.users.updateUser({ ...user, image: imageUrl });
    }
  }

  function handleDeleteImage () {
    if(user) {
      setUser({ ...user, image: '' });
      api.users.updateUser({ ...user, image: '' });
    }
  }

  function onDeleteUser () {
    if(user) {
      setLoading(true);
      return api.users.deleteUser(user.id)
        .then(() => {
          history.push('/app/users');
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        })
    } else {
      return new Promise((res, rej) => res(false));
    }
  }

  function onUpdateUserPermissions () {
    if(user) {
      setLoading(true);
      
      if(user.permissionIds.length > 0)
      {
	      api.users.updateUser(user)
		.then(() => {
		  setLoading(false);
		})
		.catch(err => {
		  console.log(err);
		  setLoading(false);
		})
      }
      else
      {
	  setResponse({ data: { success: false, message: 'Select atleast one permission' } })
	  setResponseOn(true);
	  setTimeout(() => setResponseOn(false), Consts.responseToastLifetime);
	  setLoading(false);
      }

    }
  }

 function showResponse () {
    if(response) {
      return <div className={`response-box ${responseOn ? 'on' : '' } ${response.data.success ? 'green' : 'red'} flex-column`}>{response.data.message}</div>;
    }
  }

 function onUpdateUser () {
    if(user) {
      setLoading(true);
      api.users.updateUser(user)
        .then(() => {
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        })
    }
  }

  function handleSetPosts (newPosts: Types.JoinedPostAssignment[]) {
    if(tab === 'posts') {
      setPosts(newPosts);
    }
  }

  function showTab () {
    if(user && tab === 'info') {
      return (
        <div className="info tab-content feed_page_section">
	  <div className="pull-right mb-3">
              <DeleteButton 
                onConfirm={onDeleteUser} 
                message={`Are you sure you want to delete ${user.name}?`}
                buttonText="Delete User"
                enabled={!loading}
              />
	  </div>
          <div className="basics">
           
          <Row className="basic input-row">
              <Col className="info_col_l" lg={4} md={6} xs={8}>
              <div className="gray-text">Name</div>
              <div>{user.name}</div>
                </Col>
                <Col className="info_col_r" lg={4} md={6} xs={4}>
                <div className="gray-text">Email</div>
                <div className="text-wrap">{user.email}</div>
                </Col>
              </Row>

          </div>
        </div>
      );
    } else if(user && tab === 'posts') {
      return (
        <PostAssignmentScroller 
          load={(lastSnapshot?: string) => api.postAssignments.getPostAssignmentsForUser(user.id, lastSnapshot)}
          posts={posts}
          setPosts={handleSetPosts}
          render={
            (post: Types.JoinedPostAssignment, key: string) => (
              <FeedPostAssignment post={post} key={post.id}/>
            )
          }
        />
      );
    } else if(user && tab === 'permissions') { 
      return (
        <div className="notifications tab-content">
        <div className="edit-permissions edit-notification-settings mb-4">
          
        <div className='notification_title'>
          <Row className="app_feed_row justify-content-md-center">
<Col className='app_col app_feed_title' lg={10} md={9} xs={6}>
<h2 className="flex-row table-heading">Global Permissions</h2>
</Col>

<Col className='app_col app_feed_btn' lg={2} md={3} xs={6}> <Button color="orange" onConfirm={onUpdateUserPermissions} enabled={!loading} >Update User</Button>
              {/* <DeleteButton 
                onConfirm={onDeleteUser} 
                message={`Are you sure you want to delete ${user.name}?`}
                buttonText="Delete User"
                enabled={!loading}
              /> */}</Col>
</Row>
</div>
           
<div className='notifications_list'>
          {permissions.map(permission => {
            return (
              <Row 
                className="permission notification-setting app_feed_row" 
                key={permission.id}
              >
                <Col lg={10} md={10} xs={10} className="notification_list_l app_col">
                  <h5>{permission.name}</h5>
                  <div className="notification_list_desc">{permission.description}</div>
                </Col>

               
                <Col lg={2} md={2} xs={2} className="notification_list_r app_col">
                      <div className='notification_checkbox' align="right">
                        {permission.id === 'administrateOrganization'
                  ? <Checkbox 
                    idName={permission.id}
                    onChange={(e) => onPermissionSelection(e, permission)}
                    initialChecked={user && user.permissionIds.includes(permission.id)}
                  />
                  : <Checkbox 
                    idName={permission.id}
                    onChange={(e) => onPermissionSelection(e, permission)}
                    initialChecked={user && user.permissionIds.includes(permission.id)}
                    disabled={adminSelected}
                  />
                  
                }
</div>
                    </Col>
              </Row>
            )

          })}
          </div>
        </div>
        </div>
      );
    } else if(user && tab === 'notifications') { 
      return (
        <div className="notifications tab-content">
        <div className="edit-notification-settings mb-4 ">
         
        <div className='notification_title'>
          <Row className="app_feed_row justify-content-md-center">
<Col className='app_col app_feed_title' lg={10} md={9} xs={6}>
<h2 className="flex-row table-heading">Notification Settings</h2>
</Col>

<Col className='app_col app_feed_btn' lg={2} md={3} xs={6}><Button color="orange" onConfirm={onUpdateUser} enabled={!loading} >Update User</Button></Col>
</Row>
</div>

<div className='notifications_list'>
            {notificationSettings.map(notificationSetting => {
              return (
                <Row 
                  className="notification-setting app_feed_row" 
                  key={notificationSetting.id}
                >
                  <Col lg={10} md={10} xs={10} className="notification_list_l app_col">
                    <h5>{notificationSetting.name}</h5>
                    <div className="notification_list_desc">{notificationSetting.description}</div>
                    </Col>

                    <Col lg={2} md={2} xs={2} className="notification_list_r app_col">
                      <div className='notification_checkbox' align="right">
                  <Checkbox 
                    idName={notificationSetting.id}
                    onChange={(selected) => onNotificationSelection(selected, notificationSetting)}
                    initialChecked={user.notificationSettingIds.includes(notificationSetting.id)}
                  />
                  </div>
                  </Col>
                </Row>
              );
            })}
            </div>
        </div>
        </div>
      );
    } else if(user && tab === 'activity') { 
      return (
        <div className="activity tab-content">
          <LogList 
            load={(lastSnapshot: string) => api.logs.getLogsForUser(uParams.userId, lastSnapshot)} 
          />
        </div>
      );
    }
  }

  if(user && userLoaded) {
    return (
      <div className="feed_page_section Sidebar_me user_page_section Sidebar_new-team">
        <Back />
      
        <div className='feed_tabbar_title'>
        <Row className="app_feed_row">
        <Col className="app_col app_feed_title user_title_email text-wrap w-100" lg={12} md={12} xs={12}>
        <h1>{user.name || user.email}</h1>
        </Col>
        </Row>
        
        <Row className="feed_tabbar_desc app_feed_row">
        <Col className="app_col app_feed_title_desc" lg={12} md={12} sm={12}>
        <div className="gray-text">{user ? `This is where you can find all the information about ${user.name || user.email}.` : ''}</div>
        </Col>
        </Row>

        </div>

        <div className='profile_image_sec'>
        <Row className="app_feed_row">
        <Col className='app_col' lg={6} md={6} xs={8}>
        <div className="image-control">
            {me.permissionIds.includes('manageUsers')
              ? <ImageUpload onUpload={handleUpload}>
                  {user.image
                    ? <div className="huge-user-image upload flex-row" style={{ backgroundImage: `url('${user.image}')` }}/>
                    : <HugeBlankUserSVG className="huge-user-image upload" />
                  }
                </ImageUpload>
              : <>{user.image
                ? <div className="huge-user-image non-upload flex-row" style={{ backgroundImage: `url('${user.image}')`, cursor: 'normal' }}/>
                : <HugeBlankUserSVG className="huge-user-image non-upload" style={{ cursor: 'normal' }}/>
                }</>
            }
            {me.permissionIds.includes('manageUsers') && user.image &&
              <BigOrangeDeleteSVG onClick={handleDeleteImage} />
            }
          </div>
       </Col>
        </Row>
      </div>

      
        <div className="content team-details">

        <div className='profile_tab_sec'>
      <ul className="nav nav-tabs" role="tablist">
            <li 
              className={`tab first flex-row ${tab === 'info' ? 'active' : ''}`}>
                <a onClick={() => setTab('info')}>
            General Info</a></li>
            <li 
              className={`tab middle flex-row ${tab === 'posts' ? 'active' : ''}`}>
                <a onClick={() => setTab('posts')}>Posts</a></li>
            {me.permissionIds.includes('manageUsers') &&
              <li 
                className={`tab last flex-row ${tab === 'permissions' ? 'active' : ''}`}>
                  <a onClick={() => setTab('permissions')}>Permissions</a></li>
             }
            {me.permissionIds.includes('manageUsers') &&
              <li 
                className={`tab last flex-row ${tab === 'notifications' ? 'active' : ''}`}>
                  <a onClick={() => setTab('notifications')}>Notifications</a>
                  </li>
             }
            {me.permissionIds.includes('viewLogs') &&
              <li 
                className={`tab last flex-row ${tab === 'activity' ? 'active' : ''}`}>
                  <a onClick={() => setTab('activity')}>Activity</a>
                  </li>
             }
          </ul>
        </div>

          {showTab()}
        </div>
	{showResponse()}
      </div>
    );
  } else {
    return (<div className="page flex-column">
      <Spinner/>
    </div>);
  }
}

export default UserPage;
