import React from 'react';

import {ReactComponent as SpinnerSVG} from '../svgs/spinner.svg';

interface Props {
  size?: 'small';
  fullscreen?: boolean;
}
const Spinner: React.FC<Props> = props => {

  return (
    <div className="loader-section">
	<div className={`loader ${props.fullscreen ? 'fullscreen' : ''}`}>Loading...</div>
    </div>
  );
}

export default Spinner;
