import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view'
import { Switch, Route, withRouter, NavLink } from 'react-router-dom';

import api from '../api';

import * as Types from 'types';

import TabBar from './TabBar';
import Button from './Button';



const AdminPaymentsPage: React.FC = () => {
  const [exceptions, setExceptions] = useState<Types.Exception[]>([]);
  const [filters, setFilters] = useState({});
  const [Organizations, setOrganizations] = useState<Types.Organization[]>([]);
  const [loading, setLoading] = useState(false);

  function getOrganizations() {
    setLoading(false);
    api
      .admin
      .getOrganizations(filters)
      .then(organizations => {       
	 setOrganizations(organizations);
	 setLoading(true);

      })
  }

  function checkOrganization(searchOrganization:any)
  {
	
	if(searchOrganization.trim() != '')
	{
	  setFilters({ ...filters, name: searchOrganization });
	} 
	else {
	    setFilters({});
	}
	
  }

  useEffect(() => {
    getOrganizations();
  }, [])




  return (
    <div className="users page admin-page flex-column">
      <TabBar
        title="Payments"
        explainer="Search Orgnizations"
      >
      </TabBar>

      <div className="input-row flex-row">
        {/* <div className="cellContainer pr-10"><input onChange={e => setFilters({ ...filters, name: e.target.value })} placeholder="Name" /></div> */}
        <div className="cellContainer pr-10" ><input onChange={e => checkOrganization(e.target.value)} placeholder="Organization Name" style={{width: '300px'}} /></div>

	{/*
	<div className="cellContainer pr-10" ><input onChange={e => setFilters({ ...filters, id: e.target.value })} placeholder="ID"/></div>

        <div className="cellContainer pr-10" ><input onChange={e => setFilters({ ...filters, organizationId: e.target.value })} placeholder="Organization ID" />      </div>
	*/}

        <div className="cellContainer ">   <Button color="light-orange" onConfirm={getOrganizations}>Search</Button></div>
      </div>

      <div className="user-list">
        <div className="title flex-row">Results: {Organizations.length}</div>
        <table>
          <thead>
            <tr>
              <th >Name</th>
              <th >Stripe Id</th>
	      <th >Plan</th>
	      {/* <th >Payment Id</th> */}
	      <th >Payment Date</th>
            </tr>
          </thead>
          <tbody>
            {Organizations.map(organization => {
              return <tr key={organization.id}>
                <td >
			<div className="user-name text-wrap pr-10"><NavLink to={`/admin/user/${organization.adminId}`}>{organization.name}</NavLink></div>
		</td>
		<td ><NavLink to={{pathname: `https://dashboard.stripe.com/customers/${organization.stripeId}`}} target="_blank">{organization.stripeId}</NavLink></td>
		<td >{organization.planId}</td>
		{/* <td >{organization.lastPaymentId}</td> */}
		<td >{organization.lastPaymentDate && new Date(organization.lastPaymentDate * 1000).toLocaleString()}</td>
              </tr>
            })
            }
	    {Organizations.length <= 0 && 
		<tr><td colSpan={5} className="text-align-center pt30">No Records Found</td></tr>}	    
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminPaymentsPage;
