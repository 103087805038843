import axios from './axios';
import { db } from '../firebase';
import { identity } from '../helpers';

import * as Types from 'types';

export function getExceptions(): Promise<Types.Exception[]> {
  return db
    .collection('exceptions')
    .orderBy('createdAt', 'desc')
    .get()
    .then(reference => {
      return reference
        .docs
        .map(doc => doc.data())
    })
    .then(data => {
      return identity<Types.Exception[]>(data);
    })
}
