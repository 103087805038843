import React, { useState } from 'react';

import Modal from './Modal';

import {ReactComponent as DeleteSVG} from '../svgs/delete.svg';

interface Props {
  message: string;
  onConfirm: () => Promise<any>
}

const DeleteIcon: React.FC<Props> = props => {
  const [showModal, setShowModal] = useState(false);

  function handleConfirm () {
    props.onConfirm()
      .then(() => {
        setShowModal(false);
      })
      .catch(() => {
        setShowModal(false);
      })
  }

  return (
    <div className="delete-icon flex-column">
      <DeleteSVG onClick={() => setShowModal(true)} style={{ cursor: 'pointer' }}/>
      <Modal 
        show={showModal} 
        closeModal={() => setShowModal(false)}
        title="Confirm Delete"
        onConfirm={handleConfirm}
      >
        {props.message}
      </Modal>
    </div>
  );
}

export default DeleteIcon;
