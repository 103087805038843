import React from 'react';


const PrivacyPolicy: React.FC = () => {

  return (
    <div className="privacy-policy">
      <h3>Terms of Use</h3>
      <small>
        By using the https://brandsocial.com website (“BrandSocial” or "Service"), you agree to be bound by the following Terms of Use ("Terms of Use").
        <br/>
        <br/>
        OnlyWire, LLC, the creator of BrandSocial, reserves the right to change, update and revise its Terms of Use from time to time, at its sole discretion, by posting the most current version of the Terms of Use at https://brandsocial.com/terms-of-use.
        <br/>
        <br/>

        1. The Service is intended for Adult use only. All Users must be 13 years or older to access and use the Service. In accordance with the Federal Children's Online Privacy Protection Act of 1998 (COPPA), BrandSocial will never knowingly solicit nor will it accept personally identifiable information from Users known to be under thirteen (13) years of age.
        <br/>
        <br/>

        2. BrandSocial is not responsible for maintaining the security of your username and password. It is your responsibility to notify BrandSocial if you believe your username or password have been compromised or used in an unauthorized manner. BrandSocial assumes no responsibility, liability or obligations for any loss or damage to resulting from your failure to comply with the security of your username and/or password.
        <br/>
        <br/>

        3. You are responsible for all activity and usage of the Service and for all content created on, posted to or accessed under your username and password.
        <br/>
        <br/>

        4. You agree not to share your username and password with other people.
        <br/>
        <br/>

        5. You must provide a name, valid email address and other information necessary to register with the Service. You agree to provide true and accurate information to register with the Service. You agree not to impersonate any person or entity or a false identity or attempt to manipulate identifiers to mislead or disguise the origin of any information used to access the Services. BrandSocial reserves the right to terminate your use of the Services upon the discovery that the information you provided is not complete or accurate.
        <br/>
        <br/>

        6. You are responsible for complying with all applicable local, state, national and foreign laws relating to your use of the Service. You may not violate any applicable law or regulation; post or transmit any materials that violate any applicable local law.
        <br/>
        <br/>

        7. BrandSocial may modify, suspend or discontinue this Service at any time, for any reason, at its sole discretion. BrandSocial reserves the right to refuse service to anyone for any reason at any time.
        <br/>
        <br/>

        8. If you cancel or terminate your account with BrandSocial, all of your data and information will be scheduled for deletion from the Service.
        <br/>
        <br/>

        9. BrandSocial claims no ownership, responsibility or intellectual property rights over any of the content or materials you provide to the Service.
        <br/>
        <br/>

        <h1>General Conditions of Use</h1>
        <br/>
        <br/>
        1. You agree not to upload, post, email or transmit otherwise any other unsolicited email including "spam" to BrandSocial or any Users of the Service. This practice is followed in order to ensure BrandSocial's compliance with the terms, provisions, rules and conditions of social networks and other service providers such as Facebook, Twitter, Pinterest and LinkedIn.
        <br/>
        <br/>

        2. You agree not to upload, post, email or transmit otherwise any material that contains software viruses, trojan horses, worms, files or programs intended to interrupt, destroy, deface or limit the use, access or functionality of the Service.
        <br/>
        <br/>

        3. You agree not to upload, post, email or transmit otherwise any material that is illegal, pornographic, abusive or obscene. BrandSocial, in its sole discretion, may remove content and block user access to any content that it determines to be illegal, threatening, pornographic, abusive, obscene or in violation of another party's intellectual property.
        <br/>
        <br/>

        4. BrandSocial’s software technology is protected by U.S. patent numbers 8,161,102 and 8,359,352. You agree not to "reverse engineer," change, modify, hack, make derivative works, exploit, or crack any portion of the Service for the purposes of storing, licensing, sublicensing, selling, reselling, copying, duplicating, reproducing, distributing or making available to any third party.
        <br/>
        <br/>

        5. You agree not to "crawl" or "spider" or release any automated software or service to obtain information stored, saved or contained on any web page as part of the Service.
        <br/>
        <br/>

        6. You acknowledge, understand and agree that BrandSocial cannot guarantee the security of your data while it is being transmitted over the Internet and through servers that are out of our control. We strive to protect your personal information but BrandSocial cannot ensure or warrant the security of any information you transmit to our website or services. Any data transmissions you make over the internet are done so at your own risk. Once we receive the data transmission, we make our best efforts to ensure its security and privacy on our systems.
        <br/>
        <br/>

        7. You acknowledge, understand and agree that BrandSocial provides the Service to you "As Is" and "As Available" without warranty or condition of any kind, either express or implied, including, but not limited to, the implied warranties of availability, accuracy, reliability, timeliness, security completeness or non-infringement. BrandSocial also makes no warranty that the Service will meet the user's requirements.
        <br/>
        <br/>

        8. BrandSocial reserves the right to limit the file download and/or bandwidth capacity of any or all sites in its sole discretion if it deems such limitation to be in the best interests of the operating performance across all sites. In addition, BrandSocial reserves the right to limit the number of emails transmitted from its servers for any individual site or group of related sites on a daily basis at its sole discretion.
        <br/>
        <br/>

        9. You agree to defend, indemnify, and hold harmless BrandSocial, its officers, directors, employees and its agents from and against any claims, actions or demands, including without limitation reasonable legal and accounting fees, made by any third party due to or resulting from your use of the Service or your violation of this Terms of Use.
        <br/>
        <br/>

        10. BrandSocial respects the privacy of its Users. Please read our Privacy Statement at https://www.brandsocial.com/privacy-policy, which forms part of these Terms of Use.
        <br/>
        <br/>

        11. By agreeing to the Terms of Use, you hereby agree that any legal action brought against you for violation of the Terms of Use shall be subject to Illinois Law in the United States of America and shall be subject to jurisdiction and venue in Cook County, Illinois.
        <br/>
        <br/>

        12. In the event legal action is brought against you for violating the Terms of Use, you agree to waive your rights to seek a bond with respect to any temporary restraining order or injunctive relief that may be granted to BrandSocial.
      </small>
    </div>
  );
}

export default PrivacyPolicy;
