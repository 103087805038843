import axios from './axios';
import { db } from '../firebase';
import { identity } from '../helpers';

import * as Types from 'types';

export function getResultsForNetwork(networkId: string): Promise<Types.Result[]> {
  return db
    .collection('results')
    .where('networkId', '==', networkId)
    .get()
    .then(reference => {
      return reference
        .docs.map(doc => doc.data())
    })
    .then(data => {
      return identity<Types.Result[]>(data);
    })
}

export function getResultById(resultId: string): Promise<Types.Result> {
  return db
    .collection('results')
    .where('id', '==', resultId)
    .get()
    .then(reference => {
      return reference
        .docs[0]
        .data();
    })
    .then(data => {
      return identity<Types.Result>(data);
    })
}
