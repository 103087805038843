import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import DOMPurify from 'dompurify';

import * as Types from 'types';
import * as Consts from '../consts';

import Network from './Network';
import Profile from './Profile';
import api from '../api';
import Checkbox from './Checkbox';
import TripleCheckbox from './TripleCheckbox';
import Spinner from './Spinner';
import Button from './Button';

const PostAuthPage: React.FC = match => {
  const [networks, setNetworks] = useState<Types.Network[]>([]);
  const [showAuth, setShowAuth] = useState(false);
  const [newProfiles, setNewProfiles] = useState<Types.Profile[]>([]);
  const [selectedProfiles, setSelectedProfiles] = useState<Types.Profile[]>([]);
  const [loading, setLoading] = useState(false);
  const [networksLoading, setNetworksLoading] = useState(true);
  const [profiles, setProfiles] = useState<Types.Profile[]>([]);
  const [profilesLoaded, setProfilesLoaded] = useState(false);
  const [error, setError] = useState('');
  const [team, setTeam] = useState<Types.Team | null>(null);
  const [filter, setFilter] = useState('');

  const uParams = useParams() as { authSlug: string; };
  const authSlug = uParams.authSlug;

  const search = window.location.search;
  const qParams = new URLSearchParams(search);
  const oauthToken = qParams.get('oauth_token');
  const oauthVerifier = qParams.get('oauth_verifier');
  const code = qParams.get('code');

  const [response, setResponse] = useState({ data: { success: false, message: '' } });
  const [responseOn, setResponseOn] = useState(false);

  const history = useHistory();

  useEffect(() => {
    api
      .networks
      .getNetworks()
      .then(networks => {
        setNetworks(networks)
        setNetworksLoading(false)
      })
  }, [])

  useEffect(() => {
    if(oauthToken && oauthVerifier) {
      setLoading(true);
      setShowAuth(true);
      api
        .profiles
        .getMetadata(authSlug, oauthToken, oauthVerifier)
        .then(res => {
          setTeam(res.team);
          if(res.team) {
            api
              .profiles
              .getProfilesForTeam(res.team.id as string)
              .then((teamProfiles: Types.Profile[]) => {
                setProfiles(teamProfiles);
                const filteredProfiles = res.profiles.filter(newProfile => {
                  return !teamProfiles.find(oldProfile => {
                    return oldProfile.siteId === newProfile.siteId;
                  })
                });
                setNewProfiles(filteredProfiles);
                setSelectedProfiles(filteredProfiles);
                if(filteredProfiles.length === 0) {
                  setError(`There are no Social Profiles to display. It's possible that we found none, or that the only Social Profiles that we found are already connected to your BrandSocial account.`);
                }
                setProfilesLoaded(true);
                setLoading(false);
              })
              .catch((err: any) => {
                console.log(err);
              })
          } else {
            api
              .profiles
              .getProfiles()
              .then(profiles => {
                setProfiles(profiles)
                const filteredProfiles = res.profiles.filter(newProfile => {
                  return !profiles.find(oldProfile => {
                    return oldProfile.siteId === newProfile.siteId;
                  })
                });
                setNewProfiles(filteredProfiles);
                setSelectedProfiles(filteredProfiles);
                if(filteredProfiles.length === 0) {
                  setError(`There are no Social Profiles to display. It's possible that we found none, or that the only Social Profiles that we found are already connected to your BrandSocial account.`);
                }
                setProfilesLoaded(true);
                setLoading(false);
              })
          }
        })
        .catch(err => {
          setLoading(false);
          setError(`${err.data.message} ${err.data.data}.`);
          console.log(err);
        })
    } else if(code) {
      setShowAuth(true);
      api
        .profiles
        .getMetadataOAuth2(authSlug, code)
        .then(res => {
          setTeam(res.team);
          if(res.team) {
            api
              .profiles
              .getProfilesForTeam(res.team.id as string)
              .then((teamProfiles: Types.Profile[]) => {
                setProfiles(teamProfiles);
                const filteredProfiles = res.profiles.filter(newProfile => {
                  return !teamProfiles.find(oldProfile => {
                    return oldProfile.siteId === newProfile.siteId;
                  })
                });
                setNewProfiles(filteredProfiles);
                setSelectedProfiles(filteredProfiles);
                if(filteredProfiles.length === 0) {
                  setError(`There are no Social Profiles to display. It's possible that we found none, or that the only Social Profiles that we found are already connected to your BrandSocial account.`);
                }
                setLoading(false);
                setProfilesLoaded(true);
              })
              .catch((err: any) => {
                console.log(err);
              })
          } else {
            api
              .profiles
              .getProfiles()
              .then(profiles => {
                setProfiles(profiles)
                const filteredProfiles = res.profiles.filter(newProfile => {
                  return !profiles.find(oldProfile => {
                    return oldProfile.siteId === newProfile.siteId;
                  })
                });
                setNewProfiles(filteredProfiles);
                setSelectedProfiles(filteredProfiles);
                if(filteredProfiles.length === 0) {

		  if(authSlug === 'pinterest')
		    setError(`There are no boards/profile feeds were found for this network to display. It's possible that we found none, or that the only Social Profiles that we found are already connected to your BrandSocial account.`);
		  else
	            setError(`There are no Social Profiles to display. It's possible that we found none, or that the only Social Profiles that we found are already connected to your BrandSocial account.`);
                }
                setLoading(false);
                setProfilesLoaded(true);
              })
          }
        })
        .catch(err => {
          setLoading(false);
          setError(`${err.data.message} ${err.data.data}.`);
          console.log(err);
        })
    } else {
      setLoading(false);
      history.push('/app/networks');
    }
  }, [
    authSlug,
    code,
    oauthToken,
    oauthVerifier,
  ])

  function handleConfirmProfiles () {

    if(selectedProfiles.length > 0)
    {
	    setLoading(true);
	    api
	      .profiles
	      .addProfile(selectedProfiles.map(selectedProfile => selectedProfile.name as string))
	      .then(() => {
		if(team) {
		  history.push(`/app/team/${team.id}`);
		} else {
		  history.push('/app/networks');
		}
		setLoading(false);
	      })
   }
   else
   {
	  setResponse({ data: { success: false, message: 'Select the profiles' } })
	  setResponseOn(true);
	  setTimeout(() => setResponseOn(false), Consts.responseToastLifetime);	     
   }
  }

  function onSelection (selected: boolean, newSelectedProfile: Types.Profile) {
    if(selected) {
      setSelectedProfiles([...selectedProfiles, newSelectedProfile]);
    } else {
      setSelectedProfiles(
        selectedProfiles.filter(
          (selectedProfile: Types.Profile) => selectedProfile !== newSelectedProfile
        )
      );
    }
  }

  function findNetwork() {
    return networks.find(network => network.slug === authSlug);
  }

  function createMarkup(network: Types.Network) {
    return { __html: DOMPurify.sanitize(network.icon) };
  }

  function onSelectAll(selected: number) {
    if(selected === 0) {
      setSelectedProfiles([]);
    } else if (selected === 1) {
      setSelectedProfiles(newProfiles) 
    }
  }

  function getSelectAllStatus() {
    if(selectedProfiles.length === newProfiles.length) {
      return 1;
    } else if (selectedProfiles.length === 0) {
      return 0;
    } else {
      return 2;
    }
  }

  function showResponse () {
    if(response) {
      return <div className={`response-box ${responseOn ? 'on' : '' } ${response.data.success ? 'green' : 'red'} flex-column`}>{response.data.message}</div>;
    }
  }

  if(networks.length > 0 && !!authSlug && showAuth && !!newProfiles && findNetwork() && !loading && !error && !networksLoading && profilesLoaded) {
    return (
      <div className="post-auth page flex-column">
        <div className="heading flex-row">
          <div className="network-icon flex-row" dangerouslySetInnerHTML={createMarkup(findNetwork() as Types.Network)} />
            <h1>Select {(findNetwork() as Types.Network).name} Profiles{team && ` for ${team.name}`}</h1>
          </div>
          <div className="controls flex-row">
            <TripleCheckbox 
              idName="select-all-profiles"
              initialChecked={getSelectAllStatus()} 
              onChange={(selected: number) => onSelectAll(selected)} 
            ><div className="select_all">Select All</div></TripleCheckbox>
            <input type="search" className="filter" placeholder="Search" onChange={e => setFilter(e.target.value)} />
          </div>
          {
            newProfiles.map(profile => {
              if((profile.name as string).toLowerCase().includes(filter.toLowerCase())) {
                return (
                  <div className="profile flex-row">
                    <Checkbox
                      onChange={(selected) => onSelection(selected, profile)} 
                      initialChecked={selectedProfiles.includes(profile)} 
                      idName={profile.siteId}
                    >
                      <span
                        style={{marginRight: '8px'}}
                      >{profile.name}</span>
                    </Checkbox>
                  </div>
                )
              }
            })
          }
          <div className="flex-row footer">
            <Button color="orange" onConfirm={handleConfirmProfiles}>Connect {selectedProfiles.length > 0 && selectedProfiles.length + ' '}Profiles</Button>
          </div>

	  {showResponse()}
      </div>
    );
  } else if(error && !networksLoading) {
    return (
      <div className="post-auth page flex-column">
        <div className="heading flex-row">
          <div className="network-icon flex-row" dangerouslySetInnerHTML={createMarkup(findNetwork() as Types.Network)} />
          <h1>Select {(findNetwork() as Types.Network).name} Profiles{team && ` to ${team.name}`}</h1>
        </div>
        <div className="error flex-row">{error}</div>
      </div>
    );
  } else {
    return (<div className="post-auth page flex-column">
      <Spinner/>
    </div>);
  }
}

export default PostAuthPage;
