import React from 'react';
import Button from './Button';


const ContactPage: React.FC = () => {

  return (
    <div className="wrapper">
    <div className="container flex-column">
      <h2 className="heading">
        Contact OnlyWire
      </h2>

      <Button
        color="orange"
        style={{width: "240px", marginBottom: "25px"}}
      >
      Chat with us
      </Button>
      <strong>Customer Support Hours of Operation: </strong><small>Mon-Fri: 8:00am - 5:30pm PST</small>
    </div>
  </div>
  );
}

export default ContactPage;
