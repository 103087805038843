import React, { useState, useEffect, useContext } from 'react';

import Profile from './Profile';
import Checkbox from './Checkbox';
import ExpandingProfileList from './ExpandingProfileList';
import ExpandingTeamList from './ExpandingTeamList';
import ExpandingTeamUsersList from './ExpandingTeamUsersList';
import UserImage from './UserImage';
import Button from './Button';

import UserContext from './UserContext';

import { Col, Row } from 'react-bootstrap';

import * as Types from 'types';

import {ReactComponent as CloseSVG} from '../svgs/close.svg';

export interface Props {
  show: boolean;
  closeModal: () => void;
  proceed: () => void;
  profiles: Types.Profile[];
  networks: Types.Network[];
  onProfileSelection: (selected: boolean, profile: Types.Profile) => void;
  onUserSelection: (selected: boolean, user: Types.User) => void;
  onTeamUserSelection: (selected: boolean, user: Types.User, teamId: string) => void;
  onNetworkSelection: (selected: number, network: Types.Network) => void;
  onSelectAllTeam: (selected: number, network: Types.JoinedTeam) => void;
  onSelectAllOrgUsers: (selected: boolean) => void;
  post: Types.Post;
  organizationUsers: Types.User[];
  selectedUsers: Types.User[];
  selectedProfiles: Types.Profile[];
  teams: Types.JoinedTeam[];
  isApproval: boolean;
}

const ShareToModal = ({
  show, 
  closeModal,
  proceed,
  profiles,
  networks,
  onProfileSelection,
  onUserSelection,
  onTeamUserSelection,
  onNetworkSelection,
  onSelectAllTeam,
  onSelectAllOrgUsers,
  post,
  organizationUsers,
  selectedUsers,
  selectedProfiles,
  teams,
  isApproval
}: Props)=> {
  const [tab, setTab] = useState<'teams' | 'personal' | 'organization'>('personal');
  const [searchOrg, setSearchOrg] = useState('');

  const user = useContext(UserContext) as Types.User;

  useEffect (() => {
    if(profiles.length > 0) {
      setTab('personal');
    } else if(user.permissionIds.includes('sharePosts')) {
      setTab('organization');
    } else if (teams.length > 0 && teams.find(team => 
      (team.users.find(teamUser => teamUser.id === user.id) && (team.users.length > 1 || team.profiles.length > 0))
    )) { 
      setTab('teams');
    }
  }, [])

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if(e.key === 'Escape') {
      closeModal();
    }
  }

  function usersList () {
    if(organizationUsers.length > 1) {
      return (<div className="users popup_org_users">
        {
          organizationUsers.map((organizationUser: Types.User) => {
            if(user.id !== organizationUser.id && organizationUser.name.toLowerCase().includes(searchOrg.toLowerCase())) {
              return <div className="user popup_org_user">
                <Checkbox 
                  key={organizationUser.id} 
                  onChange={(selected) => onUserSelection(selected, organizationUser)}
                  initialChecked={selectedUsers.includes(organizationUser)}
                  idName={organizationUser.id}
                >
                  <p class="flex-row">
			<div><UserImage user={organizationUser} size="large" /></div>
			<div class="text-wrap">{organizationUser.name || organizationUser.email}</div>
		</p>
                </Checkbox>
              </div>
            }
          })
        }
      </div>)
    }
  }

  function networksList () {
    if(networks.length > 0) {
      return networks.map((network: Types.Network) => {
        if(!!profiles.find(profile => profile.networkId === network.slug)) {
          return (
            <ExpandingProfileList
              network={network}
              profiles={profiles}
              selectedProfiles={selectedProfiles}
              onProfileSelection={onProfileSelection}
              onNetworkSelection={onNetworkSelection}
            />
          )
        }
      })
    }
  }

  function teamProfilesList () {
    if(validatedTeams().length > 0) {
      return validatedTeams().map((team: Types.JoinedTeam) => {
        return (
          <ExpandingTeamList
            team={{ ...team, users: team.users.filter(teamUser => {
              return teamUser.id !== user.id;
            })}}
            networks={networks}
            selectedProfiles={selectedProfiles}
            onProfileSelection={onProfileSelection}
            onSelectAllTeam={onSelectAllTeam}
            selectedUsers={selectedUsers}
            onTeamUserSelection={onTeamUserSelection}
          />
        )
      }).filter(displayTeam => !!displayTeam)

    } else {
      return <div className="error share-to-error">You are currently not a member of any Teams with destinations.</div>;
    }
  }

	function validatedTeams () {
		return teams.filter(team => {
			const myTeamUser = team.users.find(teamUser => teamUser.id === user.id);
      return (
        team.teamUsers.length > 0 
				&& !!myTeamUser 
				&& !!myTeamUser.teamRole
				&& myTeamUser.teamRole.permissionIds.includes('sharePosts')
				&& team.users.find(teamUser => teamUser.id !== user.id)
      ) || (
        team.profiles.length > 0 
				&& !!myTeamUser 
				&& !!myTeamUser.teamRole
				&& myTeamUser.teamRole.permissionIds.includes('draftPosts')
      );
		})
	}

  function showTab () {
    if(tab === 'teams') {
      return (
        <div className="popup_list teams">
          {teamProfilesList()}
        </div>
      );
    } else if(tab ==='personal') {
      if(profiles.length > 0) {
        return (
          <div className="popup_list personal">
            {networksList()}
          </div>
        );
      } else {
        return <div className="popup_list">
          <div className="error share-to-error">You have not yet connected any Personal Profiles.<Button to='/app/networks' color="light-orange">Connect Personal Profiles</Button></div>
          </div>;
      }
    } else if(tab ==='organization') {
      return (
        <div className="popup_list organization">
          <div className="tools">
            <Row className='popup_org_tool_row'>

          <Col className='popup_org_tool_l' lg={4} md={5} xs={5}>
          <Checkbox idName="select-all-org-users" onChange={value => onSelectAllOrgUsers(value)}> Select All</Checkbox>
            </Col>

            <Col className='popup_org_tool_r' lg={8} md={7} xs={7}>
            <input type="search" className="filter" placeholder="Search" onChange={e => setSearchOrg(e.target.value)} />
            </Col>
            </Row>
          </div>
          {user.permissionIds.includes('sharePosts') && usersList()}
        </div>
      );
    }
  }

  if(show) {
    return (
      <div className="new_post_modal modal-container" onKeyUp={handleKeyUp}>
        <div onClick={closeModal} className="modal-background"></div>
        <div className="share-to modal">
          <div className="modal_header">
            <ul className="nav nav-tabs" role="tablist">
              <li 
                className={`tab first ${tab === 'personal' ? 'active' : ''}`}  
              ><a onClick={() => setTab('personal')} role="tab" data-toggle="tab">Personal</a></li>
              {!isApproval
                && <li 
                  className={`tab last ${tab === 'teams' ? 'active' : ''}`}
                ><a onClick={() => setTab('teams')} role="tab" data-toggle="tab">Teams</a></li>
              }
              {organizationUsers.length > 1 && user.permissionIds.includes('sharePosts') && !isApproval &&
                <li 
                  className={`tab first ${tab === 'organization' ? 'active' : ''}`}
                ><a onClick={() => setTab('organization')} role="tab" data-toggle="tab">Organization</a></li>
               }
            </ul>
            <div onClick={closeModal} className="popup_close">
              <CloseSVG />
            </div>
          </div>
          <div className="post_popup_content">
            {showTab()}
          </div>
          <div className="share_footer">
            <Row className='share_popup_footer'>
              <Col className='popup_footer_l' lg={6} md={6} xs={6}>
              <div className="number-selected">{selectedProfiles.length + selectedUsers.length} Selected</div>
              </Col>

              <Col className='popup_footer_r' lg={6} md={6} xs={6}>
              <Button onConfirm={proceed} color="orange">Next</Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default ShareToModal;
