import React, { useState, useEffect, useRef } from 'react';

import Log from './Log';

import * as Types from 'types';

import { Table } from 'react-bootstrap';

interface Props {
  load: (lastSnapshot: any) => Promise<Types.Log[]>;
}

const LogList: React.FC<Props> = props => {
  const [logs, setLogs] = useState<Types.Log[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastSnapshot, setLastSnapshot] = useState<any>('');
  const firstPromiseRef = useRef<Promise<any> | null>(null);

  useEffect(() => {
    setLogs([]);
    setHasMore(true);
    setLoading(false);
    if(logs.length === 0 && hasMore) {
      setLoading(true);
      firstPromiseRef.current = props
        .load(null)
        .then(newLogs => {
          setLogs(logs.concat(newLogs));
          if(newLogs.length > 0) {
            setLastSnapshot(newLogs[newLogs.length - 1].id);

          } else {
            setHasMore(false);
          }
          setLoading(false);
        })
    }
    return function cleanup() {
      setLogs([]);
      if(firstPromiseRef.current) {
      //@ts-ignore
        firstPromiseRef.current.cancel();
      }
    }
  }, [props.load])

  useEffect(() => {
    window.onscroll = () => {
      if (loading || !hasMore) return;

      if (
        window.innerHeight + document.documentElement.scrollTop
        === document.documentElement.offsetHeight
      ) {
        setLoading(true);
        props.load(lastSnapshot)
          .then(recLogs => {
            const newLogs = logs.concat(recLogs);
            setLogs(newLogs);
            if(recLogs.length > 0) {
              setLastSnapshot(recLogs[recLogs.length - 1].id);
            } else {
              setHasMore(false);
            }
            setLoading(false);
          })
      }
    }
  }, [loading, lastSnapshot]);


  return (
    <div className='table_responsive'><Table className="log-list" responsive="lg">
      <thead>
      <tr>
        <th>
          Date
        </th>
        <th>
          Time
        </th>
        <th>
          Event
        </th>
        <th>
          Location
        </th>
        <th>
          IP Address
        </th>
      </tr>
      </thead>
      
      <tbody>
      {logs.map(log => {
        return <Log log={log} />
      })}
      {loading && <tr><td>Getting more logs...</td></tr>}
      {!loading && !hasMore && <tr><td>End of logs</td></tr>}
      </tbody>
      </Table>
      </div>
  );
}

export default LogList;
