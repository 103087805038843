import axios from './axios';
import { authorizedHeader } from '../helpers';

import * as Types from 'types';

export function getAuthy(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.get(`/authy`, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function send(email: string): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.post('/authy/send', { email: email }, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function enroll(id: string): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.post('/authy/enroll', { id: id }, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function signOut(): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.post('/authy/signout', {}, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function check(email: string, verificationCode: string): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.post('/authy/check', { email: email,  verificationCode: verificationCode }, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function sendWithId(id: string): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.post('/authy/send-id', { id: id }, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function checkWithId(id: string, verificationCode: string): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.post('/authy/check-id', { id: id,  verificationCode: verificationCode }, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function create(phone: string): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.post('/authy', { phone: phone }, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

