import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Types from 'types';
import * as Consts from '../consts';

import UserImage from './UserImage';

import UserContext from './UserContext';

import {ReactComponent as BlankUserSVG} from '../svgs/blank-user.svg';

interface Props {
  user: Types.User;
  size?: string;
  children?: React.ReactNode;
  noLink?: boolean;
}
const UserCard: React.FC<Props> = props => {
  const [imageError, setImageError] = useState(false);

  const me = useContext(UserContext) as Types.User;

  const history = useHistory();

  function handleClick () {
    if(props.user.id === me.id) {
      history.push(`/app/me`)
    } else {
      history.push(`/app/user/${props.user.id}`)
    }
  }

  function userCard () {
    return (
      <>
        <UserImage user={props.user} size="large" />
        {props.noLink
          ? <div className="user-name text-wrap" style={{cursor: 'unset'}}>{props.user.name || props.user.email}</div>
          : <div className="user-name text-wrap" onClick={handleClick}>{props.user.name || props.user.email}</div>
        }
      </>
    );
  }

  return (
    <div className={`user-card user_card_item black-link flex-row ${props.size || ''}`} >
      {userCard()}
      {props.children}
    </div>
  );
}

export default UserCard;
