import axios from './axios';
import { authorizedHeader } from '../helpers';
import { db } from '../firebase';
import { identity } from '../helpers';
import { getUser } from './users';

import * as Types from 'types';

export function getRoles(): Promise<Types.Role[]> {
  return getUser()
    .then(user => {
    return db
      .collection('roles')
      .where('organizationId', 'in', [user.organizationId, ''])
      .where('deleted', '==', false)
      .orderBy('createdAt', 'asc')
      .get()
      .then(reference => {
        return reference
          .docs.map(doc => doc.data());
      })
      .then(data => {
        return identity<Types.Role[]>(data);
      })
    })
}

export function getRoleById (roleId: string): Promise<Types.Role> {
  return db
    .collection('roles')
    .where('id', '==', roleId)
    .where('deleted', '==', false)
    .get()
    .then(reference => {
      return reference
        .docs[0]
        .data()
    })
    .then(data => {
      return identity<Types.User>(data);
    })
}

export function createRole (role: Types.Role): Promise<Types.Role> {
  return new Promise(async (resolve, reject) => {
    axios.post('/roles', role, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function updateRole (role: Types.Role): Promise<Types.Role> {
  return new Promise(async (resolve, reject) => {
    axios.put('/roles', role, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function deleteRole (role: Types.Role): Promise<string> {
  return new Promise(async (resolve, reject) => {
    axios.delete(`/roles/${role.id}`, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}
