import React, { useEffect, useState, useRef } from 'react';
import FeedPost from './FeedPost';

import * as Types from 'types';

interface Props {
  load: (lastSnapshot?: string) => Promise<Types.JoinedPost[]>;
  render:  (post: Types.JoinedPost, key: string) => React.ReactNode;
}

const PostScroller: React.FC<Props> = props => {
  const [posts, setPosts] = useState<Types.JoinedPost[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastSnapshot, setLastSnapshot] = useState<any>('');
  const firstPromiseRef = useRef<Promise<any> | null>(null);

  useEffect(() => {
    setPosts([]);
    setHasMore(true);
    setLoading(false);
    if(posts.length === 0 && hasMore) {
      setLoading(true);
      firstPromiseRef.current = props
        .load()
        .then(newPosts => {
          setPosts(posts.concat(newPosts));
          if(newPosts.length > 0) {
            setLastSnapshot(newPosts[newPosts.length - 1].id);
          } else {
            setHasMore(false);
          }
          setLoading(false);
        })
    }
    return function cleanup() {
      setPosts([]);
      /*
      if(firstPromiseRef.current) {
      //@ts-ignore
        firstPromiseRef.current.cancel();
      }
       */
    }
  }, [props.load])

  useEffect(() => {
    window.onscroll = () => {
      if (loading || !hasMore) return;

      if (
        window.innerHeight + document.documentElement.scrollTop
        === document.documentElement.offsetHeight
      ) {
        setLoading(true);
        props.load(lastSnapshot)
          .then(recPosts => {
            const newPosts = posts.concat(recPosts);
            setPosts(newPosts);
            if(recPosts.length > 0) {
              setLastSnapshot(recPosts[recPosts.length - 1].id);
            } else {
              setHasMore(false);
            }
            setLoading(false);
          })
      }
    }
  }, [loading, lastSnapshot]);

  if(posts.length > 0) {
    return (
      <>{
          posts
          .map((post: Types.JoinedPost) => props.render(post, post.id as string))
        }
        { loading && <div>Getting More Posts...</div> }
      </>
    )
  } else if(posts.length === 0 && !loading) {
    return (<div>There are no posts yet.</div>);
  } else {
    return (<div>Getting More Posts...</div>);
  }
}

export default PostScroller;
