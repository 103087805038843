import axios from './axios';
import { db } from '../firebase';
import { authorizedHeader } from '../helpers';
import { getResultById } from './results';
import { identity } from '../helpers';
import { getProfileById } from './profiles';
import { getUserById } from './users';

import * as Types from 'types';

export function getWork(): Promise<Types.Work[]> {
  return new Promise(async (resolve, reject) => {
    axios.get('/work', await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function createWork(workData: Types.Work): Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    axios.post('/work', workData, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
    });
}

export function getWorkForProfile(profileId: string): Promise<Types.JoinedWork[]> {
  return db
    .collection('work')
    .where('profileId', '==', profileId)
    .where('deleted', '==', false)
    .get()
    .then(reference => {
      return reference
        .docs
        .map(doc => doc.data());
    })
    .then(data => {
      return Promise.all(data.map(workItem => {
        if(workItem.resultId) {
          return getResultById(workItem.resultId)
            .then(result => {
              return { ...workItem, result: result };
            })
        } else {
          return new Promise((resolve, reject) => {
            resolve(workItem);
          });
        }
      }))
    .then((workWithResult: any) => {
      return Promise.all(workWithResult.map((workItem: Partial<Types.JoinedWork>) => {
        return getProfileById(workItem.profileId as string)
          .then(profile => {
            return { ...workItem, profile: profile };
          })
      }))
    })
    .then(joinedWork => {
      return identity<Types.JoinedWork[]>(joinedWork);
    })
  })
}

