import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view'
import { Switch, Route, withRouter, NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { addResponseHandler } from '../api/axios';
import api from '../api';
import { useMediaQuery } from 'react-responsive';
import { db, getGoogleId, firebaseAuth } from '../firebase';
import AdminSidebar from './AdminSidebar';
import Spinner from './Spinner';
import AdminExceptionsPage from './AdminExceptionsPage';
import AdminResultsPage from './AdminResultsPage';
import AdminSupportPage from './AdminSupportPage';
import AdminPaymentsPage from './AdminPaymentsPage';
import AdminUserPage from './AdminUserPage';
import AdminNetworkProfilePage from './AdminNetworkProfilePage';

import * as Types from 'types';

const AdminInterior: React.FC = () => {
  const history = useHistory();
  const [response, setResponse] = useState<null | { data: { message: string, success: boolean } }>(null);
  const [responseOn, setResponseOn] = useState(false);
  const [pathname, setPathname] = useState(window.location.pathname);
  const isMobile = useMediaQuery({
    query: '(max-device-width: 1000px)'
  })

  
  function handleClick () {
    if(pathname !== window.location.pathname) {
      window.scrollTo(0, 0);
    }
    setPathname(window.location.pathname);
  }
  
  function showResponse () {
    if(response) {
      return <div className={`response-box ${responseOn ? 'on' : '' } ${response.data.success ? 'green' : 'red'} flex-column`}>{response.data.message}</div>;
    }
  }


  return (
    
    <div className="app-interior" onClick={handleClick}>
    
      <AdminSidebar />

    {showResponse()}
    <div className={`main false`}>
      <Switch>
        <Route exact path="/admin/exceptions" component={AdminExceptionsPage}/>
        <Route exact path="/admin/support" component={AdminSupportPage}/>
        <Route exact path="/admin/payments" component={AdminPaymentsPage}/>
        <Route exact path="/admin/results" component={AdminResultsPage}/>
        <Route exact path="/admin/user/:userId" component={AdminUserPage}/>
	<Route exact path="/admin/networkProfile" component={AdminNetworkProfilePage}/>
      </Switch>
    </div>
    </div>
  );
}

export default AdminInterior;
