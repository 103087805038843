import React, { useEffect, useState } from 'react';

import {ReactComponent as ToggleOffSmallSVG} from '../svgs/toggle-off-small.svg';
import {ReactComponent as ToggleOnSmallSVG} from '../svgs/toggle-on-small.svg';

export interface Props {
  children?: React.ReactNode;
  onChange: (selected: boolean) => void;
  initialOn?: boolean;
  idName: string;
  disabled?: boolean;
}


const Toggle = ({
  children,
  onChange,
  initialOn,
  idName,
  disabled
}: Props) => {
  const [on, setOn] = useState(initialOn);

  useEffect(() => {
    if(initialOn !== undefined) {
      setOn(initialOn);
    }
  }, [initialOn])

  return (
    <span className="checkbox" onClick={() => {
      onChange(!on)
      setOn(!on)
    }}>
      { on
        ? (<ToggleOnSmallSVG />)
        : (<ToggleOffSmallSVG />)
      }
      {children}
    </span>
  );
}

export default Toggle;
