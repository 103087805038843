import firebase from 'firebase/app';
import * as ui from 'firebaseui';
import 'firebase/auth';
import 'firebase/firestore';
import api from './api';

import * as Types from 'types';
import * as Consts from './consts';


const firebaseConfig = {
  apiKey: "AIzaSyDDiJQWWVHFOWw96xtabNDh2RxoVJpe3Ac",
  authDomain: "brandsocial.com",
  databaseURL: "https://brandsocial.firebaseio.com",
  projectId: "brandsocial",
  storageBucket: "brandsocial.appspot.com",
  messagingSenderId: "932612593913",
  appId: "1:932612593913:web:e03f0657005da513ceaa28"
};

export const f = firebase;
export const fb = firebase.initializeApp(firebaseConfig);
fb.firestore().settings({ experimentalForceLongPolling: true });

export const firebaseAuth = firebase.auth();

export const db = fb.firestore();
export const credential = firebase.auth.EmailAuthProvider.credential;

export function getUiConfig (
  restart: () => void,
  setLoading: (loading: boolean) => void, 
  setErrorMessage: (errorMessage: string) => void, 
  setIs2fa: (loading: boolean) => void, 
  setIsVerifyEmail: (isVerifyEmail: boolean) => void, 
  setEmail: (email: string) => void, 
  inviteCode: string | null, 
) {
  return {
    callbacks: {
      signInSuccessWithAuthResult: function(authResult: firebase.auth.UserCredential, redirectUrl: string) {

        setErrorMessage('');
        setLoading(true);
        const currentUser = firebase.auth().currentUser as firebase.User;

        currentUser.getIdToken(true)
          .then((token: string) => {
            return api
              .authy
              .getAuthy();
          })
          .then((user: Types.User | undefined) => {

	      let logEmail = '';
              if(authResult.additionalUserInfo)
	      {
	        const profile = authResult.additionalUserInfo.profile as any;
		if (profile && profile != 'undefined')
		{
			if(profile.hasOwnProperty('email'))
			  logEmail = profile.email;		   
		}
	      }	

            if(user) {
	      
	      if(logEmail != '' && logEmail != user.email){
		/*window.location.href = '/login';*/
		restart();
                   //currentUser.delete();
                setErrorMessage("That email address doesn't match an existing account.");
                setLoading(false);
		api
		    .authy
		    .signOut()
		    .then(() => {
		      return firebase
			.auth()
			.signOut()
			  .catch(err => {
			    console.log(err);
			  });
		    })
	      }
	      else if(user.deleted)
	      {
		window.location.href = '/account-deleted';
	      }
	      else if(user.pause)
	      {
		window.location.href = '/account-paused';
	      }
              else if(user.authyId) {
                setEmail(user.email);
                setIs2fa(true);
              } else {
                api
                  .logs
                  .createLog({
                    text: `${user.name} logged in with Google`, 
                    resources: { userId: user.id as string}
                  })
                  .then(() => {
                    return api
                      .users
                      .updateMe({
                        ...user,
                        lastLogin: new Date().getTime() / 1000,
                      });
                  })
                  .then(() => {
		    return db
		      .collection('organizations')
		      .where('id', '==', user.organizationId)
		      .where('deleted', '==', false)
		      .get()
		      .then(reference => {
			return reference
			  .docs[0]
			  .data()
		      })   
                  })
		  .then(data => {
		    if( data.name === 'Your Organization Name' ||  data.name.trim() === '')
		    {
		      window.location.href = '/app/onboarding';
		    }
		    else
		    {
		      localStorage.setItem('isLoggedIn', 'Yes');
                      const targetPath = localStorage.getItem('targetPath');
                      if(targetPath) {
                        localStorage.removeItem('targetPath');
                        window.location.href = targetPath;
                      } else {
                        window.location.href = '/app/feed/all';
                      }		    
		    }		    
                  })
                  .catch((err: any) => {
                    console.log(err);
                  })
              }

            } else {
              let userData: Partial<Types.User> = {};
              if(inviteCode) {
                userData.inviteId = inviteCode;
              }

              const provider = currentUser.providerData[0];

              if(provider && provider.providerId === 'password' && currentUser.emailVerified) {
                signupGoogle(authResult, userData)
                  .then(() => {
                    if(inviteCode) {
                      window.location.href = '/app/onboarding/networks';
                    } else {
                      window.location.href = '/app/onboarding';
                    }
                  })
                  .catch((err: any) => {
                    console.log(err);
                    restart();
                    //currentUser.delete();
                    setErrorMessage(err.data.message);
                    setLoading(false);
                  });
              } else if(provider && provider.providerId === 'password' && !currentUser.emailVerified) {
                let url = '';
                if(inviteCode) {
                  url = `https://brandsocial.com/login?inviteCode=${inviteCode}`;
                } else {
                  url = `https://brandsocial.com/login`;
                }
                currentUser.sendEmailVerification({ url: url })
                  .then(x => {
                    setIsVerifyEmail(true);
                    setLoading(false);
                  })
                  .catch(err => {
                    setErrorMessage(err);
                  })
              } else if(provider && provider.providerId !== 'password') {
                signupGoogle(authResult, userData)
                  .then(() => {
                    if(inviteCode) {
                      window.location.href = '/app/onboarding/networks';
                    } else {
                      window.location.href = '/app/onboarding';
                    }
                  })
                  .catch((err: any) => {
                    console.log(err);
                    restart();
                    //currentUser.delete();
		    if(err.data)
			setErrorMessage(err.data.message);
                    setLoading(false);
                  });
              } else {
                setErrorMessage('There has been an error. Please use the contact form at the bottom left of the screen and tell them you received this message. Thank you.');
              }
            }
          })

        return false;
      },
      uiShown: function() {
        setLoading(false);
      }
    },
    signInFlow: 'popup',
    signInSuccessUrl: '/app/feed/all',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      /*
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.TwitterAuthProvider.PROVIDER_ID,
       */
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    /*
    tosUrl: '<your-tos-url>',
    privacyPolicyUrl: '<your-privacy-policy-url>'
     */
  }
};
export const firebaseui = new ui.auth.AuthUI(firebase.auth());

let currentUser: any = null;

export function getFirebaseUser () {
  if(currentUser) {
    return currentUser as firebase.User;
  };
}

export function getGoogleId () {
  if(currentUser) {
    return Promise.resolve(currentUser.uid);
  }

  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .onAuthStateChanged(user => {
        if (user) {
          currentUser = user;
          resolve(user.uid);
        } else {
          currentUser = null;
        }
    });
  })
}

export default firebase;

export function googleAuthRedirect () {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ redirect_uri: 'https://localhost:3000/redirect' });
  firebase
    .auth()
    .signInWithRedirect(provider)
}

export function handleGoogleRedirect (setLoading: (bool: boolean) => void) {
  return new Promise((resolve, reject) => {
    firebase.auth()
      .getRedirectResult()
      .then((googleUserCredential: any) => {
        const email = googleUserCredential.user.email;
        if(googleUserCredential.credential) {
          setLoading(true);
          const credential = firebase
            .auth
            .GoogleAuthProvider
            .credential(googleUserCredential.credential.idToken);
          resolve({ cred: firebase.auth().signInWithCredential(credential), email: email });
        }
      })
      .catch(err => {
        reject(err);
      })
  })
}

export function signupGoogle (firebaseUserCredential: firebase.auth.UserCredential, userData: Partial<Types.User>) {
  const currentUser = firebase.auth().currentUser as firebase.User;
  let user: Types.User;
  if(currentUser) {
    if(currentUser.email) {
      userData = { ...userData, email: currentUser.email };
    } else {
    }
    if(currentUser.displayName) {
      userData = { ...userData, name: currentUser.displayName };
    }
    if(currentUser.photoURL) {
      userData = { ...userData, image: currentUser.photoURL };
    }
    userData.googleId = currentUser.uid;
  }
  return currentUser.getIdToken()
    .then((token: string) => {
      return api
        .users
        .signupGoogle(userData);
    })
    .then((userRec: Types.User): any => {
      user = userRec;
      if(userData.inviteId) {
        return userRec;
      } else {
        return api
          .organizations
          .createOrganization({
            name: 'Your Organization Name',
            deleted: false,
            createdAt: 0,
            updatedAt: 0,
            adminId: user.id,
            status: Consts.OrgStatus.normal,
            postCount: 0,
            trialEnd: 0,
            planId: 'teamYearly'
          })
      }
    })
}

export function loginGoogle (firebaseUserCredential: firebase.auth.UserCredential) {
  const currentUser = firebase.auth().currentUser as firebase.User;

  currentUser.getIdToken(true)
    .then((token: string) => {
      api
        .logs
        .createLog({
          text: `${currentUser.displayName} logged in with Google`, 
          resources: { userId: currentUser.uid as string}
        })
      const targetPath = localStorage.getItem('targetPath');
      localStorage.setItem('isLoggedIn', 'Yes');
      if(targetPath) {
        localStorage.removeItem('targetPath');
        return window.location.href = targetPath;
      } else {
        return window.location.href = '/app/feed/all';
      }
    });
}

export function loginEmailFB (email: string, password: string) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential: firebase.auth.UserCredential) => {
        const currentUser = firebase.auth().currentUser as firebase.User;

        return currentUser.getIdToken(true);
      })
      .then((token: string) => {
        localStorage.setItem('isLoggedIn', 'Yes');
        window.location.href = '/app/feed/all';
      })
      .catch((err: any) => {
        console.log(err);
      })
  })
}

export function getFBIdToken () {
  const currentUser = firebase.auth().currentUser;

  if(currentUser) {
    return currentUser.getIdToken(true)
      .then(token => {
        return token;
      })
  } else {
    return new Promise((res, rej) => rej(null));
  }
}

export function getFBcurrentUser () {
  return firebase.auth().currentUser;
}

export function FBSignOut (): Promise<void> {
  const currentUser = firebase.auth().currentUser as firebase.User;
  localStorage.removeItem('isLoggedIn');
  localStorage.clear();
  sessionStorage.clear();
  return api
    .authy
    .signOut()
    .then(() => {
      return firebase
        .auth()
        .signOut()
          .catch(err => {
            console.log(err);
          });
    })
}

