import axios from './axios';
import { authorizedHeader } from '../helpers';

// import * as Types from 'types';

export function createCustomer (paymentMethodId: string, planId: string): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.post('/payments/customer', { paymentMethodId: paymentMethodId, planId: planId }, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

export function manageStripe (): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.post('/payments/manage-stripe', {}, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

export function checkout (): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.post('/payments/checkout', {}, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

export function updateCustomer (customer: any): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.put('/payments/customer', customer, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

export function attachMethod (paymentMethodId: string): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.post('/payments/method', { paymentMethodId: paymentMethodId }, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

export function getPaymentMethodsForCustomer (): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.get('/payments/method', await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

export function getPayments (): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.get('/payments', await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

export function getPlans (): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.get('/payments/plans', await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

export function getCustomer (): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.get('/payments/customer', await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

export function deletePaymentMethod (paymentMethodId: string): Promise<any> {
  return new Promise(async (resolve, reject) => {
    axios.delete(`/payments/method/${paymentMethodId}`, await authorizedHeader())
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => reject(err))
  });
}

