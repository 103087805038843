import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import firebase from 'firebase/app';
import { FBSignOut, getFirebaseUser } from '../firebase';

import Spinner from './Spinner';

import UserContext from './UserContext';

import api from '../api';

import * as Types from 'types';

interface Props {
}

const VerifyEmailPage: React.FC<Props> = props => {
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);

  const me = useContext(UserContext) as Types.User;

  const uParams = useParams() as { jwt: string };

  const history = useHistory();

  useEffect(() => {

    localStorage.removeItem('isLoggedIn');

    if(uParams.jwt) {
      api
        .users
        .verifyEmail(uParams.jwt)
        .then(() => {
          history.push(`/app/me`)
          setVerified(true);
          setLoading(false);
        })
        .catch((err: any) => {
          console.log(err);
          history.push(`/app/me`)
          setVerified(false);
          setLoading(false);
        })
    }
  }, [uParams.jwt])
  if(!loading && verified) {
    return (
      <div className="verify-email page flex-column">
        Your email has been verified and updated!
      </div>
    );
  } else if (!loading && !verified) {
    return (
      <div className="verify-email page flex-column">
        We have failed to verify the email address you provided. Please try again.
      </div>
    );
  } else {
    return (<div className="verify-email page flex-column">
      <Spinner />
    </div>);
  }
}

export default VerifyEmailPage;
