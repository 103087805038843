import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view'
import { Switch, Route, withRouter, NavLink } from 'react-router-dom';

import api from '../api';

import * as Types from 'types';

import TabBar from './TabBar';
import Button from './Button';



const AdminNetworkProfilePage: React.FC = () => {
  const [exceptions, setExceptions] = useState<Types.Exception[]>([]);
  const [filters, setFilters] = useState({});
  const [profiles, setProfiles] = useState<Types.Profile[]>([]);
  const [loading, setLoading] = useState(false);

  function getNetworkProfiles() {
    setLoading(false);
    api
      .admin
      .getNetworkProfiles(filters)
      .then(profiles => {       
	 setProfiles(profiles);
	 setLoading(true);

      })
  }

function checkEmail(searchEmail:any)
{
	  const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	  if(searchEmail.match(regEx)) {
	    setFilters({ ...filters, email: searchEmail });
	    setLoading(true);
	  } else {
	    setLoading(false);
	  }
	
}

function checkUser(userId: any) {
	//alert('userId : ' + userId);

    if(userId) {
      api
        .admin
        .getUserById(userId)
        .then(user => {

	  if(user && user.id)
	    alert(user.id);
	  else
	    alert('User Not Found');

        })
    }

}

function deleteSingleNetworkProfile(profileId: any, userId: any, organizationId: any)
{

  if(profileId)
  {
     //destroySingleWorkAndProfileRecord('Organization Id', 'Network Profile Id', 'Table', 'Field Name')

      api
        .admin
        .destroySingleWorkAndProfileRecord(organizationId, profileId, 'work', 'profileId')
        .then(() => {
		//alert('Work Records Deleted Successfully');

		console.log('Work Record Deleted Successfully => ' + userId + ' ##' + organizationId);
		return true;
        })
	.then(() => {
	      api
		.admin
		.destroySingleWorkAndProfileRecord(organizationId, profileId, 'profiles', 'id')
		.then(() => {
		  
		  alert('Network Profile Record Deleted Successfully');

		  console.log('Network Profile Records Deleted Successfully => ' + userId + ' ##' + organizationId);
		  return true;
		})
        })
     
  }


}


function deleteNetworkProfile(userId: any, organizationId: any) {

    //alert('userId : ' + userId + ' ## organizationId : ' + organizationId);

    console.log('userId : ' + userId + ' ## organizationId : ' + organizationId);

    if(userId && organizationId) {
      api
        .admin
        .destroyWorkAndProfileRecord(organizationId, userId, 'work')
        .then(() => {
		//alert('Work Records Deleted Successfully');

		console.log('Work Records Deleted Successfully => ' + userId + ' ##' + organizationId);
		return true;
        })
	.then(() => {
	      api
		.admin
		.destroyWorkAndProfileRecord(organizationId, userId, 'profiles')
		.then(() => {
		  //alert('Network Profile Records Deleted Successfully');

		  console.log('Network Profile Records Deleted Successfully => ' + userId + ' ##' + organizationId);
		  return true;
		})
        })

    }

}

function deleteAllNetworksNoUsers(){
	//console.log('Profiles ' + profiles);

	alert('Ok');

	profiles.forEach(function(profile) {
		//console.log('Profile Id : ' + profile.id);

	    if(profile.userId) {
	      api
		.admin
		.getUserById(profile.userId)
		.then(user => {

		  if(user && user.id)
		  {
		    console.log(profile.id + ' => User Id Exists : ' + user.id);
		  }
		  else
		  {
		    console.log('/************************ Start Delete ********************/');

		    console.log(profile.id + ' => User Id Doesnot Exists : ' + profile.userId);

		    deleteNetworkProfile(profile.userId, profile.organizationId);

		    console.log('/************************ End Delete ********************/');

		  }

		})
	    }
	});

} 



  return (
    <div className="users page admin-page flex-column">
      <TabBar
        title="Profiles"
        explainer="Search Profiles"
      >
      </TabBar>

      <div className="input-row flex-row">
        <div className="cellContainer pr-10" ><input placeholder="Name" style={{width: '300px'}} /></div>
        <div className="cellContainer ">
		<Button color="light-orange" onConfirm={getNetworkProfiles}>Search</Button> 
		<Button color="light-orange" onConfirm={deleteAllNetworksNoUsers}>Delete All Networks With No Users</Button>
	</div>
      </div>

      <div className="user-list">
        <div className="title flex-row">Results: {profiles.length}</div>
        <table>
          <thead>
            <tr>
              <th >Id</th>
              <th >Name</th>
	      <th >Network</th>
	      {/*<th >Organization</th>
	      <th >Site Id</th>*/}
	      <th >User Id</th>
	      <th >Created</th>

              <th>Deleted</th>
            </tr>
          </thead>
          <tbody>
            {profiles.map(profile => {
              return <tr key={profile.id}>
                <td ><div className="user-name text-wrap pr-10">{profile.id}</div></td>
		<td ><div className="user-name text-wrap pr-10">{profile.name}</div></td>
		{/*<td ><div className="user-name text-wrap pr-10">{profile.networkId}</div></td>
		<td ><div className="user-name text-wrap pr-10">{profile.organizationId}</div></td>*/}
		<td ><div className="user-name text-wrap pr-10">{profile.siteId}</div></td>
		<td ><div className="user-name text-wrap pr-10">{profile.userId}</div></td>
                <td className="medium">{new Date(profile.createdAt * 1000).toLocaleString()}</td>
		<td > 
			<Button color="light-orange" onConfirm={() => checkUser(profile.userId)} >Check&nbsp;User </Button>&nbsp;<Button color="light-orange" onConfirm={() => deleteSingleNetworkProfile(profile.id, profile.userId, profile.organizationId)} >Delete&nbsp;Profile</Button>
		</td>

              </tr>
            })
            }
	    {profiles.length <= 0 && 
		<tr><td colSpan={5} className="text-align-center pt30">No Records Found</td></tr>}	    
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminNetworkProfilePage;
